import styles from '../../../styles.module.scss'
import { FC } from 'react'
import InputField from '@/components/Form/Fields/InputField/InputField'
import PasswordRules from '@/components/Form/PasswordRules/PasswordRules'
import { useFormContext } from 'react-hook-form'
import { COUNTRY_PHONE_CODES } from '@/constants/countryCodes'
import SelectComp from '@/components/Form/Fields/Select/Select'

const GoogleSignInCognito: React.FC = () => {
  // Construct the Cognito Hosted UI URL
  const clientId = import.meta.env.VITE_COGNITO_CLIENT_ID
  const redirectUri = encodeURIComponent('http://localhost:3000')
  const cognitoDomain = import.meta.env.VITE_COGNITO_DOMAIN
  const loginUri = `https://${cognitoDomain}/oauth2/authorize?identity_provider=Google&redirect_uri=${redirectUri}&response_type=TOKEN&client_id=${clientId}&scope=email+openid+profile+aws.cognito.signin.user.admin`

  return <a href={loginUri}>Continue with Google</a>
}

const GoogleSignIn: React.FC = () => {
  const googleClientId: string = import.meta.env.VITE_GOOGLE_CLIENT_ID || ''

  const scope = encodeURIComponent('email profile openid')
  const redirectUri = encodeURIComponent('http://localhost:3000/creator/setup')
  const clientId = encodeURIComponent(googleClientId)
  const responseType = encodeURIComponent('code')

  const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&access_type=online&prompt=consent`

  return <a href={authUrl}>Sign in with Google1111</a>
}

const SignUpForm: FC = () => {
  const { getValues } = useFormContext()

  return (
    <div className={styles.signUpForm}>
      <GoogleSignInCognito />
      <div className={styles.signUpFormRow}>
        <InputField
          colW={6}
          label="First Name"
          name="firstName"
          type="text"
          required
        />
        <InputField
          colW={6}
          label="Last Name"
          name="lastName"
          type="text"
          required
        />
      </div>
      <div className={styles.signUpFormRow}>
        <InputField
          colW={12}
          label="Email Address"
          name="email"
          type="text"
          required
        />
      </div>
      <div className={styles.signUpFormRow}>
        <SelectComp
          colW={2}
          name="countryCode"
          optionData={COUNTRY_PHONE_CODES}
          required
        />
        <InputField
          colW={11}
          label="Mobile Number"
          name="phone"
          type="text"
          required
        />
      </div>
      <div className={styles.signUpFormRow}>
        <InputField
          colW={12}
          label="Password"
          name="password"
          type="password"
          required
        />
      </div>
      {getValues('password') && (
        <div className={styles.signUpFormRow}>
          <div className={styles.passwordRulesWrapper}>
            <PasswordRules
              value={getValues('password')}
              open={getValues('password')}
            />
          </div>
        </div>
      )}
      <div className={styles.signUpFormRow}>
        <InputField
          colW={12}
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          required
        />
      </div>
      <span className={styles.requiredWrapper}>
        <span className="required-asterix">*</span>Required
      </span>
    </div>
  )
}

export default SignUpForm
