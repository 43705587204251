import { useState, FC, useEffect } from 'react'
import { peopleFAQ, brandFAQ } from '@/constants/faqs'
import Section from '../../Section/Section'
import NewTitle from '../../Title/Title'
import styles from './styles.module.scss'
import FAQCard from './FAQItem/FAQItem'
import { useStaticPageContext } from '@/context/StaticPageContext'
import { SiteWideTypes } from '@/types/SiteContext.interface'

const FAQ: FC = () => {
  const { siteType } = useStaticPageContext() as SiteWideTypes
  const faqData = siteType === 'people' ? peopleFAQ : brandFAQ
  const [selected, setSelected] = useState(-1)
  const [questionsExpandedItems, setQuestionsExpandedItems] = useState(faqData)
  const headingSpanClass =
    siteType === 'people' ? 'vs-primary-color' : 'vs-primary-color'
  const open = (index: number) => {
    const newList = [...questionsExpandedItems]
    newList[index].isExpanded = !newList[index].isExpanded
    setQuestionsExpandedItems(newList)
    setSelected(index)
  }

  useEffect(() => {
    setQuestionsExpandedItems(faqData)
  }, [faqData, siteType])

  const subText = (
    <div className="breakTitle">
      <p>
        <span data-line="If these don’t answer your questions,">
          If these don’t answer your questions,
        </span>
        <span data-line="hit us up on our chat in the bottom right.">
          hit us up on our chat
        </span>
      </p>
    </div>
  )

  return (
    <Section className={styles.sectionFAQdParent}>
      <div className={styles.faqContent}>
        <span className={styles.titleWrapper}>
          <NewTitle
            children={
              <span>
                Have <span className={headingSpanClass}>Questions?</span>
              </span>
            }
            subText={subText}
            titleColor="#000"
            secondaryTitle
          />
        </span>
        <div className={styles.homeFaqListWrapper}>
          {questionsExpandedItems.map((question, index) => (
            <FAQCard
              key={index}
              question={question}
              index={index}
              open={open}
              selected={selected}
            />
          ))}
        </div>
      </div>
    </Section>
  )
}

export default FAQ
