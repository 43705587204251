import { FC, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/system/Box'
import CircularProgress from '@mui/material/CircularProgress'
import TopBar from '@/shared/components/TopBar/TopBar.tsx'
import Container from '@mui/system/Container'
import BackButton from '@/shared/components/BackButton/BackButton.tsx'
import StepHeader from '@/components/OnboardingCompNew/StepHeader/StepHeader.tsx'
import styles from '../../styles.module.scss'
import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext.tsx'
import { OnBaordingProps } from '@/components/OnboardingComp/types.ts'
import { updateFormState } from '@/utils/formHelpers.ts'
import WhatIsThisModal from '@/shared/components/InfoModal/InfoModal.tsx'
import useCategories from '@/hooks/useCategories.ts'
import Categories from '@/shared/components/Categories/Categories.tsx'
import FinalButton from '@/videoshops-shared/components/FinalButton/FinalButton.tsx'
import useOnboarding from '../../useOnboarding'
import { OnboardingStep } from '@/constants/onboarding.constant'

const Affinities: FC = () => {
  const { categories, loading } = useCategories()
  const { saveOnboardingData } = useOnboarding()

  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const { formData, setFormData } = useContext(OnboardingContext) as OnBaordingProps
  const initValues = formData.marketplace.affinities || []
  const [selected, setSelected] = useState<string[]>(initValues)

  const onSubmit = async () => {
    const saved = await saveOnboardingData(
      OnboardingStep.AFFINITIES,
      localStorage.getItem('TOKEN') || ''
    )
    if (saved) {
      const saved = await saveOnboardingData(
        OnboardingStep.AFFINITIES,
        localStorage.getItem('TOKEN') || ''
      )
      if (saved) {
        navigate('/brand/setup/commission')
      }
    }
  }

  useEffect(() => {
    updateFormState(setFormData, 'affinities', selected)
  }, [selected])

  return (
    <div className={styles.stepPage}>
      <TopBar />
      <Box mt={{ xs: 1.5, md: 3.5 }}>
        <Container maxWidth="lg">
          <BackButton to="/brand/setup/brand-hero-image" />
        </Container>
        <Container maxWidth={false} sx={{ maxWidth: 416 }}>
          <Box mt={3}>
            <StepHeader title="Merchant Sign Up">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                  Choose Your Categories
                  <Box ml={1} sx={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
                    <Box component="img" mr={0.5} src="/icons/question-mark.svg" />
                  </Box>
                  <WhatIsThisModal
                    open={open}
                    title="Your Categories"
                    description="When you set categories for your brand, we’ll make personalized recommendations to sellers and shoppers to help them find your brand and products."
                    onClose={() => setOpen(false)}
                  />
                </Box>
              </Box>
              <Box fontSize={10} fontWeight={400}>
                Choose Up to 5
              </Box>
            </StepHeader>
          </Box>
          <Box mt={3}>
            {loading ? (
              <Box textAlign="center" m={4}>
                <CircularProgress />
              </Box>
            ) : (
              <Categories limit={5} list={categories} selected={selected} onChange={setSelected} />
            )}
            <Box mt={4}>
              <FinalButton
                disabled={selected.length === 0}
                variant="primary"
                full
                onClick={onSubmit}
              >
                Next
              </FinalButton>
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  )
}

export default Affinities
