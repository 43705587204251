import * as Yup from 'yup'

const ContactUsFormValidation = Yup.object().shape({
  firstName: Yup.string().trim().required('First Name is required'),
  lastName: Yup.string().trim().required('Last Name is required'),
  email: Yup.string()
    .trim()
    .required('Email is required')
    .matches(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
      'Your Email does not seem to be a valid email.'
    ),
  phone: Yup.string()
    .trim()
    .matches(/^-?(0|[1-9]\d*)$/, 'Invalid phone number format')
    .required('Phone number is required'),
  message: Yup.string().trim().required('Message is required')
})

export default ContactUsFormValidation
