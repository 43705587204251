import { FC, useEffect, useState } from 'react'
import { OnboardingContext } from './OnboardingContext'
import { VendorObject } from '@/components/OnboardingComp/OnboardingData/vendor'
import { CreatorObject } from '@/components/OnboardingComp/OnboardingData/creator'
import { UserObject } from '@/components/OnboardingComp/OnboardingData/user'
import { deepMerge } from '@/utils/helpers'

const stepsData: StepsData = {
  1: ['name'],
  2: ['profileImage'],
  3: ['backgroundImage'],
  4: ['description'],
  5: ['key'],
  6: ['email', 'password', 'confirmPassword', 'phone', 'firstName', 'lastName', 'terms']
}

const stepsVendorData: StepsData = {
  1: [
    'email',
    'password',
    'confirmPassword',
    'phone',
    'firstName',
    'lastName',
    'brandName',
    'brandWebsite',
    'jobTitle',
    'terms'
  ],
  2: ['sms-validation', 'terms'],
  3: ['name'],
  4: ['profileImage'],
  5: ['backgroundImage'],
  6: ['thumbnailImage'],
  7: ['description'],
  8: ['affinities'],
  9: ['commissionRate', 'currency'],
  10: ['key']
}

export const OnboardingProvider: FC<OnboardingProviderProps> = ({ children, siteType }) => {
  const [currentStep, setCurrentStep] = useState(() => {
    const savedStep = localStorage.getItem('currentStep')
    return savedStep ? JSON.parse(savedStep) : 1
  })
  const [formData, setFormData] = useState(() => {
    const defaultData = siteType === 'creator' ? CreatorObject : VendorObject
    const savedData = localStorage.getItem('formData')

    if (savedData) {
      const savedDataObj = JSON.parse(savedData).data
      return deepMerge(defaultData, savedDataObj)
    } else {
      return defaultData
    }
  })
  const activeStepsData =
    siteType === 'creator' ? stepsData[currentStep] : stepsVendorData[currentStep]
  const [stepInputs, setStepInputs] = useState<InputKeys[]>(activeStepsData)
  const [getUserData, setUserData] = useState(UserObject)
  const [onBoardingTitleBranding, setOnBoardingTitleBranding] = useState('')
  const [onboardActions, setOnboardActions] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    localStorage.setItem('currentStep', JSON.stringify(currentStep))
    const dataToStore = { ...formData }
    if (dataToStore.user) {
      delete dataToStore.user.password
    }
    const expirationTimestamp = new Date().getTime() + 10 * 60 * 1000
    const dataWithExpiration = {
      data: dataToStore,
      expiresAt: expirationTimestamp
    }
    localStorage.setItem('formData', JSON.stringify(dataWithExpiration))
    setStepInputs(activeStepsData)

    if (window.location.pathname.includes('vendor')) {
      setOnBoardingTitleBranding('Brand')
    } else if (window.location.pathname.includes('creator')) {
      setOnBoardingTitleBranding('VideoShop')
    }
  }, [currentStep, formData, activeStepsData])

  return (
    <OnboardingContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        formData,
        setFormData,
        stepInputs,
        siteType,
        getUserData,
        onBoardingTitleBranding,
        setUserData,
        onboardActions,
        setOnboardActions,
        isLoading,
        setIsLoading
      }}
    >
      {children}
    </OnboardingContext.Provider>
  )
}
