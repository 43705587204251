import { FC } from 'react'
import Box from '@mui/system/Box'
import Category from './Category.tsx'

type categoryType = {
  id: string
  displayValue: string
}

type changeType = (value: string[]) => string[]

interface CategoriesProps {
  limit: number
  list: categoryType[]
  selected: string[]
  onChange: (value: changeType) => void
}

const Categories: FC<CategoriesProps> = ({
  limit,
  list,
  selected,
  onChange
}) => {
  const handleSelect = (value: string) => {
    onChange((prev: string[]) => {
      if (prev.includes(value)) {
        return prev.filter((item) => item !== value)
      }

      return [...prev, value]
    })
  }

  return (
    <Box m={-1} display="flex" justifyContent="center" flexWrap="wrap">
      {list.map(({ id, displayValue }, index) => (
        <Category
          key={index}
          value={id}
          label={displayValue}
          disabled={selected.length === limit && !selected.includes(id)}
          selected={selected.includes(id)}
          onClick={handleSelect}
        />
      ))}
    </Box>
  )
}

export default Categories
