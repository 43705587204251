import Container from '@mui/system/Container'
import Box from '@mui/system/Box'
import TopBar from '@/shared/components/TopBar/TopBar.tsx'
import { OnboardingProvider } from '@/context/OnboardingContext/OnboardingProvider'
import { APP_ROUTES } from '@/routes/routes'
import BackButton from '@/shared/components/BackButton/BackButton.tsx'
import { Typography, useMediaQuery } from '@mui/material'
import FinalButton from '@/videoshops-shared/components/FinalButton/FinalButton.tsx'

const moveToCreateSellerAccount = () => {
  localStorage.removeItem('formData')
  localStorage.removeItem('currentStep')
  localStorage.removeItem('TOKEN')
  window.location.href = APP_ROUTES.CREATOR_SETUP
}

const moveToCreateBrandAccount = () => {
  localStorage.removeItem('formData')
  localStorage.removeItem('currentStep')
  localStorage.removeItem('TOKEN')
  window.location.href = APP_ROUTES.BRAND_SETUP
}

function SelectAccount() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return (
    <OnboardingProvider siteType="vendor">
      <TopBar />
      <Container maxWidth="lg">
        <Box mt={{ xs: '12px', md: '14px' }}>
          <BackButton to="/" />
        </Box>
      </Container>
      <Container maxWidth={false} sx={{ maxWidth: 411 }}>
        <Box mt="22px">
          <Typography variant="xl_bold">Seller Sign Up</Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="xs_med" color="ash">
            Launch your own VideoShops Storefront and sell your favorite
            products from thousands of brands.
          </Typography>
        </Box>
        <Box mt={3} mx={{ xs: '30px', md: 0 }}>
          <FinalButton
            full
            variant="primary"
            size={isMobile ? 'md' : 'lg'}
            onClick={moveToCreateSellerAccount}
          >
            Seller Sign Up
          </FinalButton>
        </Box>
        <Box mt="48px">
          <Typography variant="xl_bold">Brand Sign Up</Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="xs_med" color="ash">
            Upload your products to our Brand Directory, so sellers can share
            and sell your products.
          </Typography>
        </Box>
        <Box mt={3} mx={{ xs: '30px', md: 0 }}>
          <FinalButton
            full
            size={isMobile ? 'md' : 'lg'}
            onClick={moveToCreateBrandAccount}
          >
            Brand Sign Up
          </FinalButton>
        </Box>
      </Container>
    </OnboardingProvider>
  )
}

export default SelectAccount
