import styled from 'styled-components'

interface ButtonStyledProps {
  width?: string;
}

export const ButtonStyled = styled.div<ButtonStyledProps>`
  width: ${({ width }: ButtonStyledProps) => width || 'fit-content'};
  cursor: pointer;
  max-width: 100%;
  padding: 0 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  font-weight: 600;
  white-space: nowrap;
  transition:
    outline 0.5s ease,
    background-color 0.5s ease,
    border 0.5s ease,
    color 0.5s ease,
    box-shadow 0.5s ease;

  height: 38px;
  background-color: var(--royal-blue);
  border: 3px solid var(--royal-blue);
  color: var(--white);

  &:focus:not(.disabled) {
    border: 3px solid var(--iris);
  }

  &:hover:not(.disabled) {
    background-color: var(--dark-blue);
    border: 3px solid var(--dark-blue);
  }

  &:active:not(.disabled) {
    background-color: var(--lavender);
    border: 3px solid var(--lavender);
  }

  &.disabled {
    background-color: var(--dirty-seagull);
    border: 3px solid var(--dirty-seagull);
    color: var(--dolphin);
    cursor: default;
  }

  &.suffix {
    border-radius: 0 6px 6px 0;
    height: 48px;
  }

  &.secondary {
    background-color: var(--white);
    color: var(--battleship);
    border: 3px solid var(--white);
    box-shadow:
      0 0 8px rgba(0, 0, 0, 0.1),
      0 0 1px rgba(0, 0, 0, 0.1);

    &:focus:not(.disabled) {
      background-color: var(--white);
      border: 3px solid var(--royal-blue);
      outline: none;
      box-shadow: none;
    }

    &:hover:not(.disabled) {
      background-color: var(--royal-blue);
      color: var(--white);
      border: 3px solid var(--royal-blue);
      box-shadow: none;
    }

    &:active:not(.disabled) {
      background-color: var(--marshmallow);
      color: var(--battleship);
      border: 3px solid var(--marshmallow);
      box-shadow: none;
    }

    &.disabled {
      background-color: var(--marshmallow);
      color: var(--gandalf);
      border: 3px solid var(--marshmallow);
    }

    &.grey {
      border-radius: 1.875rem;
      background: var(--greyscale-13-dirty-seagull, #E0E4E9);
      display: flex;
      height: 2.875rem;
      padding: 1.5rem 1.25rem;
      justify-content: center;
      align-items: center;
      gap: 1.5rem; 
      border: 2px solid var(--greyscale-13-dirty-seagull, #E0E4E9);
      color: var(--greyscale-08-dolphin, #6E7780);
      text-align: center;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1rem;
    }

    &.dark {
      background-color: var(--midnight-80);
      border: 2px solid var(--midnight-80);
      outline: 1px solid var(--dolphin);
      color: var(--bob);
      box-shadow: none;
      -webkit-background-clip: padding-box;
      -moz-background-clip: padding;
      background-clip: padding-box;

      &:focus:not(.disabled) {
        background-color: var(--midnight-80);
        border: 3px solid var(--violet);
        color: var(--bob);
        outline: none;
        box-shadow: none;
        -webkit-background-clip: unset;
        -moz-background-clip: unset;
        background-clip: unset;
      }

      &:hover:not(.disabled) {
        background-color: var(--royal-blue);
        color: var(--white);
        border: 2px solid var(--royal-blue);
        outline: 1px solid var(--dolphin);
        box-shadow: none;
        -webkit-background-clip: unset;
        -moz-background-clip: unset;
        background-clip: unset;
      }

      &:active:not(.disabled) {
        background-color: var(--royal-blue-dark);
        color: var(--bob);
        border: 2px solid var(--royal-blue-dark);
        outline: 1px solid var(--dolphin);
        box-shadow: none;
        -webkit-background-clip: unset;
        -moz-background-clip: unset;
        background-clip: unset;
      }

      &.disabled {
        background-color: var(--midnight-80);
        color: var(--bob);
        border: 2px solid var(--midnight-80);
        outline: 1px solid var(--batcave);
        -webkit-background-clip: unset;
        -moz-background-clip: unset;
        background-clip: unset;
      }
    }
  }

  &.small {
    height: 38px;
    min-height: 38px;
  }

  &.medium {
    height: 46px;
    min-height: 46px;
  }

  &.large {
    height: 56px;
    min-height: 56px;
  }

  &.circle {
    padding: 0;
    height: 46px;
    width: 46px;
    border-radius: 50%;
    display: flex;
  }
`
