export const peopleFAQ = [
  {
    isExpanded: false,
    title: 'HOW DO I GET PAID ON SALES?',
    content:
      'It’s really easy!  You choose a digital wallet or bank account;  Simply link to your Venmo, Zelle or PayPal or bank account to get paid your sales commissions.  Security is our top priority!  VideoShops uses Stripe Connect as a secure payment processor to manage all transactions and your sales commissions, so you are freed up to make serious money!'
  },
  {
    isExpanded: false,
    title: 'HOW DO I SHARE MY VIDEOSHOP?',
    content:
      'To start making money, you need to start sharing your VideoShop with all your friends and followers.Your VideoShop updates all products in real time and is powered by your unique VideoShops link and/or QR code. Simple copy your VideoShops link and share it by SMS text or add it to your video story or “link in bio” on all of your social accounts. - It’s super simple.'
  },
  {
    isExpanded: false,
    title: 'HOW DO I FIND and ADD PRODUCTS TO MY VIDEOSHOP?',
    content:
      'Great question!  We made is really easy for you to get paid selling products you love. \n VideoShops Brand Directory gives registered sellers exclusive access to millions of products and thousands of brand direct stores with full product catalogs that pay premium commissions on sales. and do not require any brand approvals to get paid. Simply Search by brand and/or product, when you find products you want to shared and sell in your VideoShop, add the products to one or more of your custom shelves.  (The sales commission is featured on every product page:)'
  },
  {
    isExpanded: false,
    title: 'DOES IT COST ANYTHING TO JOIN VIDEOSHOPS?',
    content:
      'It’s FREE! Absolutely no upfront fees, hidden costs, overhead costs, membership fees… zero, zip, zilch. Just signup and start selling in minutes.'
  },
  {
    isExpanded: false,
    title: 'CAN ANYONE BECOME A SELLER?',
    content:
      'Another Great question!  YES, anyone can sign up for a VideoShop and start selling in minutes.'
  },
  {
    isExpanded: false,
    title: 'WHERE CAN I SELL?',
    content:
      'Anywhere!~. Simple copy and paste your VideoShops link and share it on any messaging app or social accounts.'
  }
]

export const brandFAQ = [
  {
    isExpanded: false,
    title: 'DOES VIDEOSHOPS OPERATE WITH EVERY eCOMMERCE PLATFORM?',
    content:
      'YES! VideoShops, supports every major eCommerce platform (Shopify, Magento, Big Commerce, WOO, SalesForce and 40+ leading platforms). Any brand being operated on any eCommerce platform can connect their full product catalog and storefront into VideoShops Brand Directory.'
  },
  {
    isExpanded: false,
    title: 'HOW DO SELLERS DISCOVER & ADD PRODUCTS TO THEIR VIDEOSHOPS?',
    content:
      'It’s fast and easy!  The Brand Directory enables registered sellers to access millions of products and thousands of “In-Network Brand Direct Stores with full product catalogs.” Sellers simply search by brand and/or product and seamlessly add products and videos to custom shelves.  Commission, details, images and videos are featured on every product page.'
  },
  {
    isExpanded: false,
    title: 'HOW DO SELLERS and VIDEOSHOPS GET PAID?',
    content:
      'Brands select the “Seller Commission” which is between 10-50% of the gross sale and can be changed every 30 days in your settings.  Sellers choose how they want to be paid.  They can get paid out through their digital wallet; Venmo, Zelle or PayPal or they can link to their bank account.  Stripe Connect is VideoShops payment processor and securely connects the banking institution, or digital wallet of the seller’s choice. VideoShops takes 10% Platform fee off the top of every transaction.'
  },
  {
    isExpanded: false,
    title: 'WHAT IS VIDEOSHOPS RETURN POLICY?',
    content:
      'VideoShops has a 15 Day Return Policy. If a product is returned within the 15 day window, all fees and commissions are returned to the brand to refund the customer.'
  },
  {
    isExpanded: false,
    title: 'HOW DO SELLERS GET ENGAGED?',
    content:
      'Great question!  One great way to get the word out is to “Sign up as a Seller”  add your brand’s products to your own VideoShop and get your friends and followers to sign up.. As a brand, you want to turn your friends, customers and followers into shoppers and sellers.'
  },
  {
    isExpanded: false,
    title: 'DOES IT COST ANYTHING TO JOIN VIDEOSHOPS?',
    content:
      'It’s FREE! Absolutely no upfront fees, hidden costs, overhead costs, membership fees… zero, zip, zilch. Just signup and start selling in minutes.'
  },
  {
    isExpanded: false,
    title: 'CAN ANYONE BECOME A SELLER?',
    content:
      'Another Great question!  YES, anyone can sign up for a VideoShop and start selling in minutes. As a brand, you are now able to turn on social selling and build your own global sales force.'
  }
]
