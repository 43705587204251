import React, { useContext, useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import classNames from 'classnames'
import styles from '../../styles.module.scss'
import { useFormContext } from 'react-hook-form'
import { getValueFromNestedObject, updateFormState } from '@/utils/formHelpers'
import { useOnboardingContext } from '@/hooks/useOnboardingForm'
import { profanityErrorStr } from '@/constants/profantiy'
import { OnBaordingProps } from '@/components/OnboardingComp/types'
import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext'
import { checkForProfanity } from '@/utils/moderation.utils'

const TextArea: React.FC<FormElementProps> = ({
  label,
  colW,
  name,
  placeholder,
  className,
  required,
  helpText
}) => {
  const { formData, setFormData, siteType } = useOnboardingContext()
  const [focused, setFocused] = useState(false)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [userType, setUserType] = useState<string>('')
  const MAX_CHARS = 150
  const { trigger, formState, setValue, register, getValues, setError } =
    useFormContext()
  const { errors } = formState
  const error = errors[name]
  const fieldError = error as FieldErrorProps
  const { setIsLoading } = useContext(OnboardingContext) as OnBaordingProps
  const value = getValues(name)

  useEffect(() => {
    setValue(name, placeholder)
    const formValues = getValues()
    updateFormState(setFormData, name, placeholder, formValues)
  }, [])

  useEffect(() => {
    setUserType(siteType)
    setIsLoading(false)
    const retrievedValue = getValueFromNestedObject(formData, name)
    if (retrievedValue !== null) {
      setValue(name, retrievedValue)
      setFocused(true)
    } else {
      setFocused(false)
    }
  }, [name, formData, setValue])

  const handleBlur = () => {
    if (textAreaRef.current?.value) {
      return
    }
    setFocused(false)
  }

  const onClick = () => {
    setFocused(true)
    textAreaRef.current?.focus()
  }

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let currentValue = e.currentTarget.value.trim()
    if (currentValue.length > MAX_CHARS) {
      currentValue = currentValue.substring(0, MAX_CHARS)
      e.currentTarget.value = currentValue
    }
    if (checkForProfanity(currentValue)) {
      setIsLoading(true)
      setError(name, { type: 'manual', message: profanityErrorStr })
    } else {
      setIsLoading(false)
      setValue(name, currentValue === '' ? null : currentValue) // Optionally trigger validation here
      trigger(name)
      const formValues = getValues()
      updateFormState(setFormData, name, currentValue, formValues)
    }
  }

  return (
    <>
      <div
        className={classNames(
          `col-${colW}`,
          styles.formGroup,
          styles.textAreaWrapper,
          className,
          focused && styles.focusedWrapper
        )}
        onClick={onClick}
      >
        <Box position="relative">
          <label>
            {label}
            {required && <span className="required-asterix">*</span>}
          </label>
          <textarea
            {...register(name)}
            ref={textAreaRef}
            className={classNames(
              `form-control`,
              errors[name] && styles.invalid,
              styles.textAreaContainer
            )}
            name={name}
            minLength={0}
            maxLength={MAX_CHARS}
            defaultValue={placeholder}
            onChange={onChange}
            onFocus={() => setFocused(true)}
            onBlur={handleBlur}
            placeholder={placeholder}
          />
          <div className={styles.charCountIndicator}>
            {`${value?.length || 0}/${MAX_CHARS}`}
          </div>
        </Box>
        <div className={styles.fieldErrors}>
          {helpText && <p>{helpText}</p>}
          {fieldError?.message && <p>{fieldError.message}</p>}
        </div>
      </div>
    </>
  )
}

export default TextArea
