export const generateRandomSequence = () => {
  const sequence: string[] = []
  const pattern = ['p', 'l', 'p', 'l', 'l', 'p', 'l', 'p']
  const availableNumbers = Array.from({ length: 16 }, (_, i) => i + 1).sort(
    () => Math.random() - 0.5
  )

  pattern.forEach((type) => {
    const number = availableNumbers.pop()
    if (number) {
      sequence.push(`${number}-${type}`)
    }
  })
  return sequence
}

export const generateGridSequences = (numSequences: number) => {
  const gridSequences = []
  for (let i = 0; i < numSequences; i++) {
    gridSequences.push(generateRandomSequence())
  }
  return gridSequences
}

export const selectRandomImages = (numSequences: number) => {
  const extendedGridSequences = generateGridSequences(numSequences)
  const randomSequence =
    extendedGridSequences[
      Math.floor(Math.random() * extendedGridSequences.length)
    ]
  return randomSequence.map((suffix) => `grid-image${suffix}.png`)
}
