import styles from './styles.module.scss'
import NewTitle from '@/components/Title/Title'
import { ReactComponent as MobileBack } from '@/assets/home/mobile-back.svg'
import { ReactComponent as BreadCrumbArrow } from '@/assets/home/breadcrumb-arrow.svg'
import Footer from '@/components/Footer/Footer'
import TopBar from '@/components/SubPagesComp/TopBar/TopBar'
import classNames from 'classnames'

function TermsOfUsePage() {
  const isMobile = window.innerWidth < 768
  const title = (
    <div className="breakTitle">
      <span data-line="VideoShops">
        Terms of<span className="vs-primary-color"> Use </span>
      </span>
    </div>
  )
  return (
    <>
      <TopBar />
      <div className={styles.subPageWrapper}>
        <div className={styles.aboutUsTopWrapper}>
          {isMobile ? (
            <a href="/">
              {' '}
              <MobileBack />
            </a>
          ) : (
            <div className={styles.breadcrumbWrapper}>
              <a href="/">Home</a> <BreadCrumbArrow /> Terms of Use
            </div>
          )}
        </div>
        <div className={styles.clickHereWrapper}>
          <div>
            <p>
              To view our Cookie Policy, click{' '}
              <a href="http://videoshops.com/cookie-policy" target="_blank">
                here
              </a>
              .
            </p>
          </div>
          <div>
            <p>
              To view our Seller Terms of Use, click{' '}
              <a href="http://videoshops.com/seller-agreement" target="_blank">
                here
              </a>
              .
            </p>
          </div>
        </div>

        <NewTitle children={title} titleColor="#000" />
        <div
          className={classNames(styles.subPageContent, styles.fullWidthContent)}
        >
          <>
            <h1>General Terms of Use</h1>
            <p>
              BY ACCEPTING THESE TERMS OF USE (THE “
              <u>
                <b>TERMS</b>
              </u>
              ”), USING THE SERVICES, OR COMPLETING A TRANSACTION THROUGH THE
              SERVICES, YOU AGREE TO BE BOUND BY AND ACCEPT THESE TERMS. IF YOU
              DO NOT AGREE TO ALL OF THESE TERMS, YOU MAY NOT USE OR ACCESS THE
              SERVICES IN ANY MANNER.
            </p>
            <p>Effective Date: April 13, 2024</p>
            <p>
              Welcome to VideoShops. Please read through these Terms that govern
              your use of our products, website(s), mobile application(s), and
              any other products, features, tools (the “
              <u>
                <b>Platform</b>
              </u>
              ”), and/or services offered by VideoShops or its affiliated
              companies (collectively, the “
              <u>
                <b>Services</b>
              </u>
              ”). If you have any questions, comments, or concerns regarding
              these Terms or the Services, please contact us at&nbsp;
              <a href="mailto:hello@videoshops.com" className="s2" target="_blank">
                hello@videoshops.com.
              </a>
            </p>
            <p>
              These Terms are a binding contract between you and Nowwith
              Ventures, Inc. doing business as “VideoShops” (“
              <u>
                <b>VideoShops</b>
              </u>
              ,” “
              <u>
                <b>we</b>
              </u>
              ” and “
              <u>
                <b>us</b>
              </u>
              ”). The words “you” and “your” refer to the users of the Services,
              and to any successor in interest of any such user. You must agree
              to and accept all of the Terms, or you don’t have the right to use
              the Services. Your use of the Services in any way means that you
              agree to all of these Terms, and these Terms will remain in effect
              while you use the Services.
            </p>
            <p className="s3">
              NOTICE: Please read these Terms carefully. They cover important
              information about the Services provided to you. These Terms
              include the provisions in this document, as well as those in our{' '}
              <a
                href="https://videoshops.com/privacy-policy"
                className="s2"
                target="_blank"
              >
                Privacy Policy
              </a>
              <span style={{ color: '#000' }}>
                &nbsp;(“Privacy Policy”)&nbsp;and&nbsp;
              </span>
              <a
                href="http://videoshops.com/cookie-policy"
                className="s2"
                target="_blank"
              >
                Cookie Policy
              </a>
              <span style={{ color: '#000' }}>
                &nbsp;which govern your use of the Services.
              </span>
            </p>
            <h1>Will these Terms ever change?</h1>
            <p>
              These Terms may change as we are constantly trying to improve our
              Services. We reserve the right to make changes to the Terms at any
              time, and if we do, we will bring it to your attention by placing
              a notice on the VideoShops’ Platform, and/or by some other means.
              If you don’t agree with the new Terms, you are free to reject
              them; unfortunately, that means you will no longer be able to use
              the Services. If you use the Services in any way after a change to
              the Terms is effective, you agree to all of the changes. Except
              for changes by us as described here, no other amendment or
              modification of these Terms will be effective unless in writing
              and signed by both you and us.
            </p>
            <h1>
              What is VideoShops and which services does VideoShops provide to
              me?
            </h1>
            <p>
              VideoShops allows merchants (“
              <u>
                <b>Merchants</b>
              </u>
              ”) to list and sell their products on VideoShops Platform.
              VideoShops offers creators, influencers and individuals, the
              opportunity to build, maintain and manage a digital storefront to
              promote and sell products from participating Merchants (“
              <u>
                <b>Seller’s VideoShop</b>
              </u>
              ”).VideoShops helps make it easier for you to shop and to find
              products you love by allowing you to purchase Merchant’s products
              through Seller’s VideoShops. We provide the Services to further
              our mission of making shopping better for everyone, including by
              helping you:
            </p>
            <ol id="l1" className={styles.videoshopsOlRoundedLa}>
              <li data-list-text="(a)">
                <p>
                  <u>Connect with Sellers</u>: VideoShops helps you discover,
                  browse, and shop at the many Sellers who have built their
                  storefronts using VideoShops’ Platform (“
                  <u>
                    <b>Sellers</b>
                  </u>
                  ”). Using VideoShops Services, you can browse products and
                  make purchases easily from Sellers’ VideoShops. You can also
                  see and share products with other VideoShops users to help you
                  make informed decisions when you shop.
                </p>
              </li>
              <li data-list-text="(b)">
                <p>
                  <u>Experience a simpler, faster shopping experience</u>:
                  VideoShops makes it easier for you to make and complete
                  purchases through Seller’s VideoShops, such as by providing
                  accelerated VideoShops checkout options that you may use to
                  pay for purchases.
                </p>
              </li>
              <li data-list-text="(c)">
                <p>
                  <u>Complete your purchases in one place</u>: VideoShops
                  enables you to buy natively on Seller’s VideoShops through a
                  single VideoShops checkout, even when purchasing from multiple
                  merchants. We operate an integrated checkout service, allowing
                  you to add multiple items from various Merchants to your cart
                  and checkout all these items on the Seller’s Videoshops and
                  through the Services. It is a neat and efficient way of buying
                  all your favorite items in one go. VideoShops then places
                  orders on your behalf with each corresponding Merchant.
                </p>
              </li>
              <li data-list-text="(d)">
                <p>
                  <u>Find products we think you will love</u>: VideoShops allows
                  Sellers to customize your online shopping experience and
                  provide tailored product recommendations to you based on your
                  use of VideoShops’ Services, including by helping you discover
                  new products, brands, and Merchants.
                </p>
              </li>
              <li data-list-text="(e)">
                <p>
                  <u>
                    Continuously improve your shopping experience wherever you
                    shop
                  </u>
                  : VideoShops is continuously working to build and improve your
                  shopping experience online and to develop and add new and
                  improved features for you based on how you use our Services.
                </p>
              </li>
            </ol>
            <h1>Account registration</h1>
            <p className="s3">
              In order to register for the Services, you are required to provide
              your username, email address and password. You may not select as
              your username, a name that you don’t have the right to use, or
              another person’s name with the intent to impersonate that person.
              You are also able to add a profile picture and add other user
              preferences. All personal details disclosed by you on the Services
              will be collected and processed in accordance with our{' '}
              <a
                href="https://videoshops.com/privacy-policy"
                className="s2"
                target="_blank"
              >
                Privacy Policy
              </a>
              &nbsp;and&nbsp;
              <a
                href="http://videoshops.com/cookie-policy"
                className="s2"
                target="_blank"
              >
                Cookie Policy
              </a>
              <span style={{ color: '#000' }}>
                . You agree to provide us with truthful and accurate information
                about yourself when registering for and/or using the Services,
                including your name and contact information, and you agree to
                keep this information updated and current.
              </span>
            </p>
            <p>
              You agree to register with VideoShops’ Services and access and use
              your account solely for personal use. You may not authorize others
              to use your account. You are solely responsible for keeping your
              registration and other personal details (including your username
              and password) for your account confidential. You will not share
              your account or password with anyone, and you must protect the
              security of your account and your password. You’re responsible for
              any activity associated with your account. You may never use
              another person’s user account or registration information without
              his or her permission. You shall promptly notify VideoShops of any
              actual or suspected unauthorized third party access to your
              account. You shall cooperate with, and assist, us in any action or
              proceedings by us to prevent or otherwise deal with any
              unauthorized receipt, access or use of your account by any third
              party.
            </p>
            <p>
              You can also register using third party services including but not
              limited to Google, Apple or Meta, which allows you to register
              with VideoShops via your third party services account. If you
              decide to do this, you give us permission to access basic
              information from your third party services account. This
              information is collected by the third party services and is
              provided to us under the terms of the third party services’
              privacy policy. We have no control over individual account privacy
              settings on such services or policies on how your personal
              information will be used. You and those third party services are
              in control of these matters, not us. Before using these features,
              you are encouraged to read all policies and information on the
              third party services to learn more about how they handle your
              information. VideoShops is not responsible for any acts or
              omissions by the third party services and any connected social
              media service providers' use of features that come from those
              platforms.
            </p>
            <h1>Your Commitments and use of VideoShops’ Services</h1>
            <p>
              You represent and warrant that you are of legal age to form a
              binding contract (or if not, you’ve received your parent’s or
              guardian’s permission to use the Services and your parent or
              guardian agrees to these Terms on your behalf). If you’re agreeing
              to these Terms on behalf of an organization or entity, you
              represent and warrant that you are authorized to agree to these
              Terms on that organization or entity’s behalf and bind them to
              these Terms (in which case, the references to “you” and “your” in
              these Terms, except for in this sentence, refer to that
              organization or entity).
            </p>
            <p>
              The Services are designed for and intended for users in the United
              States. We make no representation that the information and
              Services provided on the VideoShops’ Platform are applicable to,
              appropriate for, or available to users in locations outside the
              United States. Accessing the Services from territories where the
              content is illegal is prohibited. If you choose to access the
              Services from a location outside the United States, you do so on
              your own initiative and you are responsible for compliance with
              local laws.
            </p>
            <p
              style={{
                textAlign: 'justify'
              }}
            >
              You represent and warrant that you will use the Services for your
              own internal, personal use and not on behalf of or for the benefit
              of any third party, and only in a manner that complies with all
              laws that apply to you. If your use of the Services is prohibited
              by applicable laws, then you aren’t authorized to use the
              Services.
            </p>
            <h1
              style={{
                textAlign: 'justify'
              }}
            >
              Your use of the Services is subject to the following additional
              restrictions:
            </h1>
            <p>
              You represent, warrant, and agree that you will not contribute any
              Content or User Submission (each of those terms is defined below)
              or otherwise use the Services or interact with the Services in a
              manner that:
            </p>
            <ol id="l2">
              <li data-list-text="(a)">
                <p>
                  Infringes or violates the intellectual property rights or any
                  other rights of anyone else (including VideoShops);
                </p>
              </li>
              <li data-list-text="(b)">
                <p>
                  Violates any law or regulation, including, without limitation,
                  any applicable export control laws;
                </p>
              </li>
              <li data-list-text="(c)">
                <p>
                  Is harmful, fraudulent, deceptive, threatening, harassing,
                  defamatory, obscene, or otherwise objectionable;
                </p>
              </li>
              <li data-list-text="(d)">
                <p>
                  Jeopardizes the security of your VideoShops account or anyone
                  else’s (such as allowing someone else to log in to the
                  VideoShops’ Platform as you);
                </p>
              </li>
              <li data-list-text="(e)">
                <p>
                  Attempts, in any manner, to obtain the password, account, or
                  other security information from any other user;
                </p>
              </li>
              <li data-list-text="(f)">
                <p>
                  Violates the security of any computer network, or cracks any
                  passwords or security encryption codes;
                </p>
              </li>
              <li data-list-text="(g)">
                <p>
                  Runs any form of auto-responder or “spam” on the VideoShops’
                  Platform, or any processes that run or are activated while you
                  are not logged into the Services, or that otherwise interfere
                  with the proper working of the Services (including by placing
                  an unreasonable load on the infrastructure of VideoShops’
                  Platform);
                </p>
              </li>
              <li data-list-text="(h)">
                <p>
                  “Crawls,” “scrapes,” or “spiders” any page, data, or portion
                  of or relating to the Services, VideoShops Platform or Content
                  (through use of manual or automated means);
                </p>
              </li>
              <li data-list-text="(i)">
                <p>Copies or stores any significant portion of the Content;</p>
              </li>
              <li data-list-text="(j)">
                <p>
                  Decompiles, reverse engineers, or otherwise attempts to obtain
                  the source code or underlying ideas or information of or
                  relating to the Services.
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p>
              Furthermore, you represent, warrant, and agree that you will
              comply with all federal, state and local laws, rules and
              regulations, including those concerning paid product endorsements
              (including but not limited to the disclosures regarding the
              sponsored nature of the Content and the endorsement relationship)
              as required by the Federal Trade Commission Guides Concerning the
              Use of Endorsements and Testimonials in Advertising 16 C.F.R. Part
              255.
            </p>
            <p>
              <br />
            </p>
            <p>
              A violation of any of the foregoing is grounds for termination of
              your right to use or access the Services.
            </p>
            <p>
              <br />
            </p>
            <h1>Buying products through VideoShops</h1>
            <p className="s3">
              <span style={{ color: '#000' }}>
                By using the Services you place your orders with VideoShops
                checkout on Seller’s VideoShop. VideoShops’ checkout utilizes
                VideoShops’ payment service provider partner (“PSP”).
                VideoShops’ PSP processes the payment with regards to the
                specific order for the benefit of the Merchant. In order to
                process these payments we will need to share your personal data,
                shipping details and, in some cases, payment details that are
                related to such transactions with each of the Merchants from
                whom you are purchasing the products, but only using our secure
                systems. The personal data, shipping details and payment details
                you provide to us will be collected, stored and processed in
                accordance with our{' '}
              </span>
              <a
                href="https://videoshops.com/privacy-policy"
                className="a"
                target="_blank"
              >
                Privacy Policy
              </a>
              &nbsp;and&nbsp;
              <a
                href="http://videoshops.com/cookie-policy"
                className="a"
                target="_blank"
              >
                Cookie Policy&nbsp;
              </a>
              <span style={{ color: '#000' }}>
                and in accordance with the privacy and cookies policies of the
                Merchants from whom you have purchased the products. These
                Merchants are solely responsible for their use of your personal
                data, shipping details and payment details once we share the
                data with them, and you should carefully review their privacy
                policies before completing a transaction via VideoShops’
                checkout.
              </span>
            </p>
            <p>
              <br />
            </p>
            <h1>Payment Terms</h1>
            <ol id="l3">
              <li data-list-text="(a)">
                <p>
                  Anytime you click “pay”, “shop” or “buy” on the Services, you
                  are authorizing VideoShops’ PSP to transfer funds out of your
                  account or to debit them from your payment card (as
                  applicable), and ultimately deposit them into the Merchant’s
                  account. If you complete a purchase with recurring payments,
                  you authorize VideoShops’ PSP to charge the card you used for
                  the initial purchase for each reoccurrence.
                </p>
              </li>
              <li data-list-text="(b)">
                <p>
                  When storing your payment information into your VideoShops’
                  Account you are in fact storing it on VideoShops’ PSP on
                  behalf of VideoShops, and you agree that the Merchant may
                  charge you in accordance with your agreement with such
                  Merchant for each transaction using the stored payment
                  information. When you complete a
                </p>
                <p>
                  transaction using your VideoShops’ Account, you agree that
                  VideoShops’ PSP may use the information stored in your
                  VideoShops’ Account to initiate the payment process with the
                  Merchant for your transaction and to fulfill the order.
                </p>
              </li>
              <li data-list-text="(c)">
                <p>
                  By submitting your payment request, you represent and warrant
                  that you are authorized to use the payment method and
                  authorize VideoShops’ PSP to (1) debit, withdraw or charge
                  your payment method and (2) to initiate any credit, debit or
                  charge to your payment method in order to process any returns,
                  chargebacks, reversals, refunds, corrections or other
                  necessary adjustments. All payment requests are subject to
                  authorization by your card issuer, financial institution or
                  payment network.
                </p>
              </li>
              <li data-list-text="(d)">
                <p>
                  A payment request does not necessarily ensure that VideoShops’
                  PSP will accept your request. VideoShops’ PSP reserves the
                  right to refuse, suspend, or cancel the request at their sole
                  discretion.
                </p>
              </li>
              <li data-list-text="(e)">
                <p>
                  Once VideoShops’ PSP receives a properly completed payment
                  request from you and authorized by your card issuer, payment
                  network or other financial institution, authorization of your
                  form of payment is received and if VideoShops’ PSP accepts
                  your order, VideoShops will promptly place your order in line
                  for fulfillment.
                </p>
              </li>
              <li data-list-text="(f)">
                <p>
                  If you make a purchase in a currency other than United States
                  Dollars (“USD”), you acknowledge and agree to be charged and,
                  if applicable, refunded according to the then-current exchange
                  rate set by your card issuer.
                </p>
              </li>
              <li data-list-text="(g)">
                <p>
                  Additional terms and conditions may apply to your payment
                  request and to specific portions or features of the Services
                  including VideoShops’ PSP specific terms and rules related to
                  your use of your payment method such as Visa, MasterCard,
                  American Express, and Discover payment networks and bank
                  transfers. You agree to abide by such other terms and
                  conditions, including where applicable representing that you
                  are of sufficient legal age to use or participate in such
                  service or feature. These Terms do not alter the Terms you’ve
                  agreed to with a Merchant, except with respect to the direct
                  relationship between you and VideoShops.
                </p>
              </li>
              <li data-list-text="(h)">
                <p>
                  Orders may be subject to additional taxes, duties and charges.
                  Please be aware that the price of a Merchant product may
                  change at checkout if it is subject to any taxes, duties,
                  fees, levies or delivery charges applied in the location you
                  have selected for the delivery of your order. Where possible,
                  VideoShops will calculate these charges on your behalf and add
                  them to the total cost of your order. Where it is not possible
                  for VideoShops to calculate and charge additional taxes,
                  duties and charges at checkout, including customs charges, you
                  may be responsible for the payment of these charges when you
                  receive your order.
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <h1>Your Relationship with Merchants</h1>
            <p>
              VideoShops only facilitates the transactions conducted on
              VideoShops’ Platform and does not act as the merchant of record or
              as the seller of record. You are purchasing goods and/or services
              from the Merchants and your purchase is governed by any Merchant
              terms and conditions offered or any contract between you and the
              Merchant. Accordingly, the contract formed by the completion of a
              sale for the Merchant products is a contract between you and the
              Merchant .
            </p>
            <p>
              VideoShops is not a party to that contract nor does VideoShops
              accept any liability, responsibility or obligation for the terms
              therein. The Merchant is solely responsible for the sale of the
              Merchant’s products and is solely responsible for customer service
              issues in connection therewith. You must contact the Merchant to
              resolve any issues you have with respect to the goods and services
              you ordered or purchased from the Merchant, including any issues
              relating to shipping and delivery, product warranty, product
              return, refund, exchange, and the terms of any agreements you
              entered into with the Merchant in connection with your order or
              purchase. You acknowledge and agree that VideoShops is not
              responsible for any of these issues.
            </p>
            <p>
              You also acknowledge and agree that VideoShops is not in any way
              responsible for the quality, usability, or any other element of
              the goods or services you order or purchase from a Merchant. You
              acknowledge that VideoShops does not endorse any goods or services
              offered for sale on Merchant websites linked to the Services.
              VideoShops does not warrant the accuracy or reliability of any
              information or marketing messages contained in Merchant websites
              linked to the Services.
            </p>
            <p>
              <br />
            </p>
            <p>
              If you have dispute rights through your credit card issuer, any
              disputes filed with your credit card issuer shall be between you
              and the issuer, and VideoShops shall not be held liable for such
              disputes.
            </p>
            <p>
              You agree that we can make information about your purchase
              available to the Merchant through a Merchant dashboard and/or our
              API in order for the Merchant to fulfill your order, each of which
              will require the Merchant to pass certain authentication measures.
              If your Merchant operates through a third party platform, such as
              Shopify, your purchase information may be shared with that
              platform through the Merchant.
            </p>
            <h1>What are my rights when using the Services?</h1>
            <p>
              The materials displayed or performed or available on or through
              the Services, including, but not limited to, text, graphics, data,
              articles, photos, images, illustrations, video, User Submissions
              (all of the foregoing, the “
              <u>
                <b>Content</b>
              </u>
              ”) are protected by intellectual property laws. You promise to
              abide by all copyright notices, trademark rules, information, and
              restrictions contained in any Content you access through the
              Services, and you won’t use, copy, reproduce, modify, translate,
              publish, broadcast, transmit, distribute, perform, upload,
              display, license, sell, commercialize or otherwise exploit for any
              purpose any Content not owned by you, (i) without the prior
              consent of the owner of that Content, or (ii) in a way that
              violates someone else’s rights (including VideoShops’ rights).
            </p>
            <p>
              You understand that VideoShops owns the VideoShops’ Platform and
              associated Services. You won’t modify, publish, transmit,
              participate in the transfer or sale of, reproduce (except as
              expressly provided in this Section), create derivative works based
              on, or otherwise exploit any of the Platform.
            </p>
            <p>
              <br />
            </p>
            <h1>What are VideoShops’ rights?</h1>
            <ol id="l4">
              <li data-list-text="(a)">
                <p>
                  <u>Permission to update VideoShops’ software</u>: If you
                  download or use VideoShops’ software, you give VideoShops
                  permission to download and install updates to the software
                  where available, including on services where software may
                  update automatically on your device when a new version or
                  feature becomes available. You may adjust your automatic
                  update settings on some devices.
                </p>
              </li>
              <li data-list-text="(b)">
                <p>
                  <u>Communications</u>: To provide you with the Services,
                  VideoShops may communicate with you via email, our messaging
                  tools, or through text/short message service (“SMS”) to the
                  extent permitted by applicable law and/or consistent with your
                  permissions. You're responsible for any mobile charges you may
                  incur for using any of the SMS services, including
                  text-messaging (e.g., SMS, MMS) and data charges.
                </p>
              </li>
              <li data-list-text="(c)">
                <p>
                  <u>Marketing and Advertising</u>: In addition, VideoShops may
                  market, advertise, and promote Services, Sellers, Merchants or
                  Merchants' products or services to you on VideoShops.
                </p>
              </li>
              <li data-list-text="(d)">
                <p>
                  <u>Access Third-Party Emails to provide the Services</u>: If
                  you link your VideoShops account with a
                </p>
              </li>
            </ol>
            <p>
              third-party email service or third-party shopping platform for
              order tracking, you consent to give VideoShops access to the
              contents of your emails and to your shopping history. VideoShops
              only uses this information to provide the Services to you, and
              does not use information obtained from third-party partners you
              integrate with VideoShops to show or target advertising to you.
              You can revoke this consent at any time by de-linking these
              accounts in the VideoShops’ Platform.
            </p>
            <h1>
              Do I have to grant any licenses to VideoShops or to other users?
            </h1>
            <p>
              Anything you post, upload, share, store, or otherwise provide
              through the Services, including any videos submitted through the
              VideoShops’ Platform, is your “
              <u>
                <b>User Submission</b>
              </u>
              .” User Submissions are viewable by other users. In order to
              display your User Submissions on the VideoShops’ Platform, and to
              allow other users to view them (where applicable), you grant us
              certain rights in those User Submissions. Please note that all of
              the following licenses are subject to our Privacy Policy to the
              extent they relate to User Submissions that are also your
              personally-identifiable information.
            </p>
            <p>
              For all User Submissions, you hereby grant VideoShops a license to
              translate, modify (for technical purposes, for example making sure
              your content is viewable on an iPhone as well as an Android) and
              reproduce and otherwise act with respect to such User Submissions,
              in each case to enable us to operate the Services, as described in
              more detail below. This is a license only – your ownership in User
              Submissions is not affected.
            </p>
            <p>
              <br />
            </p>
            <p>
              If you share a User Submission publicly on the VideoShops’
              Platform or if you provide us with any feedback, suggestions,
              improvements, enhancements, and/or feature requests relating to
              the Services (each of the foregoing, a “
              <u>
                <b>Public User</b>
              </u>
              <b> </b>
              <u>
                <b>Submission</b>
              </u>
              ”), then you grant VideoShops the licenses above, as well as a
              license to display, perform, and distribute your
            </p>
            <p>
              Public User Submission for the purpose of making that Public User
              Submission accessible to all VideoShops users and providing the
              VideoShops’ Platform necessary to do so, as well as all other
              rights necessary to use and exercise all rights in that Public
              User Submission in connection with the Services and/or otherwise
              in connection with Company’s business, which may include licensing
              to third parties. Also, you grant all other users of the Services
              a license to access that Public User Submission, and to use and
              exercise all rights in it, as permitted by the functionality of
              the VideoShops Platform and in accordance with these Terms.
            </p>
            <p>
              You agree that the licenses you grant are royalty-free, perpetual,
              sublicensable, irrevocable, and worldwide, provided that when you
              delete your VideoShops account, we will stop displaying your User
              Submissions (other than Public User Submissions, which may remain
              fully available) to other users (if applicable), but you
              understand and agree that it may not be possible to completely
              delete that content from VideoShops’ records, and that your User
              Submissions may remain viewable elsewhere to the extent that they
              were copied or stored by other users.
            </p>
            <p>
              Finally, you understand and agree that VideoShops, in performing
              the required technical steps to provide the Services to our users
              (including you), may need to make changes to your User Submissions
              to conform and adapt those User Submissions to the technical
              requirements of connection networks, devices, services, or media,
              and the foregoing licenses include the rights to do so.
            </p>
            <h1>
              What if I see something on the VideoShops’ Platform that infringes
              my copyright?
            </h1>
            <p>
              We respect the intellectual property rights of others, and we
              reserve the right to delete or disable Content alleged to be
              infringing, and to terminate the accounts of repeat alleged
              infringers.
            </p>
            <h1>
              Who is responsible for what I see and do on the VideoShops’
              Platform?
            </h1>
            <p>
              Any information or content publicly posted or privately
              transmitted through the Services is the sole responsibility of the
              person from whom such content originated, and you access all such
              information and content at your own risk, and we aren’t liable for
              any errors or omissions in that information or content or for any
              damages or loss you might suffer in connection with it. We cannot
              control and have no duty to take any action regarding how you may
              interpret and use the Content or what actions you may take as a
              result of having been exposed to the Content, and you hereby
              release us from all liability for you having acquired or not
              acquired Content through the Services. We can’t guarantee the
              identity of any users with whom you interact in using the Services
              and are not responsible for which users gain access to the
              Services.
            </p>
            <p className="s3">
              You understand and agree that your identity may be discovered by
              other users on the VideoShops’ Platform. You provide User
              Submissions at your own risk, and you understand that VideoShops’
              privacy obligations are as set forth in our&nbsp;
              <a
                href="https://videoshops.com/privacy-policy"
                className="a"
                target="_blank"
              >
                Privacy Policy
              </a>
              &nbsp;and&nbsp;
              <a
                href="http://videoshops.com/cookie-policy"
                className="a"
                target="_blank"
              >
                Cookie Policy
              </a>
              <span style={{ color: '#000' }}>
                . If you do not accept this risk, do not use the Services.
              </span>
            </p>
            <p>
              You are responsible for all Content you contribute, in any manner,
              to the Services, and you represent and warrant you have all rights
              necessary to do so, in the manner in which you contribute it. You
              will keep all your registration information accurate and current.
              You are responsible for all your activity in connection with the
              Services.
            </p>
            <p>
              The Services may contain links or connections to third party
              websites or services that are not owned or controlled by
              VideoShops. When you access third party websites or use third
              party services, you accept that there are risks in doing so, and
              that VideoShops is not responsible for such risks. The appearance
              of a link does not constitute an endorsement, recommendation or
              certification by VideoShops. We encourage you to be aware when you
              leave the Services and to read the terms and conditions and
              privacy policy of each third party website or service that you
              visit or utilize.
            </p>
            <p>
              VideoShops has no control over, and assumes no responsibility for,
              the content, accuracy, privacy policies, or practices of or
              opinions expressed in any third party websites or by any third
              party that you interact with through the Services. In addition,
              VideoShops will not and cannot monitor, verify, censor or edit the
              content of any third party site or service. By using the Services,
              you release and hold us harmless from any and all liability
              arising from your use of any third party website or service.
            </p>
            <p>
              Your interactions with organizations and/or individuals found on
              or through the Services, including payment and delivery of goods
              or services, and any other terms, conditions, warranties or
              representations associated with such dealings, are solely between
              you and such organizations and/or individuals. You should make
              whatever investigation you feel necessary or appropriate before
              proceeding with any online or offline transaction with any of
              these third parties. You agree that
            </p>
            <p>
              VideoShops shall not be responsible or liable for any loss or
              damage of any sort incurred as the result of any such dealings.
            </p>
            <p>
              If there is a dispute involving the Services, or between users and
              any third party, you agree that VideoShops is under no obligation
              to become involved. In the event that you have a dispute with one
              or more other users, you release VideoShops, its officers,
              employees, agents, and successors from claims, demands, and
              damages of every kind or nature, known or unknown, suspected or
              unsuspected, disclosed or undisclosed, arising out of or in any
              way related to such disputes and/or our Services. If you are a
              California resident, you shall and hereby do waive California
              Civil Code Section 1542, which says: "A general release does not
              extend to claims which the creditor does not know or suspect to
              exist in his or her favor at the time of executing the release,
              which, if known by him or her must have materially affected his or
              her settlement with the debtor.
            </p>
            <h1>Will VideoShops ever change the Services?</h1>
            <p>
              We’re always trying to improve the Services, so they may change
              over time. We may suspend or discontinue any part of the Services,
              or we may introduce new features or impose limits on certain
              features or restrict access to parts or all of the VideoShops’
              Platform. We reserve the right to remove any Content from the
              VideoShops’ Platform at any time, for any reason (including, but
              not limited to, if someone alleges you contributed that Content in
              violation of these Terms), in our sole discretion, and without
              notice.
            </p>
            <h1>What about my privacy?</h1>
            <p className="s3">
              VideoShops takes the privacy of its users very seriously. You can
              access VideoShops’&nbsp;
              <a
                href="https://videoshops.com/privacy-policy"
                className="a"
                target="_blank"
              >
                Privacy Policy
              </a>
              &nbsp;and&nbsp;
              <a
                href="http://videoshops.com/cookie-policy"
                className="a"
                target="_blank"
              >
                Cookie Policy
              </a>
              <span style={{ color: '#000' }}>.</span>
            </p>
            <p>
              The Children’s Online Privacy Protection Act (“
              <u>
                <b>COPPA</b>
              </u>
              ”) requires that online service providers obtain parental consent
              before they knowingly collect personally identifiable information
              online from children who are under 13. We do not knowingly collect
              or solicit personally identifiable information from children under
              13; if you are a child under 13, please do not attempt to register
              for the Services or send any personal information about yourself
              to us. If we learn we have collected personal information from a
              child under 13, we will delete that information as quickly as
              possible. If you believe that a child under 13 may have provided
              us personal information, please contact us at&nbsp;
              <a href="mailto:hello@videoshops.com" className="s2" target="_blank">
                hello@videoshops.com
              </a>
            </p>
            <h1
              style={{
                textAlign: 'justify'
              }}
            >
              What if I want to stop using the Services?
            </h1>
            <p
              style={{
                textAlign: 'justify'
              }}
            >
              You’re free to do that at any time, by terminating your account in
              the application; please refer to our Privacy Policy, as well as
              the licenses above, to understand how we treat information you
              provide to us after you have stopped using our Services.
            </p>
            <p>
              VideoShops is also free to terminate (or suspend access to) your
              use of the Services or your account, for any reason in our
              discretion, including your breach of these Terms. VideoShops has
              the sole right to decide whether you are in violation of any of
              the restrictions set forth in these Terms.
            </p>
            <p
              style={{
                textAlign: 'justify'
              }}
            >
              Account termination may result in destruction of any Content
              associated with your account, so keep that in mind before you
              decide to terminate your account.
            </p>
            <p>
              Provisions that, by their nature, should survive termination of
              these Terms shall survive termination. By way of example, all of
              the following will survive termination: any obligation you have to
              pay us or indemnify us, any limitations on our liability, any
              terms regarding ownership of intellectual property rights, and
              terms regarding disputes between us, including without limitation
              the arbitration agreement.
            </p>
            <h1>What else do I need to know?</h1>
            <p>
              Neither VideoShops nor its licensors or suppliers make any
              representations or warranties concerning any content contained in
              or accessed through the Services, and we will not be responsible
              or liable for the accuracy, copyright compliance, legality, or
              decency of material contained in or accessed through the Services.
              We (and our licensors and suppliers) make no representations or
              warranties regarding suggestions or recommendations of services or
              products offered or purchased through the Services Products and
              services purchased or offered (whether or not following such
              recommendations and suggestions) through the Services are provided
              “AS IS” and without any warranty of any kind from VideoShops or
              others (unless, with respect to such others only, provided
              expressly and unambiguously in writing by a designated third party
              for a specific product.) THE SERVICES AND CONTENT ARE PROVIDED BY
              VIDEOSHOPS
            </p>
            <p>
              (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT
              WARRANTIES OR ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
              WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
              FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE
              SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT
              ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE
              ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
            </p>
            <p>
              TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO
              CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT
              LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL
              VIDEOSHOPS (OR ITS LICENSORS OR SUPPLIERS) BE LIABLE TO YOU OR TO
              ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR
              CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST
              PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR
              COMPUTER FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE
              AGGREGATE, IN EXCESS OF THE GREATER OF (A) $100, OR (B) ANY MATTER
              BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE
              EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE
              LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
            </p>
            <p>
              To the fullest extent allowed by applicable law, you agree to
              indemnify and hold VideoShops, its affiliates, officers, agents,
              employees, and partners harmless from and against any and all
              claims, liabilities, damages (actual and consequential), losses
              and expenses (including attorneys’ fees) arising from or in any
              way related to any third party claims relating to (a) your use of
              the Services (including any actions taken by a third party using
              your account), (b) your User Submissions, and (c) your violation
              of these Terms. In the event of such a claim, suit, or action (“
              <u>
                <b>Claim</b>
              </u>
              ”), we will attempt to provide notice of the Claim to the contact
              information we have for your account (provided that failure to
              deliver such notice shall not eliminate or reduce your
              indemnification obligations hereunder).
            </p>
            <p>
              You may not assign, delegate or transfer these Terms or your
              rights or obligations hereunder, or your VideoShops’ Platform
              account, in any way (by operation of law or otherwise) without
              VideoShops’ prior written consent. We may transfer, assign, or
              delegate these Terms and our rights and obligations without
              consent.
            </p>
            <p>
              These Terms are governed by and will be construed under the
              Federal Arbitration Act, applicable federal law, and the laws of
              the State of New York, without regard to the conflicts of laws
              provisions thereof.
            </p>
            <p>
              Please read the following ARBITRATION AGREEMENT carefully because
              it requires you to arbitrate certain disputes and claims with
              VideoShops and limits the manner in which you can seek relief from
              us. Both you and VideoShops acknowledge and agree that for the
              purposes of any dispute arising out of or relating to the subject
              matter of these Terms, VideoShops’ officers, directors, employees
              and independent contractors (“
              <u>
                <b>Personnel</b>
              </u>
              ”) are third party beneficiaries of these Terms, and that upon
              your acceptance of these Terms, Personnel will have the right (and
              will be deemed to have accepted the right) to enforce these Terms
              against you as the third party beneficiary hereof.
            </p>
            <p>
              Any dispute arising out of or relating to the subject matter of
              these Terms shall be finally settled by binding arbitration in New
              York. The arbitration will proceed in the English language, in
              accordance with the Streamlined Arbitration Rules and Procedures
              of Judicial Arbitration and Mediation Platform, Inc. ("
              <u>
                <b>JAMS</b>
              </u>
              ") then in effect, by one commercial arbitrator with substantial
              experience in resolving intellectual property and commercial
              contract disputes, who shall be selected from the appropriate list
              of JAMS arbitrators in accordance with such Rules. Judgment upon
              the award rendered by such arbitrator may be entered in any court
              of competent jurisdiction. Notwithstanding the foregoing
              obligation to arbitrate disputes, each party shall have the right
              to pursue injunctive or other equitable relief at any time, from
              any court of competent jurisdiction.
            </p>
            <p>
              VideoShops will pay all arbitration filing fees and arbitrator’s
              costs and expenses upon your written request given prior to the
              commencement of the arbitration. You are responsible for all
              additional fees and costs that you incur in the arbitration,
              including, but not limited to, attorneys or expert witnesses. Fees
              and costs may be awarded as provided pursuant to applicable law.
            </p>
            <p>
              Furthermore, either you or VideoShops may assert claims, if they
              qualify, in small claims court in New York or any United States
              county where you live or work.
            </p>
            <p>
              YOU AND VIDEOSHOPS WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS
              TO GO TO COURT AND
            </p>
            <p>
              HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and VideoShops are
              instead choosing to have claims and disputes resolved by
              arbitration. Arbitration procedures are typically more limited,
              more efficient, and less costly than rules applicable in court and
              are subject to very limited review by a court. In any litigation
              between you and VideoShops over whether to vacate or enforce an
              arbitration award, YOU AND VIDEOSHOPS WAIVE ALL RIGHTS TO A JURY
              TRIAL, and elect instead to have the dispute be resolved by a
              judge.
            </p>
            <p>
              ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION
              AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS
              AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER
              CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED
            </p>
            <p>
              WITH THOSE OF ANY OTHER CUSTOMER OR USER. If, however, this waiver
              of class or consolidated actions is deemed invalid or
              unenforceable, neither you nor we are entitled to arbitration;
              instead all claims and disputes will be resolved in a court as set
              forth herein.
            </p>
            <p>
              You have the right to opt out of the provisions of this Section by
              sending written notice of your decision to opt out to the
              following address: Nowwith Ventures, Inc. doing business as
              “VideoShops”, 167-177 Lafayette Street, Suite 300, New York, NY
              10013, postmarked within 30 days of first accepting these Terms.
              You must include (1) your name and residence address; (2) the
              email address and/or telephone number associated with your
              account; and (3) a clear statement that you want to opt out of
              these Terms’ arbitration agreement.
            </p>
            <p>
              If you send the opt-out notice as provided herein, and/or in any
              circumstances where the foregoing arbitration agreement permits
              either you or VideoShops to litigate any dispute arising out of or
              relating to the subject matter of these Terms in court, then the
              foregoing arbitration agreement will not apply to either party and
              both you and VideoShops agree that any judicial proceeding (other
              than small claims actions) will be brought in the state or federal
              courts located in New York, New York.
            </p>
            <p>
              You will be responsible for paying, withholding, filing, and
              reporting all taxes, duties, and other governmental assessments
              associated with your activity in connection with the Services. The
              failure of either you or us to exercise, in any way, any right
              herein shall not be deemed a waiver of any further rights
              hereunder. If any provision of these Terms is found to be
              unenforceable or invalid, that provision will be limited or
              eliminated, to the minimum extent necessary, so that these Terms
              shall otherwise remain in full force and effect and enforceable.
              You and VideoShops agree that these Terms are the complete and
              exclusive statement of the mutual understanding between you and
              VideoShops, and that these Terms supersede and cancel all previous
              written and oral agreements, communications and other
              understandings relating to the subject matter of these Terms. You
              hereby acknowledge and agree that you are not an employee, agent,
              partner, or joint venture of VideoShops, and you do not have any
              authority of any kind to bind VideoShops in any respect
              whatsoever.
            </p>
          </>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default TermsOfUsePage
