import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import VideoBackground from "@/components/OnboardingCompNew/SellerSteps/Sellers/VideoBackground.tsx";
import FinalButton from "@/videoshops-shared/components/FinalButton/FinalButton.tsx";
import Routing from "@/shared/icons/Routing.tsx";
import { appConfig } from "@/app-config.ts";
import TopBar from "@/shared/components/TopBar/TopBar.tsx";
import SellersBackground from "@/components/OnboardingCompNew/SellerSteps/Sellers/SellersBackground.tsx";

export default function Welcome() {
  const [searchParams] = useSearchParams();

  const handleClick = () => {
    const token = searchParams.get("TOKEN");
    localStorage.removeItem("currentStep");
    localStorage.removeItem("formData");
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("protectedPagePassword");
    localStorage.removeItem("categories");
    localStorage.removeItem("themeColor");
    window.location.href = `${appConfig.CLIENT_APP_URL}?TOKEN=${token}`;
  };

  return (
    <SellersBackground>
      <Box position="relative">
        <TopBar />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          minHeight="calc(100vh - 48px)"
          padding="3rem 1.25rem"
        >
          <VideoBackground />
          <Typography
            align="center"
            fontSize={48}
            lineHeight="48px"
            fontWeight={800}
            letterSpacing="-1.92px"
            color="white"
          >
            Welcome to
            <br /> Your Shop!
          </Typography>
          <Box
            mt={3.5}
            width="196px"
            height="196px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ backgroundImage: "url(/icons/welcome-image-frame.svg)" }}
          >
            <Box component="img" src="/icons/welcome-image.svg" />
          </Box>
          <Box
            mt={2}
            width="276px"
            height="200px"
            display="flex"
            alignItems="center"
            flexDirection="column"
            sx={{ backgroundImage: "url(/icons/welcome-text-frame.svg)" }}
          >
            <Typography mt="44px" fontWeight={800} fontSize={28}>
              Hi I’m Stella,
            </Typography>
            <Typography
              align="center"
              mt={1}
              variant="md_bold"
              lineHeight="16px"
            >
              I’m your personal <br />
              shop guide
            </Typography>
            <Typography
              align="center"
              px="30px"
              mt={1}
              variant="md_bold"
              lineHeight="16px"
            >
              Let me show you how to set up your shop in <br />3 easy steps
            </Typography>
          </Box>
          <Box mt={6} width="350px">
            <FinalButton variant="primary" full onClick={handleClick}>
              <Box display="flex" alignItems="center">
                <Routing sx={{ mr: 2 }} /> Stella’s Guided Tour
              </Box>
            </FinalButton>
          </Box>
        </Box>
      </Box>
    </SellersBackground>
  );
}
