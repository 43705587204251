import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import Box from '@mui/system/Box'
import Container from '@mui/system/Container'
import TopBar from '@/shared/components/TopBar/TopBar.tsx'
import BackButton from '@/shared/components/BackButton/BackButton.tsx'
import StepHeader from '../../StepHeader/StepHeader.tsx'
import formStyles from '@/components/Form/styles.module.scss'
import UploadImage from '../../CommonSteps/ProfileImage/UploadImage.tsx'
import styles from '../../styles.module.scss'
import StepCounter from '@/components/OnboardingCompNew/StepCounter.tsx'
import ProgressBar from '../../ProgressBar.tsx'
import FinalButton from '@/videoshops-shared/components/FinalButton/FinalButton.tsx'
import { OnboardingStep } from '@/constants/onboarding.constant.ts'
import useOnboarding from '../../useOnboarding.ts'

const imageKey = 'profileImageKey'
const imageName = 'profileImage'

const ProfileImage: FC = () => {
  const navigate = useNavigate()
  const { saveOnboardingData } = useOnboarding()

  const form = useForm({
    mode: 'onChange',
    defaultValues: { name: '' }
  })

  const onSubmit = async () => {
    const saved = await saveOnboardingData(
      OnboardingStep.PROFILE_IMAGE,
      localStorage.getItem('TOKEN') || ''
    )
    if (saved) {
      navigate('/creator/setup/categories')
    }
  }

  return (
    <div className={styles.stepPage}>
      <TopBar />
      <ProgressBar value={(100 / 8) * 6} />
      <Box mt={{ xs: 1.5, md: 3.5 }}>
        <Container maxWidth="lg">
          <Box height={36} display="flex" alignItems="center" justifyContent="space-between">
            <BackButton to="/creator/setup/description" />
            <StepCounter step={6} total={8} />
          </Box>
        </Container>
        <Container maxWidth={false} sx={{ maxWidth: 459 }}>
          <Box mt={2}>
            <StepHeader>Add Profile Image</StepHeader>
          </Box>
          <Box mt="52px">
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className={formStyles.formContainer}>
                <UploadImage
                  croppingType="square"
                  buttonLabel="Select profile image"
                  imageKey={imageKey}
                  imageName={imageName}
                />
                <Box mt={4}>
                  <FinalButton type="submit" full variant="primary">
                    Next
                  </FinalButton>
                </Box>
              </form>
            </FormProvider>
          </Box>
        </Container>
      </Box>
    </div>
  )
}

export default ProfileImage
