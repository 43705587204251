import styles from './styles.module.scss'
import NewTitle from '@/components/Title/Title'
import { ReactComponent as MobileBack } from '@/assets/home/mobile-back.svg'
import { ReactComponent as BreadCrumbArrow } from '@/assets/home/breadcrumb-arrow.svg'
import Footer from '@/components/Footer/Footer'
import TopBar from '@/components/SubPagesComp/TopBar/TopBar'
import classNames from 'classnames'

function MerchantAgreement() {
  const isMobile = window.innerWidth < 768
  const title = (
    <div className="breakTitle">
      <span data-line="VideoShops">
        <span className="vs-primary-color">Merchant</span> Agreement
      </span>
    </div>
  )
  return (
    <>
      <TopBar />
      <div className={styles.subPageWrapper}>
        <div className={styles.aboutUsTopWrapper}>
          {isMobile ? (
            <a href="/">
              {' '}
              <MobileBack />
            </a>
          ) : (
            <div className={styles.breadcrumbWrapper}>
              <a href="/">Home</a> <BreadCrumbArrow /> Merchant Agreement
            </div>
          )}
        </div>
        <NewTitle children={title} titleColor="#000" />
        <div
          className={classNames(styles.subPageContent, styles.fullWidthContent)}
        >
          <>
            <div className="WordSection1">
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <a />
                <b>
                  <span style={{ fontSize: '11.0pt' }}>
                    Last updated on: April 13, 2024{' '}
                  </span>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}> </span>
              </p>
              <table
                border={1}
                cellSpacing={0}
                cellPadding={0}
                width={970}
                style={{
                  marginLeft: '1.4pt',
                  borderCollapse: 'collapse',
                  border: 'none'
                }}
              >
                <tbody>
                  <tr style={{ height: '83.25pt' }}>
                    <td
                      width={667}
                      valign="top"
                      style={{
                        width: '500.15pt',
                        border: 'solid black 1.0pt',
                        padding: '4.0pt 4.0pt 4.0pt 4.0pt',
                        height: '83.25pt'
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          This “Merchant Agreement” (the “
                          <b>
                            <u>Agreement</u>
                          </b>
                          ”) is between you, the brand (“
                          <b>
                            <u>Merchant</u>
                          </b>
                          ”), and NOWwith Ventures, Inc. d/b/a “VideoShops”, 154
                          Grand Street, 3rd Floor New York, NY 10013 (“
                          <b>
                            <u>VideoShops</u>
                          </b>
                          ”) and is effective as of the date Merchant accepts
                          this Agreement as provided below.&nbsp;{' '}
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          This Agreement sets forth the terms and conditions of
                          Merchant’s sales of products on VideoShops’ platform
                          (“
                          <b>
                            <u>VideoShops’ Platform</u>
                          </b>
                          ”).&nbsp;{' '}
                        </span>
                      </p>
                    </td>
                    <td
                      width={303}
                      valign="top"
                      style={{
                        width: '227.45pt',
                        border: 'solid black 1.0pt',
                        borderLeft: 'none',
                        padding: '4.0pt 4.0pt 4.0pt 4.0pt',
                        height: '83.25pt'
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      width={667}
                      valign="top"
                      style={{
                        width: '500.15pt',
                        border: 'solid white 1.0pt',
                        borderTop: 'none',
                        padding: '0in 5.75pt 0in 5.75pt'
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <b>
                          <u>
                            <span style={{ fontSize: '11.0pt' }}>
                              How Does VideoShops’ Platform Work?
                            </span>
                          </u>
                        </b>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <u>
                          <span style={{ fontSize: '11.0pt' }}>
                            <span style={{ textDecoration: 'none' }}>
                              &nbsp;
                            </span>
                          </span>
                        </u>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          Videoshops offers creators, influencers and
                          individuals (collectively, “
                          <b>
                            <u>Sellers</u>
                          </b>
                          ”) the opportunity to build, maintain and manage a
                          storefront to promote products from participating
                          merchants. Sellers can promote the products to their
                          audiences, and shoppers can buy natively on the
                          seller's storefront through a single Videoshops
                          checkout, even when purchasing from multiple
                          merchants. VideoShops then places orders on behalf of
                          Shoppers with each relevant merchant.
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          The content posted by a Seller (“
                          <b>
                            <u>Seller Content</u>
                          </b>
                          ”) is all “shoppable” by third party users (“
                          <b>
                            <u>Customers</u>
                          </b>
                          ”) who may purchase Merchant’s products (“
                          <b>
                            <u>Merchant SKUs</u>
                          </b>
                          ”) through such Seller’s “VideoShop” on any social
                          media platform or messaging application (a “
                          <b>
                            <u>Seller’s VideoShop</u>
                          </b>
                          ”).&nbsp;&nbsp;{' '}
                        </span>
                      </p>
                    </td>
                    <td
                      width={303}
                      valign="top"
                      style={{
                        width: '227.45pt',
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderBottom: 'solid white 1.0pt',
                        borderRight: 'solid white 1.0pt',
                        padding: '0in 5.75pt 0in 5.75pt'
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          Merchant, VideoShops and the Seller each have the
                          right to (i) promote any shared content featuring
                          Merchant SKUs solely in accordance with this
                          Agreement, and (ii) to remove any particular Seller
                          Content in accordance with this Agreement.
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          Merchant agrees not to use any materials contained in
                          Seller Content in any manner other than as provided in
                          this Agreement.&nbsp;{' '}
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          Merchant acknowledges that Merchant must obtain any
                          clearances or licenses from third party owners for the
                          use of Seller Content not expressly permitted in this
                          Agreement and Program Policies.&nbsp;&nbsp;{' '}
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: '434.55pt' }}>
                    <td
                      width={667}
                      valign="top"
                      style={{
                        width: '500.15pt',
                        border: 'solid black 1.0pt',
                        borderTop: 'none',
                        padding: '4.0pt 4.0pt 4.0pt 4.0pt',
                        height: '434.55pt'
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <b>
                          <u>
                            <span style={{ fontSize: '11.0pt' }}>
                              How Does a Merchant Manage their Account on
                              VideoShops’ Platform?
                            </span>
                          </u>
                        </b>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <u>
                          <span style={{ fontSize: '11.0pt' }}>
                            <span style={{ textDecoration: 'none' }}>
                              &nbsp;
                            </span>
                          </span>
                        </u>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          Merchant’s account is self-serve and easy to use (the
                          “
                          <b>
                            <u>Brand Manager Tool</u>
                          </b>
                          ”).&nbsp; As used herein, the term “
                          <b>
                            <u>Merchant Content</u>
                          </b>
                          ” means the account information that Merchant uploads,
                          including Merchant’s trademarks, trade names and other
                          intellectual property associated with Merchant and the
                          Merchant SKUs.{' '}
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          The Brand Manager Tool includes tools that enable
                          Merchant the ability to manage Merchant SKUs within
                          Merchant’s VideoShops dashboard (the “
                          <b>
                            <u>Merchant Dashboard</u>
                          </b>
                          ”) and contains “near-real-time” data and insights
                          with respect to the performance of Merchant SKUs on
                          the Platform. The Dashboard also includes Merchant’s
                          specific sales reports and sufficient data to verify
                          Merchant’s sales and payments (subject to the “Program
                          Policies” (as defined below).{' '}
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          Merchant can add other individuals who are authorized
                          to manage Merchant’s account (each a “
                          <b>
                            <u>Merchant Authorized User</u>
                          </b>
                          ”).&nbsp; Merchant is solely responsible for managing
                          its access credentials to its Brand Manager Tool and
                          is further solely responsible for any use of such
                          credentials.&nbsp; Merchant is responsible for
                          advising VideoShops immediately if any Merchant
                          Authorized User is no longer authorized by the
                          Merchant to access the Brand Manager Tool and needs to
                          be disabled.&nbsp;{' '}
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}> </span>
                      </p>
                    </td>
                    <td
                      width={303}
                      valign="top"
                      style={{
                        width: '227.45pt',
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderBottom: 'solid black 1.0pt',
                        borderRight: 'solid black 1.0pt',
                        padding: '4.0pt 4.0pt 4.0pt 4.0pt',
                        height: '434.55pt'
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          Merchant agree that any Merchant Content furnished by
                          or on behalf of the Merchant for use under this
                          Agreement, including to market and promote the
                          Merchant and Merchant SKUs through and in connection
                          with VideoShops’ Platform, shall at all times adhere
                          to{' '}
                        </span>
                        <a href="https://policies.nowwith.com/brand-program-policies">
                          <span style={{ fontSize: '11.0pt', color: 'blue' }}>
                            Program Policies
                          </span>
                        </a>
                        <span style={{ fontSize: '11.0pt' }}>
                          .&nbsp; Merchant shall cooperate and/or facilitate, as
                          needed, in providing required product information and
                          coordinating API or other automated data feed to
                          ingest the Merchant’s Merchant SKU catalog information
                          in accordance with the Program Policies.&nbsp;
                          Merchant and Merchant SKUs will be featured and will
                          need to be tagged by Merchant in all relevant search
                          criteria associated with the provided Merchant SKU
                          product description upon ingestion and acceptance into
                          the Platform.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: '302.3pt' }}>
                    <td
                      width={667}
                      valign="top"
                      style={{
                        width: '500.15pt',
                        border: 'solid black 1.0pt',
                        borderTop: 'none',
                        padding: '4.0pt 4.0pt 4.0pt 4.0pt',
                        height: '302.3pt'
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <b>
                          <u>
                            <span style={{ fontSize: '11.0pt' }}>
                              What are VideoShops’ Fees?{' '}
                            </span>
                          </u>
                        </b>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <u>
                          <span style={{ fontSize: '11.0pt' }}>
                            <span style={{ textDecoration: 'none' }}>
                              &nbsp;
                            </span>
                          </span>
                        </u>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          VideoShops will charge the Merchant:
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <u>
                          <span style={{ fontSize: '11.0pt' }}>
                            <span style={{ textDecoration: 'none' }}>
                              &nbsp;
                            </span>
                          </span>
                        </u>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>(i)&nbsp; </span>
                        <span style={{ fontSize: '11.0pt' }}>
                          As part of the Merchant onboarding to Videoshops
                          Platform via Brand Manager Tool, the Merchant selects
                          a commission rate which can be set between 10% to 50%
                          (in increments of 10%){' '}
                        </span>
                        <span style={{ fontSize: '11.0pt' }}>
                          of the price of the Merchant SKUs “Net Sales”
                          generated by each Seller (“
                          <b>
                            <u>Seller Commission</u>
                          </b>
                          ”)
                        </span>
                        <span style={{ fontSize: '11.0pt' }}>
                          , unless such Commission Rate is agreed otherwise in
                          written between the Merchant and Videoshops
                        </span>
                        <span style={{ fontSize: '11.0pt' }}>
                          . Net Sales equal to all the sales generated by each
                          Seller of the Merchant SKUs less returns, refunds and
                          cancellations.&nbsp;{' '}
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          (ii) five percent (5%) as a fee for each VideoShops
                          powered merchant transaction on Seller’s VideoShops as
                          a percentage of the price of the Merchant SKUs (“
                          <b>
                            <u>VideoShops’ Fee</u>
                          </b>
                          ”), and{' '}
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          (iii) 2.9% plus a $0.30 per transaction (“
                          <b>
                            <u>Payment Fees</u>
                          </b>
                          ”)
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <u>
                          <span style={{ fontSize: '11.0pt' }}>
                            <span style={{ textDecoration: 'none' }}>
                              &nbsp;
                            </span>
                          </span>
                        </u>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          The Seller Commission, VideoShops’ Fees and the
                          Payment Fees are sometimes collectively referred to as
                          the “
                          <b>
                            <u>Fees</u>
                          </b>
                          ”
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          Merchant agrees that the sales prices of the Merchant
                          SKUs available to Customers on the Platform cannot be
                          higher than the sale prices offered by Merchant on
                          Merchant’s own website.&nbsp;{' '}
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          VideoShops does not guarantee that any Merchant SKUs
                          will be selected by Sellers for inclusion in Seller
                          Content, nor is VideoShops required to make any
                          particular Merchant SKUs available for sale and may
                          remove any Merchant SKU from the Platform (in
                          VideoShops’ sole discretion) in accordance with the{' '}
                        </span>
                        <a href="https://policies.nowwith.com/brand-program-policies">
                          <span
                            style={{
                              fontSize: '11.0pt',
                              textDecoration: 'none'
                            }}
                          >
                            Program Policies
                          </span>
                        </a>
                        <span style={{ fontSize: '11.0pt' }}>.</span>
                      </p>
                    </td>
                    <td
                      width={303}
                      valign="top"
                      style={{
                        width: '227.45pt',
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderBottom: 'solid black 1.0pt',
                        borderRight: 'solid black 1.0pt',
                        padding: '4.0pt 4.0pt 4.0pt 4.0pt',
                        height: '302.3pt'
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: '398.55pt' }}>
                    <td
                      width={667}
                      valign="top"
                      style={{
                        width: '500.15pt',
                        border: 'solid black 1.0pt',
                        borderTop: 'none',
                        padding: '4.0pt 4.0pt 4.0pt 4.0pt',
                        height: '398.55pt'
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <b>
                          <u>
                            <span style={{ fontSize: '11.0pt' }}>
                              Who collects the money and how is the product
                              shipped?
                            </span>
                          </u>
                        </b>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <u>
                          <span style={{ fontSize: '11.0pt' }}>
                            <span style={{ textDecoration: 'none' }}>
                              &nbsp;
                            </span>
                          </span>
                        </u>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          VideoShops allows Merchants to list and sell their
                          products on VideoShops’ Platform that may be sold by
                          Sellers. Customers place their orders through Seller’s
                          checkout which utilizes VideoShops’ payment service
                          provider. VideoShops’ payment service provider
                          processes the payment for the benefit of the Merchant.
                          VideoShops’ payment service provider automatically
                          deducts Fees and credits the balance to Merchant’s
                          registered bank account: it is that simple. VideoShops
                          only facilitates the transactions conducted on
                          VideoShops’ Platform and does not act as the merchant
                          of record or as the seller of record. Accordingly, the
                          contract formed by the completion of a sale for the
                          Merchant SKU is a contract between the Merchant and
                          the Customer. VideoShops is not a party to that
                          contract nor does VideoShops accept any liability,
                          responsibility or obligation for the terms therein
                          (and VideoShops is not the Seller’s agent). The
                          Merchant is solely responsible for the sale of the
                          Merchant SKUs and is solely responsible for customer
                          service issues in connection therewith.
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          &nbsp;Merchant must ship Merchant SKUs sold directly
                          to the Customer in accordance with the terms of this
                          Agreement.&nbsp; VideoShops does not maintain any
                          product inventory to fulfill Customer orders.&nbsp;
                          All necessary information related to each order placed
                          on the Platform, including order number, product and
                          variant details, pricing, necessary shipping
                          information and any other Customer data necessary for
                          the Merchant to fulfill shipping the Merchant SKU(s)
                          purchased by a Customer shall be available in the
                          Brand Manager Tool.
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        &nbsp;
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          Merchant must provide VideoShops with valid bank
                          account information for a bank account acceptable by
                          VideoShops. The Merchant authorizes VideoShops to
                          verify the Merchant’s information from time to
                          time.&nbsp; The Merchant shall provide VideoShops (or
                          its authorized designees) with any other information
                          that may be required to account to the Merchant for
                          such payments in compliance with applicable
                          laws.&nbsp; In the event the Merchant does not provide
                          banking information as required hereunder, VideoShops
                          shall accrue sums payable to the Merchant until the
                          Merchant provides VideoShops with such required
                          information, but in no event shall VideoShops be
                          required to accrue any sums for the Merchant’s benefit
                          for longer than 12 months (after which time VideoShops
                          shall have no responsibility or liability for the
                          same).
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          Payments made to the Merchant, as reduced by all
                          deductions or withholdings described in the Agreement,
                          will constitute full payment and settlement to the
                          Merchant of amounts payable under the Agreement.
                        </span>
                      </p>
                    </td>
                    <td
                      width={303}
                      valign="top"
                      style={{
                        width: '227.45pt',
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderBottom: 'solid black 1.0pt',
                        borderRight: 'solid black 1.0pt',
                        padding: '4.0pt 4.0pt 4.0pt 4.0pt',
                        height: '398.55pt'
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: '94.05pt' }}>
                    <td
                      width={667}
                      valign="top"
                      style={{
                        width: '500.15pt',
                        border: 'solid black 1.0pt',
                        borderTop: 'none',
                        padding: '4.0pt 4.0pt 4.0pt 4.0pt',
                        height: '94.05pt'
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <b>
                          <u>
                            <span style={{ fontSize: '11.0pt' }}>
                              What are the returns policies?
                            </span>
                          </u>
                        </b>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <u>
                          <span style={{ fontSize: '11.0pt' }}>
                            <span style={{ textDecoration: 'none' }}>
                              &nbsp;
                            </span>
                          </span>
                        </u>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          The Merchant may set their own returns policy. Unless
                          the “Same Day Pay” feature is selected by the Seller,
                          VideoShops will release the Seller Commission and
                          VideoShops’ Fee automatically after a 30 day period.
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          In the event that a Customer initiates a return on an
                          order during the 30 day period, VideoShops will return
                          (i) the VideoShops’ Fee (except as otherwise
                          specifically provided herein), and (ii) the Seller
                          Commission to Merchant in accordance with VideoShops’
                          payment service provider.
                          <b>&nbsp; </b>Merchant is responsible to return the
                          entire amount back to the customer once the product is
                          returned.{' '}
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          If any excess payment has been made to the Merchant
                          for any reason whatsoever, VideoShops reserve the
                          right to adjust or offset the same against any monies
                          paid or payable to Merchant under the Agreement.
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <b>
                          <u>
                            <span style={{ fontSize: '11.0pt' }}>
                              Same-Day Pay: an optional feature selected by
                              enrollment
                            </span>
                          </u>
                        </b>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{
                          marginLeft: '0in',
                          textIndent: '-.1pt',
                          background: 'white'
                        }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          VideoShops offers “
                          <b>
                            <u>Same Day Pay</u>
                          </b>
                          ”, a feature that affords Sellers the ability to
                          receive payment for their sales on the same day that
                          an order is successfully placed.&nbsp; In the standard
                          payout cycle, Merchant’s proceeds are escrowed by the
                          payment service provider utilized by VideoShops and
                          amounts are reconciled 30 days after the successful
                          placement of the respective order. Merchants that
                          select “Same Day Pay” waive any claims for the return
                          of VideoShops’ Fee and the Seller Commission related
                          to cancellations, returns and refunds. In exchange for
                          a shorter cash conversion cycle (which is more
                          favorable to Sellers than current market standards),
                          Merchant assumes the risk of paying out the Seller
                          Commissions and VideoShops’ Fee in the event of
                          returns and refunds.{' '}
                        </span>
                      </p>
                    </td>
                    <td
                      width={303}
                      valign="top"
                      style={{
                        width: '227.45pt',
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderBottom: 'solid black 1.0pt',
                        borderRight: 'solid black 1.0pt',
                        padding: '4.0pt 4.0pt 4.0pt 4.0pt',
                        height: '94.05pt'
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                    </td>
                  </tr>
                  <tr style={{ height: '73.85pt' }}>
                    <td
                      width={667}
                      valign="top"
                      style={{
                        width: '500.15pt',
                        border: 'solid black 1.0pt',
                        borderTop: 'none',
                        padding: '4.0pt 4.0pt 4.0pt 4.0pt',
                        height: '73.85pt'
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <b>
                          <u>
                            <span style={{ fontSize: '11.0pt' }}>
                              Customer Support
                            </span>
                          </u>
                        </b>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          Merchant will be responsible for first level customer
                          support with respect to any Merchant SKUs and orders.
                          The Merchant is responsible for maintaining accurate
                          customer service contact details and a link to their
                          terms of service within Videoshops' Platform.
                        </span>{' '}
                      </p>
                    </td>
                    <td
                      width={303}
                      valign="top"
                      style={{
                        width: '227.45pt',
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderBottom: 'solid black 1.0pt',
                        borderRight: 'solid black 1.0pt',
                        padding: '4.0pt 4.0pt 4.0pt 4.0pt',
                        height: '73.85pt'
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      width={667}
                      valign="top"
                      style={{
                        width: '500.15pt',
                        border: 'solid white 1.0pt',
                        borderTop: 'none',
                        padding: '0in 5.75pt 0in 5.75pt'
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <b>
                          <u>
                            <span style={{ fontSize: '11.0pt' }}>
                              What rights does Merchant grant to VideoShops?
                            </span>
                          </u>
                        </b>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          Merchant grants to VideoShops, licensees, designees,
                          successors and assigns (including Sellers, as
                          applicable), the following rights throughout the
                          world:
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          (i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the
                          right to share, sell, feature, promote Merchant in
                          VideoShops’ public relations and promotional materials
                          including all media now known or hereafter
                          devised.&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        &nbsp;
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        (ii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; t
                        <span style={{ fontSize: '11.0pt' }}>
                          he right to use the Merchant SKUs and/or all or any
                          portion of Merchant Content in connection with the
                          following:{' '}
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          (iii) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the dissemination
                          of information about Merchant and/or any Merchant SKUs
                          in connection with the marketing and/or sale of
                          Merchant SKUs to Customers.{' '}
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          (iv) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the creation and
                          use of marketing and promotional materials, in any
                          format or media now known or hereafter devised, that
                          promote the Merchant, the Merchant Content and/or any
                          Merchant SKUs, the distribution of Seller Content and
                          VideoShops;{' '}
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          (v) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; permission
                          for VideoShops, and for any Seller, to (A)
                          “whitelist”, “boost” and/or otherwise support via paid
                          media any posts of Merchant Content (including Seller
                          Content), and (B) tag Merchant’s handles in social
                          media posts that includes Merchants’ shoppable
                          products.
                        </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>
                          (vi)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Edit, adapt
                          and otherwise modify Merchant Content to ensure
                          quality control, to make the content interactive and
                          shoppable.&nbsp; The foregoing rights do not include
                          any right to edit or modify any of the Merchant’s
                          trademarks (other than for proportionately
                          resizing).&nbsp; The Merchant acknowledges that the
                          Merchant does not have any right to control or take
                          down any Seller Content or any Merchant SKUs included
                          in Seller Content or otherwise on any of the
                          Platform’s editorial or video player pages, other than
                          on the VideoShops’ Platform or as permitted in
                          accordance with the{' '}
                        </span>
                        <a href="https://policies.nowwith.com/brand-program-policies">
                          <span
                            style={{
                              fontSize: '11.0pt',
                              textDecoration: 'none'
                            }}
                          >
                            Program Policies
                          </span>
                        </a>
                        <span style={{ fontSize: '11.0pt' }}>.&nbsp; </span>
                      </p>
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                    </td>
                    <td
                      width={303}
                      valign="top"
                      style={{
                        width: '227.45pt',
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderBottom: 'solid white 1.0pt',
                        borderRight: 'solid white 1.0pt',
                        padding: '0in 5.75pt 0in 5.75pt'
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{ marginLeft: '0in', textIndent: '-.1pt' }}
                      >
                        <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <a />
                <b>
                  <u>
                    <span style={{ fontSize: '11.0pt' }}>
                      Merchant’s responsibilities
                    </span>
                  </u>
                </b>
                <b>
                  <span style={{ fontSize: '11.0pt' }}>:</span>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <a />
                <span style={{ fontSize: '11.0pt' }}>
                  The Merchant agrees to comply with, VideoShops’ Terms of Use,
                  Privacy Policy and other policies made available on the
                  Platform (collectively, the “
                </span>
                <a href="https://policies.nowwith.com/brand-program-policies">
                  <span style={{ fontSize: '11.0pt', color: 'blue' }}>
                    Program Policies
                  </span>
                </a>
                <span style={{ fontSize: '11.0pt', color: '#4472C4' }}> </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <a />
                <b>
                  <u>
                    <span style={{ fontSize: '11.0pt' }}>
                      Third Party Rights and Obligations
                    </span>
                  </u>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  In the event the Merchant includes name, license or other
                  materials owned by third parties in Merchant Content, the
                  Merchant shall be solely responsible for obtaining and/or
                  paying for all licenses, waivers, clearances and other
                  approvals or payment obligations required (“
                  <b>
                    <u>Third Party Obligations</u>
                  </b>
                  ”) so that the Merchant Content may be used in all manners
                  contemplated in this Agreement without restriction in
                  accordance with the Program Policies.&nbsp; In such
                  connection, the Merchant represents and warrants that the
                  Merchant has secured (and paid for, if applicable) all
                  consents, permissions and releases required to allow the
                  Merchant Content featuring any third party and/or third party
                  content (such as music) to be used by or on behalf of the
                  Merchant and VideoShops (and its permitted licensees and
                  designees) shall have no obligations, financial or otherwise,
                  in connection therewith.&nbsp;{' '}
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;&nbsp;&nbsp; </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  “
                  <b>
                    <u>VideoShops’ Property</u>
                  </b>
                  ” shall mean VideoShops’ name as well as the brands{' '}
                  <b>
                    <i>MYVIDEOSHOP(S), NOWWITH, NOW//WITH, SHOPPERTAINMENT</i>
                  </b>{' '}
                  and all related names, trademarks, trade names, product names,
                  service names, service marks, trade dress, designs and design
                  marks, copyrights, slogans, logos or other intellectual
                  property and/or other identifiers of VideoShops, including any
                  discoveries, inventions, innovations, improvements, data,
                  technology, know-how, trade secrets, copyrights and other
                  intellectual property that is owned and/or controlled by
                  VideoShops.&nbsp; During the Term of this Agreement,
                  VideoShops grants the Merchant the limited, non-exclusive,
                  revocable, royalty-free right to use, display, publish and/or
                  reference VideoShops’ company name, the word mark VIDEOSHOPS,
                  MYVIDEOSHOPS, MY VIDEOSHOP, NOWWITH, NOW//WITH and VideoShops’
                  official logo marks for each (the “
                  <b>
                    <u>Licensed VideoShops’ Property</u>
                  </b>
                  ”) solely as embodied and/or referenced in Merchant Content
                  produced and used by the Merchant as permitted in accordance
                  with the terms of this Agreement including the{' '}
                </span>
                <a href="https://policies.nowwith.com/brand-program-policies">
                  <span style={{ fontSize: '11.0pt', color: 'blue' }}>
                    Program Policies
                  </span>
                </a>
                <span style={{ fontSize: '11.0pt' }}>
                  {' '}
                  exclusively on the Platform, provided that the Merchant shall
                  not (i) use VideoShops’ Property other than the Licensed
                  VideoShops’ Property as expressly permitted hereunder, (ii)
                  use Licensed VideoShops’ Property in any manner to suggest any
                  sponsorship, affiliation, endorsement or other connection
                  between the Merchant and VideoShops (or any third party) other
                  than in connection with&nbsp; the Merchant’s participation in
                  the Merchant Platform (unless otherwise agreed by the Merchant
                  and VideoShops in writing, if applicable) and/or (iii) use
                  and/or reference VideoShops’ Property in any manner, and/or
                  otherwise take any action, that may harm or otherwise
                  adversely affect the VideoShops’ Property and/or the goodwill
                  associated with the VideoShops’ Property.&nbsp;{' '}
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  The Merchant acknowledges and agrees that VideoShops has
                  exclusive ownership rights in and to the VideoShops’
                  Property.&nbsp; The Merchant agrees not to authorize or assist
                  any third party to, (x) take any action that might in any way
                  impair the right, title and interest of VideoShops in and to
                  the VideoShops’ Property and/or (y) challenge or otherwise
                  contest the validity, ownership or enforceability of any
                  VideoShops’ Property or bring any action for
                  non-infringement.&nbsp; All uses of VideoShops’ Property shall
                  inure to the benefit of VideoShops, and the Merchant shall not
                  acquire, directly or by implication, any rights or license in
                  the VideoShops’ Property except as expressly provided in this
                  Agreement.&nbsp; The Merchant’s right to use the Licensed
                  VideoShops’ Property will terminate immediately if the
                  Merchant violates any of the terms of this Agreement and shall
                  automatically cease upon the termination of this Agreement for
                  any reason whatsoever. VideoShops shall not acquire any right,
                  title or interest in and to the Merchant’s trademarks, trade
                  names, trade dress or other intellectual property; any
                  goodwill generated in the Merchant’s intellectual property
                  will inure solely to the Merchant.&nbsp;&nbsp;{' '}
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <a />
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  Merchant agrees to the following:&nbsp; Merchant shall not
                  modify any graphic image or text contained on the VideoShops
                  Site in any way, and Merchant shall not utilize any content of
                  the VideoShops Site or any materials that are proprietary to
                  VideoShops, without VideoShops’ prior written permission.
                  Merchant will not frame any page on the VideoShops Site.
                  VideoShops reserves all rights in all graphic images and text
                  contained on VideoShops Site and all other VideoShops
                  Property.&nbsp;{' '}
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  To protect the security of VideoShops’ platform, VideoShops
                  may, but is not required to, impose transaction limits on some
                  or all sales or other transactions through the Platform
                  relating to the value of any transaction or disbursement, the
                  cumulative value of all transactions or disbursements during a
                  period of time, or the number of transactions per day or other
                  period of time and VideoShops shall not be liable to the
                  Merchant in connection therewith.{' '}
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  Any payments (if applicable) to Sellers related to Merchant
                  SKUs or any other authorized Seller Commission payments via
                  the Brand Manager Tool shall be made directly by VideoShops to
                  each such Seller. The Merchant agrees that any payments or
                  transactions related to the Merchant and/or Merchant SKUs
                  within Seller Content distributed via the Platform shall
                  solely take place via tools provided by the VideoShops service
                  and any violations of this shall be cause for immediate
                  termination from the Platform (however, nothing herein shall
                  prevent exclusive endorsement deals between Merchants and
                  Sellers made on a broader basis for content outside of the
                  Platform). Termination shall be in addition to, and not in
                  lieu of, other remedies available to VideoShops at law and/or
                  in equity, including without limitation an action to recover
                  the funds due to VideoShops for a violation of the foregoing.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <a />
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <b>
                  <u>
                    <span style={{ fontSize: '11.0pt' }}>Tax Matters</span>
                  </u>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  Merchant will be solely responsible for the collection,
                  reporting, and payment of any and all of the Merchant’s sales
                  taxes. All fees and payments payable by the Merchant to
                  VideoShops under this Agreement (including the{' '}
                </span>
                <a href="https://policies.nowwith.com/brand-program-policies">
                  <span style={{ fontSize: '11.0pt' }}>
                    Merchant Program&nbsp; Policies
                  </span>
                </a>
                <span style={{ fontSize: '11.0pt' }}>
                  ) are exclusive of any applicable taxes, deductions or
                  withholding (including but not limited to cross-border
                  withholding taxes), and the Merchant will be responsible for
                  paying VideoShops any of the Merchant’s taxes imposed on such
                  fees and any deduction or withholding required on any
                  payment.&nbsp; It is expressly understood that VideoShops may
                  deduct or withhold any taxes that VideoShops may be legally
                  obligated to deduct or withhold from any amounts payable to
                  the Merchant under the Merchant Platform.&nbsp; The Merchant
                  shall provide VideoShops with any tax information that
                  VideoShops may request from the Merchant from time to time to
                  comply with VideoShops’ obligations.&nbsp; If the Merchant
                  does not provide the tax information requested by VideoShops,
                  VideoShops reserves the right (in addition to other rights and
                  remedies available to VideoShops) to withhold the Merchant
                  Revenue Share otherwise payable to the Merchant until the
                  Merchant provide this information or the Merchant provide
                  information that satisfies VideoShops (in VideoShops’
                  discretion).
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  It is expressly understood and agreed that any request by the
                  Merchant to make Third Party Payments hereunder, if
                  accommodated by VideoShops in its sole discretion (with no
                  obligation to do so), shall be subject to the terms and
                  conditions of this Agreement including this Tax Matters
                  section.&nbsp; Without limitation of the foregoing, in no
                  event shall VideoShops make any Third Party Payments unless
                  and until the applicable third party has delivered to
                  VideoShops (or its authorized vendor) all necessary bank
                  information (as described in the Payment to Merchant section
                  above) and other tax information that may be requested from
                  time to time to comply with any tax reporting obligations in
                  connection with such payments (and to keep such information
                  updated as needed, including promptly advising VideoShops of
                  any change to such third party’s address and/or other
                  information provided).&nbsp; VideoShops’ compliance with this
                  authorization will constitute accommodation to the Merchant
                  alone, and nothing herein shall constitute the applicable
                  third party to whom the Merchant directs a Merchant Third
                  Party Payment a beneficiary of this Agreement.&nbsp;
                  VideoShops shall have no responsibility or liability to the
                  Merchant or such third party for erroneous payments made as a
                  result of information provided that is incorrect, incomplete
                  or not properly updated as required hereunder, or failure to
                  comply with the request to make Third Party Payments in its
                  discretion.&nbsp; The Merchant shall indemnify VideoShops for
                  any claims asserted against VideoShops and any damages, losses
                  or expenses incurred by VideoShops by reason of any such Third
                  Party Payments or requests therefor.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <a />
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <b>
                  <u>
                    <span style={{ fontSize: '11.0pt' }}>
                      Confidentiality and Personal Data
                    </span>
                  </u>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <a />
                <span style={{ fontSize: '11.0pt' }}>
                  During the course of the Merchant’s use of VideoShops’
                  Platform, the Merchant may receive Confidential Information. “
                  <b>
                    <u>Confidential Information</u>
                  </b>
                  ” means information relating to VideoShops (and its officers,
                  directors, stockholders, employees or other representatives),
                  the Platform or VideoShops Customers and/or users that is not
                  known to the general public including, but not limited to, any
                  information identifying or unique to specific Customers;
                  reports, insights and other information about the Platform
                  and/or Customers; data derived from the Platform except for
                  data (other than Customer personal data) arising from the sale
                  of the Merchant’s products comprising of products sold,
                  prices, sales, volumes and time of the transaction; and
                  technical or operational specifications relating to the
                  Platform. For the purposes of this Agreement, Customer
                  personal data constitutes Confidential Information at all
                  times which, as between the Merchant and VideoShops, shall be
                  considered VideoShops’ exclusive property and cannot be used
                  or processed by the Merchant in any way inconsistent with
                  applicable law.&nbsp; The Merchant shall only use Customer
                  personal data for the purpose of fulfilling Customer orders
                  under this Agreement unless otherwise expressly agreed by
                  VideoShops in writing (in VideoShops’ sole discretion) or by
                  the applicable Customer through the tools (if any) VideoShops
                  provide to Customer on the Platform.&nbsp; Except to the
                  extent required to fulfill Customer orders, the Merchant shall
                  not resell, transfer, export or otherwise use, exploit or
                  process any such Customer personal data other than as required
                  by law or as expressly agreed by VideoShops in advance in
                  writing, and in all events the Merchant will only use Customer
                  Personal Data with, and in accordance with, a Customer’s
                  express, duly documented consent.&nbsp; The Merchant agree
                  that for the Term of the Agreement and 5 years after
                  termination or such longer period herein prescribed: (a) all
                  Confidential Information will remain VideoShops’ exclusive
                  property (which ownership shall continue in perpetuity after
                  the Term); (b) the Merchant will use Confidential Information
                  only as is reasonably necessary for the Merchant’s
                  participation in the Platform and will not resell or repurpose
                  such information in any manner without obtaining VideoShops’
                  prior written consent (as well as the consent of the
                  applicable Customer); (c) the Merchant will not otherwise
                  disclose Confidential Information to any other person or
                  entity except as required to comply with applicable law and/or
                  to the extent reasonably necessary to perform under this
                  Agreement, and the Merchant shall ensure that all authorized
                  persons or entities who have access to Confidential
                  Information in connection with the Merchant’s account will be
                  made aware of, and required to comply with, the
                  confidentiality obligations herein (provided the Merchant
                  shall remain liable for any breach by such representatives of
                  this paragraph); (d) the Merchant will take all reasonable
                  measures to protect the Confidential Information against any
                  use or disclosure that is not expressly permitted in this
                  Agreement; and (e) the Merchant will retain Confidential
                  Information only for so long as its use is necessary for
                  participation on the Platform or to fulfill the Merchant’s
                  statutory obligations (e.g. tax) and in all cases will delete
                  such information upon termination or as soon as no longer
                  required for the fulfillment of statutory obligations.&nbsp;
                  Notwithstanding the foregoing, the Merchant are not prohibited
                  from sharing Confidential Information as may be required by
                  applicable law to any governmental entity that has
                  jurisdiction over the Merchant, provided that the Merchant
                  limit the disclosure to the minimum necessary and explicitly
                  indicate the confidential nature of the shared information to
                  the governmental entity. The Merchant may not issue any press
                  release or make any public statement related to the Platform,
                  or use VideoShops’ name, trademarks, or logo, in any way
                  (including in promotional material) without VideoShops’
                  advance written permission or as otherwise expressly permitted
                  in this Agreement or misrepresent or embellish the
                  relationship between VideoShops in any way.&nbsp; The Merchant
                  will take no action that would cause VideoShops or the
                  Platform to be in violation of applicable laws, including
                  without limitation any privacy laws, data retention and/or
                  destruction laws, data transfer laws, or otherwise, nor will
                  use any Customer data in a manner contrary to VideoShops’{' '}
                </span>
                <a href="https://policies.nowwith.com/brand-program-policies">
                  <span style={{ fontSize: '11.0pt' }}>Program Policies</span>
                </a>
                <span style={{ fontSize: '11.0pt' }}>. </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <a />
                <b>
                  <u>
                    <span style={{ fontSize: '11.0pt' }}>
                      Required Disclosures / FTC Guidelines
                    </span>
                  </u>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  The Merchant agrees to comply with applicable laws, including
                  FTC guidelines, with respect to the marketing and sale of
                  Merchant SKUs, including as set forth in the{' '}
                </span>
                <a href="https://policies.nowwith.com/brand-program-policies">
                  <span style={{ fontSize: '11.0pt', color: 'blue' }}>
                    Program Policies
                  </span>
                </a>
                <span style={{ fontSize: '11.0pt' }}>
                  .&nbsp; The Merchant understands it is a legal requirement
                  that Customers and other end users clearly understand that
                  Sellers may be entitled to compensation as a result of
                  purchases of products through their Seller Content.&nbsp; The
                  Merchant agree that VideoShops may clearly disclose this
                  financial relationship between the Merchant and the Seller on
                  VideoShops’ Platform and any other location where VideoShops
                  may authorize the Merchant’s display of Seller Content, such
                  as:&nbsp; “Sellers are paid from qualifying purchases of
                  products through the VideoShops Marketplace”.&nbsp; In
                  addition, the Merchant will also need to disclose any
                  endorsement or similar relationship between the Merchant and a
                  Seller to the extent that a separate endorsement or similar
                  relationship exists unrelated to VideoShops.&nbsp; Except as
                  required by applicable law, the Merchant will not make public
                  statements about this Agreement or the details of the
                  Merchant’s participation in the Merchant Platform without
                  VideoShops’ advance written permission.&nbsp; The Merchant
                  will not misrepresent the extent of VideoShops’ relationship
                  with the Merchant (for example, VideoShops is not required to
                  sponsor or endorse the Merchant or the Merchant’s
                  participation on the VideoShops Platform) or otherwise suggest
                  there is any legal or other affiliation between the Merchant
                  and VideoShops or any other person or entity (including any
                  Seller, except to the extent the Merchant do have a separate
                  endorsement, sponsorship or similar relationship that exists
                  outside of the Merchant’s use of the VideoShops Site with a
                  Seller and such statements are permitted by such Seller),
                  except as expressly permitted in the Agreement.&nbsp;
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <b>
                  <u>
                    <span style={{ fontSize: '11.0pt' }}>
                      Representations and Warranties{' '}
                    </span>
                  </u>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  The Merchant represents and warrants to VideoShops that (a)
                  the Merchant is duly organized, validly existing and in good
                  standing under the laws of the applicable jurisdiction in
                  which the Merchant’s business is registered, and the Merchant
                  has the right, power and authority to enter into this
                  Agreement and grant all of the rights granted herein, (b) the
                  Merchant shall comply with the terms of this Agreement
                  (including the{' '}
                </span>
                <a href="https://policies.nowwith.com/brand-program-policies">
                  <span style={{ fontSize: '11.0pt' }}>Program Policies</span>
                </a>
                <span style={{ fontSize: '11.0pt' }}>
                  ) and all applicable laws in the Merchant’s performance of
                  this Agreement, in the creation, production, manufacture,
                  labelling, packaging, distribution, advertising marketing and
                  sale of the Merchant’s merchandise (including without
                  limitation, labor standards, consumer product and safety laws,
                  disclosure of information to consumers and truth in
                  advertising laws and regulations, etc.), and in the processing
                  of any Customer personal data, (c) the merchandise shipped
                  under any purchase order meets its product specifications at
                  the time of shipment and is fit and safe for the use for which
                  it was manufactured, (d) such merchandise (including
                  packaging, labeling and inserts) does not, and the resale
                  thereof by VideoShops will not, violate any applicable law,
                  regulation or ordinance or infringe on any intellectual
                  property or other right of any third party and (e) the
                  Merchant and the Merchant’s affiliates are not subject to any
                  sanctions or prohibitions by any governmental authority.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <a />
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <b>
                  <u>
                    <span style={{ fontSize: '11.0pt' }}>Indemnification</span>
                  </u>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  The Merchant agrees to indemnify, defend, and hold harmless
                  VideoShops and its subsidiary and affiliated companies and
                  related entities, program partners and licensees, and their
                  respective directors, officers, employees, contractors,
                  stockholders, agents, representatives and designees (including
                  Sellers), from and against any and all losses, damages,
                  liabilities, penalties, credit monitoring service costs, data
                  breach notification costs, costs of settlement and expenses
                  (including, without limitation, reasonable attorneys’ fees and
                  expenses) arising from any third party claims, suits,
                  proceedings, demands or causes of action (including, without
                  limitation, governmental actions) (collectively,&nbsp; “
                  <b>
                    <u>Losses</u>
                  </b>
                  ”) arising out of, resulting from, or relating to (a) the
                  design, creation, manufacture, distribution and/or use of the
                  Merchant SKUs, Merchant Content and any information provided
                  by or on behalf of the Merchant in connection therewith,
                  including&nbsp; any death, bodily injury or property damage
                  occurring or alleged to have occurred as a result of products
                  provided by the Merchant, statements, instructions for use or
                  warnings on the label(s), boxes, inserts or other packaging
                  for the Merchant’s products or directions for use or
                  application provided or approved by the Merchant, (b) the
                  negligence or willful misconduct of the Merchant, or the
                  Merchant Authorized Users, employees, contractors, agents or
                  representatives, in the furnishing of merchandise or in the
                  performance of work under any purchase order, (c) any actual
                  or alleged infringement by the Merchant, products provided by
                  the Merchant, or the Merchant’s intellectual property or any
                  third party intellectual property right, (d) any actual or
                  alleged violation of or noncompliance by the Merchant, the
                  Merchant’s products and/or the Merchant’s use of the Platform
                  with any applicable law, regulation or ordinance, (e) any
                  allegation that any product provided by the Merchant was
                  defective or harmful or violated a warranty of the Merchant in
                  any way, (f) any actual or alleged breach by the Merchant of
                  this Agreement, (g) any unauthorized use of the Merchant’s
                  access credentials for the Brand Manager Tool, whether by the
                  Merchant, the Merchant’s Merchant Authorized Users, or any
                  third party, (h) any claims related to the Merchant’s
                  processing (or failure to process) a purchase order as
                  contemplated and required in this Agreement, or (i) the
                  Merchant’s taxes and duties or the collection, payment or
                  failure to collect or pay the Merchant’s taxes or duties, or
                  the failure to meet tax registration obligations or
                  duties.&nbsp; All indemnification obligations of the Merchant
                  under this Agreement will survive termination of this
                  Agreement or any purchase order. Upon the making or filing of
                  any such claim, action or demand, VideoShops shall be entitled
                  to withhold from any amounts payable to the Merchant under
                  this Agreement such amounts as are reasonably related to the
                  potential liability in issue; provided, however, VideoShops
                  shall release any such amounts withheld if no litigation is
                  commenced within twelve (12) months following the assertion of
                  such claim, action or demand unless VideoShops believe, in
                  VideoShops’ reasonable judgment, that such a proceeding may be
                  instituted notwithstanding the passage of time.&nbsp;
                  Notwithstanding the foregoing, VideoShops shall not withhold
                  any monies pursuant to the immediately preceding sentence if
                  the Merchant make bonding arrangements satisfactory to
                  VideoShops in VideoShops’ sole discretion, to assure
                  VideoShops of reimbursement for all damages, liabilities,
                  costs and expenses (including reasonable actual and documented
                  legal expenses and reasonable outside counsel fees) which
                  VideoShops may incur as a result of such claim.&nbsp; The
                  Merchant shall be notified of any such claim, action or demand
                  and shall have the right, at the Merchant’s own expense, to
                  participate in the defense thereof with counsel of the
                  Merchant’s own choosing; provided, however, that VideoShops’
                  decision in connection with the defense of any such claim,
                  action or demand shall be final.{' '}
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <u>
                  <span style={{ fontSize: '11.0pt' }}>
                    <span style={{ textDecoration: 'none' }}>&nbsp;</span>
                  </span>
                </u>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <u>
                  <span style={{ fontSize: '11.0pt' }}>Term; Termination</span>
                </u>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  This Agreement will commence upon the Merchant’s registration
                  to create an account and shall terminate as provided in the
                  next two sentences (the “
                  <b>
                    <u>Term</u>
                  </b>
                  ”).&nbsp; Either party may terminate this Agreement (a) at any
                  time upon 30 days’ written notice; or (b) upon the other
                  party's material breach of this Agreement, provided that the
                  non-breaching party provides 7 days’ written notice to the
                  breaching party and the breach is not cured within such 7-day
                  period.&nbsp; The Merchant can provide termination notice by
                  logging into the Merchant’s account on VideoShops’ Platform
                  and selecting the option to close the Merchant’s account in
                  “Account Settings”.&nbsp; VideoShops may provide written
                  notice to the Merchant of termination through the Merchant’s
                  account on VideoShops’ Platform as well as by sending notice
                  by email to the Merchant’s then-current email address noted in
                  VideoShops’ Platform account.&nbsp; Termination of this
                  Agreement shall not relieve the parties of obligations accrued
                  through the date of termination (including fulfillment of and
                  customer service for any orders placed by Customers prior to
                  the end of the Term and any subsequent shipping, handling,
                  returns, etc. related to such sales that may occur after the
                  end of the Term). All provisions in this Agreement that should
                  by their nature survive termination shall so survive.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  Notwithstanding anything to the contrary contained in this
                  Agreement and for the avoidance of doubt, VideoShops has the
                  right to immediately suspend public access to VideoShops’
                  Platform in its sole business discretion for any violations of
                  this Agreement (including the{' '}
                </span>
                <a href="https://policies.nowwith.com/brand-program-policies">
                  <span style={{ fontSize: '11.0pt', color: 'blue' }}>
                    Program Policies
                  </span>
                </a>
                <span style={{ fontSize: '11.0pt' }}>).</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  Termination of this Agreement shall not affect the rights
                  granted to VideoShops or its designees pursuant to this
                  Agreement during the Term.&nbsp; Accordingly, any rights
                  granted and exercised during the Term, including the
                  distribution of any Merchant Content, the creation of any post
                  on Socials, the creation of any permitted promotional content
                  or the like, shall survive the expiration or termination of
                  this Agreement (e.g., it shall not be a breach of this
                  Agreement if a post on a Social that was properly made prior
                  to the end of the Term continues to be included in a feed
                  after the Term).
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <u>
                  <span style={{ fontSize: '11.0pt' }}>
                    Disclaimer of Liability
                  </span>
                </u>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  Neither VideoShops nor any parent or any of VideoShops’
                  respective affiliates, licensors, partners, officers,
                  directors, stockholders, members, managers, employees or other
                  representatives shall be liable (i) for any matter directly or
                  indirectly relating to the creation, maintenance or operation
                  of VideoShops’ Platform or the Merchant’s violation of this
                  Agreement and/or (ii) for indirect, incidental, special,
                  punitive, consequential, exemplary damages, or any loss of
                  revenue, profits, goodwill, loss of use or loss of data
                  arising in connection with the Platform, even if VideoShops
                  have been advised of the possibility of those damages.&nbsp;
                  Further, VideoShops’ aggregate liability arising in connection
                  with the Agreement will not exceed the total amounts paid to
                  the Merchant under this Agreement in the 12 months immediately
                  preceding the date on which the event giving rise to the most
                  recent claim of liability occurred.&nbsp; The Merchant hereby
                  waives any right or remedy in equity, including the right to
                  seek specific performance, injunctive or other equitable
                  relief, in connection with this Agreement.&nbsp; This
                  paragraph shall be enforced to the maximum extent permitted by
                  applicable law, and nothing in this paragraph will operate to
                  limit liabilities that cannot be limited under applicable law.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  It is expressly agreed that the Platform, and all content and
                  elements thereof and any information provided for the same,
                  are provided “as-is”, and the Merchant agrees that the
                  Merchant is using the Platform at the Merchant’s own
                  risk.&nbsp; To the maximum extent permissible under applicable
                  law and except as agreed in the “Representations and
                  Warranties” section above, VideoShops expressly disclaim any
                  and all representations or warranties related to the Platform,
                  the activities contemplated by this Agreement, including any
                  warranties of merchantability or use, fitness for a particular
                  purpose, title, quiet enjoyment, and non-infringement, as well
                  as any other implied warranties, such as warranties regarding
                  data loss, availability, accuracy, functionality and lack of
                  malware, or damages as the result of the illegal activities of
                  third parties.&nbsp; VideoShops do not provide any guarantee
                  or warranty that the Platform will meet any specific standard
                  or be available for use at any time or time to time, that the
                  Platform will be free of errors, interruptions, harmful
                  components or failures or that transactions may not be
                  impacted by any such failures.&nbsp;&nbsp;{' '}
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <a />
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <b>
                  <u>
                    <span style={{ fontSize: '11.0pt' }}>Force Majeure</span>
                  </u>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  VideoShops will not be liable for any delay or failure to
                  perform any of VideoShops’ obligations under this Agreement by
                  reasons, events or other matters beyond VideoShops’ reasonable
                  control, including without limitation telecommunication
                  failures, utility failures, or equipment failures; labor
                  disputes, riots, war, or terrorist attacks; nonperformance of
                  VideoShops’ vendors or suppliers, fires or acts of God; or any
                  other event over which VideoShops have no reasonable control.
                  For the avoidance of doubt, nothing in this Agreement will
                  excuse the Merchant’s payment obligations to VideoShops.&nbsp;{' '}
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <u>
                  <span style={{ fontSize: '11.0pt' }}>
                    Successors; Assignment; Waiver{' '}
                  </span>
                </u>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <a />
                <span style={{ fontSize: '11.0pt' }}>
                  This Agreement shall be binding upon and inure to the benefit
                  of the parties and their respective successors in interest and
                  permitted assigns.&nbsp; The Merchant may not assign this
                  Agreement, in whole or in part, without VideoShops’ prior
                  written consent.&nbsp; No failure by VideoShops to enforce any
                  provision of this Agreement (including the{' '}
                </span>
                <a href="https://policies.nowwith.com/brand-program-policies">
                  <span style={{ fontSize: '11.0pt', color: 'blue' }}>
                    Program Policies
                  </span>
                </a>
                <span style={{ fontSize: '11.0pt' }}>
                  ) shall constitute a waiver of VideoShops’ right to
                  subsequently enforce such provision or any other provision of
                  the Agreement.
                  <br />
                  <br />
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <u>
                  <span style={{ fontSize: '11.0pt' }}>
                    Independent Contractors{' '}
                  </span>
                </u>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  The Merchant and VideoShops are independent contractors, and
                  nothing in this Agreement will create any partnership, joint
                  venture, agency, franchise, sales representative, or
                  employment relationship between the Merchant and VideoShops or
                  VideoShops’ respective affiliates. The Merchant will have no
                  authority to make or accept any offers or representations on
                  behalf of VideoShops or its affiliates.{' '}
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <u>
                  <span style={{ fontSize: '11.0pt' }}>
                    Governing Law and Jurisdiction; Severability
                  </span>
                </u>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  This Agreement and the rights and obligations of the parties
                  shall be governed by and construed according to the laws of
                  the state of New York, without regard to its choice of law
                  provisions.{' '}
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  To the fullest extent permitted by applicable law, neither the
                  Merchant nor VideoShops will seek to have a dispute heard as a
                  class action or private attorney general action or in any
                  other proceeding in which either party acts or proposes to act
                  in a consolidated or representative capacity, regardless of
                  the forum or venue. No arbitration or proceeding can be
                  combined with another without the prior written consent of all
                  parties to the arbitrations or proceedings.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  The Merchant and VideoShops agree to arbitrate, as provided
                  below, all disputes between the parties hereto, that are not
                  resolved informally, except with respect to any claim for a
                  preliminary injunction or similar equitable relief related to
                  the ownership or enforcement of intellectual property rights
                  and/or any misuse of such intellectual property rights (“
                  <b>
                    <u>Excluded Claims</u>
                  </b>
                  ”).&nbsp; This obligation and agreement to arbitrate applies
                  regardless of whether the underlying action arises in
                  contract, warranty, tort, or otherwise, and relates to claim
                  related to or arising under this Agreement, its
                  enforceability, and any claim regarding the agreement to
                  arbitrate and waive the right to participate in any class
                  action type claims or otherwise.&nbsp;{' '}
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  If the parties hereto cannot amicably resolve a dispute, the
                  same will be resolved by binding arbitration before a neutral
                  arbitrator(s).&nbsp; The arbitration will be conducted by the
                  American Arbitration Association (AAA) under its commercial
                  rules.&nbsp; The parties will cooperate with AAA and with one
                  another in selecting a mutually approved arbitrator from the
                  AAA panel of neutrals with at least 10 years of experience in
                  the applicable area(s) of the disputes.&nbsp; If the parties
                  cannot agree on one arbitrator within 15 days of the
                  commencement of the arbitration, the parties shall each select
                  an arbitrator with the qualifications specified in this
                  paragraph within 15 days, and those two arbitrators shall
                  mutually select a third arbitrator, who shall serve as Chair
                  of the arbitration tribunal, within 15 days of their
                  appointment.&nbsp; Notwithstanding the foregoing, in the event
                  that the claims involve over $1,000,000 in dispute, either
                  party may require the appointment of three arbitrators in
                  accordance with the procedure in the preceding sentence.&nbsp;
                  The determination and award of the arbitrator(s) shall be
                  based upon application of New York law.&nbsp; Payment of all
                  filing, administration and arbitrator fees will be governed by
                  the AAA’s rules.&nbsp; The decision of such arbitrator(s) will
                  be final except for a limited right of appeal under the U.S.
                  Federal Arbitration Act.&nbsp; BOTH PARTIES HERETO ARE GIVING
                  UP AND WAIVING THE RIGHT TO LITIGATE A DISPUTE BEFORE A JURY
                  OR A JUDGE IN COURT OTHER THAN WITH RESPECT TO EXCLUDED
                  CLAIMS. Arbitration will be conducted either electronically
                  (by telephone or other means of video conference as permitted
                  by the American Arbitration Association) or in the borough of
                  Manhattan, New York, New York. The Merchant and VideoShops
                  hereby agree to submit to the exclusive jurisdiction of the
                  federal courts for the Southern District of New York and the
                  state courts city in New York, New York, where necessary
                  (e.g.) to compel arbitration, to stay any filed proceedings
                  pending arbitration, or to confirm, modify, vacate, or enter
                  judgment on any award by the arbitrator.&nbsp;&nbsp;{' '}
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  To the extent litigation or other proceeding is permitted in
                  local or federal courts hereunder, each party irrevocably
                  submits to the exclusive jurisdiction of the federal courts
                  for the Southern District of New York and state court of the
                  State of New York located in New York, NY, United States, and
                  the Merchant expressly waive any objection to the personal
                  jurisdiction and venue of such courts, including, without
                  limitation, on grounds of forum non conveniens.&nbsp;
                  Notwithstanding anything to the contrary contained in this
                  Agreement, VideoShops may seek injunctive or other relief in
                  any state, federal or national court of competent jurisdiction
                  for any infringement of VideoShops’ or any other person or
                  entity’s intellectual property or proprietary rights.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  For service of process and notices of claims, the then-current
                  contact person/entity noted in the Merchant’s account shall be
                  authorized to receive and accept service of process on the
                  Merchant’s behalf.&nbsp;{' '}
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  In the event any provision of this Agreement is determined to
                  be invalid or unenforceable for any reason, such provision
                  shall be amended solely to the extent necessary to make such
                  provision enforceable to the maximum extent possible and such
                  provision shall be so enforced to give effect to the intent of
                  the same.&nbsp; Any such invalid or modified provision shall
                  in no way affect the validity or enforceability of any other
                  provisions of the Agreement.&nbsp;{' '}
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <u>
                  <span style={{ fontSize: '11.0pt' }}>
                    Entire Agreement; Interpretation
                  </span>
                </u>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>
                  This Agreement (including the{' '}
                </span>
                <a href="https://policies.nowwith.com/brand-program-policies">
                  <span style={{ fontSize: '11.0pt', color: 'blue' }}>
                    Program Policies
                  </span>
                </a>
                <span style={{ fontSize: '11.0pt' }}>
                  ) constitutes the entire agreement between the Merchant and
                  VideoShops with respect to the subject matter thereof and
                  supersede all prior agreements.&nbsp; Except as expressly
                  specified herein, no amendment, waiver, or discharge of any
                  provision of this Agreement will be effective unless made in
                  writing signed by VideoShops and the Merchant (except where
                  changes to the Platform may be binding upon the Merchant as
                  provided herein).&nbsp; In the event of a conflict between
                  this Agreement and any purchase order or other pre-printed
                  terms on forms submitted by the Merchant, this Agreement shall
                  control, and no pre-printed terms on any documents the
                  Merchant submits to VideoShops will serve to supplement this
                  Agreement and its terms.&nbsp; If an ambiguity or question of
                  intent or interpretation arises, this Agreement will be
                  construed as if drafted jointly by the parties to this
                  Agreement, and no presumption or burden of proof will arise
                  favoring or disfavoring any party to this Agreement by virtue
                  of the authorship of any of the provisions of this Agreement.
                </span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <a />
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <b>
                  <u>
                    <span style={{ fontSize: '11.0pt' }}>
                      Acceptance of the Agreement
                    </span>
                  </u>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <b>
                  <span style={{ fontSize: '11.0pt' }}>
                    By clicking “Accept” at the bottom of this Agreement to
                    acknowledge acceptance of this Agreement, the account owner
                    represents and warrants that: (i) the individual reviewing
                    and accepting this Agreement on behalf of the registered
                    owner of this account (“
                    <u>Merchant</u>”) is at least 18 years of age and has the
                    full legal capacity and authority to enter into this
                    Agreement on the Merchant’s behalf and bind the Merchant to
                    the terms and conditions of this Agreement, (ii) the
                    individual, on behalf of the Merchant, has reviewed and
                    understands this Agreement, and (iii) the Merchant shall be
                    bound by all of the terms of this Agreement and any other
                    agreements referenced in this Agreement.&nbsp;{' '}
                  </span>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <b>
                  <span style={{ fontSize: '11.0pt' }}>
                    VideoShops has the right to modify the terms and conditions
                    of this Agreement from time to time in VideoShops’ sole
                    discretion by posting a revised Agreement (including any
                    revised Program Policies) on the Platform.&nbsp; The
                    Merchant understands and agrees that the Merchant’s
                    continued use of the Platform after the effective date of
                    any change to this Agreement will be deemed to constitute
                    the Merchant’s acceptance of such change.&nbsp; If any
                    change is unacceptable to the Merchant, the Merchant
                    acknowledges and agrees that the Merchant’s only recourse is
                    to discontinue the use of the Platform and to end the
                    Agreement as provided below.&nbsp;{' '}
                  </span>
                </b>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                <span style={{ fontSize: '11.0pt' }}>&nbsp;</span>
              </p>
              <p
                className="MsoNormal"
                style={{ marginLeft: '0in', textIndent: '-.1pt' }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;{' '}
              </p>
            </div>
          </>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default MerchantAgreement
