import BackgroundImageUpload from '../OnboardingComp/OnbaordingSteps/BackgroundImageUpload/BackgroundImageUpload'
import CreatorSignupForm from '../OnboardingComp/OnbaordingSteps/CreatorSignupForm/CreatorSignupForm'
import MarketPlaceDescription from '../OnboardingComp/OnbaordingSteps/MarketPlaceDescription/MarketPlaceDescription'
import MarketPlaceName from '../OnboardingComp/OnbaordingSteps/MarketPlaceName/MarketPlaceName'
import CurrencyCommision from '../OnboardingComp/OnbaordingSteps/CurrencyCommision/CurrencyCommision'
import MarketPlaceURL from '../OnboardingComp/OnbaordingSteps/MarketPlaceURL/MarketPlaceURL'
import MarketPlaceAffinities from '../OnboardingComp/OnbaordingSteps/MarketPlaceAffinities/MarketPlaceAffinities'
import BrandDirectoryThumbnail from '../OnboardingComp/OnbaordingSteps/BrandDirectoryThumbnail/BrandDirectoryThumbnail'
import ProfileImageUpload from '../OnboardingComp/OnbaordingSteps/ProfileImageUpload/ProfileImageUpload'
import SMSValidation from '../OnboardingComp/OnbaordingSteps/SMSValidation/SMSValidation'
import VendorSignupForm from '../OnboardingComp/OnbaordingSteps/VendorSignupForm/VendorSignupForm'
import { BRAND_ONBOARDING_STEPS, SELLER_ONBOARDING_STEPS } from '@/constants/onboarding.constant'

export const generateCreatorOnboardingSteps = (currentStep: number) => {
  switch (currentStep) {
    case SELLER_ONBOARDING_STEPS.SELLER_VIDEOSHOP_NAME:
      return <MarketPlaceName />
    case SELLER_ONBOARDING_STEPS.SELLER_PROFILE_IMAGE:
      return <ProfileImageUpload />
    case SELLER_ONBOARDING_STEPS.SELLER_BACKGROUND_IMAGE:
      return <BackgroundImageUpload />
    case SELLER_ONBOARDING_STEPS.SELLER_DESCRIPTION:
      return <MarketPlaceDescription />
    case SELLER_ONBOARDING_STEPS.SELLER_STORE_URL:
      return <MarketPlaceURL />
    case SELLER_ONBOARDING_STEPS.SELLER_SIGN_UP:
      return <CreatorSignupForm />
    default:
      return <p>Someting went wrong please refresh</p>
  }
}

export const generateVendorOnboardingSteps = (currentStep: number) => {
  switch (currentStep) {
    case BRAND_ONBOARDING_STEPS.BRAND_SIGN_UP:
      return <VendorSignupForm />
    case BRAND_ONBOARDING_STEPS.BRAND_SMS_VALIDATION:
      return <SMSValidation />
    case BRAND_ONBOARDING_STEPS.BRAND_MARKETPLACE_NAME:
      return <MarketPlaceName />
    case BRAND_ONBOARDING_STEPS.BRAND_PROFILE_IMAGE:
      return <ProfileImageUpload />
    case BRAND_ONBOARDING_STEPS.BRAND_BACKGROUND_IMAGE:
      return <BackgroundImageUpload />
    case BRAND_ONBOARDING_STEPS.BRAND_THUMBNAIL_IMAGE:
      return <BrandDirectoryThumbnail />
    case BRAND_ONBOARDING_STEPS.BRAND_DESCRIPTION:
      return <MarketPlaceDescription />
    case BRAND_ONBOARDING_STEPS.BRAND_AFFINITIES:
      return <MarketPlaceAffinities />
    case BRAND_ONBOARDING_STEPS.BRAND_COMMISSION:
      return <CurrencyCommision />
    case BRAND_ONBOARDING_STEPS.BRAND_STORE_URL:
      return <MarketPlaceURL />
    default:
      return <p>Someting went wrong please refresh</p>
  }
}
