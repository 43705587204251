export const isProduction =
  location.host.includes('videoshops.com') &&
  !location.host.includes('b4.') &&
  !location.host.includes('my.')
export const isQa = location.host.includes('b4.videoshops.com')
export const isDev =
  location.host.includes('my.videoshops.com') || location.host.includes('localhost')

const CONTACT_API_BASE_URL_DEV =
  'https://rthwbsmnbkz4hsopb4evyul76e0rmsyl.lambda-url.us-east-1.on.aws/api/contact'
const CONTACT_API_BASE_URL_QA =
  'https://6f2fhrnqyytc4zuvnawyviyx7q0afprx.lambda-url.us-east-1.on.aws/api/contact'
const CONTACT_API_BASE_URL_PRODUCTION =
  'https://lp4ziy4cuh35ngio44daa527fy0qzdnw.lambda-url.us-east-1.on.aws/api/contact'

const API_BASE_URL_DEV = 'https://rthwbsmnbkz4hsopb4evyul76e0rmsyl.lambda-url.us-east-1.on.aws/api'
const API_BASE_URL_QA = 'https://6f2fhrnqyytc4zuvnawyviyx7q0afprx.lambda-url.us-east-1.on.aws/api'
const API_BASE_URL_PRODUCTION =
  'https://lp4ziy4cuh35ngio44daa527fy0qzdnw.lambda-url.us-east-1.on.aws/api'

const API_UPLOAD_BASE_URL_DEV =
  'https://kedny4avwpflx2l6tsrrtypxly0tbdnp.lambda-url.us-east-1.on.aws/api'
const API_UPLOAD_BASE_URL_QA =
  'https://lep5cozzwin7x3e5m7z4ecz52q0hcuzr.lambda-url.us-east-1.on.aws/api'
const API_UPLOAD_BASE_URL_PRODUCTION =
  'https://gxhe3wx7lm7dihe56j2opaydc40rqony.lambda-url.us-east-1.on.aws/api'

export const STRIPE_PUBLISHABLE_KEY =
  'pk_test_51IkrEUKBDhkKRUFx90nBuwG9tWxxuJFcLIbt8YdilcH0soLsRScofr8ctfRJJ1btAeKD9iIB5OCJCBj6IchIEKn600m81zyHvL'

const CLIENT_APP_URL_DEV = 'https://marketplace.my.videoshops.com/'
const CLIENT_APP_URL_QA = 'https://marketplace.b4.videoshops.com/'
const CLIENT_APP_URL_PRODUCTION = 'https://marketplace.videoshops.com/'

let API_BASEL_URL
let CLIENT_APP_URL
let UPLOAD_BASE_URL
let CONTACT_API_BASE_URL

if (isProduction) {
  API_BASEL_URL = API_BASE_URL_PRODUCTION
  CLIENT_APP_URL = CLIENT_APP_URL_PRODUCTION
  UPLOAD_BASE_URL = API_UPLOAD_BASE_URL_PRODUCTION
  CONTACT_API_BASE_URL = CONTACT_API_BASE_URL_PRODUCTION
} else if (isQa) {
  API_BASEL_URL = API_BASE_URL_QA
  CLIENT_APP_URL = CLIENT_APP_URL_QA
  UPLOAD_BASE_URL = API_UPLOAD_BASE_URL_QA
  CONTACT_API_BASE_URL = CONTACT_API_BASE_URL_QA
} else if (isDev) {
  API_BASEL_URL = API_BASE_URL_DEV
  CLIENT_APP_URL = CLIENT_APP_URL_DEV
  UPLOAD_BASE_URL = API_UPLOAD_BASE_URL_DEV
  CONTACT_API_BASE_URL = CONTACT_API_BASE_URL_DEV
} else {
  // Default to production if none of the above conditions are met
  API_BASEL_URL = API_BASE_URL_PRODUCTION
  CLIENT_APP_URL = CLIENT_APP_URL_PRODUCTION
  UPLOAD_BASE_URL = API_UPLOAD_BASE_URL_PRODUCTION
  CONTACT_API_BASE_URL = CONTACT_API_BASE_URL_PRODUCTION
}

export const appConfig = {
  API_BASEL_URL,
  CLIENT_APP_URL,
  UPLOAD_BASE_URL,
  CONTACT_API_BASE_URL,
  HOST: location.host,
  GA_MEASUREMENT_ID: 'G-4V5H6MZGW2'
}
