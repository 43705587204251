import { FC } from 'react'
import styles from './styles.module.scss'
import Button from '../../Button/Button'
import getSignInUrl from '@/utils/url.ts'

const TopBar: FC = () => {
  const logoTagLine = '/icons/video-shops-mini-logo.svg'
  return (
    <>
      <div className={styles.TopBarContainer}>
        <div className={styles.TopBarInnerContainer}>
          <Button
            children={<>Sign In</>}
            onClick={() => window.open(getSignInUrl(), '_self')}
            greyOutline
            transparent
          />

          <div className={styles.TopBarLogo}>
            <img src={logoTagLine} />
          </div>
          <div className={styles.TopBarRightIcons}>
            <img src="/icons/heart-outline.svg" />
            <img src="/icons/cart-outline.svg" />
          </div>
        </div>
      </div>
      <div></div>
    </>
  )
}

export default TopBar
