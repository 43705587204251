import React from 'react'
import { ReactComponent as SuccessIcon } from '@/assets/icons/success.svg'
import { ReactComponent as ErrorIcon } from '@/assets/icons/error.svg'
import { ToasterProps } from './types'
import styles from './styles.module.scss'
import classnames from 'classnames'

const CustomToast: React.FC<ToasterProps> = ({ closeToast, text, type }) => {
  const toasterStyles = classnames(styles.toasterWrapper, {
    [styles.success]: type === 'success',
    [styles.error]: type === 'error'
  })

  return (
    <div className={toasterStyles}>
      {type === 'success' && <SuccessIcon />}
      {type === 'error' && <ErrorIcon />}
      <p> {(text && text) || '...Loading'}</p>
      {/* <button onClick={closeToast}>X</button> */}
    </div>
  )
}
export default CustomToast
