import { FC, ReactNode } from 'react'
import Box from '@mui/material/Box'

type DividerTypes = {
  children: ReactNode
}

const Divider: FC<DividerTypes> = ({ children }) => {
  return (
    <Box
      mt={1}
      mb={1}
      display="flex"
      justifyContent="center"
      position="relative"
      borderTop="1px solid #000"
    >
      <Box
        p="0 24px"
        mt={-1}
        display="flex"
        lineHeight="14px"
        letterSpacing="0em"
        color="#000"
        bgcolor="#fff"
        sx={{ typography: { xs: '2xs_bold', md: 'xs_bold' } }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default Divider
