import React, { FC, useContext, useEffect } from 'react'
import { StyledStepPage } from './StepPage.styled'
import { ThemeProvider } from 'styled-components'
import onboardingTheme from './OnboardingTheme/Theme.styled'

import { getRequest } from '@/api/api'
import { useLocation, useNavigate } from 'react-router-dom'
import { ONBOARDING_ROUTE, OnboardingStep } from '@/constants/onboarding.constant'
import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext'
import { updateFormState } from '@/utils/formHelpers'

interface StepPageProps {
  children: React.ReactNode
}

const StepPage: FC<StepPageProps> = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { setFormData } = useContext(OnboardingContext) as OnBaordingState

  const changeStep = (onboardingStep: OnboardingStep, onboardingState: FormDataType) => {
    let onboardingType = ''
    if (location.pathname.includes('creator')) {
      onboardingType = 'creator'
    } else {
      onboardingType = 'brand'
    }
    Object.keys(onboardingState).forEach((key) => {
      Object.keys(onboardingState[key as keyof FormDataType]).forEach((subKey) => {
        const formKey = subKey
        const formValue = onboardingState[key as keyof FormDataType][subKey as keyof FormDataType]
        console.log(formKey, formValue)
        updateFormState(setFormData, formKey, formValue)
      })
    })
    //   switch (onboardingStep) {
    //     case OnboardingStep.SIGNUP_FORM:
    //       // navigate(`/${onboardingType}/setup/${ONBOARDING_ROUTE.SIGNUP_FORM}`)
    //       break
    //     case OnboardingStep.VERIFY_SMS:
    //       navigate(`/${onboardingType}/setup/${ONBOARDING_ROUTE.SHOP_NAME}`)
    //       updateFormState(setFormData, 'phoneNumber', onboardingState.user.phoneNumber)
    //       break
    //     case OnboardingStep.SHOP_NAME:
    //       // navigate(`/${onboardingType}/setup/${ONBOARDING_ROUTE.SHOP_URL}`)
    //       updateFormState(setFormData, 'name', onboardingState.marketplace.name)
    //       break
    //     case OnboardingStep.MERCHANT_NAME:
    //       // navigate(`/${onboardingType}/setup/${ONBOARDING_ROUTE.SHOP_DESCRIPTION}`)
    //       updateFormState(setFormData, 'name', onboardingState.marketplace.name)
    //       break
    //     case OnboardingStep.SHOP_URL:
    //       // navigate(`/${onboardingType}/setup/${ONBOARDING_ROUTE.SHOP_DESCRIPTION}`)
    //       updateFormState(setFormData, 'key', onboardingState.marketplace.key)
    //       break
    //     case OnboardingStep.SHOP_DESCRIPTION:
    //       // onboardingType === 'brand'
    //       //   ? navigate(`/${onboardingType}/setup/${ONBOARDING_ROUTE.BRAND_LOGO}`)
    //       //   : navigate(`/${onboardingType}/setup/${ONBOARDING_ROUTE.PROFILE_IMAGE}`)
    //       updateFormState(setFormData, 'description', onboardingState.marketplace.description)
    //       break
    //     case OnboardingStep.BRAND_LOGO:
    //       // navigate(`/${onboardingType}/setup/${ONBOARDING_ROUTE.BRAND_HERO_IMAGE}`)
    //       updateFormState(setFormData, 'profileImage', onboardingState.marketplace.profileImage)
    //       break
    //     case OnboardingStep.PROFILE_IMAGE:
    //       // navigate(`/${onboardingType}/setup/${ONBOARDING_ROUTE.CATEGORIES}`)
    //       updateFormState(setFormData, 'profileImage', onboardingState.marketplace.profileImage)
    //       break
    //     case OnboardingStep.BRAND_HERO_IMAGE:
    //       // navigate(`/${onboardingType}/setup/${ONBOARDING_ROUTE.AFFINITIES}`)
    //       updateFormState(setFormData, 'backgroundImage', onboardingState.marketplace.backgroundImage)
    //       break
    //     case OnboardingStep.AFFINITIES:
    //       if (onboardingType === 'brand') {
    //         // navigate(`/${onboardingType}/setup/${ONBOARDING_ROUTE.SELLER_COMMISSION}`)
    //         updateFormState(setFormData, 'affinities', onboardingState.marketplace.affinities)
    //       } else {
    //         // navigate(`/${onboardingType}/setup/${ONBOARDING_ROUTE.THEME}`)
    //         updateFormState(setFormData, 'categories', onboardingState.user.categories)
    //       }
    //       // onboardingType === 'brand'
    //       //   ? navigate(`/${onboardingType}/setup/${ONBOARDING_ROUTE.SELLER_COMMISSION}`)
    //       //   : navigate(`/${onboardingType}/setup/${ONBOARDING_ROUTE.THEME}`)
    //       break
    //     default:
    //       break
    //   }
  }

  useEffect(() => {
    const checkOnboardingStep = async () => {
      const searchParams = new URLSearchParams(location.search)
      const token = searchParams.get('token')

      if (token) {
        try {
          const res = await getRequest('/user/profile', { headers: { Authorization: `${token}` } })
          if (res.data?.data) {
            const user = res.data.data
            if (user.onboardingStep && user.onboardingState) {
              localStorage.removeItem('formData')
              localStorage.removeItem('currentStep')
              localStorage.removeItem('TOKEN')
              localStorage.setItem('TOKEN', token)
              localStorage.setItem('formData', JSON.stringify(user.onboardingState))
              setFormData(user.onboardingState)
              changeStep(user.onboardingStep, user.onboardingState)
            }
          }
        } catch (error) {
          console.error('Failed to fetch user profile', error)
        }
      }
    }

    checkOnboardingStep()
  }, [location, navigate])

  return (
    <ThemeProvider theme={onboardingTheme}>
      <StyledStepPage>{children}</StyledStepPage>
    </ThemeProvider>
  )
}

export default StepPage
