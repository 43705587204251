export function formatQueryString(string: string) {
  return string.trim().split(' ').join('%20')
}

export function unFormatQueryString(string: string) {
  return string.trim().replace('%20', ' ')
}
export function deepMerge(obj1: any, obj2: any): any {
  const output = Object.assign({}, obj1)
  if (isObject(obj1) && isObject(obj2)) {
    Object.keys(obj2).forEach((key) => {
      if (isObject(obj2[key])) {
        if (!(key in obj1)) Object.assign(output, { [key]: obj2[key] })
        else output[key] = deepMerge(obj1[key], obj2[key])
      } else {
        Object.assign(output, { [key]: obj2[key] })
      }
    })
  }
  return output
}

function isObject(item: any): item is Record<string, any> {
  return item && typeof item === 'object' && !Array.isArray(item)
}

export const isMobile = () => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
}

export const unCodifyStr = (str: string) => {
  if (typeof str !== 'string') {
    console.error('Input is not a string:', str)
    return str
  }

  if (!str) {
    console.log('string was not present')
    return str
  }
  const snakeCaseStr = str.replace(/([a-z0-9])([A-Z])/g, '$1_$2').toLowerCase()
  const words = snakeCaseStr.split('_')
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
  )
  return capitalizedWords.join(' ')
}

export const codifyStr = (str: string) => {
  return str
    .replace(/[^a-zA-Z0-9 _\-]/g, '')
    .replace(/\s/g, '-')
    .toLowerCase()
}

export const numberToMoneyString = (number: number) => {
  return new Intl.NumberFormat().format(number)
}
