import React, { useCallback, useContext, useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import classNames from 'classnames'
import styles from '@/components/Form/styles.module.scss'
import { useFormContext } from 'react-hook-form'
import { getValueFromNestedObject, updateFormState } from '@/utils/formHelpers'
import { OnBaordingProps } from '@/components/OnboardingComp/types'
import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext'
import { getRequest } from '@/api/api'
import { toast } from 'react-toastify'
import debounce from 'lodash/debounce'
import { codifyStr } from '@/utils/helpers'
import { appConfig } from '@/app-config'
import CustomToast from '@/components/Toaster/Toaster'
import { profanityErrorStr } from '@/constants/profantiy'
import { checkForProfanity } from '@/utils/moderation.utils'
import URLSuggestions from '@/components/Form/Fields/URLField/URLSuggestions.tsx'
import Box from '@mui/system/Box'
import WhatIsThisURL from '@/components/OnboardingCompNew/SellerSteps/MarketPlaceURL/WhatIsThisURL.tsx'
import InputField from '@/components/Form/Fields/InputField/InputField.tsx'
import Alert from './Alert'
import FinalButton from '@/videoshops-shared/components/FinalButton/FinalButton.tsx'

interface AxiosResponseData {
  data: any
}

const URLField: React.FC<FormElementProps> = ({ name }) => {
  const [loading, setLoading] = useState(false)
  const [key, setKey] = useState('')
  const [recommendedKeys, setRecommendedKeys] = useState<string[]>([])
  const [marketPlaceURL, setMarketPlaceURL] = useState('')
  const { setFormData, formData, setIsLoading } = useContext(OnboardingContext) as OnBaordingProps
  const {
    trigger,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { isValid }
  } = useFormContext()
  const value = watch(name)
  const validURLRegex = /^[a-z0-9_-]+$/
  const [isValidURL, setValidURL] = useState(false)
  const profanity = checkForProfanity(value)

  const invalid = !isValidURL || isValid === false

  const validateURLDebounced = useCallback(
    debounce((url: string) => {
      validateURL(url)
    }, 300),
    []
  )

  const validateURL = useCallback(async (url: string) => {
    if (!validURLRegex.test(url)) {
      return
    }
    try {
      setRecommendedKeys([])
      const response: AxiosResponseData = await getRequest(
        `${appConfig.API_BASEL_URL}/marketplace/available/${url}`
      )
      if (response && response.data.data && response.data.data.isMarketplaceAvailable) {
        setValidURL(true)
        setTimeout(() => {
          setIsLoading(false)
        }, 4000)
        setLoading(false)
      } else {
        if (response?.data?.data?.recommendedKeys) {
          setRecommendedKeys(response.data.data.recommendedKeys)
        }

        setValidURL(false)
        setIsLoading(true)
        setLoading(false)
      }
    } catch (error: any) {
      setLoading(false)
      setIsLoading(true)
      const errorMessage = error?.response?.data?.error ?? error.message ?? 'Error with Submission'
      toast(<CustomToast type="error" text={errorMessage} />)
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)
    const retrievedValue = getValueFromNestedObject(formData, name)
    const tempURL =
      retrievedValue !== null
        ? retrievedValue
        : formData.marketplace.name
          ? codifyStr(formData.marketplace.name)
          : ''

    setMarketPlaceURL(tempURL)

    if (validURLRegex.test(tempURL)) {
      setValidURL(true)
      setValue(name, tempURL)
      validateURLDebounced(tempURL, isValidURL)
      updateFormState(setFormData, name, tempURL)
    } else {
      setValue(name, tempURL)
      setValidURL(false)
      setIsLoading(true)
    }
  }, [])

  const handleChange = (currentValue: string) => {
    setLoading(true)
    setValidURL(false)
    setIsLoading(true)
    if (validateURLDebounced) {
      validateURLDebounced.cancel()
    }
    if (checkForProfanity(currentValue)) {
      setError(name, { type: 'manual', message: profanityErrorStr })
      setValidURL(false)
      return
    }
    const isValid = validURLRegex.test(currentValue)
    setValue(name, currentValue)
    trigger(name)

    if (!isValid || !validURLRegex.test(currentValue)) {
      setError(name, {
        type: 'manual',
        message: 'Your Store Key should be all lowercase and contain no spaces'
      })
      setValidURL(false)
      setMarketPlaceURL('')
      updateFormState(setFormData, name, currentValue)
      return
    }

    setValidURL(true)
    clearErrors(name)

    setMarketPlaceURL(currentValue)
    const formValues = getValues()
    updateFormState(setFormData, name, currentValue, formValues)
    validateURLDebounced(currentValue, isValidURL)
  }

  const setSuggestedUrl = (value: string) => {
    setKey(Date.now().toString())
    handleChange(value)
  }

  useEffect(() => {
    if (value) {
      handleChange(value || '')
    } else {
      setValue(name, value)
      trigger(name)
      setLoading(false)
      setValidURL(false)
    }
  }, [value])

  return (
    <>
      <Box
        className={classNames({
          [styles.urlInputError]: invalid && value
        })}
      >
        <InputField key={key} label="marketplace.videoshops.com/" name="key" type="text" />
        <Box display="flex" justifyContent="flex-end">
          <WhatIsThisURL value={value} />
        </Box>
      </Box>
      {!invalid && loading ? (
        <Box mt={3} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {!profanity && (
            <>
              {recommendedKeys.length > 0 ? (
                <URLSuggestions
                  isValidURL={!invalid}
                  values={recommendedKeys}
                  setUrl={setSuggestedUrl}
                />
              ) : (
                <Box mt={1}>
                  <Box mt="21px">
                    {!invalid && !loading ? (
                      <Alert variant="success" title="Link available" />
                    ) : (
                      <Alert variant="pending" title="Pending Eligibility" />
                    )}
                  </Box>
                  <Box mt={3}>
                    <FinalButton type="submit" full variant="primary" disabled={invalid || loading}>
                      Next
                    </FinalButton>
                  </Box>
                </Box>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default URLField
