import classNames from 'classnames'
import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from '../../styles.module.scss'
import { useFormContext } from 'react-hook-form'
import { getValueFromNestedObject, updateFormState } from '@/utils/formHelpers'
import { OnBaordingProps } from '@/components/OnboardingComp/types'
import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext'

const CheckBox: React.FC<FormElementProps> = ({
  label,
  colW,
  name,
  placeholder,
  type,
  className,
  required,
  children,
  helpText
}) => {
  const checkBoxRef = useRef<HTMLInputElement>(null)
  // const { setFormData, formData } = useContext(OnboardingContext) as OnBaordingProps;

  const { trigger, formState, setValue, register, getValues } = useFormContext()
  const isChecked = Boolean(getValues(name))
  useEffect(() => {
    setValue(name, false)
  }, [])

  // useEffect(() => {
  //     const retrievedValue = getValueFromNestedObject(formData, name);
  //     if (retrievedValue !== null) {
  //         setValue(name, retrievedValue);
  //         setFocused(true);
  //     }
  // }, [name, formData, setValue]);

  const { errors } = formState
  const error = errors[name]
  const fieldError = error as FieldErrorProps

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.currentTarget.checked // Use checked instead of value
    setValue(name, isChecked) // Store true or false based on whether the checkbox is checked
    trigger(name)
  }

  return (
    <>
      <div className={classNames(`col-${colW}`, className)}>
        <div className={styles.checkboxWrapper}>
          <div>
            <input
              {...register(name)}
              type="checkbox"
              ref={checkBoxRef}
              id={name}
              defaultChecked={false}
              name={name}
              onChange={onChange}
              required
            />
            <label className={styles.checkLabel} htmlFor={name}>
              {children}
            </label>
          </div>
          <div className={styles.fieldErrors}>
            {helpText && <p>{helpText}</p>}

            {fieldError?.message && <p>{fieldError.message}</p>}
          </div>
        </div>
      </div>
    </>
  )
}

export default CheckBox
