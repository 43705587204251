import React, { FC } from 'react'
import { StyledFinalButton } from './FinalButton.styled'
import { SVG } from '../../../shared/constants/svg.constant'

interface Button extends React.HTMLAttributes<HTMLButtonElement> {
  suffixIcon?: React.ReactNode
  variant?: 'primary' | 'secondary' | 'tertiary' | 'danger'
  size?: 'xl' | 'lg' | 'md' | 'sm' | 'xs'
  className?: string
  disabled?: boolean
  loading?: boolean
  full?: boolean
  type?: string
}

interface ButtonWithChildren extends Button {
  children: React.ReactNode
  icon?: React.ReactNode
}

interface ButtonWithIcon extends Button {
  icon: React.ReactNode
}

type ButtonProps = ButtonWithChildren | ButtonWithIcon

const FinalButton: FC<ButtonProps> = ({
  variant,
  size,
  icon,
  suffixIcon,
  full,
  className,
  loading,
  children,
  ...props
}) => {
  const classes: string[] = [size || 'lg']

  if (variant) classes.push(variant)
  if (!children && icon) classes.push('icon-only')
  if (loading) classes.push('loading')
  if (full) classes.push('full')
  if (className) classes.push(className)

  return (
    <StyledFinalButton {...props} className={classes.join(' ')}>
      {icon}
      {children ? <span>{children}</span> : null}
      {suffixIcon}
      {loading ? <span className="loading-icon">{SVG.LOADER}</span> : null}
    </StyledFinalButton>
  )
}

export default FinalButton
