import WhatIsThis from '@/shared/components/InfoModal/WhatIsThis.tsx'
import Box from '@mui/system/Box'

interface URLWhatIsThisProps {
  value: string
}

export default function WhatIsThisURL({ value }: URLWhatIsThisProps) {
  return (
    <WhatIsThis
      title="Your VideoShops Link"
      description={
        <>
          This is your personal VideoShops link.
          <br /> You can use this link to make your Storefront and Shelves
          shoppable on any social platform and messaging app.
          <Box mt={2}>
            <Box
              display="inline-block"
              color="text.thunder"
              sx={{ float: 'left' }}
            >
              marketplace.videoshops.com/
            </Box>
            <Box sx={{ overflowWrap: 'break-word' }}>{value}</Box>
          </Box>
        </>
      }
    />
  )
}
