import React from 'react'
import { createTheme } from '@mui/material/styles'
import { ReactComponent as CheckboxIcon } from '../assets/icons/checkbox-icon.svg'
import { ReactComponent as CheckboxCheckedIcon } from '../assets/icons/checkbox-checked.svg'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    action: true
    white: true
  }
  interface ButtonPropsSizeOverrides {
    height48: true
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    ash: string
    snow: string
    textPrimary?: string
    buttons: {
      primary: string
    }
    surfaces: {
      frost: string
    }
    borders: {
      smoke: string
      glacier: string
    }
  }
  interface PaletteOptions {
    ash: string
    snow: string
    textPrimary?: string
    buttons: {
      primary: string
    }
    surfaces: {
      frost: string
    }
    borders: {
      smoke: string
      glacier: string
    }
  }
  interface TypeText {
    ash: string
    thunder: string
    link: string
    snow: string
    charcoal: string
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    xs: React.CSSProperties
    xs_med: React.CSSProperties
    xs_bold: React.CSSProperties
    lg: React.CSSProperties
    lg_med: React.CSSProperties
    lg_bold: React.CSSProperties
    xl: React.CSSProperties
    xl_med: React.CSSProperties
    xl_bold: React.CSSProperties
    [name: string]: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    xs?: React.CSSProperties
    xs_med?: React.CSSProperties
    xs_bold?: React.CSSProperties
    lg?: React.CSSProperties
    lg_med?: React.CSSProperties
    lg_bold?: React.CSSProperties
    xl?: React.CSSProperties
    xl_med?: React.CSSProperties
    xl_bold?: React.CSSProperties
    [name: string]: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    xs: true
    xs_med: true
    xs_bold: true
    lg: true
    lg_med: true
    lg_bold: true
    xl: true
    xl_med: true
    xl_bold: true
    [name: string]: true
  }
}

const fontFamily = '"Montserrat"'
let theme = createTheme({})

const createFont = (name: string, props: React.CSSProperties) => {
  const styles = {
    fontFamily,
    ...props
  }
  return {
    [name]: styles,
    [`${name}_med`]: {
      ...styles,
      fontWeight: 500
    },
    [`${name}_bold`]: {
      ...styles,
      fontWeight: 600
    }
  }
}

const createFontResponsive = (
  name: string,
  props: React.CSSProperties,
  desktop: React.CSSProperties
) => {
  const styles = {
    fontFamily,
    ...props,
    [theme.breakpoints.up('md')]: {
      ...desktop
    }
  }

  const font = {
    [name]: styles,
    [`${name}_med`]: {
      ...styles,
      fontWeight: 500
    },
    [`${name}_bold`]: {
      ...styles,
      fontWeight: 600
    }
  }

  return font
}

theme = createTheme({
  palette: {
    ash: '#70777E',
    snow: '#FCFCFC',
    text: {
      snow: '#FCFCFC',
      ash: '#70777E',
      primary: '#232A31',
      thunder: '#B0B9C1',
      link: '#5231FA',
      charcoal: '#000'
    },
    buttons: {
      primary: '#5231FA'
    },
    surfaces: {
      frost: '#F3F7FD'
    },
    borders: {
      smoke: '#EBEBEB',
      glacier: '#D5E1F3'
    }
  },
  components: {
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxIcon />,
        checkedIcon: <CheckboxCheckedIcon />
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline'
          }
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'action' },
          style: {
            borderRadius: '50px',
            height: '50px',
            textTransform: 'none',
            border: '1px solid #5231FA',
            background: '#5231FA',
            color: '#fff',
            fontWeight: 700,
            '&:hover': {
              border: '1px solid #5231FA',
              background: '#3B1ECF',
              color: '#fff'
            },
            '&:active': {
              border: '1px solid #291591',
              background: '#291591',
              color: '#fff'
            },
            '&:disabled': {
              border: '1px solid #EBEBEB',
              background: '#EBEBEB',
              color: '#B0B9C1'
            }
          }
        },
        {
          props: { variant: 'outlined' },
          style: {
            borderRadius: '50px',
            height: '50px',
            textTransform: 'none',
            border: '1px solid #D5E1F3',
            background: '#F3F7FD',
            color: '#5231FA',
            fontWeight: 700,
            '&:active': {
              border: '1px solid #5231FA'
            },
            '&:disabled': {
              border: '1px solid #EBEBEB',
              background: '#EBEBEB',
              color: '#B0B9C1'
            }
          }
        },
        {
          props: { variant: 'white' },
          style: {
            borderRadius: '50px',
            height: '50px',
            textTransform: 'none',
            border: '1px solid #EBEBEB',
            background: '#fff',
            color: '#000',
            fontSize: 16,
            fontWeight: 600
          }
        },
        {
          props: { size: 'height48' },
          style: { fontWeight: 600, height: '48px' }
        }
      ]
    }
  },
  typography: {
    allVariants: {
      fontFamily
    },
    ...createFontResponsive(
      'xs_responsive',
      { fontSize: 12 },
      { fontSize: 16 }
    ),
    ...createFontResponsive(
      'xl_responsive',
      { fontSize: 20 },
      { fontSize: 30 }
    ),
    ...createFont('2xs', { fontSize: 10 }),
    ...createFont('xs', { fontSize: 12 }),
    ...createFont('sm', { fontSize: 14 }),
    ...createFont('md', { fontSize: 16 }),
    ...createFont('lg', { fontSize: 18 }),
    ...createFont('xl', { fontSize: 20 }),
    ...createFont('2xl', { fontSize: 24 }),
    ...createFont('3xl', { fontSize: 30 }),
    ...createFont('4xl', { fontSize: 36 }),
    ...createFont('5xl', { fontSize: 48 })
  }
})

export default theme
