import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'

const styles = {
  button: {
    padding: '7px 18px',
    borderRadius: '54px',
    border: '1px solid #EBEBEB',
    background: '#FCFCFC',
    fontSize: '14px',
    fontWeight: 600,
    color: '#000000',
    textTransform: 'none'
  }
}

interface InfoModal {
  title: string
  description: React.ReactNode
  open: boolean
  onClose: () => void
}

export default function InfoModal({
  open,
  title,
  description,
  onClose
}: InfoModal) {
  return (
    <Dialog
      disablePortal
      open={open}
      onClose={onClose}
      maxWidth="xs"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '24px'
        }
      }}
    >
      <Box p={2.5}>
        <Box display="flex" fontWeight={700} color="#000" alignItems="center">
          <img src="/icons/info-circle-black.svg" alt="Icon" />
          <Box ml={1}>{title}</Box>
        </Box>
        <Box mt={2} typography="xs_med" lineHeight="16px">
          {description}
        </Box>
        <Box mt={3} display="flex" justifyContent="flex-end">
          <Button onClick={onClose} sx={styles.button}>
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
