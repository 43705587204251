import * as Yup from 'yup'
import { passwordValidation } from './PasswordValidation'

export interface UserInterface {
  email: string | null
  password: string | null
  phone: string | null
  firstName: string | null
  lastName: string | null
}

export const userSchema: Yup.ObjectSchema<UserInterface> = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required('Email is required')
    .matches(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/,
      'Your Email does not seem to be a valid email.'
    ),
  password: passwordValidation,
  confirmPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  phone: Yup.string()
    .trim()
    .matches(/^-?(0|[1-9]\d*)$/, 'Invalid phone number format')
    .min(7, 'Invalid phone number')
    .max(15, 'Invalid phone number')
    .required('Phone number is required'),
  firstName: Yup.string().trim().required('First Name is required'),
  lastName: Yup.string().trim().required('Last Name is required'),
  terms: Yup.bool()
    .oneOf([true], 'Field must be checked')
    .required('Field must be checked')
})

const creatorSetupFormSchema = Yup.object().shape({
  marketplace: Yup.object().shape({
    name: Yup.string().trim().required('Your VideoShop Name is required'),
    key: Yup.string().trim().required('Your Videoshops URL is required'),
    terms: Yup.boolean().oneOf([true], 'Please agree to the terms.'),
    description: Yup.string().nullable().trim(),
    profileImage: Yup.string().nullable().trim(),
    backgroundImage: Yup.string().nullable().trim()
  }),
  user: Yup.object().shape({
    email: Yup.string()
      .trim()
      .required('Email is required')
      .matches(
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/,
        'Your Email does not seem to be a valid email.'
      ),
    password: passwordValidation,
    confirmPassword: Yup.string()
      .trim()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
    phone: Yup.string()
      .trim()
      .matches(/^-?(0|[1-9]\d*)$/, 'Invalid phone number format')
      .required('Phone number is required'),
    firstName: Yup.string().trim().required('First Name is required'),
    lastName: Yup.string().trim().required('Last Name is required')
  })
})

export default creatorSetupFormSchema
