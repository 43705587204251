import { appConfig } from '@/app-config.ts'
import { postRequest } from '@/api/api.ts'

type sendCodeType = {
  userId: string
  countryCode: string
  phone: string
}

export const sendCode = ({ userId, countryCode, phone }: sendCodeType) => {
  const sendCodeUrl = `${appConfig.API_BASEL_URL}/user/send-code`
  const body = {
    userId,
    countryCode,
    phone
  }
  const headers = { Authorization: localStorage.getItem('TOKEN') }
  return postRequest(sendCodeUrl, body, { headers })
}
