export const COUNTRY_PHONE_CODES = [
  { value: '+1', label: '+1' },
  { value: '+44', label: '+44' },
  { value: '+61', label: '+61' },
  { value: '+33', label: '+33' },
  { value: '+49', label: '+49' },
  { value: '+34', label: '+34' },
  { value: '+39', label: '+39' },
  { value: '+40', label: '+40' },
  { value: '+7', label: '+7' },
  { value: '+55', label: '+55' },
  { value: '+81', label: '+81' },
  { value: '+86', label: '+86' },
  { value: '+91', label: '+91' },
  { value: '+27', label: '+27' },
  { value: '+972', label: '+972' }
]
