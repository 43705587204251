import React, { FC, MouseEvent, ReactNode } from 'react'
import { ButtonStyled } from './button.styled'
import classNames from 'classnames'
export enum ButtonSize {
  SMALL,
  MEDIUM,
  LARGE,
  CIRCLE,
}

export enum ButtonType {
  PRIMARY,
  SECONDARY,
  SUFFIX,
  GREY
}

export enum ButtonTheme {
  DEFAULT,
  DARK,
  GREY
}

export interface ButtonProps {
  children?: ReactNode
  size?: ButtonSize
  type?: ButtonType
  danger?: boolean
  className?: string
  width?: string
  height?: string
  disabled?: boolean
  loading?: boolean
  theme?: ButtonTheme
  loader?: ReactNode
  onClick?: (event: MouseEvent) => void
  text?: string
}

export const Button: FC<ButtonProps> = ({
  children,
  size = ButtonSize.MEDIUM,
  type = ButtonType.PRIMARY,
  theme = ButtonTheme.DEFAULT,
  className,
  loading,
  loader,
  disabled,
  onClick,
  text = 'Click me!',
  width,
}: ButtonProps) => {
  const classes = classNames(className, {
    // Label classes
    'button-label-small': size === ButtonSize.SMALL,
    'button-label-medium': size === ButtonSize.MEDIUM,
    'button-label-large': size === ButtonSize.LARGE,

    // Size classes
    small: size === ButtonSize.SMALL,
    medium: size === ButtonSize.MEDIUM,
    large: size === ButtonSize.LARGE,
    circle: size === ButtonSize.CIRCLE,
    secondary: type === ButtonType.SECONDARY,
    suffix: type === ButtonType.SUFFIX,

    loading,
    disabled,
    dark: theme === ButtonTheme.DARK,
    grey: theme === ButtonTheme.GREY
  })

  const onButtonClicked = (event: MouseEvent) => !disabled && onClick && onClick(event)

  return (
    <ButtonStyled className={classes} width={width} onClick={onButtonClicked}>
      {children ? children : text}
      {!!loading && loader && <div className="loader">{loader}</div>}
    </ButtonStyled>
  )
}

export default Button
