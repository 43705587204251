import { FC, useRef } from 'react'
import styles from './styles.module.scss'
import Section from '@/components/Section/Section'
import { ReactComponent as SwitchOn } from '@/assets/home/switcher_on.svg'
import { ReactComponent as SwitchOff } from '@/assets/home/switcher_off.svg'
import Title from '../../Title/Title'
import Button from '@/components/Button/Button'
import { useInFocus } from '@/hooks/useInFocus'
import { useStaticPageContext } from '@/context/StaticPageContext'
import { SiteWideTypes } from '@/types/SiteContext.interface'
import { APP_ROUTES } from '@/routes/routes'

const LightSwitch: FC = () => {
  const { siteType } = useStaticPageContext() as SiteWideTypes

  const lightSwitchRef = useRef<HTMLDivElement>(null)
  const isInFocus = useInFocus(lightSwitchRef, '30% 0% 0px 30%', [0.1, 0.9])

  const redirectUrl =
    siteType === 'people' ? APP_ROUTES.CREATOR_SETUP : APP_ROUTES.BRAND_SETUP

  const title = (
    <span>
      Turn On {''}
      <span className="vs-primary-color">Your VideoShop</span>
    </span>
  )
  const handleClick = () => {
    localStorage.removeItem('formData')
    localStorage.removeItem('currentStep')
    localStorage.removeItem('TOKEN')
    window.open(redirectUrl, '_self')
  }
  return (
    <Section>
      <div ref={lightSwitchRef} className={styles.lightSwitchWrapper}>
        <div className={styles.lightSwitch}>
          {isInFocus ? <SwitchOn /> : <SwitchOff />}
        </div>
        <div className={styles.lightSwitchTitle} />
        <Title
          titlePosition="center"
          titleColor="#000"
          children={title}
          secondaryTitle
        />
        <Button children="Get Started Free!" onClick={handleClick} primary />
      </div>
    </Section>
  )
}

export default LightSwitch
