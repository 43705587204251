import Section from '@/components/Section/Section'
import { FC } from 'react'
import styles from './styles.module.scss'
import Title from '@/components/Title/Title'
import Cards from '@/components/Cards/Cards'
import {
  threeCardsBrandData,
  threeCardsPeopleData
} from '@/constants/cardsData'
import { useStaticPageContext } from '@/context/StaticPageContext'
import { SiteWideTypes } from '@/types/SiteContext.interface'
import classNames from 'classnames'

const ThreeCards: FC = () => {
  const { siteType } = useStaticPageContext() as SiteWideTypes
  const isPeople = siteType === 'people'

  const titleText = (
    <div className={classNames('breakTitle', styles.marketplaceTitle)}>
      {true ? (
        <>
          <span data-line="Access Millions of Products">
            It's
            <span className="vs-primary-color"> Fast & Easy</span>
          </span>
        </>
      ) : (
        <>
          <span data-line="Turn On">
            The <span className="vs-primary-color"> Future</span> of Retail
          </span>
        </>
      )}
    </div>
  )

  const subText = !isPeople && <p>Turn on Social Commerce through VideoShops</p>

  return (
    <Section className={styles.threeCardsSectionWrapper}>
      <div id="accessProducts"></div>
      <div className={styles.titleWrapper}>
        <Title
          children={titleText}
          titleColor="#000"
          subText={subText}
          secondaryTitle
        />
      </div>
      <div className={styles.cardsWrapper}>
        <Cards data={isPeople ? threeCardsPeopleData : threeCardsBrandData} />
      </div>
    </Section>
  )
}

export default ThreeCards
