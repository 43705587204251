import React, { FC, useState } from 'react'
import Box from '@mui/system/Box'

import InfoModal from './InfoModal.tsx'

interface WhatIsThisType {
  mt?: string | number
  title: string
  description: React.ReactNode
}

const WhatIsThis: FC<WhatIsThisType> = ({ mt, title, description }) => {
  const [open, setOpen] = useState(false)

  const handleModalClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Box
        mt={mt}
        display="flex"
        alignItems="center"
        typography="xs_med"
        color="text.link"
        sx={{ cursor: 'pointer' }}
        onClick={() => setOpen(true)}
      >
        <Box component="img" mr={0.5} src="/icons/question-mark.svg" />
        What is this?
      </Box>
      <InfoModal
        open={open}
        title={title}
        description={description}
        onClose={handleModalClose}
      />
    </>
  )
}

export default WhatIsThis
