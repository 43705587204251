import Box from '@mui/system/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { Link as MuiLink, useNavigate } from 'react-router-dom'
import getSignInUrl from '@/utils/url.ts'
import ContinueWithGoogle from '@/components/OnboardingCompNew/SellerSteps/CreateAccount/ContinueWithGoogle.tsx'
import { APP_ROUTES } from '@/routes/routes.ts'
import ArrowCircleRight from '@/shared/icons/arrow-circle-right.tsx'
import FinalButton from '@/videoshops-shared/components/FinalButton/FinalButton.tsx'
import SellersBackground from './SellersBackground.tsx'

const styles = {
  link: {
    typography: 'sm_bold',
    textDecoration: 'none',
    color: '#fff'
  },
  terms: {
    color: '#fff',
    fontWeight: 600
  },
  customContinue: {
    fontSize: '0.875rem',
    blockSize: '3rem'
  }
}

export default function Sellers() {
  const navigate = useNavigate()
  return (
    <Box>
      <SellersBackground>
        <Box
          position="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height="100vh"
          color="#FFFFFF"
        >
          <ArrowCircleRight
            htmlColor="white"
            sx={{ fontSize: { xs: 40, md: 66 } }}
          />
          <Typography
            fontWeight="700"
            mt={{ xs: '14px', md: '27px' }}
            align="center"
            fontSize={{ xs: '40px', md: '75px' }}
            lineHeight={{ xs: '36px', md: '65px' }}
            letterSpacing={{ xs: '-1.6px', md: '-3.75px' }}
          >
            Turn On
            <br />
            Your Shop
          </Typography>
          <Box mt="2.25rem" width={{ xs: 230, md: 304 }}>
            <ContinueWithGoogle />
          </Box>
          <Box mt="10px" width={{ xs: 230, md: 304 }}>
            <FinalButton
              style={styles.customContinue}
              full
              variant="primary"
              onClick={() => navigate('create-account')}
            >
              Continue with Email
            </FinalButton>
          </Box>
          <Box mt={3.5} textAlign="center">
            <Typography variant="sm_med">Already a Seller?</Typography>{' '}
            <Link component={MuiLink} to={getSignInUrl()} sx={styles.link}>
              Log in
            </Link>
          </Box>
          <Box mt={4} textAlign="center">
            <Typography variant="sm_med">Are you a Merchant?</Typography>{' '}
            <Link
              component={MuiLink}
              to={APP_ROUTES.BRAND_SETUP}
              sx={styles.link}
            >
              Get started here
            </Link>
          </Box>
          <Typography
            textAlign="center"
            variant="xs_med"
            mt={3.5}
            maxWidth={{ xs: 318, md: 410 }}
            lineHeight="1rem"
          >
            By continuing, you certify that you are at least 18 years old and
            that you agree to VideoShops{' '}
            <Link
              color="#fff"
              component={MuiLink}
              to={APP_ROUTES.TERMS_OF_USE}
              sx={styles.terms}
              target="_blank"
            >
              Terms & Conditions
            </Link>{' '}
            and{' '}
            <Link
              color="#fff"
              component={MuiLink}
              to={APP_ROUTES.PRIVACY_POLICY}
              sx={styles.terms}
              target="_blank"
            >
              Privacy Policy
            </Link>
          </Typography>
        </Box>
      </SellersBackground>
    </Box>
  )
}
