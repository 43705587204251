import styled from 'styled-components'
import { TextProps } from './Text'

export const StyledText = styled.div<TextProps>`
  font-family: Montserrat;
  font-style: normal;
  letter-spacing: -0.04em;
  text-align: ${({ alignment }) => alignment || 'center'};
  color: ${({ theme, color }) => (color ? theme.COLORS[color] : theme.COLORS.black)};
  font-size: ${({ theme, size }) =>
    size ? theme.FONT_SIZES[size].size : theme.FONT_SIZES.medium.size};
  line-height: ${({ theme, size }) =>
    size ? theme.FONT_SIZES[size].lineHeight : theme.FONT_SIZES.medium.lineHeight};
  font-weight: ${({ theme, size, weight }) =>
    weight ? weight : size ? theme.FONT_SIZES[size].weight : theme.FONT_SIZES.medium.weight};
`
