export const BRAND_ONBOARDING_STEPS = {
  BRAND_SIGN_UP: 1,
  BRAND_SMS_VALIDATION: 2,
  BRAND_MARKETPLACE_NAME: 3,
  BRAND_PROFILE_IMAGE: 4,
  BRAND_BACKGROUND_IMAGE: 5,
  BRAND_THUMBNAIL_IMAGE: 6,
  BRAND_DESCRIPTION: 7,
  BRAND_AFFINITIES: 8,
  BRAND_COMMISSION: 9,
  BRAND_STORE_URL: 10
}

export const SELLER_ONBOARDING_STEPS = {
  SELLER_VIDEOSHOP_NAME: 1,
  SELLER_PROFILE_IMAGE: 2,
  SELLER_BACKGROUND_IMAGE: 3,
  SELLER_DESCRIPTION: 4,
  SELLER_STORE_URL: 5,
  SELLER_SIGN_UP: 6
}

export enum OnboardingStep {
  // Seller steps
  SIGNUP_FORM = 'SIGNUP_FORM',
  VERIFY_SMS = 'VERIFY_SMS',
  SHOP_NAME = 'SHOP_NAME',
  SHOP_URL = 'SHOP_URL',
  SHOP_DESCRIPTION = 'SHOP_DESCRIPTION',
  PROFILE_IMAGE = 'PROFILE_IMAGE',
  AFFINITIES = 'AFFINITIES',
  THEME = 'THEME',

  // Merchant steps
  MERCHANT_NAME = 'MERCHANT_NAME',
  BRAND_NAME = 'BRAND_NAME',
  BRAND_LOGO = 'BRAND_LOGO',
  BRAND_HERO_IMAGE = 'BRAND_HERO_IMAGE',
  SELLER_COMMISSION = 'SELLER_COMMISSION'
}

export const ONBOARDING_ROUTE = {
  SIGNUP_FORM: 'verify-code',
  VERIFY_SMS: 'name',
  SHOP_NAME: 'link',
  SHOP_URL: 'link',
  SHOP_DESCRIPTION: 'description',
  PROFILE_IMAGE: 'profile-image',
  AFFINITIES: 'affinities',
  CATEGORIES: 'categories',
  THEME: 'theme',
  BRAND_LOGO: 'brand-logo',
  BRAND_HERO_IMAGE: 'brand-hero-image',
  SELLER_COMMISSION: 'commission'
}
