import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import styles from '../../styles.module.scss'

import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext'
import { formatPhone } from '@/utils/formatNumber'
import { getRequest, postRequest } from '@/api/api'
import { toast } from 'react-toastify'
import debounce from 'lodash/debounce'
import { appConfig } from '@/app-config'
import CustomToast from '@/components/Toaster/Toaster'

const VendorSignupForm: FC = () => {
  const [smsCode, setSmsCode] = useState(Array(6).fill(undefined))
  const [activeIndex, setActiveIndex] = useState(0)
  const headers = {
    Authorization: localStorage.getItem('TOKEN') || ''
  }
  const { getUserData, setCurrentStep } = useContext(
    OnboardingContext
  ) as OnBaordingState
  const [cancelDebounce, setCancelDebounce] = useState<Function | null>(null)

  const validateCodeDebounced = useCallback(
    debounce((smsCode: string) => {
      const toastId = toast(<CustomToast />)
      if (smsCode) {
        validateCode(smsCode)
      }
      setTimeout(() => {
        toast.dismiss(toastId)
      }, 2000)
    }, 2000),
    []
  )

  useEffect(() => {
    window.scrollTo(0, 0)

    return () => {
      setCancelDebounce(() => validateCodeDebounced.cancel)
    }
  }, [])

  const handleInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    e.preventDefault()
    const value: string = e.target.value
    if (smsCode && smsCode.length < 6) {
      if (cancelDebounce) {
        cancelDebounce()
      }
    }

    if (/^\d*$/.test(value)) {
      const newCode = [...smsCode]
      newCode[index] = value
      setSmsCode(newCode)
      validateCodeDebounced(newCode)
      if (value && index < 5) {
        const nextSibling = e.target.nextElementSibling
        if (nextSibling) {
          setTimeout(() => {
            nextSibling.focus()
          }, 50)
        }
      }
    }
  }

  const validateCode = useCallback(
    async (code: any) => {
      try {
        const isValid = /^\d{6}$/.test(code.join(''))
        if (code.length !== 6 || !isValid) {
          return
        }

        const smsCodeBody = {
          code: code.join(''),
          phone: getUserData.phone
        }

        const response = await postRequest(
          `${appConfig.API_BASEL_URL}/user/sms-code`,
          smsCodeBody,
          { headers }
        )

        if (response && response.data.data) {
          toast(
            <CustomToast type="success" text="Your Phone Has Been Validated!" />
          )
          setCurrentStep((prev) => prev + 1)
        } else {
          throw new Error('Code Not Valid')
        }
      } catch (error: any) {
        const errorMessage =
          error?.response?.data?.error ??
          error.message ??
          'Error with Submission'
        toast(<CustomToast type="error" text={errorMessage} />)
      }
    },
    [smsCode]
  )
  const handeResendCode = async () => {
    try {
      const response = await getRequest(
        `${appConfig.API_BASEL_URL}/user/resend-code`,
        { headers }
      )
      if (response && response.data.data) {
        toast(<CustomToast type="success" text="Your Code Has Been Resent" />)
      } else {
        throw new Error('Code Not Valid')
      }
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ?? error.message ?? 'Error with Submission'
      toast(<CustomToast type="error" text={errorMessage} />)
    }
  }

  return (
    <div className={styles.stepContainer}>
      <div className={styles.vendorStepContent}>
        <h1>
          Verify <span className="vs-primary-color">Your Number</span>
          {/* Verify Your Number */}
        </h1>
        <p>
          A 6 digit code was sent to {formatPhone(getUserData.phone)} <br />{' '}
          Please enter below to authenticate.
        </p>
        <div className={styles.smsWrapper}>
          <div className={styles.smsInnerWrapper}>
            {/* <p>Enter Your Code</p> */}
            <div className={styles.smsInputWrapper}>
              {smsCode.map((digit, index) => (
                <input
                  onKeyDown={(e) => {
                    if (![...'0123456789', 'Backspace'].includes(e.key)) {
                      e.preventDefault()
                    }
                  }}
                  key={index}
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  defaultValue={digit}
                  onChange={(e) => handleInputChange(e, index)}
                  maxLength={1}
                  onFocus={() => setActiveIndex(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <span className="reg-link" onClick={handeResendCode}>
        Resend Code
      </span>
    </div>
  )
}

export default VendorSignupForm
