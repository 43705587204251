import axios, { AxiosRequestConfig } from 'axios'
const API_URL = import.meta.env.VITE_API_URL

const api = axios.create({
  baseURL: API_URL,
})

api.interceptors.request.use((config) => {
  // Attach headers if needed, e.g., authentication tokens
  // config.headers.Authorization = `Bearer ${token}`;
  return config
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error)
  },
)

export const getRequest = <T>(url: string, params?: object) => {
  return api.get<T>(url, params)
}

export const postRequest = <T>(url: string, data?: object, config?: AxiosRequestConfig) => {
  return api.post<T>(url, data, config)
}

export const putRequest = <T>(url: string, data?: object, config?: AxiosRequestConfig) => {
  return api.put<T>(url, data, config)
}
