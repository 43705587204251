import { FC, useContext, useState } from 'react'
import { useForm, FormProvider, useFormContext } from 'react-hook-form'
import Box from '@mui/system/Box'
import Container from '@mui/system/Container'
import TopBar from '@/shared/components/TopBar/TopBar.tsx'
import StepHeader from '../../StepHeader/StepHeader.tsx'
import VerifySMS from '../../CommonSteps/VerifyCode/VerifySMS.tsx'
import StepCounter from '@/components/OnboardingCompNew/StepCounter.tsx'
import ProgressBar from '../../ProgressBar.tsx'
import styles from './styles.module.scss'
import formStyles from '@/components/Form/styles.module.scss'
import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext.tsx'
import { OnBaordingProps } from '@/components/OnboardingComp/types.ts'
import BackButton from '@/shared/components/BackButton/BackButton.tsx'
import InputField from '@/components/Form/Fields/InputField/InputField.tsx'
import FinalButton from '@/videoshops-shared/components/FinalButton/FinalButton.tsx'
import { putRequest } from '@/api/api.ts'
import { appConfig } from '@/app-config.ts'
import CustomToast from '@/components/Toaster/Toaster.tsx'
import { toast } from 'react-toastify'

const VerifyCode: FC = () => {
  const formMethods = useForm()
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false)
  const [showError, setShowError] = useState(false)
  const { formData, setFormData } = useContext(OnboardingContext) as OnBaordingProps

  const formattedPhoneNumber = formData.user.phone
    ? `(${formData.user.phone.slice(0, 3)}) ${formData.user.phone.slice(3, 6)}-${formData.user.phone.slice(6, 10)}`
    : ''

  const validatePhoneNumber = (phone: string) => {
    const isValid = /^\d{10}$/.test(phone)
    console.log(isValid, phone, 'isValid<wcdcdscdcd')
    setIsPhoneNumberValid(isValid)
    return isValid
  }

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, '') // Remove non-numeric characters
    setPhoneNumber(value)
    if (value.length > 0) {
      setShowError(true)
      validatePhoneNumber(value)
    } else {
      setShowError(false)
    }
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validatePhoneNumber(phoneNumber)) {
      toast(<CustomToast type="error" text="Invalid phone number" />)
      return
    }

    const data = {
      countryCode: '+1',
      phone: phoneNumber
    }
    const createUrl = `${appConfig.API_BASEL_URL}/user/phone`
    const headers = { Authorization: localStorage.getItem('TOKEN') }
    setLoading(true)

    try {
      const response: OnboardingResponseData = await putRequest(createUrl, data, { headers })
      console.log(data, response)
      setFormData({ ...formData, user: { ...formData.user, phone: phoneNumber } })
      toast(<CustomToast type="success" text="Phone Number Updated" />)
      setLoading(false)
      setEdit(false)
    } catch (error: any) {
      const errorMessage = error.response.data.error
        ? error.response.data.error
        : 'Error Changing Number'
      toast(<CustomToast type="error" text={errorMessage} />)
      setLoading(false)
    }
  }

  console.log(`Phone number is ${isPhoneNumberValid ? 'valid' : 'invalid'}`)

  return (
    <FormProvider {...formMethods}>
      <div className={styles.stepPage}>
        <TopBar />
        <ProgressBar value={(100 / 8) * 2} />
        <Box mt={{ xs: 1.5, md: 3.5 }}>
          <Container maxWidth="lg">
            <Box height={36} display="flex" alignItems="center" justifyContent="space-between">
              {edit && (
                <BackButton to="/creator/setup/verify-code" callback={() => setEdit(false)} />
              )}
              <StepCounter step={2} total={8} />
            </Box>
          </Container>
          <Container maxWidth={false} sx={{ maxWidth: 459 }}>
            <Box mt={2} fontFamily="Montserrat">
              <StepHeader>Verify Your Account</StepHeader>
            </Box>
            <div className={styles.subText}>
              {!edit ? (
                <p>
                  We have sent you a verification code to <br /> + 1 {formattedPhoneNumber}{' '}
                  <span onClick={() => setEdit(true)}>Change</span>
                </p>
              ) : (
                <p>Update your mobile number and get a 6-digit verification code.</p>
              )}
            </div>
            <Box mt="22px">
              {!edit ? (
                <VerifySMS nextPage="/creator/setup/name" />
              ) : (
                <form onSubmit={onSubmit} className={formStyles.formContainer}>
                  <Box>
                    <InputField
                      label="New Mobile Number"
                      name="phone"
                      type="text"
                      maxCount={10}
                      required
                      onCustomChange={handlePhoneNumberChange}
                    />
                    {showError && !isPhoneNumberValid && (
                      <p className={formStyles.errorText}>
                        The phone number you have entered contains invalid characters. Please ensure
                        you only include numbers (0-9).
                      </p>
                    )}
                  </Box>
                  <Box mt={7}>
                    <FinalButton
                      type="submit"
                      variant="primary"
                      loading={loading}
                      disabled={!isPhoneNumberValid || loading}
                      full
                    >
                      Save Number and Resend Code
                    </FinalButton>
                  </Box>
                </form>
              )}
            </Box>
          </Container>
        </Box>
      </div>
    </FormProvider>
  )
}

export default VerifyCode
