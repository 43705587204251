import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import InputField from '@/components/Form/Fields/InputField/InputField'
import PasswordRules from '@/components/Form/PasswordRules/PasswordRules'
import { COUNTRY_PHONE_CODES } from '@/constants/countryCodes'
import SelectComp from '@/components/Form/Fields/Select/Select'
import Divider from '@/videoshops-shared/components/Divider/Divider.tsx'
import AgreeCheckbox from '@/components/OnboardingCompNew/AgreeCheckbox.tsx'
import Text from '@/components/OnboardingCompNew/SellerSteps/Text/Text'
import FinalButton from '@/videoshops-shared/components/FinalButton/FinalButton.tsx'
import { ReactComponent as HelpIcon } from '@/assets/icons/help-icon.svg'
import { ReactComponent as InfoIcon } from '@/assets/icons/info-circle.svg'
import {
  StyledPasswordRulesWrapper,
  StyledRequiredWrapper,
  StyledSignUpActions,
  StyledSignUpForm,
  StyledSignUpFormRow,
  StyledSignUpFormStepWrapper
} from '../../OnboardingGlobal.styled'

const SignUpForm: FC = () => {
  const {
    getValues,
    formState: { isValid, isSubmitting },
    watch
  } = useFormContext()
  const confirmPassword = watch('confirmPassword')
  const terms = watch('terms')

  return (
    <>
      <StyledSignUpFormStepWrapper>
        <Divider>BRAND DETAILS</Divider>
        <StyledSignUpForm>
          <StyledSignUpFormRow>
            <InputField colW={12} label="Merchant Name" name="brandName" type="text" required />
          </StyledSignUpFormRow>
          <StyledSignUpFormRow>
            <InputField
              colW={12}
              label="Merchant Website"
              name="brandWebsite"
              type="text"
              required
            />
          </StyledSignUpFormRow>
          <StyledSignUpFormRow>
            <InputField
              colW={12}
              label="Merchant Privacy Policy URL"
              name="privacyUrl"
              type="text"
              additionalHelpInfo={{
                icon: <HelpIcon />,
                heading: 'Merchant URL’s',
                headingIcon: <InfoIcon />,
                text: (
                  <div>
                    <p className="infotext">
                      We’ll populate your Terms and Privacy Policy in checkout for shoppers.
                    </p>
                    <p className="infotext">
                      You can find these links to these in the footer of your website. Paste the
                      full URL into the form, starting with https://
                    </p>
                  </div>
                ),
                actions: [
                  {
                    label: 'Close',
                    action: () => {}
                  }
                ]
              }}
              required
            />
          </StyledSignUpFormRow>
          <StyledSignUpFormRow>
            <InputField
              colW={12}
              label="Merchant Terms of Service URL"
              name="termsUrl"
              type="text"
              additionalHelpInfo={{
                icon: <HelpIcon />,
                heading: 'Merchant URL’s',
                headingIcon: <InfoIcon />,
                text: (
                  <div>
                    <p className="infotext">
                      We’ll populate your Terms and Privacy Policy in checkout for shoppers.
                    </p>
                    <p className="infotext">
                      You can find these links to these in the footer of your website. Paste the
                      full URL into the form, starting with https://
                    </p>
                  </div>
                ),
                actions: [
                  {
                    label: 'Close',
                    action: () => {}
                  }
                ]
              }}
              required
            />
          </StyledSignUpFormRow>
          <StyledRequiredWrapper>
            <span className="required-asterix">*</span>
            <Text text="Required" color="grey" size="xs" />
          </StyledRequiredWrapper>
        </StyledSignUpForm>
      </StyledSignUpFormStepWrapper>

      <StyledSignUpFormStepWrapper marginTop="1.5rem">
        <Divider>AUTHORIZED ACCOUNT MANAGER </Divider>
        <StyledSignUpForm>
          <StyledSignUpFormRow>
            <InputField colW={6} label="First Name" name="firstName" type="text" required />
            <InputField colW={6} label="Last Name" name="lastName" type="text" required />
          </StyledSignUpFormRow>
          <StyledSignUpFormRow>
            <InputField colW={12} label="Email Address" name="email" type="text" required />
          </StyledSignUpFormRow>
          <StyledSignUpFormRow>
            <SelectComp colW={2} name="countryCode" optionData={COUNTRY_PHONE_CODES} required />
            <InputField colW={11} label="Mobile Number" name="phone" type="text" required />
          </StyledSignUpFormRow>

          <StyledSignUpFormRow>
            <InputField
              colW={12}
              label="Create Password"
              name="password"
              type="password"
              required
            />
          </StyledSignUpFormRow>
          {getValues('password') && (
            <StyledSignUpFormRow>
              <StyledPasswordRulesWrapper>
                <PasswordRules value={getValues('password')} open={getValues('password')} />
              </StyledPasswordRulesWrapper>
            </StyledSignUpFormRow>
          )}
          <StyledSignUpFormRow>
            <InputField
              colW={12}
              label="Confirm The Password"
              name="confirmPassword"
              type="password"
              required
            />
          </StyledSignUpFormRow>
          <AgreeCheckbox type="brand" />
          <StyledRequiredWrapper marginTop="1rem">
            <span className="required-asterix">*</span>
            <Text text="Required" color="grey" size="xs" />
          </StyledRequiredWrapper>
        </StyledSignUpForm>
        {confirmPassword && !terms && (
          <Text
            text="Please review and agree to the terms and conditions to proceed."
            size="small"
            alignment="left"
            weight={500}
            color="danger"
          />
        )}
        <StyledSignUpActions>
          <FinalButton
            type="submit"
            disabled={!isValid || isSubmitting}
            variant="primary"
            loading={isSubmitting}
            full
          >
            Next
          </FinalButton>
        </StyledSignUpActions>
      </StyledSignUpFormStepWrapper>
    </>
  )
}

export default SignUpForm
