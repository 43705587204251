import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import styles from '@/components/OnboardingCompNew/styles.module.scss'
import TopBar from '@/shared/components/TopBar/TopBar.tsx'
import Box from '@mui/system/Box'
import Container from '@mui/system/Container'
import BackButton from '@/shared/components/BackButton/BackButton.tsx'
import StepHeader from '@/components/OnboardingCompNew/StepHeader/StepHeader.tsx'
import formStyles from '@/components/Form/styles.module.scss'
import InputField from '@/components/Form/Fields/InputField/InputField.tsx'
import FinalButton from '@/videoshops-shared/components/FinalButton/FinalButton.tsx'
import { OnboardingStep } from '@/constants/onboarding.constant'
import useOnboarding from '../../useOnboarding'

const MerchantName: FC = () => {
  const navigate = useNavigate()
  const { saveOnboardingData } = useOnboarding()
  const form = useForm({
    mode: 'onChange',
    defaultValues: { name: '' }
  })

  const onSubmit = async () => {
    const saved = await saveOnboardingData(OnboardingStep.MERCHANT_NAME, localStorage.getItem('TOKEN') || '' )
    if (saved) {
      navigate('/brand/setup/description')
    } else {
      console.log('Error saving onboarding data')
    }
  }

  return (
    <div className={styles.stepPage}>
      <TopBar />
      <Box mt={{ xs: 1.5, md: 3.5 }}>
        <Container maxWidth="lg">
          <BackButton to="/brand/setup/verify-code" />
        </Container>
        <Container maxWidth={false} sx={{ maxWidth: 411 }}>
          <Box mt={3}>
            <StepHeader title="Merchant Sign Up">Confirm Your Merchant Name</StepHeader>
          </Box>
          <Box mt={2}>
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className={formStyles.formContainer}>
                <InputField label="Merchant Name" name="name" type="text" required />
                <Box mt={4}>
                  <FinalButton type="submit" variant="primary" full className={styles.button}>
                    Next
                  </FinalButton>
                </Box>
              </form>
            </FormProvider>
          </Box>
        </Container>
      </Box>
    </div>
  )
}

export default MerchantName
