import classNames from 'classnames'
import { useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styles from '../styles.module.scss'

export default function ContactSelect({
  label,
  colW,
  name,
  optionData,
  className,
  required,
  helpText
}: FormElementProps) {
  const selectRef = useRef<HTMLSelectElement>(null)
  const { trigger, formState, setValue, getValues, register } = useFormContext()
  const { errors } = formState
  const error = errors[name]
  const fieldError = error as FieldErrorProps

  const [focused, setFocused] = useState(true)

  const handleBlur = () => {
    if (selectRef.current?.value) {
      return
    }
    setFocused(false)
  }

  const onClick = () => {
    setFocused(true)
    selectRef.current?.focus()
  }

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const currentValue = e.currentTarget.value.trim()
    setValue(name, currentValue === '' ? null : currentValue)
    trigger(name)
  }

  return (
    <>
      <div
        className={classNames(
          colW && `col-${colW}`,
          styles.selectWrapper,
          className
        )}
        onClick={onClick}
      >
        {label && (
          <label>
            {label}
            {required && <span className={styles.requiredAsterix}>*</span>}
          </label>
        )}
        <select
          {...register(name)}
          ref={selectRef}
          name={name}
          onChange={onChange}
          defaultValue="+1"
          onFocus={() => setFocused(true)}
          onBlur={handleBlur}
        >
          {optionData?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className={styles.fieldErrors}>
          {helpText && <p className={styles.helpText}>{helpText}</p>}
          {fieldError?.message && <p>{fieldError.message}</p>}
        </div>
      </div>
    </>
  )
}
