import classNames from 'classnames'

import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import { PhoneCollageProps, PhoneCollageType } from './types'
import { useStaticPageContext } from '@/context/StaticPageContext'
import { SiteWideTypes } from '@/types/SiteContext.interface'
import { brandPhoneStack, personalPhoneStack } from '@/constants/phoneCollage'
import { ReactComponent as SameDayPayoutBtn } from '@/assets/home/same-payout-button.svg'
import Phone from './Phone/Phone'
import { numberToMoneyString } from '@/utils/helpers'
import { useInFocus } from '@/hooks/useInFocus'
const VITE_VIDEO_URL = import.meta.env.VITE_VIDEO_URL

const PhoneCollage: React.FC<PhoneCollageProps> = ({ className }) => {
  const { siteType } = useStaticPageContext() as SiteWideTypes
  const isPeople = siteType === 'people'
  const isMobile = window.innerWidth < 768
  const values = isMobile ? '10% 0% 10% 0%' : '56% 0% 0px 30%'
  const ref = useRef<HTMLDivElement>(null)
  const inFocus = useInFocus(ref, values, [0.1, 0.9])
  const [phoneCollageData, setPhoneCollageData] =
    React.useState<any[]>(personalPhoneStack)
  const [currentRevenue, setCurrentRevenue] = useState(0)
  const [currentFollowers, setCurrentFollowers] = useState(0)
  const duration = 120000

  // useEffect(() => {
  //   setPhoneCollageData(
  //     siteType === 'people' ? personalPhoneStack : brandPhoneStack
  //   )
  //   let animationId: ReturnType<typeof setInterval>
  //   let timeoutId: ReturnType<typeof setTimeout>

  //   if (siteType) {
  //     resetStats()
  //   }

  //   animationId = setInterval(() => {
  //     setCurrentFollowers((prev: number) => prev + 10)
  //     setCurrentRevenue((prev: number) => prev + 0.4)
  //   }, 100)

  //   timeoutId = setTimeout(() => {
  //     clearInterval(animationId)
  //   }, 60 * duration)
  //   return () => {
  //     clearInterval(animationId)
  //     clearTimeout(timeoutId)
  //   }
  // }, [])

  const resetStats = () => {
    const totalRevenue = isPeople ? 8200 : 88200
    const totalFollowers = isPeople ? 200 : 400
    setCurrentRevenue(totalRevenue)
    setCurrentFollowers(totalFollowers)
  }

  const renderPhoneCollageElement = (
    phone: PhoneCollageType,
    index: number
  ) => {
    switch (index) {
      case 2:
        return (
          <div key={phone.name + index} className={styles.phoneInnerWrapper}>
            <div className={styles.videoContainer}>
              <video autoPlay playsInline muted loop>
                <source
                  src={`${VITE_VIDEO_URL}hero-video.mp4`}
                  type="video/mp4"
                />
              </video>
            </div>

            <div className={styles.phoneInnerPhoneImage}>
              <Phone
                key={index}
                name={!isMobile ? phone.name : ''}
                videoTitle={phone.videoTitle}
              />
            </div>
          </div>
        )
      default:
        return (
          <Phone
            key={phone.name + index}
            name={phone.name}
            videoTitle={phone.videoTitle}
          />
        )
    }
  }

  return (
    <div ref={ref} className={styles.phoneCollageContainer}>
      <div className={classNames(className, styles.phoneWrapper)}>
        {personalPhoneStack.map((phone, index) =>
          renderPhoneCollageElement(phone, index)
        )}
      </div>
    </div>
  )
}

export default PhoneCollage
