import { FC } from 'react'
import * as Yup from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import TopBar from '@/shared/components/TopBar/TopBar.tsx'
import Box from '@mui/system/Box'
import Container from '@mui/system/Container'
import Typography from '@mui/material/Typography'
import BackButton from '@/shared/components/BackButton/BackButton.tsx'
import StepHeader from '../../StepHeader/StepHeader.tsx'
import formStyles from '@/components/Form/styles.module.scss'
import styles from '../../styles.module.scss'
import StepCounter from '@/components/OnboardingCompNew/StepCounter.tsx'
import URLField from './URLField'
import ProgressBar from '../../ProgressBar.tsx'
import { yupResolver } from '@hookform/resolvers/yup'
import useOnboarding from '../../useOnboarding.ts'
import { OnboardingStep } from '@/constants/onboarding.constant.ts'

const vendorSetupFormSchema = Yup.object().shape({
  key: Yup.string()
    .min(3, 'Your shop link is too short')
    .matches(
      /^[a-z0-9_-]+$/,
      'Your shop link can only contain lowercase letters, numbers, hyphens, and underscores'
    )
    .max(30, 'Your shop link must be less than 30 characters')
    .required('A URL is required for your shop link')
    .trim()
})

const MarketPlaceURL: FC = () => {
  const navigate = useNavigate()
  const { saveOnboardingData } = useOnboarding()
  const form = useForm({
    resolver: yupResolver(vendorSetupFormSchema),
    mode: 'onChange',
    defaultValues: { key: '' }
  })

  const onSubmit = async () => {
    try {
      const saved = await saveOnboardingData(OnboardingStep.SHOP_URL,  localStorage.getItem('TOKEN') || '' )
      if (saved) {
        navigate('/creator/setup/description')
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className={styles.stepPage}>
      <TopBar />
      <ProgressBar value={(100 / 8) * 4} />
      <Box mt={{ xs: 1.5, md: 3.5 }}>
        <Container maxWidth="lg">
          <Box height={36} display="flex" alignItems="center" justifyContent="space-between">
            <BackButton to="/creator/setup/name" />
            <StepCounter step={4} total={8} />
          </Box>
        </Container>
        <Container maxWidth={false} sx={{ maxWidth: 459 }}>
          <Box mt={2}>
            <StepHeader>Your Shop Link</StepHeader>
            <Box mt="22px" textAlign="center">
              <Typography align="center" variant="xs_med" color="ash">
                Your link is permanent and cannot be changed.
              </Typography>
            </Box>
          </Box>
          <Box mt="22px">
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className={formStyles.formContainer}>
                <URLField label="marketplace.videoshops.com/" name="key" type="text" />
              </form>
            </FormProvider>
          </Box>
        </Container>
      </Box>
    </div>
  )
}

export default MarketPlaceURL
