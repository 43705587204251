import { FC, useRef } from 'react'
import { toast } from 'react-toastify'
import { useFormContext } from 'react-hook-form'
import { useOnboardingContext } from '@/hooks/useOnboardingForm.ts'
import { postRequest } from '@/api/api.ts'
import { appConfig } from '@/app-config.ts'
import { UploadResponseData } from '@/components/Form/Fields/FileUpload/types.ts'
import CustomToast from '@/components/Toaster/Toaster.tsx'
import { updateFormState } from '@/utils/formHelpers.ts'
import Box from '@mui/system/Box'
import { OnboardingStep } from '@/constants/onboarding.constant'

import useImageEditor from '../../../../videoshops-shared/hooks/ImageEditor/useImageEditorHook.ts'
import { ResObj } from '../../../../videoshops-shared/components/ImageEditor/types.ts'
import ImageEditor from '../../../../videoshops-shared/components/ImageEditor/ImageEditor.tsx'
import Modal from '../../../../videoshops-shared/components/Utility/Modal/Modal.tsx'
import styles from '../../styles.module.scss'
import FinalButton from '@/videoshops-shared/components/FinalButton/FinalButton.tsx'
import useOnboarding from '../../useOnboarding.ts'

interface ProfileImageUploadProps {
  croppingType: 'circle' | 'rectangle' | 'square'
  buttonLabel: string
  imageKey: string
  imageName: string
}

const ProfileImageUpload: FC<ProfileImageUploadProps> = ({
  croppingType,
  buttonLabel,
  imageKey,
  imageName
}) => {
  const { formData, siteType, setFormData } = useOnboardingContext()
  const { marketplace } = formData
  const imageUrl: string | null = marketplace[imageName]
  const hasProfileImage = imageUrl ? true : false

  const profileImage =
    marketplace && hasProfileImage ? `${imageUrl}` : '/homeappimages/hero-mobile-placeholder.png'

  const { setImage, image, setOpenModal, openModal } = useImageEditor()
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { setValue } = useFormContext()
  const { saveOnboardingData } = useOnboarding()

  const handleOnClick = () => {
    setOpenModal(true)
    setImage(profileImage)
  }

  const handleSubmitImageEdit = async (resObj: ResObj) => {
    if (!resObj) return
    const toastId = toast(<CustomToast />)
    timeoutRef.current = setTimeout(() => {
      toast.dismiss(toastId)
      toast(<CustomToast type="error" text="Please try again with a smaller file size image." />)
    }, 15000)
    try {
      const headers = {
        'Content-Type': resObj.imageType,
        ...(siteType === 'vendor' ? { Authorization: localStorage.getItem('TOKEN') || '' } : {})
      }

      const uploadFileRequest = await postRequest<UploadResponseData>(
        `${appConfig.UPLOAD_BASE_URL}/user/upload`,
        resObj.blob,
        { headers }
      )
      if (
        uploadFileRequest.status === 200 &&
        uploadFileRequest.data &&
        'data' in uploadFileRequest.data
      ) {
        const { key, path } = (uploadFileRequest.data as UploadResponseData).data
        setValue(imageKey, key)
        setValue(imageName, path)
        updateFormState(setFormData, imageName, path)
        updateFormState(setFormData, imageKey, key)
        toast.dismiss(toastId)
        clearTimeout(timeoutRef.current!)
        const saved = await saveOnboardingData(
          siteType === 'creator' ? OnboardingStep.PROFILE_IMAGE : OnboardingStep.BRAND_LOGO,
          localStorage.getItem('TOKEN') || ''
        )
        if (saved) {
          toast(<CustomToast type="success" text="Image Uploaded Successfully!" />)
        }
      } else {
        toast.dismiss(toastId)
        clearTimeout(timeoutRef.current!)
        toast(<CustomToast type="error" text="Image upload failed please try again." />)
      }
    } catch (error: any) {
      const errorMsg = 'Upload failed - ' + error?.response?.data.error
      toast.dismiss(toastId)
      clearTimeout(timeoutRef.current!)
      toast(<CustomToast type="error" text={errorMsg} />)
    }
  }

  return (
    <>
      {imageUrl && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          width="100%"
          height="100%"
          top={0}
          left={0}
          sx={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${imageUrl})`
          }}
          onClick={handleOnClick}
        />
      )}
      <div className={styles.userElipseWrapper}>
        <FinalButton type="button" onClick={handleOnClick} variant="secondary" size="md">
          {buttonLabel}
        </FinalButton>
        {image && (
          <Modal onClose={() => setOpenModal(false)} open={openModal} heading="">
            <ImageEditor
              image={image}
              setOpenModal={setOpenModal}
              croppingType={croppingType}
              submitCrop={handleSubmitImageEdit}
              hasImageAlready={hasProfileImage}
            />
          </Modal>
        )}
      </div>
    </>
  )
}

export default ProfileImageUpload
