import { useEffect } from "react";
import gaActions from "../utils/ga.utils";

const useGoogleAnalytics = () => {
  // Expecting this to get more rebust down the road

  useEffect(() => {
    gaActions.initialize();
  }, []);
};

export default useGoogleAnalytics;
