import React from "react";
import Box from "@mui/system/Box";

interface SellersBackgroundProps {
  children: React.ReactNode;
}

export default function SellersBackground({
  children,
}: SellersBackgroundProps) {
  return (
    <Box position="relative" height="100vh" width="100vw">
      <Box
        component="video"
        autoPlay
        playsInline
        muted
        loop
        sx={{ objectFit: "cover" }}
        overflow="hidden"
        position="fixed"
        top={0}
        left={0}
        bottom={0}
        right={0}
        bgcolor="rgba(0, 0, 0, 0.9)"
        width="100%"
        height="100%"
      >
        <source src="/homeappvideos/seller-bg.mp4" type="video/mp4" />
      </Box>
      {children}
    </Box>
  );
}
