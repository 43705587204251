import React, { FC, useContext, useState } from 'react'
import { toast } from 'react-toastify'
import styles from '@/components/OnboardingCompNew/styles.module.scss'
import TopBar from '@/shared/components/TopBar/TopBar.tsx'
import Box from '@mui/system/Box'
import Container from '@mui/system/Container'
import BackButton from '@/shared/components/BackButton/BackButton.tsx'
import StepHeader from '@/components/OnboardingCompNew/StepHeader/StepHeader.tsx'
import SameDayPay from './SameDayPay.tsx'
import SellerCommissionRate from './SellerCommissionRate.tsx'
import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext.tsx'
import { OnBaordingProps } from '@/components/OnboardingComp/types.ts'
import { appConfig } from '@/app-config.ts'
import { getRequest, postRequest } from '@/api/api.ts'
import CustomToast from '@/components/Toaster/Toaster.tsx'
import FinalButton from '@/videoshops-shared/components/FinalButton/FinalButton.tsx'
import { ReactComponent as SameDayPayBanner } from '@/videoshops-shared/assets/images/merchants/same-day-pay-banner.svg'
const allOptions = Array(9)
  .fill(10)
  .map((value, index) => ({
    value: index * 5 + value,
    label: `${index * 5 + value}% Commission`
  }))

const commissionRateKey = 'commissionRate'
const sameDayPayEnabledKey = 'sameDayPayEnabled'

const Commission: FC = () => {
  const initRate = parseInt(localStorage.getItem(commissionRateKey)) || ''
  const [loading, setLoading] = useState(false)
  const [enabled, setEnabled] = useState(true)
  const [rate, setRate] = React.useState(initRate)
  const { formData } = useContext(OnboardingContext) as OnBaordingProps

  const toggleSameDayPay = (value: boolean) => {
    setEnabled(value)
  }

  const getMarketplaceLink = (link: string) => {
    const headers = { Authorization: localStorage.getItem('TOKEN') }
    return getRequest(`${appConfig.API_BASEL_URL}/marketplace/available/${link}`, { headers }).then(
      ({ data }) => data.data
    )
  }

  const createMarketplace = async () => {
    const link = `${formData.user.firstName}${formData.user.lastName}`
    const linkResponse = await getMarketplaceLink(link)
    const key = linkResponse.isMarketplaceAvailable ? link : linkResponse.recommendedKeys[0]
    const marketplace = {
      key,
      userId: localStorage.getItem('userId'),
      name: formData.marketplace.name,
      description: formData.marketplace.description,
      affinities: formData.marketplace.affinities,
      commissionRate: rate ? parseInt(rate) : rate,
      isTheSameDayPay: enabled,
      currency: 'USD'
    }
    if (formData.marketplace.description === null) {
      marketplace.description = ''
    }
    if (formData.marketplace.key === null) {
      marketplace.key = formData.marketplace.name?.toLowerCase().replace(/ /g, '-') || ''
    }

    if (formData.marketplace.profileImageKey) {
      marketplace.profileImageKey = formData.marketplace.profileImageKey
    }

    const createUrl = `${appConfig.API_BASEL_URL}/marketplace/vendor`
    const headers = { Authorization: localStorage.getItem('TOKEN') }
    return await postRequest(createUrl, marketplace, { headers })
  }

  const onSubmit = async () => {
    setLoading(true)
    try {
      const response = await createMarketplace()
      if (response.status === 200) {
        const authToken = localStorage.getItem('TOKEN')
        if (authToken) {
          setTimeout(() => {
            setLoading(false)
            window.location.href = `${appConfig.CLIENT_APP_URL}?TOKEN=${authToken}`
          }, 1000)
          localStorage.removeItem('currentStep')
          localStorage.removeItem('formData')
          localStorage.removeItem('TOKEN')
          localStorage.removeItem('protectedPagePassword')
          localStorage.removeItem('heroImageKey')
          localStorage.removeItem(commissionRateKey)
          localStorage.removeItem(sameDayPayEnabledKey)
          toast(
            <CustomToast
              type="success"
              text="Congrats! An account has been created. Redirecting to your account."
            />
          )
        }
      }
    } catch (error: any) {
      const errorMessage = error.response.data.error
        ? error.response.data.error
        : 'Error with Submission'
      toast(<CustomToast type="error" text={errorMessage} />)
    }
  }

  React.useEffect(() => {
    localStorage.setItem(commissionRateKey, rate)
  }, [rate])

  React.useEffect(() => {
    localStorage.setItem(sameDayPayEnabledKey, enabled)
  }, [enabled])

  return (
    <div className={styles.stepPage}>
      <TopBar />
      <Box mt={{ xs: 1.5, md: 3.5 }}>
        <Container maxWidth="lg">
          <BackButton to="/brand/setup/affinities" />
        </Container>
        <Container maxWidth={false} sx={{ maxWidth: 411 }}>
          <Box mt={3}>
            <StepHeader title="Merchant Sign Up"></StepHeader>
          </Box>
          <SameDayPayBanner />
          <Box mt={2}>
            <Box mb={2} fontSize="14px" fontWeight={700} mt={3.5} color="#232A31">
              Select Seller Commission
            </Box>
            <SellerCommissionRate
              enabled={enabled}
              value={rate}
              options={allOptions}
              setValue={setRate}
            />
            <Box fontSize="10px" lineHeight="12px" mt={1.5} color="#1E1F22">
              {enabled
                ? 'In addition, VideoShops deducts a 5% platform fee on the gross transaction amount. With Same Day Pay, you (the merchant) and sellers will receive payment the same day orders are placed.'
                : 'In addition, VideoShops deducts a 5% platform fee on the gross transaction amount. Your current selection has a 30 Day Payout for you (the merchant) and sellers.'}
            </Box>
            <Box mt={4}>
              <FinalButton
                disabled={!rate}
                type="submit"
                variant="primary"
                loading={loading}
                full
                onClick={onSubmit}
              >
                Next
              </FinalButton>
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  )
}

export default Commission
