import classNames from 'classnames'
import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from '../../styles.module.scss'
import { useFormContext } from 'react-hook-form'
import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext'
import { OnBaordingProps } from '@/components/OnboardingComp/types'
import { getValueFromNestedObject, updateFormState } from '@/utils/formHelpers'
import { ReactComponent as HideIcon } from '@/assets/icons/hide.svg'
import { ReactComponent as ShowIcon } from '@/assets/icons/open.svg'

import { codifyStr } from '@/utils/helpers'
import { profanityErrorStr } from '@/constants/profantiy'
import { checkForProfanity } from '@/utils/moderation.utils'
import Box from '@mui/material/Box'
import styled from 'styled-components'

const InputField: React.FC<FormElementProps> = ({
  label,
  colW,
  name,
  placeholder,
  type,
  className,
  required,
  helpText,
  additionalHelpInfo,
  staticCopy,
  maxCount,
  onCustomChange
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { trigger, formState, setValue, getValues, register, setError } = useFormContext()

  const { formData, setFormData, setIsLoading } = useContext(OnboardingContext) as OnBaordingProps

  const { errors } = formState
  const error = errors[name]
  const fieldError = error as FieldErrorProps
  const activeValue = getValues(name)

  const [focused, setFocused] = useState(!!activeValue)
  const [isPasswordShown, setPasswordShown] = useState(false)

  const [openPopup, setOpenPopup] = React.useState(false)

  useEffect(() => {
    const retrievedValue = getValueFromNestedObject(formData, name)
    if (retrievedValue !== null) {
      setValue(name, retrievedValue)
      setFocused(!!retrievedValue)
    }
  }, [name, formData, setValue])

  const handleBlur = () => {
    if (inputRef.current?.value) {
      return
    }
    setFocused(false)
  }
  const togglePasswordVisibility = () => {
    setPasswordShown(!isPasswordShown)
  }

  const onClick = () => {
    setFocused(true)
    inputRef.current?.focus()
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.currentTarget.value
    if (name === 'phone') {
      currentValue.replace(/[^\d]/g, '')
    }
    if (checkForProfanity(currentValue)) {
      setIsLoading(true)
      setValue(name, currentValue === '' ? null : currentValue)
      setError(name, { type: 'manual', message: profanityErrorStr })
    } else {
      setIsLoading(false)
      setValue(name, currentValue === '' ? null : currentValue)
      trigger(name).then()
      updateFormState(setFormData, name, currentValue)
      if (name === 'brandName') {
        const newName = currentValue.trim()
        setValue('name', currentValue === '' ? null : newName)
        if (currentValue) {
          updateFormState(setFormData, 'name', newName)
        }
      }
      if (name === 'name') {
        const newName = codifyStr(currentValue.trim())
        setValue('key', currentValue === '' ? null : newName)
        if (currentValue) {
          updateFormState(setFormData, 'key', newName)
        }
      }
    }
  }

  const HelpInfoPopup = styled(Box)`
    position: absolute;
    right: -360px;
    top: -50px;
    background-color: #ffffff;
    box-shadow: 0 0 12px 0 #00000033;
    z-index: 1;
    width: 350px;
    height: 228px;
    padding: 20px;
    gap: 20px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;

    & > div.card-header {
      display: flex;
      flex-direction: row;
      gap: 8px;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;

      & > div.heading-icon {
        display: flex;

        & > svg {
          position: initial;
        }
      }
    }

      p.infotext {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        margin-bottom: .5rem;
      }
    }

    div.card-footer {
      display: flex;
      justify-content: flex-end;

      button {
        background: #ffffff;
        border: 1px solid #ebebeb;
        padding: 10px 18px;
        border-radius: 54px;
        gap: 0;
        text-align: center;
        cursor: pointer;
      }
    }
  `
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  return (
    <>
      <div className={classNames(`col-${colW}`, styles.formGroup, className)} onClick={onClick}>
        <label className={focused ? styles.labelFocused : ''}>
          {label}
          {required && <span className="required-asterix">*</span>}
        </label>
        <div className={styles.inputWrapper}>
          <input
            {...register(name)}
            ref={inputRef}
            name={name}
            type={
              name === 'password' || name === 'confirmPassword'
                ? isPasswordShown
                  ? 'text'
                  : 'password'
                : type
            }
            defaultValue={activeValue}
            maxLength={maxCount}
            onChange={onCustomChange || onChange}
            onKeyDown={handleKeyPress}
            onFocus={() => setFocused(true)}
            onBlur={handleBlur}
            placeholder={placeholder}
          />
          {additionalHelpInfo &&
            React.cloneElement(additionalHelpInfo?.icon, {
              cursor: 'pointer',
              onClick: () => setOpenPopup((prevState: boolean) => !prevState)
            })}
          {openPopup && (
            <HelpInfoPopup>
              <div className="card-header">
                <div className="heading-icon">
                  {React.cloneElement(additionalHelpInfo?.headingIcon)}
                </div>
                <h3>{additionalHelpInfo?.heading}</h3>
              </div>
              <div className="card-content">{additionalHelpInfo?.text}</div>
              <div className="card-footer">
                <button onClick={() => setOpenPopup(false)}>Close</button>
              </div>
            </HelpInfoPopup>
          )}
          {(name === 'password' || name === 'confirmPassword') && (
            <span onClick={togglePasswordVisibility}>
              {isPasswordShown ? <HideIcon /> : <ShowIcon />}
            </span>
          )}
        </div>
        <div className={styles.fieldErrors}>
          {staticCopy && staticCopy}
          {helpText && <p className={styles.helpText}>{helpText}</p>}
          {fieldError?.message && <p>{fieldError.message}</p>}
        </div>
      </div>
    </>
  )
}

export default InputField
