import { FC, useContext, useEffect, useState } from 'react'
import styles from '../../styles.module.scss'
import { BrandMarketPlaceAffinitiesKeys } from '@/constants/affinities'
import { useFormContext } from 'react-hook-form'
import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext'
import { OnBaordingProps } from '@/components/OnboardingComp/types'
import { updateFormState } from '@/utils/formHelpers'

const MarketPlaceAffinities: FC = () => {
  // const { onBoardingTitleBranding, siteType } = useOnboardingContext()
  const [selectedAffinities, setSelectedAffinities] = useState<string[]>([])
  const { trigger, formState, setValue, register } = useFormContext()
  const { formData, setFormData } = useContext(
    OnboardingContext
  ) as OnBaordingProps

  useEffect(() => {
    register('affinities')
  }, [register])

  useEffect(() => {
    const affinitiesValue = formData.marketplace?.affinities
    if (affinitiesValue) {
      const affinitiesArray =
        typeof affinitiesValue === 'string'
          ? [affinitiesValue]
          : affinitiesValue
      setSelectedAffinities(affinitiesArray)
      setValue('affinities', affinitiesArray) // Update react-hook-form state as well
    }
  }, [formData, setValue])

  const { errors } = formState
  const error = errors['affinities']
  const fieldError = error as FieldErrorProps
  const toggleAffinity = (affinity: string) => {
    setSelectedAffinities((prevSelectedAffinities) => {
      const name = 'addinities'
      let updatedSelectedAffinities: string[]
      if (prevSelectedAffinities.includes(affinity)) {
        updatedSelectedAffinities = prevSelectedAffinities.filter(
          (a) => a !== affinity
        )
      } else {
        updatedSelectedAffinities = [...prevSelectedAffinities, affinity]
      }

      setValue(name, updatedSelectedAffinities)
      trigger(name)

      setFormData((prevData) => {
        const updatedData: VendorState = {
          ...prevData,
          marketplace: {
            ...prevData.marketplace,
            affinities: updatedSelectedAffinities
          }
        }

        return updatedData
      })
      return updatedSelectedAffinities
    })
  }

  return (
    <div className={styles.stepContainer}>
      <h1>
        Select <span className="vs-primary-color">Affinities</span>
      </h1>
      <div className={styles.affinityWrapper}>
        {Object.keys(BrandMarketPlaceAffinitiesKeys).map((key) => {
          const affinity =
            BrandMarketPlaceAffinitiesKeys[
              key as keyof typeof BrandMarketPlaceAffinitiesKeys
            ]
          const isSelected = selectedAffinities.includes(affinity)
          return (
            <span
              key={affinity}
              className={`${styles.affinityPill} ${
                isSelected ? styles.selected : ''
              }`}
              onClick={() => toggleAffinity(affinity)}
            >
              {affinity.replace(/_/g, '-').toUpperCase()}
            </span>
          )
        })}
      </div>
      <div className={styles.fieldErrors}>
        {fieldError?.message && <p>{fieldError.message}</p>}
      </div>
    </div>
  )
}

export default MarketPlaceAffinities
