import { FC } from 'react'
import { useParams } from 'react-router-dom'
import CreateAccount from './CreateAccount/CreateAccount.tsx'
import VerifyCode from './VerifyCode/VerifyCode.tsx'
import MerchantName from './MerchantName/MerchantName.tsx'
import BrandDescription from './BrandDescription/BrandDescription.tsx'
import BrandLogo from './BrandLogo/BrandLogo.tsx'
import BrandHeroImage from './BrandHeroImage/BrandHeroImage.tsx'
import Affinities from './Affinities/Affinities.tsx'
import Commission from './Commission/Commission.tsx'

const BrandSteps: FC = () => {
  const { page = 'create-account' } = useParams()

  switch (page) {
    case 'create-account':
      return <CreateAccount />
    case 'verify-code':
      return <VerifyCode />
    case 'name':
      return <MerchantName />
    case 'description':
      return <BrandDescription />
    case 'brand-logo':
      return <BrandLogo />
    case 'brand-hero-image':
      return <BrandHeroImage />
    case 'affinities':
      return <Affinities />
    case 'commission':
      return <Commission />
    default:
      return null
  }
}

export default BrandSteps
