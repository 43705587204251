import { FC, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/system/Box'
import Container from '@mui/system/Container'
import TextArea from '@/components/Form/Fields/TextArea/TextArea'
import { FormProvider, useForm } from 'react-hook-form'
import TopBar from '@/shared/components/TopBar/TopBar.tsx'
import BackButton from '@/shared/components/BackButton/BackButton.tsx'
import StepHeader from '../../StepHeader/StepHeader.tsx'
import formStyles from '@/components/Form/styles.module.scss'
import styles from '../../styles.module.scss'
import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext.tsx'
import { OnBaordingProps } from '@/components/OnboardingComp/types.ts'
import StepCounter from '@/components/OnboardingCompNew/StepCounter.tsx'
import ProgressBar from '../../ProgressBar.tsx'
import FinalButton from '@/videoshops-shared/components/FinalButton/FinalButton.tsx'
import { OnboardingStep } from '@/constants/onboarding.constant.ts'
import useOnboarding from '../../useOnboarding.ts'

const MarketPlaceDescription: FC = () => {
  const navigate = useNavigate()
  const { saveOnboardingData } = useOnboarding()

  const { formData } = useContext(OnboardingContext) as OnBaordingProps
  const form = useForm({
    mode: 'onChange',
    defaultValues: { description: '' }
  })
  const description =
    formData.marketplace.description || 'I’m excited for you to shop all my favorite products.'

  const onSubmit = async () => {
    const saved = await saveOnboardingData(
      OnboardingStep.SHOP_DESCRIPTION,
      localStorage.getItem('TOKEN') || ''
    )
    if (saved) {
      navigate('/creator/setup/profile-image')
    }
  }

  useEffect(() => {
    form.setValue('description', description)
  })

  return (
    <div className={styles.stepPage}>
      <TopBar />
      <ProgressBar value={(100 / 8) * 5} />
      <Box mt={{ xs: 1.5, md: 3.5 }}>
        <Container maxWidth="lg">
          <Box height={36} display="flex" alignItems="center" justifyContent="space-between">
            <BackButton to="/creator/setup/link" />
            <StepCounter step={5} total={8} />
          </Box>
        </Container>
        <Container maxWidth={false} sx={{ maxWidth: 459 }}>
          <Box mt={2}>
            <StepHeader>Describe Your Shop</StepHeader>
          </Box>
          <Box mt="21px">
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className={formStyles.formContainer}>
                <TextArea label="Shop Description" name="description" placeholder={description} />
                <Box mt={3}>
                  <FinalButton type="submit" variant="primary" full>
                    Next
                  </FinalButton>
                </Box>
              </form>
            </FormProvider>
          </Box>
        </Container>
      </Box>
    </div>
  )
}

export default MarketPlaceDescription
