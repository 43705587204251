import { useState, useEffect, useMemo } from 'react'

export function useInFocus(
  ref: React.RefObject<HTMLElement>,
  rootMargin: string = '0px',
  thresholds: number[] = [0.1, 0.9]
) {
  const [inFocus, setInFocus] = useState(false)
  const observer = useMemo(
    () =>
      new IntersectionObserver(
        (entries) => {
          for (const entry of entries) {
            if (
              entry.isIntersecting &&
              entry.intersectionRatio >= thresholds[1]
            ) {
              setInFocus(true)
            } else if (
              !entry.isIntersecting ||
              (entry.isIntersecting && entry.intersectionRatio < thresholds[0])
            ) {
              setInFocus(false)
            }
          }
        },
        {
          rootMargin,
          threshold: thresholds
        }
      ),
    []
  )

  useEffect(() => {
    if (!ref?.current) return
    observer.observe(ref?.current)

    return () => {
      observer.disconnect()
    }
  }, [ref, observer])

  return inFocus
}
