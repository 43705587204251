import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { useInFocus } from '@/hooks/useInFocus'
import { CardData } from '@/constants/cardsData'

const Card: React.FC<CardData> = ({
  label,
  icon,
  descriptionLineOne,
  descriptionLineTwo,
  iconActive
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const inFocus = useInFocus(ref, '30% 0% 0px 30%', [0.1, 0.9])
  const [isHovered, setIsHovered] = useState(false)
  const isMobile = window.innerWidth <= 768

  return (
    <div
      className={classNames(styles.cardContainer, styles.greyBackground)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      ref={ref}
    >
      <div className={classNames(styles.label)}>
        <div className={styles.cardFront}>
          <div className={styles.iconContainer}>
            <div
              className={classNames(styles.icon, {
                [styles.hide]: (!isMobile && isHovered) || (isMobile && inFocus)
              })}
            >
              {icon}
            </div>
            <div
              className={classNames(styles.iconActive, {
                [styles.hide]:
                  (!isMobile && !isHovered) || (isMobile && !inFocus)
              })}
            >
              {iconActive}
            </div>
          </div>
          <label className={styles.label}>{label}</label>
          <p>
            {descriptionLineOne}{' '}
            {descriptionLineTwo && (
              <>
                <br />
                {descriptionLineTwo}
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Card
