import useGoogleAnalytics from "@/videoshops-shared/hooks/ga.hooks";
import { FC } from "react";

interface SiteWrapperProps {
  children: React.ReactNode;
}

// Created this to wrap the entire app so we could use a hook on all pages
// Could be context, felt like it would be overkill
const SiteWrapper: FC<SiteWrapperProps> = ({ children }) => {
  useGoogleAnalytics();
  return children;
};

export default SiteWrapper;
