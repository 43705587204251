import { FC } from 'react'
import styles from '../../styles.module.scss'
import TextArea from '@/components/Form/Fields/TextArea/TextArea'
import { useOnboardingContext } from '@/hooks/useOnboardingForm'
import { OnboardingDescription } from '@/constants/onboarting-copy'

const MarketPlaceDescription: FC = () => {
  const { onBoardingTitleBranding, siteType } = useOnboardingContext()
  const title = siteType === 'creator' ? onBoardingTitleBranding : 'Brand'
  const description =
    siteType === 'vendor'
      ? OnboardingDescription.BRAND
      : OnboardingDescription.SELLER
  return (
    <div className={styles.stepContainer}>
      <h1>
        Describe <span className="vs-primary-color">Your {title}</span>
      </h1>
      <TextArea
        colW={12}
        label="Description"
        name="description"
        placeholder={description}
      />
    </div>
  )
}

export default MarketPlaceDescription
