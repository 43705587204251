import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'

const width = 550
const height = width * 1.32

const VITE_VIDEO_URL = import.meta.env.VITE_VIDEO_URL
const videoSrc = `${VITE_VIDEO_URL}seller-background.mp4`

const VideoBackground = () => {
  const [numVideos, setNumVideos] = useState(0)

  useEffect(() => {
    const calculateNumVideos = () => {
      const containerWidth = window.innerWidth + width
      const rows = Math.ceil(window.innerHeight / height)
      const calculatedNumVideos = Math.ceil(containerWidth / width)
      setNumVideos(calculatedNumVideos * rows)
    }

    calculateNumVideos()

    const handleResize = () => {
      calculateNumVideos()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const containerRef = React.useRef(null)

  return (
    <Box
      ref={containerRef}
      position="absolute"
      top={0}
      left={0}
      height="100vh"
      width="100vw"
      overflow="hidden"
      bgcolor="#000"
      zIndex={-1}
    >
      <Box display="flex" flexWrap="wrap" justifyContent="center" mx={`-${width * 1.1}px`}>
        {Array.from({ length: numVideos }).map((_, index) => (
          <Box
            component="video"
            key={index}
            width={width}
            height={height}
            playsInline
            autoPlay
            muted
            loop
            sx={{ objectFit: 'cover' }}
            className="background-video"
            my="-6px"
            mx="-6px"
            overflow="hidden"
          >
            <source src={videoSrc} type="video/mp4" />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default VideoBackground
