import { useStaticPageContext } from '@/context/StaticPageContext'
import { SiteWideTypes } from '@/types/SiteContext.interface'
import { PhoneCollageType } from '../types'
import styles from './styles.module.scss'
import classNames from 'classnames'

const VITE_VIDEO_URL = import.meta.env.VITE_VIDEO_URL

const Phone: React.FC<PhoneCollageType> = ({ name, videoTitle }) => {
  const { siteType } = useStaticPageContext() as SiteWideTypes
  const missingAssetsArray: string[] = ['amelia']
  const phoneStyles = classNames(styles.phoneContainer, {
    [styles.phoneContainerBrand]: siteType === 'brand'
  })
  // console.log(
  //   `${VITE_VIDEO_URL}${name}.mp4`,
  //   !missingAssetsArray.includes(name)
  // )

  return (
    <div className={phoneStyles}>
      {/* <div className={styles.videoContainer}>
        <div className={styles.blackOverlay}></div>

        <span>
          <p>{videoTitle}</p>
          <img src="/icons/share-outline-no-bg.svg" height="20px" />
        </span>
        {!missingAssetsArray.includes(name) && (
          <video
            autoPlay
            playsInline
            muted
            loop
            className={styles.videoOverlay}
            key={name}
          >
            <source src={`${VITE_VIDEO_URL}${name}.mp4`} type="video/mp4" />
          </video>
        )}
      </div> */}
      <div style={{ height: '400px', width: '200px' }}>
        {name && (
          <img
            src={`/images/hero-devices/people/${name}.png`}
            width="100%"
            height="100%"
          />
        )}
      </div>
    </div>
  )
}

export default Phone
