import styles from './styles.module.scss'
import NewTitle from '@/components/Title/Title'
import { ReactComponent as MobileBack } from '@/assets/home/mobile-back.svg'
import { ReactComponent as BreadCrumbArrow } from '@/assets/home/breadcrumb-arrow.svg'
import Footer from '@/components/Footer/Footer'
import TopBar from '@/components/SubPagesComp/TopBar/TopBar'
import classNames from 'classnames'

function ReturnsPage() {
  const isMobile = window.innerWidth < 768
  const title = (
    <div className="breakTitle">
      <span data-line="VideoShops">
        <span className="vs-primary-color">Returns</span>
      </span>
    </div>
  )
  return (
    <>
      <TopBar />
      <div className={styles.subPageWrapper}>
        <div className={styles.aboutUsTopWrapper}>
          {isMobile ? (
            <a href="/">
              {' '}
              <MobileBack />
            </a>
          ) : (
            <div className={styles.breadcrumbWrapper}>
              <a href="/">Home</a> <BreadCrumbArrow /> Returns
            </div>
          )}
        </div>
        <NewTitle children={title} titleColor="#000" />
        <div
          className={classNames(styles.subPageContent, styles.fullWidthContent)}
        >
          <h6>
            <u>What is VideoShops Returns Policy?</u>{' '}
          </h6>
          <p>VideoShops has a 15 day return policy. </p>{' '}
          <p>
            In the event that a customer initiates a return on an order during
            the 15 day period, VideoShops will return the Platform Fee (except
            as otherwise specifically provided for below) and the Sales
            Commission to you in accordance with Stripe Connects Policies.You
            are responsible to return the entire amount back to the customer
            once the product is returned.
          </p>
        </div>
        <Footer />
      </div>
    </>
  )
}
export default ReturnsPage
