import SvgIcon from '@mui/material/SvgIcon'
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon'

export default function ArrowCircleRight(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 41 41" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3543 40.7438C31.3854 40.7438 40.3278 31.8014 40.3278 20.7703C40.3278 9.7393 31.3854 0.796875 20.3543 0.796875C9.32328 0.796875 0.380859 9.7393 0.380859 20.7703C0.380859 31.8014 9.32328 40.7438 20.3543 40.7438ZM31.9131 20.7295C31.9131 22.0056 30.6165 23.8542 28.4311 24.8625L15.5983 31.4184C13.7946 32.3398 11.6544 31.0298 11.6544 29.0045L11.6544 27.04C11.6544 26.0432 12.2014 25.1269 13.0788 24.6539L20.3474 20.7358L13.2025 17.3441C12.2569 16.8952 11.6543 15.942 11.6543 14.8953L11.6543 12.4299C11.6543 10.4135 13.7775 9.10295 15.58 10.0067L28.7476 16.6091C30.9695 17.5771 31.9131 19.4535 31.9131 20.7295Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
