import ReactDOM from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from '@/pages/Home'
import { initAxiosConfiguration } from '@/utils/axios'
import CreatorSetupPage from './pages/Onboarding/CreatorSetupPage/CreatorSetupPage'
import CreatorSetupPageNew from './pages/Onboarding/CreatorSetupPageNew/CreatorSetupPage'
import BrandSetupPage from './pages/Onboarding/BrandSetupPage/BrandSetupPage'
import VendorSetupPage from './pages/Onboarding/VendorSetupPage/VendorSetupPage'
import AboutUsPage from './pages/subPages/aboutUs'
import { APP_ROUTES } from './routes/routes'
import ContactUsPage from './pages/subPages/contactUs'
import PrivacyPolicyPage from './pages/subPages/privacyPolicy'
import TermsOfUsePage from './pages/subPages/termsOfUse'
import AffiliateDisclosures from './pages/subPages/affiliateDisclosures'
import ReturnsPage from './pages/subPages/returns'
import ShippingPage from './pages/subPages/shipping'
import BrandAgreement from './pages/subPages/brandAgreement'
import SellerAgreemnet from './pages/subPages/sellerAgreement'
import ChoosePath from './pages/subPages/selectAccount'
import theme from './shared/theme/theme.tsx'
import MerchantAgreement from "@/pages/subPages/merchantAgreement.tsx";
import CookiePolicyPage from "@/pages/subPages/cookiePolicy.tsx";
import SiteWrapper from './videoshops-shared/components/SiteWrapper/SiteWrapper.tsx'

initAxiosConfiguration()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ThemeProvider theme={theme}>
    <SiteWrapper>
      <Router>
        <>
          <Routes>
            {/* <Route path="/" element={<LandingPage />} /> */}
            <Route path={APP_ROUTES.HOME} element={<Home />} />
            <Route path={APP_ROUTES.ABOUT} element={<AboutUsPage />} />
            <Route path={APP_ROUTES.CONTACT} element={<ContactUsPage />} />
            <Route
              path={APP_ROUTES.PRIVACY_POLICY}
              element={<PrivacyPolicyPage />}
            />
            <Route path={APP_ROUTES.TERMS_OF_USE} element={<TermsOfUsePage />} />
            <Route
              path={APP_ROUTES.AFFILIATE_DISCLOSURE}
              element={<AffiliateDisclosures />}
            />
            <Route path={APP_ROUTES.RETURNS} element={<ReturnsPage />} />
            <Route path={APP_ROUTES.SHIPPING} element={<ShippingPage />} />{' '}
            <Route
              path={APP_ROUTES.BRAND_MARKETPLACE_AGREEMENT}
              element={<BrandAgreement />}
            />{' '}
            <Route
              path={APP_ROUTES.SELLER_AGREEMENT}
              element={<SellerAgreemnet />}
            />
            <Route
              path={APP_ROUTES.MERCHANT_AGREEMENT}
              element={<MerchantAgreement />}
            />
            <Route
              path={APP_ROUTES.COOKIE_POLICY}
              element={<CookiePolicyPage />}
            />
            <Route path={APP_ROUTES.SELECT_ACCOUNT} element={<ChoosePath />} />
            <Route
              path={APP_ROUTES.CREATOR_SETUP_OLD}
              element={<CreatorSetupPage />}
            />
            <Route
              path={APP_ROUTES.CREATOR_SETUP_NEW}
              element={<CreatorSetupPageNew />}
            />
            <Route
              path={APP_ROUTES.BRAND_SETUP_OLD}
              element={<VendorSetupPage />}
            />
            <Route
              path={APP_ROUTES.BRAND_SETUP_NEW}
              element={<BrandSetupPage />}
            />
          </Routes>
        </>
      </Router>
    </SiteWrapper>
  </ThemeProvider>
)
