export const peopleUl = [
  'Daily Sales',
  'VideoShop Views',
  'Best Shoppers',
  'Highest Performing Shelves',
  'Social Traffic By Platform',
  'And Much More...'
]

export const vendorUl = [
  'Daily Sales',
  'VideoShop Views',
  'Best Shoppers',
  'Highest Performing Shelves',
  'Social Traffic By Platform',
  'And Much More...'
]
