import { FC } from 'react'
import styles from './styles.module.scss'
import { footerLinks } from '@/constants/footer'
import { FooterLinkType, FooterLinksType } from './types'

const FooterLinks: FC = () => {
  const renderLink = (item: FooterLinksType, index: number) => {
    return (
      <li key={index} className="margin-bottom-l margin-bottom-s-mobile">
        <a href={item.url} target="_blank" className="button-label-medium link">
          {item.name}
        </a>
      </li>
    )
  }

  const renderSection = (section: FooterLinkType, index: number) => {
    return (
      <div className={styles.footerLinkSection} key={index}>
        <h4 className="section-header margin-bottom-xl margin-bottom-m-mobile">
          {section.title}
        </h4>
        <ul>{section.links.map(renderLink)}</ul>
      </div>
    )
  }

  return (
    <div className={styles.footerLinksWrapper}>
      <div className={styles.footerLinks}>{footerLinks.map(renderSection)}</div>
    </div>
  )
}

export default FooterLinks
