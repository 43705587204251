import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import PasswordProtectHOC from '../HOC/PasswordProtectHOC'
import SellerSteps from '@/components/OnboardingCompNew/SellerSteps/SellerSteps.tsx'
import StepPage from './StepPage.tsx'
import Footer from '@/components/Footer/Footer.tsx'
import { StyledOnboardingContent } from './OnboardingGlobal.styled.ts'

const CreatorSetupComp: FC = () => {
  const { page } = useParams()
  const displayFooter = ![undefined, 'welcome'].includes(page)

  return (
    <>
      <ToastContainer
        theme="light"
        autoClose={2000}
        newestOnTop={false}
        hideProgressBar={true}
        limit={2}
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
      />
      <StepPage>
        <SellerSteps />
        {displayFooter && (
          <StyledOnboardingContent>
            <Footer />
          </StyledOnboardingContent>
        )}
      </StepPage>
    </>
  )
}

export default CreatorSetupComp
