import classNames from 'classnames'
import { SectionProps } from './SectionTypes'
import styles from './styles.module.scss'

const Overlay: React.FC = () => {
  return <div className={styles.overlay}></div>
}

const Section: React.FC<SectionProps> = ({
  children,
  bgImage,
  overlay,
  bgVideo,
  topTriangle,
  bottomTriangle,
  bgColor,
  zIndex,
  className,
  bottomTriangleLargeSection
}) => {
  const sectionClasses = classNames({
    [styles.sectionContainer]: true,
    [styles.bottomTriangle]: bottomTriangle,
    [styles.topTriangle]: topTriangle,
    [styles.bottomTriangleLargeSection]: bottomTriangleLargeSection
  })
  const sectionStyle = {
    backgroundColor: bgColor || 'transparent',
    zIndex: zIndex || 0
  }
  return (
    <div
      className={classNames(sectionClasses, className && className)}
      style={sectionStyle}
    >
      {overlay && <Overlay />}
      {bgVideo && (
        <video
          controls={false}
          ref={bgVideo.videoRef}
          muted
          autoPlay
          loop
          playsInline
        >
          <source src={bgVideo.videoUrl} type="video/mp4" />
        </video>
      )}
      {children}
    </div>
  )
}
export default Section
