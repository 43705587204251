import classNames from 'classnames'
import { useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styles from '../styles.module.scss'
import { ReactComponent as HideIcon } from '@/assets/icons/hide.svg'
import { ReactComponent as ShowIcon } from '@/assets/icons/open.svg'

export default function ContactInput({
  type,
  label,
  colW,
  name,
  placeholder,
  className,
  required,
  helpText
}: FormElementProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const { trigger, formState, setValue, getValues, register } = useFormContext()
  const { errors } = formState
  const error = errors[name]
  const fieldError = error as FieldErrorProps
  const activeValue = getValues(name)
  const [focused, setFocused] = useState(activeValue ? true : false)
  const [isPasswordShown, setPasswordShown] = useState(false)

  const onClick = () => {
    setFocused(true)
    inputRef.current?.focus()
  }

  const handleBlur = () => {
    if (inputRef.current?.value) {
      return
    }
    setFocused(false)
  }

  const togglePasswordVisibility = () => {
    setPasswordShown(!isPasswordShown)
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.currentTarget.value
    setValue(name, currentValue === '' ? null : currentValue)
    trigger(name)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  return (
    <>
      <div
        className={classNames(colW && `col-${colW}`, styles.formGroup, className)}
        onClick={onClick}
      >
        <label className={focused ? styles.labelFocused : ''}>
          {label}
          {required && <span className="required-asterix">*</span>}
        </label>
        <div className={styles.inputWrapper}>
          <input
            {...register(name)}
            ref={inputRef}
            name={name}
            type={
              type === 'password' || type === 'confirmPassword'
                ? isPasswordShown
                  ? 'text'
                  : 'password'
                : type
            }
            defaultValue={activeValue}
            onKeyDown={handleKeyPress}
            onChange={onChange}
            onFocus={() => setFocused(true)}
            onBlur={handleBlur}
            placeholder={placeholder}
          />
          {(name === 'password' || name === 'confirmPassword') && (
            <span onClick={togglePasswordVisibility}>
              {isPasswordShown ? <HideIcon /> : <ShowIcon />}
            </span>
          )}
        </div>
        <div className={styles.fieldErrors}>
          {helpText && <p className={styles.helpText}>{helpText}</p>}
          {fieldError?.message && <p>{fieldError.message}</p>}
        </div>
      </div>
    </>
  )
}
