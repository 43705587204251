import { StyledText } from './Text.styled'

type Alignment = 'center' | 'left' | 'right'

export interface TextProps {
  text?: string
  size?: string
  color?: string
  weight?: number
  alignment?: Alignment
  className?: string
}

const Text = ({ text, size, color, weight, alignment, className }: TextProps) => {
  return (
    <StyledText
      className={className}
      size={size}
      color={color}
      weight={weight}
      alignment={alignment}
    >
      {text}
    </StyledText>
  )
}

export default Text
