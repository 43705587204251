import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { ButtonProps } from './types'

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  children,
  className,
  disabled,
  onClick,
  fullWidth,
  greyOutline,
  white,
  primary,
  secondary,
  transparent,
  darkHover,
  medium,
  fixed,
  blackText,
  primaryDefault
}) => {
  const styleClass = classNames(className, {
    [styles.default]: true,
    [styles.fullWidth]: fullWidth,
    [styles.greyOutline]: greyOutline,
    [styles.white]: white,
    [styles.transparent]: transparent,
    [styles.primary]: primary,
    [styles.secondary]: secondary,
    [styles.darkHover]: darkHover,
    [styles.medium]: medium,
    [styles.blackText]: blackText,
    [styles.fixed]: fixed,
    [styles.disabled]: disabled,
    [styles.primaryDefault]: primaryDefault
  })
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) onClick(e)
  }

  const renderBtn = () => {
    return (
      <button
        type={type}
        className={styleClass}
        onClick={handleClick}
        disabled={disabled}
      >
        {children}
      </button>
    )
  }

  return renderBtn()
}

export default Button
