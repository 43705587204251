export const APP_ROUTES = {
  FORWARD_SLASH: '/',

  // Current Rouetes HomeApp Is using to my knowledge
  HOME: '/',
  CONTACT: '/contact-us',
  ABOUT: '/about-us',
  AFFILIATE_DISCLOSURE: '/affiliate-disclosure',
  TERMS_OF_USE: '/terms-of-use',
  PRIVACY_POLICY: '/privacy-policy',
  RETURNS: '/returns',
  SHIPPING: '/shipping',
  SELLER: '/SELLER',
  SELECT_ACCOUNT: '/select-account',
  CREATOR_SETUP: '/creator/setup',
  CREATOR_SETUP_OLD: '/creator/setup/old',
  CREATOR_SETUP_NEW: '/creator/setup/:page?',
  BRAND_SETUP: '/brand/setup',
  BRAND_SETUP_OLD: '/brand/setup/old',
  BRAND_SETUP_NEW: '/brand/setup/:page?',
  BRAND_MARKETPLACE_AGREEMENT: '/brand-marketplace-agreement',
  SELLER_AGREEMENT: '/seller-agreement',
  MERCHANT_AGREEMENT: '/merchant-agreement',
  COOKIE_POLICY: '/cookie-policy',
  //

  SIGN_UP_CREATOR: '/signup/creator',
  SIGN_UP_COSTUMER: '/signup/customer',
  SIGN_UP_BRAND: '/signup/brand',
  SIGN_UP_VERIFY: '/signup/verify',
  SIGN_UP_COMPLETE: '/signup/complete',

  ONBOARDING_SETUP: '/onboarding/setup',

  SIGN_IN: '/signin',
  SIGN_IN_FORGOT: '/signin/forgot',
  SIGN_IN_RETURN_URL_ORDER_HISTORY: '/signin?returnUrl=/orderHistory',

  ORDER_INFORMATION: '/orderinformation',
  CHECKOUT: '/checkout',
  CART: '/cart',
  FAVORITES: '/favorites',
  ACCOUNT: '/account',
  PROFILE: '/profile',
  PASSWORD: '/password',
  BANKING: '/banking',
  ADDRESSES: '/addresses',
  CARDS: '/cards',
  PURCHASES: '/purchases',
  CONTINUE: '/continue',
  SOCIAL: '/social',
  USER_ADMINISTRATION: '/administration',

  BRAND_DIRECTORY: '/brands',
  MARKETPLACE: '/marketplace',

  DASHBOARD: '/dashboard',
  DASHBOARD_MARKETPLACE: '/dashboard/marketplace',
  DASHBOARD_PRODUCTS: '/dashboard/products',
  DASHBOARD_ORDERS: '/dashboard/orders',
  DASHBOARD_PAYOUTS: '/dashboard/payouts',

  BRAND_PROGRAM_POLICIES: '/brand-program-policies',
  COPYRIGHT_POLICY: '/copyright-policy',
  SALES_COMMISSIONS: '/sales-commissions',
  CREATOR_PROGRAM_POLICIES: '/creator-program-policies',
  AGREEMENT_MARKETPLACE: '/agreement/marketplace',
  DEV_SHOWCASE: '/dev/showcase',
  NOT_FOUND: '/404'
}
