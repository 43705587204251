import { FC } from 'react'
import Box from '@mui/system/Box'
import ButtonBase from '@mui/material/ButtonBase'
import {getBackgroundIcon} from '@/shared/utils/image/get-background.ts'

interface ColorsProps {
  value: string
  onChange: (value: string) => void
  list: string[]
}

const Colors: FC<ColorsProps> = ({ value, onChange, list }) => {
  return (
    <Box display="flex" flexWrap="wrap" m="-2.5px">
      {list.map((color, index) => (
        <ButtonBase
          key={index}
          sx={{ m: '2.5px', borderRadius: '50%' }}
          onClick={() => onChange(color)}
        >
          <Box
            border={color === value ? '2px solid #000' : undefined}
            borderRadius="50%"
            width="54px"
            height="54px"
            sx={getBackgroundIcon(color)}
          />
        </ButtonBase>
      ))}
    </Box>
  )
}

export default Colors
