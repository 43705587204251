import { appConfig } from '@/app-config'
import ReactGA from 'react-ga4'
import events from '../constants/ga-events.constant'

export interface GAEvent {
  category: string
  action: string
}

const VITE_ENV = import.meta.env.VITE_STAGE
const isProduction = VITE_ENV === 'videoshops-prod'

const options = {
  testMode: !isProduction,
}

const gaActions = {
  initialize: () => {
    ReactGA.initialize(appConfig.GA_MEASUREMENT_ID, options)
  },
  event: (event: GAEvent) => {
    ReactGA.event(event)
  },
  view: (page: string) => {
    ReactGA.send({ hitType: 'pageview', page })
  },
}

console.log('<=============== GA initialized ===============>')
console.log('VITE_ENV', VITE_ENV)
console.log('isProduction', isProduction)
console.log('appConfig.GA_MEASUREMENT_ID', appConfig.GA_MEASUREMENT_ID)
console.log('<=============== GA initialized ===============>')

// Export them both from one place
export const gaEvents = events

export default gaActions
