import { FC } from 'react'
import { ReactComponent as LogoIcon } from '@/assets/logo.svg'
import styles from './styles.module.scss'
import FooterLinks from './FooterLinks/FooterLinks'

const Footer: FC = () => {
  return (
    <footer className={styles.footerWrapper}>
      <div className={styles.footerContainer}>
        <div>
          <div className={styles.logoContainer}>
            <LogoIcon />
          </div>
          <div className={styles.contentContainer}>
            {/*<div className={styles.logoSubTextWrapper}>
              <label className={styles.logoSubText}>Claim Your Name</label>
              <span className={styles.subscribeInputWrapper}>
                <MarketPlaceInput />
              </span>
            </div>*/}
            <FooterLinks />
          </div>
        </div>
        <div>
          <hr />
          <p className={styles.copyright}>
            © 2024 NOW with Ventures Inc. All Rights Reserved.
          </p>
        </div>
        {/*<div className={styles.bottomSection}>
          <div className={styles.footerIcons}>
            <a target="_blank" href={twitterLink}>
              <TwitterIcon />
            </a>
            <a target="_blank" href={instagramLink}>
              <InstagramIcon />
            </a>
          </div>
        </div>*/}
      </div>
    </footer>
  )
}

export default Footer
