import styles from './styles.module.scss'
import NewTitle from '@/components/Title/Title'
import { ReactComponent as MobileBack } from '@/assets/home/mobile-back.svg'
import { ReactComponent as BreadCrumbArrow } from '@/assets/home/breadcrumb-arrow.svg'
import Footer from '@/components/Footer/Footer'
import TopBar from '@/components/SubPagesComp/TopBar/TopBar'
import classNames from 'classnames'

function ShippingPage() {
  const isMobile = window.innerWidth < 768
  const title = (
    <div className="breakTitle">
      <span data-line="VideoShops">
        <span className="vs-primary-color">Shipping</span>
      </span>
    </div>
  )
  return (
    <>
      <TopBar />
      <div className={styles.subPageWrapper}>
        <div className={styles.aboutUsTopWrapper}>
          {isMobile ? (
            <a href="/">
              {' '}
              <MobileBack />
            </a>
          ) : (
            <div className={styles.breadcrumbWrapper}>
              <a href="/">Home</a> <BreadCrumbArrow /> Shipping
            </div>
          )}
        </div>
        <NewTitle children={title} titleColor="#000" />
        <div
          className={classNames(styles.subPageContent, styles.fullWidthContent)}
        >
          <h6>Placeholder Copy</h6>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis hic
            velit debitis veritatis, voluptatem, porro vel quae esse id a
            consectetur commodi accusantium perspiciatis alias quas sint
            expedita consequuntur labore?
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis hic
            velit debitis veritatis, voluptatem, porro vel quae esse id a
            consectetur commodi accusantium perspiciatis alias quas sint
            expedita consequuntur labore?
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis hic
            velit debitis veritatis, voluptatem, porro vel quae esse id a
            consectetur commodi accusantium perspiciatis alias quas sint
            expedita consequuntur labore?
          </p>
        </div>
        <Footer />
      </div>
    </>
  )
}
export default ShippingPage
