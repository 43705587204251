import styles from '../../../styles.module.scss'
import { FC } from 'react'
import InputField from '@/components/Form/Fields/InputField/InputField'
import PasswordRules from '@/components/Form/PasswordRules/PasswordRules'
import { useFormContext } from 'react-hook-form'
import classNames from 'classnames'
import SelectComp from '@/components/Form/Fields/Select/Select'
import { COUNTRY_PHONE_CODES } from '@/constants/countryCodes'

const SignUpForm: FC = () => {
  const { getValues } = useFormContext()

  return (
    <div className={styles.signUpForm}>
      <div className={styles.formSectionWrapper}>
        <div className={styles.formHeadline}>Account Manager</div>
        <div className={classNames(styles.signUpFormRow, styles.noTopMargin)}>
          <InputField
            colW={6}
            label="First Name"
            name="firstName"
            type="text"
            required
          />
          <InputField
            colW={6}
            label="Last Name"
            name="lastName"
            type="text"
            required
          />
        </div>
        <div className={styles.signUpFormRow}>
          <InputField
            colW={12}
            label="Email Address"
            name="email"
            type="text"
            required
          />
        </div>
        <div className={styles.signUpFormRow}>
          <SelectComp
            colW={2}
            name="countryCode"
            optionData={COUNTRY_PHONE_CODES}
            required
          />
          <InputField
            colW={11}
            label="Mobile Number"
            name="phone"
            type="text"
            required
          />
        </div>
        <div className={styles.signUpFormRow}>
          <InputField
            colW={12}
            label="Job Title"
            name="jobTitle"
            type="text"
            required
          />
        </div>
      </div>
      <div className={styles.formSectionWrapper}>
        <div className={styles.formHeadline}>Your Brand Profile</div>

        <div className={classNames(styles.signUpFormRow, styles.noTopMargin)}>
          <InputField
            colW={12}
            label="Brand Name"
            name="brandName"
            type="text"
            required
          />
        </div>
        <div className={styles.signUpFormRow}>
          <InputField
            colW={12}
            label="Brand Website"
            name="brandWebsite"
            type="text"
            required
          />
        </div>
      </div>
      <div className={classNames(styles.signUpFormRow, styles.noTopMargin)}>
        <InputField
          colW={12}
          label="Create Password"
          name="password"
          type="password"
          required
        />
      </div>
      {getValues('password') && (
        <div className={styles.signUpFormRow}>
          <div className={styles.passwordRulesWrapper}>
            <PasswordRules
              value={getValues('password')}
              open={getValues('password')}
            />
          </div>
        </div>
      )}
      <div className={styles.signUpFormRow}>
        <InputField
          colW={12}
          label="Confirm The Password"
          name="confirmPassword"
          type="password"
          required
        />
      </div>
      <span className={styles.requiredWrapper}>
        <span className="required-asterix">*</span>Required
      </span>
    </div>
  )
}

export default SignUpForm
