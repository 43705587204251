import { FC, useState } from 'react'
import Button from '../Button/Button'
import ContactInput from '../SubPagesComp/FormFields/TextInput'
import styles from './styles.module.scss'
import { FormProvider, useForm } from 'react-hook-form'
import { ToastContainer, toast } from 'react-toastify'
import { ReactComponent as MobileBack } from '@/assets/home/mobile-back.svg'
import { ReactComponent as BreadCrumbArrow } from '@/assets/home/breadcrumb-arrow.svg'
import CustomToast from '../Toaster/Toaster'
import Newsletter from '../NewsLetter/NewsLetter'

const VITE_SECRET_PASSWORD = import.meta.env.VITE_SECRET_PASSWORD

const PasswordProtectHOC = (WrappedComponent: FC<any>) => {
  const ProtectedWrapper: FC = (props: any) => {
    const storedPassword = localStorage.getItem('protectedPagePassword')
    const [password, setPassword] = useState<string | null>(storedPassword)
    const form = useForm()
    const isMobile = window.innerWidth < 768

    const { handleSubmit, getValues } = form

    const handlePasswordSubmit = () => {
      if (
        getValues('password') &&
        getValues('password')?.toLowerCase() === VITE_SECRET_PASSWORD?.toLowerCase()
      ) {
        const inputValue = getValues('password')
        toast(<CustomToast type="success" text="Success! You will be redirected shortly." />)
        localStorage.setItem('protectedPagePassword', inputValue)
        setPassword(inputValue)
      } else {
        toast(<CustomToast type="error" text="Wrong Access Code" />)
      }
    }

    if (password?.toLowerCase() === VITE_SECRET_PASSWORD?.toLowerCase()) {
      return <WrappedComponent {...props} />
    }

    return (
      <>
        <div className={styles.topWrapper}>
          {isMobile ? (
            <a href="/">
              {' '}
              <MobileBack />
            </a>
          ) : (
            <div className={styles.breadcrumbWrapper}>
              <a href="/">Home</a> <BreadCrumbArrow /> Access Code
            </div>
          )}
        </div>
        <div className={styles.passwordWrapper}>
          <ToastContainer
            theme="light"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            limit={2}
            closeOnClick
            pauseOnHover
            pauseOnFocusLoss
          />
          <h1>
            Enter Your <br /> <span className="vs-primary-color">Access Code</span>
            {/* Verify Your Number */}
          </h1>
          <FormProvider {...form}>
            <form className={styles.formContainer}>
              <div className={styles.formRow}>
                <ContactInput
                  colW={12}
                  type="password"
                  label="Access Code"
                  name="password"
                  required
                />
                <Button onClick={handleSubmit(handlePasswordSubmit)} primary>
                  Continue
                </Button>
              </div>
            </form>
          </FormProvider>
          <div className={styles.hLine}></div>
          <div className={styles.contactEmailWrapper}>
            <span>Don't Have a Code?</span>
            <p className={styles.contactFormP}>
              Sign up to get on our Waiting List and we will send you a code shortly
            </p>
            <Newsletter />
          </div>

          <div className={styles.contact}>
            <span>Need Help?</span>
            <a href="mailto:hello@videoshops.com">hello@videoshops.com</a>
          </div>
        </div>
      </>
    )
  }

  return ProtectedWrapper
}

export default PasswordProtectHOC
