import { FC } from 'react'
import { ToastContainer } from 'react-toastify'
import PasswordProtectHOC from '../HOC/PasswordProtectHOC'
import BrandSteps from './BrandSteps/BrandSteps.tsx'
import StepPage from './StepPage.tsx'
import Footer from '@/components/Footer/Footer.tsx'
import { StyledOnboardingContent } from './OnboardingGlobal.styled'

const BrandSetupComp: FC = () => {
  return (
    <>
      <ToastContainer
        theme="light"
        autoClose={2000}
        newestOnTop={false}
        hideProgressBar={true}
        limit={2}
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
      />
      <StepPage>
        <BrandSteps />
        <StyledOnboardingContent>
          <Footer />
        </StyledOnboardingContent>
      </StepPage>
    </>
  )
}

export default BrandSetupComp
