import { FC, useRef } from 'react'
import styles from '../../styles.module.scss'
import { useOnboardingContext } from '@/hooks/useOnboardingForm'
import classNames from 'classnames'
import { ReactComponent as Mascot } from '@/assets/home/mascot.svg'
import { useFormContext } from 'react-hook-form'
import { postRequest } from '@/api/api'
import { appConfig } from '@/app-config'
import { UploadResponseData } from '@/components/Form/Fields/FileUpload/types'
import CustomToast from '@/components/Toaster/Toaster'
import { updateFormState } from '@/utils/formHelpers'
import { toast } from 'react-toastify'

import useImageEditor from '../../../../videoshops-shared/hooks/ImageEditor/useImageEditorHook'
import { ResObj } from '../../../../videoshops-shared/components/ImageEditor/types'
import ImageEditor from '../../../../videoshops-shared/components/ImageEditor/ImageEditor'
import Modal from '../../../../videoshops-shared/components/Utility/Modal/Modal'

const ProfileImageUpload: FC = () => {
  const { formData, siteType, setFormData } = useOnboardingContext()
  const { marketplace } = formData
  // const previewElipseName =
  //   marketplace && marketplace.name ? marketplace.name[0] : <Mascot />
  const previewElipseName = <Mascot />
  const heading = siteType === 'vendor' ? 'Brand Logo' : 'Profile Image'
  const hasProfileImage = marketplace.profileImage ? true : false
  const profileImage =
    marketplace && hasProfileImage
      ? `${marketplace.profileImage}`
      : '/homeappimages/hero-mobile-placeholder.png'
  const previewImageStyle = hasProfileImage
    ? { backgroundImage: `url(${marketplace.profileImage})` }
    : {}

  const { setImage, image, setOpenModal, openModal } = useImageEditor()
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { setValue } = useFormContext()
  const backgroundImageKey = 'profileImageKey'
  const backgroundImageName = 'profileImage'

  const handleOnClick = () => {
    setOpenModal(true)
    setImage(profileImage)
  }
  const handleSubmitImageEdit = async (resObj: ResObj) => {
    if (!resObj) return
    const toastId = toast(<CustomToast />)
    timeoutRef.current = setTimeout(() => {
      toast.dismiss(toastId)
      toast(
        <CustomToast
          type="error"
          text="Please try again with a smaller file size image."
        />
      )
    }, 15000)
    try {
      const headers = {
        'Content-Type': resObj.imageType,
        ...(siteType === 'vendor'
          ? { Authorization: localStorage.getItem('TOKEN') || '' }
          : {})
      }

      const uploadFileRequest = await postRequest<UploadResponseData>(
        `${appConfig.UPLOAD_BASE_URL}/user/upload`,
        resObj.blob,
        { headers }
      )
      if (
        uploadFileRequest.status === 200 &&
        uploadFileRequest.data &&
        'data' in uploadFileRequest.data
      ) {
        const { key, path } = (uploadFileRequest.data as UploadResponseData)
          .data
        setValue(backgroundImageKey, key)
        setValue(backgroundImageName, path)
        updateFormState(setFormData, backgroundImageName, path)
        updateFormState(setFormData, backgroundImageKey, key)
        toast.dismiss(toastId)
        clearTimeout(timeoutRef.current!)
        toast(
          <CustomToast type="success" text="Image Uploaded Successfully!" />
        )
      } else {
        toast.dismiss(toastId)
        clearTimeout(timeoutRef.current!)
        toast(
          <CustomToast
            type="error"
            text="Image upload failed please try again."
          />
        )
      }
    } catch (error: any) {
      const errorMsg = "Upload failed - " + error?.response?.data.error
      toast.dismiss(toastId)
      clearTimeout(timeoutRef.current!)
      toast(
        <CustomToast
          type="error"
          text={errorMsg}
        />
      )
    }
  }

  return (
    <div className={classNames(styles.stepContainer, 'profile-image-wrapper')}>
      <h1>
        Choose Your <span className="vs-primary-color">{heading}</span>
      </h1>
      {/* <p>{hasProfileImage ? 'Update' : 'Add'} Your Profile Image</p> */}
      <div className={styles.userElipseWrapper}>
        <div
          onClick={handleOnClick}
          className={classNames(
            styles.userElipse,
            hasProfileImage && styles.removeBGColor
          )}
          style={previewImageStyle}
        >
          <div className={styles.userElipseIconWrapper}>
            <img src={`/icons/camera-blue.svg`} />
          </div>
          {!hasProfileImage && previewElipseName}
        </div>
        {image && (
          <Modal
            onClose={() => console.log('dcfd')}
            open={openModal}
            heading="Profile Photo"
          >
            <ImageEditor
              image={image}
              setOpenModal={setOpenModal}
              croppingType="circle"
              submitCrop={handleSubmitImageEdit}
              hasImageAlready={hasProfileImage}
            />
          </Modal>
        )}
      </div>
    </div>
  )
}

export default ProfileImageUpload
