import { FC, ReactNode } from 'react'
import { StyledTypography } from './StepHeader.styled'

interface StepHeaderProps {
  children?: ReactNode
}

const StepHeader: FC<StepHeaderProps> = ({ children }) => {
  return <StyledTypography>{children}</StyledTypography>
}
export default StepHeader
