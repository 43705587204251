import { FC } from 'react'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import { Controller, useFormContext } from 'react-hook-form'
import { APP_ROUTES } from '@/routes/routes.ts'
import styles from '@/components/OnboardingCompNew/SellerSteps/CreateAccount/styles.module.scss'

const messages = {
  brand: 'I agree to VideoShops',
  seller:
    'I certify that I am at least 18 years old and that I agree to VideoShops'
}

interface AgreeCheckboxType {
  type: 'brand' | 'seller'
}

const AgreeCheckbox: FC<AgreeCheckboxType> = ({ type = 'seller' }) => {
  const { control } = useFormContext()

  return (
    <div className={styles.termsWrapper}>
      <Controller
        name="terms"
        control={control}
        render={({ field: props }) => (
          <Checkbox
            disableRipple
            sx={{ p: 0, mr: 1.5 }}
            {...props}
            onChange={(e) => props.onChange(e.target.checked)}
          />
        )}
      />
      <Typography variant="xs_med" component="div">
        {messages[type]}{' '}
        <Link
          to={
            type === 'seller'
              ? APP_ROUTES.TERMS_OF_USE
              : APP_ROUTES.MERCHANT_AGREEMENT
          }
          target="_blank"
        >
          Terms & Conditions
        </Link>{' '}
        and{' '}
        <Link to={APP_ROUTES.PRIVACY_POLICY} target="_blank">
          Privacy Policy
        </Link>
      </Typography>
    </div>
  )
}

export default AgreeCheckbox
