export enum BrandMarketPlaceAffinitiesKeys {
  FEMALE_FOUNDED = 'female_founded',
  AAP_OWNED = 'aapi_owned',
  SUSTAINABLE = 'sustainable',
  LGTBQ_OWNED = 'lgtbq+_owned',
  CHARITABLE = 'charitable',
  BLACK_FOUNDED = 'black_founded',
  LATINX_OWNED = 'latinx_owned',
  CLEAN_BEAUTY = 'clean_beauty',
  VEGAN = 'vegan',
  BEAUTY = 'beauty',
  FASHION = 'fashion',
  LIFESTYLE = 'lifestyle',
  PETS = 'pets',
  HOME = 'home'
}
