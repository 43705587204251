import styled from 'styled-components'

export const StyledTypography = styled.div`
  text-align: center;
  letter-spacing: -0.04em;
  line-height: 2.25rem;
  font-weight: 600;
  font-size: 1.88rem;
  ${({ theme }) => theme.breakpoints.down('tabletPortrait')} {
    font-size: ${({ theme }) => theme.FONT_SIZES.medium};
  }
`
