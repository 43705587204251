import classnames from 'classnames'
import styles from './styles.module.scss'
import { HeroTabsProps, Tab } from './types'

const HeroTabs: React.FC<HeroTabsProps> = ({ tabs, tab, setTab }) => {
  return (
    <div className={styles.tabOuterWrapper}>
      <div className={styles.tabInnerWrapper}>
        {tabs.map((_tab: Tab, index: number) => (
          <div
            key={index}
            className={classnames(
              styles.tab,
              _tab.key === tab ? styles.activeTab : ''
            )}
            onClick={() => setTab(_tab.key)}
          >
            <label>{_tab.name}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default HeroTabs
