import { useState} from 'react'

function useImageEditor() {
    // Hook State
    const [image, setImage] = useState<string | undefined>(undefined)
    const [blobImage, setBlobImage] = useState<Blob | null>(null)
    const [openModal, setOpenModal] = useState<boolean>(false)
   
    // Return combined values and functions from the hook
    return {
        openModal,
        setOpenModal,
        blobImage,
        setBlobImage,
        image,
        setImage,
    }
}


export default useImageEditor


