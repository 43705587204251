import { default as HeroSection } from '@/components/HomePageSections/HomeHero/HomeHero'
import { useEffect, useRef, useState, userSc } from 'react'
import { StaticPageContextProvider } from '@/context/StaticPageContext'
import '../styles/global.scss'
import 'react-toastify/dist/ReactToastify.css'
import HomeTopBar from '@/components/HomePageSections/HomeTopBar/HomeTopBar'
import styles from './styles.module.scss'
import Footer from '@/components/Footer/Footer'
import FAQ from '@/components/HomePageSections/FAQ/FAQ'
import LightSwitch from '@/components/HomePageSections/LightSwitch/LightSwitch'
import HelpVideos from '@/components/HomePageSections/HelpVideos/HelpVideos'
import ThreeCards from '@/components/HomePageSections/ThreeCards/ThreeCards'
import DashboardStats from '@/components/HomePageSections/DashboardStats/DashboardStats'
import SlideInAlert from '@/components/HomePageSections/SlideInAlert/SlideInAlert'
import SearchProducts from '@/components/HomePageSections/SearchProducts/SearchProducts'
import ImageEditor from 'shared/components/ImageEditor/ImageEditor'

const HomePage = () => {
  const [siteType, setSiteType] = useState<string>('people')

  const payload = {
    siteType: siteType,
    setSiteType: setSiteType
  }

  return (
    <StaticPageContextProvider data={payload}>
      <>
        <HomeTopBar />
        <div className={styles.homepageWrapper}>
          <HeroSection />
          <ThreeCards />
          <SearchProducts />
          <SlideInAlert />
          <DashboardStats />
          <FAQ />
          <LightSwitch />
          <Footer />
        </div>
      </>
    </StaticPageContextProvider>
  )
}

export default HomePage
