import { FC, useContext, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { toast } from 'react-toastify'
import Box from '@mui/system/Box'
import TopBar from '@/shared/components/TopBar/TopBar.tsx'
import Container from '@mui/system/Container'
import BackButton from '@/shared/components/BackButton/BackButton.tsx'
import StepHeader from '../../StepHeader/StepHeader.tsx'
import styles from '../../styles.module.scss'
import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext.tsx'
import { OnBaordingProps } from '@/components/OnboardingComp/types.ts'
import { appConfig } from '@/app-config.ts'
import { postRequest, putRequest } from '@/api/api.ts'
import ProfileCard from '@/components/OnboardingCompNew/SellerSteps/ThemeColor/ProfileCard.tsx'
import Colors from './Colors.tsx'
import CustomToast from '@/components/Toaster/Toaster.tsx'
import StepCounter from '@/components/OnboardingCompNew/StepCounter.tsx'
import ProgressBar from '../../ProgressBar.tsx'
import FinalButton from '@/videoshops-shared/components/FinalButton/FinalButton.tsx'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@/routes/routes.ts'
import gaActions, { gaEvents } from '@/videoshops-shared/utils/ga.utils.ts'

const patterns = ['f2', 'f3', 'f4', 'f8', 'f9', 'f10', 'f11', 'f12', 'f5', 'f6', 'f1', 'f7']

const colors = [
  '#FFD9D9',
  '#10AF9C',
  '#005020',
  '#8F0000',
  '#000000',
  '#C50076',
  '#F4574D',
  '#5C2C00',
  '#510082',
  '#FFED4F',
  '#B7F6FF',
  '#007E8F'
]

const ThemeColor: FC = () => {
  const navigate = useNavigate()
  const initSelected = 'f9'
  const [loading, setLoading] = useState(false)
  const { formData } = useContext(OnboardingContext) as OnBaordingProps
  const [selected, setSelected] = useState<string>(initSelected)
  const step = loading ? 8 : 7.5

  const saveColor = async () => {
    const createUrl = `${appConfig.API_BASEL_URL}/marketplace`
    const headers = { Authorization: localStorage.getItem('TOKEN') }
    return putRequest(
      createUrl,
      {
        themeColor: selected,
        name: formData.marketplace.name,
        description: formData.marketplace.description
      },
      { headers }
    )
  }

  const createMarketplace = async () => {
    const marketplace = {
      name: formData.marketplace.name,
      key: formData.marketplace.key,
      ...(formData.marketplace.profileImageKey
        ? { profileImageKey: formData.marketplace.profileImageKey }
        : {}),
      ...(formData.marketplace.backgroundImageKey
        ? { backgroundImageKey: formData.marketplace.backgroundImageKey }
        : {}),
      ...(formData.marketplace.description ? { description: formData.marketplace.description } : {})
    }
    if (formData.marketplace.description === null) {
      marketplace.description = ''
    }
    if (formData.marketplace.key === null) {
      marketplace.key = `${formData.marketplace.name?.toLowerCase().replace(/ /g, '-')}`
    }
    const createUrl = `${appConfig.API_BASEL_URL}/marketplace/creator`
    const headers = { Authorization: localStorage.getItem('TOKEN') }
    setLoading(true)
    const response: OnboardingResponseData = await postRequest(createUrl, marketplace, { headers })
    return response
  }

  const onSubmit = async () => {
    setLoading(true)
    try {
      const response = await createMarketplace()
      await saveColor()
      gaActions.event(gaEvents.account.create)

      if (response.status === 200) {
        toast(<CustomToast type="success" text="Congrats! An account has been created." />)
        const token = localStorage.getItem('TOKEN')
        setTimeout(() => {
          navigate(`${APP_ROUTES.CREATOR_SETUP}/welcome?TOKEN=${token}`)
        }, 1000)
      }
    } catch (error: any) {
      setLoading(false)
      const errorMessage = error.response.data.error
        ? error.response.data.error
        : 'Error with Submission'
      toast(<CustomToast type="error" text={errorMessage} />)
    }
  }

  useEffect(() => {
    localStorage.setItem('themeColor', selected)
  }, [selected])

  return (
    <div className={styles.stepPage}>
      <TopBar />
      <ProgressBar value={(100 / 8) * step} />
      <Box mt={{ xs: 1.5, md: 3.5 }}>
        <Container maxWidth="lg">
          <Box height={36} display="flex" alignItems="center" justifyContent="space-between">
            <BackButton to="/creator/setup/categories" />
            <StepCounter step={8} total={8} />
          </Box>
        </Container>
        <Container maxWidth={false} sx={{ maxWidth: 459 }}>
          <Box mt={2}>
            <StepHeader>Customize Your Shop</StepHeader>
          </Box>
          <Box mt="22px">
            <Typography variant="2xs_bold" color="ash">
              PATTERNS
            </Typography>
          </Box>
          <Box mt={1}>
            <Colors value={selected} onChange={setSelected} list={patterns} />
          </Box>
          <Box mt={2}>
            <Typography variant="2xs_bold" color="ash">
              SOLID COLORS
            </Typography>
          </Box>
          <Box mt={1}>
            <Colors value={selected} onChange={setSelected} list={colors} />
          </Box>
        </Container>
        <Container maxWidth={false} sx={{ maxWidth: { xs: 439, md: 886 } }}>
          <Box mt={4.5}>
            <ProfileCard color={selected} />
          </Box>
        </Container>
        <Container maxWidth={false} sx={{ maxWidth: 459 }}>
          <Box mt={3}>
            <FinalButton disabled={loading} variant="primary" full onClick={onSubmit}>
              Save & Continue
            </FinalButton>
          </Box>
        </Container>
      </Box>
    </div>
  )
}

export default ThemeColor
