import React, { FC, useCallback, useContext, useState } from 'react'
import Checkbox from '@mui/material/Checkbox'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import VerificationInput from 'react-verification-input'
import Box from '@mui/system/Box'
import { toast } from 'react-toastify'
import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext.tsx'
import CustomToast from '@/components/Toaster/Toaster.tsx'
import { postRequest } from '@/api/api.ts'
import { appConfig } from '@/app-config.ts'
import formStyles from '@/components/Form/styles.module.scss'
import { responseType } from '@/components/OnboardingCompNew/SellerSteps/VerifyAccount/types.ts'
import { useNavigate } from 'react-router-dom'
import ResendCode from '@/components/OnboardingCompNew/CommonSteps/VerifyCode/ResendCode.tsx'
import smsStyles from './styles.module.scss'
import FinalButton from '@/videoshops-shared/components/FinalButton/FinalButton.tsx'
import { OnboardingStep } from '@/constants/onboarding.constant'
import useOnboarding from '../../useOnboarding'

interface VerifySMSProps {
  nextPage: string
}

const VerifySMS: FC<VerifySMSProps> = ({ nextPage }) => {
  const navigate = useNavigate()
  const { saveOnboardingData } = useOnboarding()
  const form = useForm({
    mode: 'onChange',
    defaultValues: { isSmsSubscribed: false }
  })

  const [value, setValue] = useState('')
  const headers = {
    Authorization: localStorage.getItem('TOKEN') || ''
  }
  const { getUserData } = useContext(OnboardingContext) as OnBaordingState
  const disabled = value.length !== 6

  const validateCode = useCallback(async (code: string) => {
    try {
      if (code.length !== 6) {
        return
      }

      const smsCodeBody = {
        code: code,
        phone: getUserData.phone,
        isSmsSubscribed: form.getValues().isSmsSubscribed
      }

      const response: responseType = await postRequest(
        `${appConfig.API_BASEL_URL}/user/sms-code`,
        smsCodeBody,
        { headers }
      )

      if (response && response.data.data) {
        const saved = await saveOnboardingData(
          OnboardingStep.VERIFY_SMS,
          localStorage.getItem('TOKEN') || ''
        )
        if (saved) {
          toast(<CustomToast type="success" text="Your Phone Has Been Validated!" />)
          navigate(nextPage)
        }
      } else {
        throw new Error('Code Not Valid')
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error ?? error.message ?? 'Error with Submission'
      toast(<CustomToast type="error" text={errorMessage} />)
    }
  }, [])

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      validateCode(value)
    }
  }

  const handleChange = (value: string) => {
    if (value.length <= 6) {
      setValue(value)
    }
  }

  return (
    <div>
      <VerificationInput
        value={value}
        onChange={handleChange}
        inputProps={{
          onKeyDown: handleEnter
        }}
        autoFocus
        validChars="[0-9]*"
        placeholder=""
        classNames={{
          container: smsStyles.container,
          character: smsStyles.character,
          characterInactive: smsStyles.characterInactive,
          characterSelected: smsStyles.characterSelected,
          characterFilled: smsStyles.characterFilled
        }}
      />
      <FormProvider {...form}>
        <form className={formStyles.formContainer}>
          <Box mt={3.5} typography="xs_med" color="#232A31">
            <Controller
              name="isSmsSubscribed"
              control={form.control}
              render={({ field: props }) => (
                <Checkbox
                  disableRipple
                  sx={{ p: 0, mr: 1.5 }}
                  {...props}
                  onChange={(e) => props.onChange(e.target.checked)}
                />
              )}
            />
            Opt-In to receive SMS notifications
          </Box>
        </form>
      </FormProvider>
      <FinalButton
        disabled={disabled}
        type="submit"
        full
        variant="primary"
        onClick={() => validateCode(value)}
      >
        Next
      </FinalButton>
      <ResendCode />
    </div>
  )
}

export default VerifySMS
