import { FC } from 'react'
import { toast } from 'react-toastify'
import Box from '@mui/system/Box'

import { getRequest } from '@/api/api.ts'
import { appConfig } from '@/app-config.ts'
import CustomToast from '@/components/Toaster/Toaster.tsx'

const ResendCode: FC = () => {
  const handeResendCode = async () => {
    try {
      const headers = {
        Authorization: localStorage.getItem('TOKEN') || ''
      }
      const response = await getRequest(`${appConfig.API_BASEL_URL}/user/resend-code`, { headers })
      if (response && response.data.data) {
        toast(<CustomToast type="success" text="Your Code Has Been Resent" />)
      } else {
        throw new Error('Code Not Valid')
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error ?? error.message ?? 'Error with Submission'
      toast(<CustomToast type="error" text={errorMessage} />)
    }
  }

  return (
    <>
      <Box mt={2} fontSize={12} fontWeight={500} textAlign="center" color="#232A31">
        Didn’t Receive a Text?
      </Box>
      <Box
        mt={0.5}
        fontSize={12}
        fontWeight={500}
        textAlign="center"
        color="#5231FA"
        sx={{ cursor: 'pointer' }}
        onClick={handeResendCode}
      >
        Resend Here
      </Box>
    </>
  )
}

export default ResendCode
