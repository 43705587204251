import { ReactElement } from 'react'
import { ReactComponent as WalletOff } from '@/assets/home/wallet_off.svg'
import { ReactComponent as WalletOn } from '@/assets/home/wallet_on.svg'
import { ReactComponent as TagsOff } from '@/assets/home/tags_off.svg'
import { ReactComponent as TagsOn } from '@/assets/home/tags_on.svg'
import { ReactComponent as KpisOff } from '@/assets/home/kpis_off.svg'
import { ReactComponent as KpisOn } from '@/assets/home/kpis_on.svg'
import { ReactComponent as AccessOff } from '@/assets/home/access_off.svg'
import { ReactComponent as AccessOn } from '@/assets/home/access_on.svg'

import { ReactComponent as FingersOff } from '@/assets/home/fingers_off.svg'
import { ReactComponent as FingersOn } from '@/assets/home/fingers_on.svg'
import { ReactComponent as CartOff } from '@/assets/home/cart_off.svg'
import { ReactComponent as CartOn } from '@/assets/home/cart_on.svg'

export interface CardData {
  label: string
  icon: ReactElement | null
  iconActive?: ReactElement | null
  descriptionLineOne: string | null
  descriptionLineTwo?: string | null
  frontImage?: string
  buttonLable?: string
  buttonLink?: string
}

export const threeCardsPeopleData: CardData[] = [
  {
    icon: <WalletOff />,
    iconActive: <WalletOn />,
    label: 'No Fees',
    descriptionLineOne: 'Turn on your VideoShop',
    descriptionLineTwo: 'and start making money'
  },
  {
    icon: <FingersOff />,
    iconActive: <FingersOn />,
    label: 'No Approvals',
    descriptionLineOne: 'No approvals needed',
    descriptionLineTwo: 'to get paid on sales'
  },
  {
    icon: <CartOff />,
    iconActive: <CartOn />,
    label: 'No Inventory',
    descriptionLineOne: 'Just share & sell',
    descriptionLineTwo: '- we take care of the rest'
  }
]

export const threeCardsBrandData: CardData[] = [
  {
    icon: <TagsOff />,
    iconActive: <TagsOn />,
    label: 'Upload Your Catalog',
    descriptionLineOne: 'Connect your store in minutes.'
  },
  {
    icon: <AccessOff />,
    iconActive: <AccessOn />,
    label: 'Ignite Sellers',
    descriptionLineOne: 'We made it simple.'
  },
  {
    icon: <KpisOff />,
    iconActive: <KpisOn />,
    label: 'It’s Fast & Easy',
    descriptionLineOne: 'It’s never been easier to scale your business'
  }
]

export const getTurnedOnData: CardData[] = [
  {
    icon: null,
    label: 'Kendall Michelle',
    descriptionLineOne:
      'I love Videoshops, other apps are complicated and expensive, I launched my Marketplace in seconds!',
    frontImage: '/images/gate-hero.jpg',
    buttonLable: 'Shop Marketplace',
    buttonLink: '#'
  },
  {
    icon: null,
    label: 'Sir John',
    descriptionLineOne:
      'I love Videoshops, other apps are complicated and expensive, I launched my Marketplace in seconds!',
    frontImage: '/images/sir-john.jpg',
    buttonLable: 'Shop Marketplace',
    buttonLink: '#'
  },
  {
    icon: null,
    label: 'Olivia Rink',
    descriptionLineOne:
      'I love Videoshops, other apps are complicated and expensive, I launched my Marketplace in seconds!',
    frontImage: '/images/olivia-rink.jpg',
    buttonLable: 'Shop Marketplace',
    buttonLink: '#'
  },
  {
    icon: null,
    label: 'Thalia',
    descriptionLineOne:
      'I love Videoshops, other apps are complicated and expensive, I launched my Marketplace in seconds!',
    frontImage: '/images/thalia.jpg',
    buttonLable: 'Shop Marketplace',
    buttonLink: '#'
  }
]
