import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import InputField from '@/components/Form/Fields/InputField/InputField'
import PasswordRules from '@/components/Form/PasswordRules/PasswordRules'
import { COUNTRY_PHONE_CODES } from '@/constants/countryCodes'
import SelectComp from '@/components/Form/Fields/Select/Select'
import Divider from '@/videoshops-shared/components/Divider/Divider.tsx'
import AgreeCheckbox from '@/components/OnboardingCompNew/AgreeCheckbox.tsx'
import styles from '../../styles.module.scss'
import getSignInUrl from '@/utils/url.ts'
import { APP_ROUTES } from '@/routes/routes.ts'
import Text from '@/components/OnboardingCompNew/SellerSteps/Text/Text'
import ContinueWithGoogle from '@/components/OnboardingCompNew/SellerSteps/CreateAccount/ContinueWithGoogle.tsx'
import FinalButton from '@/videoshops-shared/components/FinalButton/FinalButton.tsx'
import {
  StyledFormRowWithStaticCopy,
  StyledPasswordRulesWrapper,
  StyledRequiredWrapper,
  StyledSignUpActions,
  StyledSignUpForm,
  StyledSignUpFormRow,
  StyledSignUpSignUpActions
} from '../../OnboardingGlobal.styled'

const SignUpForm: FC = () => {
  const {
    getValues,
    formState: { errors, isValid, isSubmitting },
    watch
  } = useFormContext()
  const confirmPassword = watch('confirmPassword')
  const terms = watch('terms')
  const showStaticPhoneCopy = errors.phone || errors.countryCode

  return (
    <>
      <StyledSignUpForm>
        <StyledSignUpFormRow>
          <InputField colW={6} label="First Name" name="firstName" type="text" required />
          <InputField colW={6} label="Last Name" name="lastName" type="text" required />
        </StyledSignUpFormRow>
        <StyledSignUpFormRow>
          <InputField colW={12} label="Email Address" name="email" type="text" required />
        </StyledSignUpFormRow>
        <StyledFormRowWithStaticCopy height={!showStaticPhoneCopy ? '5.6rem' : '4.2rem'}>
          <StyledSignUpFormRow>
            <SelectComp colW={2} name="countryCode" optionData={COUNTRY_PHONE_CODES} required />
            <InputField colW={11} label="Mobile Number" name="phone" type="text" required />
          </StyledSignUpFormRow>
          {!showStaticPhoneCopy && (
            <Text
              className="phone-static-copy"
              text="We'll send your commissions to the Venmo account tied to this phone number."
              size="small"
              alignment="left"
              weight={500}
            />
          )}
        </StyledFormRowWithStaticCopy>
        <StyledSignUpFormRow>
          <InputField colW={12} label="Password" name="password" type="password" required />
        </StyledSignUpFormRow>
        {getValues('password') && (
          <StyledSignUpFormRow>
            <StyledPasswordRulesWrapper>
              <PasswordRules value={getValues('password')} open={getValues('password')} />
            </StyledPasswordRulesWrapper>
          </StyledSignUpFormRow>
        )}
        <StyledSignUpFormRow>
          <InputField
            colW={12}
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            required
          />
        </StyledSignUpFormRow>
        <AgreeCheckbox type="seller" />
        <StyledRequiredWrapper marginTop="1.5rem">
          <span className="required-asterix">*</span>
          <Text text="Required" color="grey" size="xs" />
        </StyledRequiredWrapper>
      </StyledSignUpForm>
      {confirmPassword && !terms && (
        <Text
          text="Please review and agree to the terms and conditions to proceed."
          size="small"
          alignment="left"
          weight={500}
          color="danger"
        />
      )}
      <StyledSignUpActions>
        <FinalButton
          variant="primary"
          disabled={!isValid || isSubmitting}
          loading={isSubmitting}
          full
        >
          Next
        </FinalButton>
        <Divider>OR</Divider>
        <ContinueWithGoogle />
        <StyledSignUpSignUpActions>
          <Text text="Already a Seller?" size="small" alignment="center" weight={500} />
          <a href={getSignInUrl()}>Log in</a>
        </StyledSignUpSignUpActions>
        <StyledSignUpSignUpActions>
          <Text text="Are you a Merchant?" size="small" alignment="center" weight={500} />
          <a href={APP_ROUTES.BRAND_SETUP}>Get started here</a>
        </StyledSignUpSignUpActions>
      </StyledSignUpActions>
    </>
  )
}

export default SignUpForm
