import heic2any from 'heic2any'

/**
 * Apply brightness to canvas image data.
 * @param {HTMLCanvasElement} canvas - The canvas element.
 * @param {number} brightness - The brightness level.
 * @returns {void}
 */

export const applyBrightnessToCanvas = (canvas: HTMLCanvasElement, brightness: number) => {
  const ctx = canvas.getContext('2d')
  if (ctx) {
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    const data = imageData.data

    const mappedBrightness = 1.3 - brightness

    for (let i = 0; i < data.length; i += 4) {
      data[i] = data[i] * mappedBrightness
      data[i + 1] = data[i + 1] * mappedBrightness
      data[i + 2] = data[i + 2] * mappedBrightness
    }

    ctx.putImageData(imageData, 0, 0)
  }
}

export const resizeImage = (
  file: File,
  maxWidth: number,
  callback: (resizedUrl: string) => void
) => {
  if (file.type === 'image/heic' || file.type === 'image/heif') {
    heic2any({ blob: file, toType: 'image/jpeg' })
      .then((convertedBlob) => {
        const reader = new FileReader()
        reader.readAsDataURL(convertedBlob as Blob)
        reader.onload = (event) => {
          processImage(event.target?.result as string, maxWidth, callback)
        }
      })
      .catch((error) => {
        console.error('Error converting HEIC file:', error)
      })
  } else {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (event) => {
      processImage(event.target?.result as string, maxWidth, callback)
    }
  }
}

const processImage = (
  imageUrl: string,
  maxWidth: number,
  callback: (resizedUrl: string) => void
) => {
  const img = new Image()
  img.src = imageUrl
  img.onload = () => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    let width = img.width
    let height = img.height
    if (width > maxWidth) {
      height = (height * maxWidth) / width
      width = maxWidth
    }

    canvas.width = width
    canvas.height = height
    ctx?.drawImage(img, 0, 0, width, height)

    callback(canvas.toDataURL())
  }
}
