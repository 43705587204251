import { FC } from 'react'
import Section from '@/components/Section/Section'
import NewTitle from '../../Title/Title'
import MarketPlaceInput from '../../MarketplaceInput/MarketplaceInput'
import styles from './styles.module.scss'
import { useStaticPageContext } from '@/context/StaticPageContext'
import { SiteWideTypes } from '@/types/SiteContext.interface'
import PhoneCollage from '@/components/PhoneCollage/PhoneCollage'
import classNames from 'classnames'

const HomeHero: FC = () => {
  const { siteType } = useStaticPageContext() as SiteWideTypes
  const isMobile = window.innerWidth < 768
  const title =
    siteType === 'people' ? (
      <div className={classNames('breakTitle', styles.homeHeaderHeading)}>
        <span data-line="If You Can Share">If You Can Share</span>
        <span data-line="You Can Sell">
          <span className="vs-primary-color"> You Can Sell</span>
        </span>
      </div>
    ) : (
      <div className="breakTitle">
        <span data-line="We Power">We Power</span>
        <span data-line="Social Selling">
          <span className="vs-primary-color">Social Selling</span>
        </span>
      </div>
    )

  const subText =
    siteType === 'people' ? (
      <div className="breakTitle">
        {isMobile ? (
          <p>
            <span data-line="Turn on a money-making">
              {' '}
              You can make serious money
            </span>
          </p>
        ) : (
          <p>
            <span data-line="Turn on a money-making eCommerce business.">
              {' '}
              Turn on a money-making eCommerce business.
            </span>
          </p>
        )}
      </div>
    ) : (
      <div className="breakTitle">
        <p>
          <span data-line="Connect your eCommerce store to Ignite">
            {' '}
            Connect your eCommerce store to Ignite
          </span>
          <span data-line="sellers for your brand.">
            sellers for your brand.
          </span>
        </p>
      </div>
    )

  return (
    <Section className={styles.heroSectionWrapper} bgColor="#fff">
      <div className={styles.heroWrapper}>
        <div className={styles.titleAndSignUp}>
          <div className={styles.titleWrapper}>
            <NewTitle children={title} subText={subText} titleColor="#000" />
          </div>
          {!isMobile && <MarketPlaceInput />}
        </div>
        <div className={styles.phoneCollageWrapper}>
          <PhoneCollage />
        </div>
      </div>
    </Section>
  )
}

export default HomeHero
