import NewTitle from '@/components/Title/Title'
import { ReactComponent as MobileBack } from '@/assets/home/mobile-back.svg'
import { ReactComponent as BreadCrumbArrow } from '@/assets/home/breadcrumb-arrow.svg'
import Footer from '@/components/Footer/Footer'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import ContactUsFormValidation from '@/components/Form/Validations/ContactUsFormValidation'
import ContactTextArea from '@/components/SubPagesComp/FormFields/TextArea'
import ContactInput from '@/components/SubPagesComp/FormFields/TextInput'
import Button from '@/components/Button/Button'
import styles from './styles.module.scss'
import { appConfig } from '@/app-config'
import { postRequest } from '@/api/api'
import { AxiosResponse } from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import CustomToast from '@/components/Toaster/Toaster'
import { COUNTRY_PHONE_CODES } from '@/constants/countryCodes'
import ContactSelect from '@/components/SubPagesComp/FormFields/Select'
import TopBar from '@/components/SubPagesComp/TopBar/TopBar'

function ContactUsPage() {
  const defaultCountryCode = COUNTRY_PHONE_CODES[0]?.value || "";
  const form = useForm({
    resolver: yupResolver(ContactUsFormValidation),
    defaultValues: { countryCode: defaultCountryCode }
  })

  const { handleSubmit } = form

  let toastId: Id | undefined

  const onSubmit = async (data: any) => {
    try {
      toastId = toast(<CustomToast />)
      const onboardingFormResponse: AxiosResponse<OnboardingResponseData> =
        await postRequest(`${appConfig.CONTACT_API_BASE_URL}`, data)

      if ('data' in onboardingFormResponse) {
        console.log(onboardingFormResponse)

        toast.dismiss(toastId)
        toast(
          <CustomToast
            type="success"
            text="Thank you! We will get back to you shortly."
          />
        )
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      }
    } catch (error: any) {
      toast.dismiss(toastId)
      const errorMessage = error.response.data.error
        ? error.response.data.error
        : 'Error with Submission'
      toast(<CustomToast type="error" text={errorMessage} />)
    }
  }

  const isMobile = window.innerWidth < 768
  const title = isMobile ? (
    <div className="breakTitle">
      <span data-line="What's on">What's on</span>
      <span data-line="Your Mind?">
        <span className="vs-primary-color">Your Mind?</span>
      </span>
    </div>
  ) : (
    <div className="breakTitle">
      <span data-line="What's on">What's on</span>
      <span data-line="Your Mind?">
        <span className="vs-primary-color">Your Mind?</span>
      </span>
    </div>
  )

  return (
    <>
      <ToastContainer
        theme="light"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        limit={2}
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
      />
      <TopBar />
      <div className={styles.subPageWrapper}>
        <div className={styles.aboutUsTopWrapper}>
          {isMobile ? (
            <a href="/">
              {' '}
              <MobileBack />
            </a>
          ) : (
            <div className={styles.breadcrumbWrapper}>
              <a href="/">Home</a> <BreadCrumbArrow /> Contact Us
            </div>
          )}
        </div>
        <NewTitle children={title} titleColor="#000" />
        <div className={styles.twoColumns}>
          <div className={styles.contactInfo}>
            <h6>Videoshops HQ</h6>
            Videoshops
            <br />
            <a
              className={styles.addressLink}
              href="https://www.google.com/maps/search/?api=1&query=167+Lafayette+St%2C+3rd+Floor%2C+New+York%2C+NY+10013"
              target="_blank"
              rel="noopener noreferrer"
            >
              167 Lafayette St, 3rd Floor
              <br />
              New York, NY 10013
              <br />
            </a>
            <a className={styles.mailLink} href="mailto:hello@videoshops.com">
              hello@videoshops.com
            </a>
          </div>
          <div className={styles.contactForm}>
            <h6>Let's Connect</h6>
            <FormProvider {...form}>
              <form className={styles.formContainer}>
                <div className={styles.signUpFormRow}>
                  <ContactInput
                    colW={6}
                    label="First Name"
                    name="firstName"
                    required
                  />
                  <ContactInput
                    colW={6}
                    label="Last Name"
                    name="lastName"
                    type="text"
                    required
                  />
                </div>
                <div className={styles.signUpFormRow}>
                  <ContactInput
                    colW={12}
                    label="Email Address"
                    name="email"
                    type="text"
                    required
                  />
                </div>
                <div className={styles.signUpFormRow}>
                  <ContactSelect
                    name="countryCode"
                    optionData={COUNTRY_PHONE_CODES}
                    required
                  />
                  <ContactInput
                    label="Mobile Number"
                    name="phone"
                    type="text"
                    required
                  />
                </div>
                <div className={styles.signUpFormRow}>
                  <ContactTextArea
                    colW={12}
                    label="How Can We Help"
                    name="message"
                    maxCount={200}
                    required
                  />
                </div>
                <span className={styles.requiredWrapper}>
                  <span className="required-asterix">*</span>Required
                </span>
                <Button
                  children="Submit"
                  onClick={handleSubmit(onSubmit)}
                  primary
                />
              </form>
            </FormProvider>
          </div>
        </div>{' '}
        <Footer />
      </div>
    </>
  )
}
export default ContactUsPage
