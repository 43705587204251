import NewTitle from '../../Title/Title'
import styles from './styles.module.scss'
import Section from '../../Section/Section'
// import HomeBrandsCarousel from '@/pages/ui/HomeBrandsCarousel'
import { useStaticPageContext } from '@/context/StaticPageContext'
import { SiteWideTypes } from '@/types/SiteContext.interface'
import { useState, useEffect } from 'react'
import { isIOS } from 'react-device-detect'
import classNames from 'classnames'
import { generateGridSequences } from './useSearchProducts'

export default function SearchProducts() {
  const { siteType } = useStaticPageContext() as SiteWideTypes
  const [selectedImages, setSelectedImages] = useState<string[]>([])
  const [fade, setFade] = useState(false)
  const isMobile = window.innerWidth < 768
  const isPeople = siteType === 'people'
  const TRANSISTION_DURATION = 3000
  const FADE_DURATION = 3000

  const titleText = (
    <div className="breakTitle">
      {isPeople ? (
        isMobile ? (
          <>
            <span data-line="Access to Millions of Products">
              Access <span className="vs-primary-color"> Millions</span>
            </span>
            <span data-line="of Products">of Products</span>
          </>
        ) : (
          <span data-line="Access to Millions of Products">
            Access <span className="vs-primary-color"> Millions</span> of
            Products
          </span>
        )
      ) : (
        <span data-line="Let’s get started!">
          Connect <span className="vs-primary-color">Your Brand </span>
        </span>
      )}
    </div>
  )

  useEffect(() => {
    const fadeOutTimeout = setTimeout(() => {
      setFade(false)
    }, FADE_DURATION)

    const changeImageTimeout = setTimeout(() => {
      selectRandomImages()
      setFade(true)
    }, 2 * FADE_DURATION)

    const intervalId = setInterval(() => {
      setFade(false)

      setTimeout(() => {
        setFade(true)
        selectRandomImages()
      }, FADE_DURATION)
    }, TRANSISTION_DURATION)
    selectRandomImages()
    return () => {
      clearInterval(intervalId)
      clearTimeout(fadeOutTimeout)
      clearTimeout(changeImageTimeout)
    }
  }, [])

  const selectRandomImages = () => {
    const extendedGridSequences = generateGridSequences(50) // Generate 50 new sequences
    const randomArray =
      extendedGridSequences[
        Math.floor(Math.random() * extendedGridSequences.length)
      ]
    setSelectedImages(randomArray)
    setFade(false)
  }

  return (
    <Section>
      <div
        className={classNames(styles.searchProductsContainer, {
          [styles.isIOS]: isIOS
        })}
      >
        <div className={styles.centeredContent}>
          <span className={styles.titleWrapper}>
            <NewTitle
              className={styles.searchProductsTitle}
              children={titleText}
              titleColor="#000"
              secondaryTitle
            />
          </span>
          <div className={styles.gridWrapper}>
            <div className={styles.gridWrapperColumn}>
              <img
                className={classNames(fade ? styles.fadeOut : styles.fadeIn)}
                src={`/images/grid-images/grid-image${selectedImages[1]}.png`}
                alt={`grid-image${selectedImages[1]}.png`}
              />
              <img
                className={classNames(fade ? styles.fadeOut : styles.fadeIn)}
                src={`/images/grid-images/grid-image${selectedImages[0]}.png`}
                alt={`grid-image${selectedImages[0]}.png`}
              />
            </div>
            <div className={styles.gridWrapperColumn}>
              <img
                className={classNames(fade ? styles.fadeOut : styles.fadeIn)}
                src={`/images/grid-images/grid-image${selectedImages[7]}.png`}
                alt={`grid-image${selectedImages[8]}.png`}
              />
              <img
                className={classNames(fade ? styles.fadeOut : styles.fadeIn)}
                src={`/images/grid-images/grid-image${selectedImages[6]}.png`}
                alt={`grid-image${selectedImages[6]}.png`}
              />
            </div>
            <div className={styles.gridWrapperColumn}>
              <img
                className={classNames(fade ? styles.fadeOut : styles.fadeIn)}
                src={`/images/grid-images/grid-image${selectedImages[3]}.png`}
                alt={`grid-image${selectedImages[3]}.png`}
              />
              <img
                className={classNames(fade ? styles.fadeOut : styles.fadeIn)}
                src={`/images/grid-images/grid-image${selectedImages[5]}.png`}
                alt={`grid-image${selectedImages[6]}.png`}
              />
            </div>
            <div className={styles.gridWrapperColumn}>
              <img
                className={classNames(fade ? styles.fadeOut : styles.fadeIn)}
                src={`/images/grid-images/grid-image${selectedImages[2]}.png`}
                alt={`grid-image${selectedImages[2]}.png`}
              />
              <img
                className={classNames(fade ? styles.fadeOut : styles.fadeIn)}
                src={`/images/grid-images/grid-image${selectedImages[4]}.png`}
                alt={`grid-image${selectedImages[4]}.png`}
              />
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}
