import { FC } from 'react'
import Box from '@mui/system/Box'
import Container from '@mui/system/Container'
import TopBar from '@/shared/components/TopBar/TopBar.tsx'
import StepHeader from '@/components/OnboardingCompNew/StepHeader/StepHeader.tsx'
import Divider from '@/videoshops-shared/components/Divider/Divider.tsx'
import styles from '../../styles.module.scss'
import VerifySMS from '../../CommonSteps/VerifyCode/VerifySMS.tsx'

const VerifyCode: FC = () => {
  return (
    <div className={styles.stepPage}>
      <TopBar />
      <Box mt={{ xs: 1.5, md: 3.5 }}>
        <Box height={36} />
        <Container maxWidth={false} sx={{ maxWidth: 411 }}>
          <Box mt={3}>
            <StepHeader title="Merchant Sign Up">Verify Your Account</StepHeader>
          </Box>
          <Box mt={4}>
            <Divider>FINAL DETAILS</Divider>
          </Box>
          <Box mt={2}>
            <VerifySMS nextPage="/brand/setup/name" />
          </Box>
        </Container>
      </Box>
    </div>
  )
}

export default VerifyCode
