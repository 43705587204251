import { FC } from 'react'
import styles from './styles.module.scss'
import { useOnboardingContext } from '@/hooks/useOnboardingForm'
import Button from '../Button/Button'
import getSignInUrl from '@/utils/url.ts'

const TopBarOld: FC = () => {
  const { formData } = useOnboardingContext()
  const { marketplace } = formData

  const hasMarketPlaceName = marketplace && marketplace.name
  const logoTagLine = hasMarketPlaceName
    ? '/icons/power-by-videoshops.svg'
    : '/icons/video-shops-mini-logo.svg'
  return (
    <>
      <div className={styles.TopBarContainer}>
        <div className={styles.TopBarInnerContainer}>
          <Button
            children={<>Sign In</>}
            onClick={() => window.open(getSignInUrl(), '_self')}
            greyOutline
            transparent
          />

          <div className={styles.TopBarLogo}>
            {hasMarketPlaceName && <span>{marketplace.name}</span>}
            <img src={logoTagLine} />
          </div>
          <div className={styles.TopBarRightIcons}>
            <div className={styles.elementPlaceholder}></div>
            {/*
            <img src="/icons/heart-outline.svg" />
            <img src="/icons/cart-outline.svg" /> */}
          </div>
        </div>
      </div>
      <div></div>
    </>
  )
}

export default TopBarOld
