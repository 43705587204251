import { useState, FC } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { ReactComponent as Plus } from '@/assets/home/plus.svg'
import { ReactComponent as Minus } from '@/assets/home/minus.svg'

import { FAQItem } from './types'

const FAQCard: FC<FAQItem> = ({ question, index, selected }) => {
  const [open, setOpen] = useState(false)
  return (
    <div
      onClick={() => setOpen(!open)}
      className={classNames(styles.faqCard, {
        [styles.selected]: selected === index
      })}
    >
      <div className={styles.faqQuestion}>
        <label>{question.title}</label>
        <div
          className={classNames(styles.arrow, {
            [styles.open]: open,
            [styles.close]: !open
          })}
        >
          {open ? <Minus /> : <Plus />}
        </div>
      </div>
      <div className={classNames(styles.faqAnswer, open && styles.expanded)}>
        {question.content}
      </div>
    </div>
  )
}

export default FAQCard
