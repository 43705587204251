import { useEffect, useRef } from 'react'
import cn from 'classnames'
import styles from '../../../components/Utility/Modal/styles.module.css'
import useOnClickOutside from '../../../hooks/on-click-outside'
import { ModalProps } from '../../../components/Utility/Modal/types'

export default function Modal({
  open,
  children,
  onClose,
  heading
}: ModalProps) {
  const modalContentRef = useRef(null)

  useEffect(() => {
    const onKeyDown = (e: any) => {
      if (e.key === 'Escape') {
        onClose?.()
      }
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  useOnClickOutside(modalContentRef, onClose)

  if (!open) {
    return null
  }

  return (
    <div className={styles.modal}>
      <div
        className={cn({
          [styles['modal-header']]: true,
          [styles.isEmpty]: !heading
        })}
      >
        <h3>{heading}</h3>
      </div>
      <div ref={modalContentRef} className={styles['modal-content']}>
        {children}
      </div>
    </div>
  )
}
