export const VendorObject: VendorState = {
  user: {
    email: null,
    password: null,
    countryCode: null,
    phone: null,
    firstName: null,
    lastName: null,
    brandName: null,
    brandWebsite: null,
    jobTitle: null
  },
  marketplace: {
    name: null,
    key: null,
    description: null,
    affinities: null,
    profileImageKey: null,
    profileImage: null,
    backgroundImageKey: null,
    backgroundImage: null,
    heroImageKey: null,
    heroImage: null,
    thumbnailImageKey: null,
    thumbnailImage: null,
    currency: 'USD',
    commissionRate: null
  }
}

export const keyMapping = {
  user: Object.keys(VendorObject.user),
  marketplace: Object.keys(VendorObject.marketplace)
}
