import classNames from 'classnames'

import React from 'react'
import styles from './styles.module.scss'
import { TitleProps } from './types'
import { isIOS } from 'react-device-detect'

const Title: React.FC<TitleProps> = ({
  className,
  children,
  text,
  noWrap,
  titleColor,
  secondaryTitle,
  titlePosition,
  subText
}) => {
  const styleClass = classNames(
    className,
    titlePosition && styles[titlePosition],
    {
      [styles.default]: true,
      [styles.noWrap]: noWrap,
      [styles.secondaryTitle]: secondaryTitle
    }
  )
  const TitleStyle = {
    color: titleColor || '#fff'
  }

  const renderTitle = () => {
    return (
      <div className={styleClass} style={TitleStyle}>
        <div className={classNames({ [styles.isIOS]: isIOS })}>
          {text && text}
          {children && children}
          {subText && <span className={styles.subText}>{subText}</span>}
        </div>
      </div>
    )
  }
  return renderTitle()
}

export default Title
