import { FC } from 'react'
import { OnboardingProvider } from '@/context/OnboardingContext/OnboardingProvider'
import VendorSetupComp from '@/components/OnboardingComp/VendorSetupComp'

const VendorSetupPage: FC = () => {
  return (
    <OnboardingProvider siteType="vendor">
      <VendorSetupComp />
    </OnboardingProvider>
  )
}

export default VendorSetupPage
