import { FC, useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import { unCodifyStr } from '@/utils/helpers'
import { useInFocus } from '@/hooks/useInFocus'
import classNames from 'classnames'
import { isIOS } from 'react-device-detect'
import { ReactComponent as Shelve } from '@/assets/icons/shelves.svg'

type AlertSlideData = {
  name: string
  offer: string
}

export const alertSlideData = [
  {
    name: 'kendall',
    offer: 'eye serums'
  },
  {
    name: 'amelia',
    offer: 'skin-care treatments'
  },
  {
    name: 'tyler',
    offer: 'hand creams'
  }
]

const SlideAlert: FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const inFocus = useInFocus(ref, '30% 0% 0px 30%', [0.1, 0.9])
  const [animatedSlides, setAnimatedSlides] = useState<number[]>([])

  useEffect(() => {
    let intervalId: NodeJS.Timeout

    if (ref.current && inFocus) {
      const currentSlide = 0
      setAnimatedSlides([currentSlide])

      intervalId = setInterval(() => {
        setAnimatedSlides((prev) => {
          const nextSlide = prev[prev.length - 1] + 1
          return [...prev, nextSlide]
        })

        if (animatedSlides.length >= alertSlideData.length - 1) {
          clearInterval(intervalId)
        }
      }, 500)
    } else {
      setAnimatedSlides([])
    }

    return () => clearInterval(intervalId)
  }, [inFocus])

  return (
    <div
      ref={ref}
      className={classNames(styles.alertSlideWrapper, {
        [styles.isIOS]: isIOS
      })}
    >
      {alertSlideData.map((item: AlertSlideData, index: number) => (
        <div
          className={classNames(styles.alertSlide, {
            [styles.slideInRight]:
              animatedSlides.includes(index) && index % 2 === 0,
            [styles.slideInLeft]:
              animatedSlides.includes(index) && index % 2 !== 0
          })}
          key={`${item.name}-${item.offer}`}
        >
          <div className={styles.alertSlideImage}>
            <img src={`/images/alert-${item.name}.png`} alt="slide alert" />
          </div>
          <div className={styles.alertSlideCopy}>
            <div className={styles.alertSlideHeading}>
              <h1>{unCodifyStr(item.name)} Shared a New</h1>
              <span className={styles.shelfIcon}>
                <span>
                  <Shelve />
                </span>
              </span>
            </div>
            <p>
              Shop {unCodifyStr(item.name)}’s must-have {item.offer}
            </p>
          </div>
          <div className={styles.alertSlideProducts}>
            <img
              src={`/images/alert-${item.name}-products.png`}
              alt="slide alert"
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default SlideAlert
