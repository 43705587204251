import React from 'react'
import AnimateHeight from 'react-animate-height'
import { StyledPasswordRules } from './PasswordRules.styled'
import { ReactComponent as Icon } from '../../../../public/icons/check-password.svg'
import { ReactComponent as ErrorIcon } from '../../../../public/icons/red-x-circle.svg'
import classNames from 'classnames'

interface PasswordRulesProps {
  value: string
  open?: boolean
}

const rules = [
  { condition: 'At Least 8 Characters', regex: /^.{8,}$/ },
  { condition: 'At Least 1 Capital Letter', regex: /[A-Z]/ },
  { condition: 'At Least 1 Lower Letter', regex: /[a-z]/ },
  { condition: 'At Least 1 Number', regex: /[0-9]/ },
  {
    condition: 'At Least 1 Special Character: !@#$%^&*-_',
    regex: /[!@#$%^&*\-_]/
  },
  {
    condition: 'You can change this now or anytime later in Account Details.',
    regex: /^.+$/
  }
]

const PasswordRules: React.FC<PasswordRulesProps> = ({ value, open }) => (
  <StyledPasswordRules>
    <AnimateHeight duration={160} height={open ? 'auto' : 0}>
      <ul>
        {rules.map((rule, i) => (
          <li
            key={i}
            className={classNames('hint-text', {
              active: rule.regex.test(value)
            })}
          >
            {rule.regex.test(value) ? <Icon /> : <ErrorIcon />}
            <span>{rule.condition}</span>
          </li>
        ))}
      </ul>
    </AnimateHeight>
  </StyledPasswordRules>
)

export default PasswordRules
