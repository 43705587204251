import { FC, useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import styles from './styles.module.scss'
import { FormProvider, useForm } from 'react-hook-form'
import Input from '@/shared/components/form/input/input'
import Button from '@/shared/components/button/button'
import { postRequest } from '@/api/api'
import { appConfig } from '@/app-config'

export interface INewsletterForm {
  email: string
}

const newsletterForm = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required('Email is required')
    .matches(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
      'Your Email does not seem to be a valid email.'
    )
})

const Newsletter: FC = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(newsletterForm)
  })
  const form: INewsletterForm = watch()
  const [emailSent, setEmailSent] = useState(false)

  const onSubmit = async (formValues: INewsletterForm) => {
    await postRequest(
      `${appConfig.API_BASEL_URL}/newsletter/subscribe`,
      formValues,
      {}
    ).then(() => setEmailSent(true))
  }

  return (
    <div className={styles.newsletter}>
      {emailSent ? (
        <p className={styles.message}>
          Thank you for subscribing to Videoshops.
        </p>
      ) : (
        <form className={styles['form-container']}>
          <Input
            value={form.email}
            register={register}
            errors={errors}
            label="EMAIL"
            required={true}
            field="email"
            button={
              <Button
                onClick={handleSubmit(onSubmit)}
                className={styles['input-button']}
              >
                Submit
              </Button>
            }
          />
        </form>
      )}
    </div>
  )
}

export default Newsletter
