import { FC } from 'react'
import Box from '@mui/system/Box'
import { useOnboardingContext } from '@/hooks/useOnboardingForm.ts'
import { getBackgroundImage } from '@/shared/utils/image/get-background.ts'
import HeroDetails from '@/components/HeroDetails/HeroDetails'

interface ProfileCardProps {
  color?: string
}

const lightTheme = ['f2', 'f6', 'f1', '#FFD9D9DB', '#FFED4F', '#B7F6FF']

const ProfileCard: FC<ProfileCardProps> = ({ color }) => {
  const light = color && lightTheme.includes(color)
  const textColor = light ? '#000' : '#fff'
  const { formData } = useOnboardingContext()
  const { marketplace } = formData

  return (
    <Box
      position="relative"
      height="auto"
      borderRadius="24px"
      overflow="hidden"
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      sx={getBackgroundImage(color)}
      color={textColor}
      alignItems={{ md: 'stretch' }}
      padding={{ md: '1.25rem' }}
      gap={{ md: '2rem' }}
    >
      <Box
        position="relative"
        display="flex"
        alignItems="flex-end"
        overflow="hidden"
        width={{ xs: '100%', md: 300 }}
        style={{ aspectRatio: '18.75 / 15' }}
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${marketplace.profileImage})`,
          borderRadius: {
            xs: '0px 0px 200px 200px / 0px 0px 25px 25px',
            md: '16px',
          },
        }}
      ></Box>
      <HeroDetails marketplace={marketplace} />
    </Box>
  )
}

export default ProfileCard
