import React from 'react'
import { CardsProps } from './types'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { CardData } from '@/constants/cardsData'
import Card from './Card/Card'
import { codifyStr } from '@/utils/helpers'

const Cards: React.FC<CardsProps> = ({ data, columnCount, className }) => {
  const cardRowStyles = classNames(styles.cardRow, className && className, {
    [styles.threeColumns]: columnCount === 3 || !columnCount,
    [styles.fourColumns]: columnCount === 4,
    [styles.smallGap]: columnCount === 3 || !columnCount
  })

  return (
    <div className={cardRowStyles}>
      {data.map((card: CardData) => (
        <Card
          key={`${codifyStr(card.label)}-${
            card.descriptionLineOne && codifyStr(card.descriptionLineOne)
          }`}
          label={card.label}
          icon={card.icon}
          descriptionLineOne={card.descriptionLineOne}
          descriptionLineTwo={card.descriptionLineTwo}
          iconActive={card.iconActive}
        />
      ))}
    </div>
  )
}

export default Cards
