export const CreatorObject: CreatorState = {
  user: {
    email: null,
    password: null,
    countryCode: null,
    phone: null,
    firstName: null,
    lastName: null
  },
  marketplace: {
    name: null,
    key: null,
    description: null,
    profileImageKey: null,
    profileImage: null,
    backgroundImage: null,
    backgroundImageKey: null
  }
}

export const keyMapping = {
  user: Object.keys(CreatorObject.user),
  marketplace: Object.keys(CreatorObject.marketplace)
}
