import { FC, useEffect, useRef, useState } from 'react'
import Section from '@/components/Section/Section'
import NewTitle from '../../Title/Title'
import styles from './styles.module.scss'
import { useStaticPageContext } from '@/context/StaticPageContext'
import { SiteWideTypes } from '@/types/SiteContext.interface'
import { peopleUl, vendorUl } from '@/constants/dashboardStats'
import { codifyStr } from '@/utils/helpers'
import { useInFocus } from '@/hooks/useInFocus'
import DashboardDevice from './Dashboard/Dashboard'
const VITE_VIDEO_URL = import.meta.env.VITE_VIDEO_URL

const DashboardStats: FC = () => {
  const { siteType } = useStaticPageContext() as SiteWideTypes
  const isPeople = siteType === 'people'
  const ref = useRef<HTMLDivElement>(null)

  const inFocus = useInFocus(ref, '30% 0% 0px 30%', [0.1, 0.9])
  const isMobile = window.innerWidth < 768
  const ulData = isPeople ? peopleUl : vendorUl
  const title = (
    <div className="breakTitle">
      <span data-line="That Makes YOU Serious Money.">
        Make
        <span className="vs-primary-color"> Serious Money.</span>
      </span>
    </div>
  )

  const subText = (
    <div className="breakTitle">
      {!isMobile ? (
        <p>
          <span data-line="Access Millions of Products to Sell in">
            From product discovery to checkout{' '}
          </span>
          <span data-line="Your Videoshop">
            VideoShops sellers control their customer data
          </span>
          <span>to build and scale their personal business.</span>
        </p>
      ) : (
        <p>
          From product discovery to checkout VideoShop sellers control their
          customer data to build and scale their personal business.
        </p>
      )}
    </div>
  )

  return (
    <Section className={styles.dashboardStatsWrapper} bgColor="#fff">
      <div className={styles.dashboardStatsInnerContainer}>
        <div className={styles.dashboardStatsLeftCol}>
          <div className={styles.titleAndSignUp}>
            <div className={styles.titleWrapper}>
              <NewTitle
                children={title}
                subText={subText}
                titleColor="#000"
                secondaryTitle
              />
            </div>
          </div>
          <ul>
            {ulData.map((item: string, index) => (
              <li key={`${codifyStr(item)}-${index}`}>{item}</li>
            ))}
          </ul>
        </div>
        <div ref={ref} className={styles.dashboardStatsRightCol}>
          <DashboardDevice inFocus={inFocus} />
        </div>
      </div>
    </Section>
  )
}

export default DashboardStats
