import { FC } from 'react'
import Box from '@mui/system/Box'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'

const styles = {
  select: {
    height: '46px',
    background: '#FCFCFC',
    '& .MuiSelect-select': { mt: 1 },
    '& legend': { display: 'none' }
  },
  menuItem: {
    fontSize: '12px',
    color: '#232A31',
    py: 1.5,
    borderTop: '1px solid #EBEBEB'
  }
}

interface SellerCommissionRateProps {
  value: string
  enabled: boolean
  options: { label: string; value: string }[]
  setValue: (value: string) => void
}

const SellerCommissionRate: FC<SellerCommissionRateProps> = ({
  value,
  enabled,
  options,
  setValue
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string)
  }

  return (
    <FormControl fullWidth>
      <InputLabel
        shrink={false}
        id="rate-select-label"
        sx={{
          font: 'Montserrat',
          color: '#70777E',
          fontSize: '12px',
          top: `${value ? -17 : -3}px !important`
        }}
      >
        Seller Commission Rate *
      </InputLabel>
      <Select
        labelId="rate-select-label"
        id="rate-select"
        value={value}
        label="Seller Commission Rate *"
        onChange={handleChange}
        renderValue={(value) => options.find((v) => v.value === value)?.label}
        sx={styles.select}
      >
        {options.map((item) => (
          <MenuItem key={item.value} value={item.value} sx={styles.menuItem}>
            {enabled && (
              <ListItemIcon>
                <Box mr={1} component="img" src="/icons/usd-icon.svg" />
              </ListItemIcon>
            )}
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SellerCommissionRate
