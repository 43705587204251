/** @format */

import styled from 'styled-components'

export const StyledPasswordRules = styled.div`
  text-align: start;
  ul {
    color: var(--battleship);
    list-style: none;
    margin-bottom: 0;

    li {
      color: var(--battleship);
      width: max-content;
      display: flex;
      gap: 10px;
      justify-content: flex-start;
      align-items: center;

      & span {
        font-size: 0.625rem;
      }

      svg {
        color: var(--bonsai);
      }
    }
  }
`
