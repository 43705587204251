import { ReactComponent as VSLogo } from '@/assets/videoshops-logo.svg'
import { useStaticPageContext } from '@/context/StaticPageContext'
import { SiteWideTypes } from '@/types/SiteContext.interface'
import classNames from 'classnames'
import { FC, useEffect, useRef, useState } from 'react'
import Button from '../../Button/Button'
import HeroTabs from '../HomeHero/HeroTabs/HeroTabs'
import styles from './styles.module.scss'
import { tabs } from '@/constants/homepage'
import { isIOS } from 'react-device-detect'
import { APP_ROUTES } from '@/routes/routes'
import getSignInUrl from '@/utils/url.ts'

const HomeTopBar: FC = () => {
  const { siteType, setSiteType } = useStaticPageContext() as SiteWideTypes
  const topWrapperRef = useRef<HTMLDivElement>(null)
  const [isScrolled, setIsScrolled] = useState(false)

  const topWrapperStyles = classNames({
    [styles.topContent]: true,
    [styles.opaque]: isScrolled,
    [styles.isIOS]: isIOS
  })

  const handleScroll = () => {
    const scrollPosition = window.scrollY
    const topBarHeight = topWrapperRef.current?.offsetHeight
    if (!scrollPosition || !topBarHeight) return
    const opacity = scrollPosition / topBarHeight
    setIsScrolled(opacity >= 0.5)
  }

  const handleClick = () => {
    localStorage.removeItem('formData')
    localStorage.removeItem('currentStep')
    localStorage.removeItem('TOKEN')

    window.open(APP_ROUTES.SELECT_ACCOUNT, '_self')
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)
    return () => {
      window.removeEventListener('scroll', handleScroll, true)
    }
  }, [topWrapperRef])

  return (
    <div ref={topWrapperRef} className={topWrapperStyles}>
      <div className={styles.heroTopWrapper}>
        <div className={styles.logoTabsWrapper}>
          <VSLogo />
          <span className={styles.heroTabsDesktop}>
            <HeroTabs tab={siteType} setTab={setSiteType} tabs={tabs} />
          </span>
        </div>
        <div className={styles.heroActions}>
          <Button
            children="Sign in"
            onClick={() => (window.location.href = getSignInUrl())}
            primaryDefault
          />
          <Button children="Get Started Free!" onClick={handleClick} primary />
        </div>
      </div>
      <div className={styles.heroTabsMobile}>
        <HeroTabs tab={siteType} setTab={setSiteType} tabs={tabs} />
      </div>
    </div>
  )
}

export default HomeTopBar
