import React, { FC } from 'react'
import Box from '@mui/system/Box'
import Typography from '@mui/material/Typography'
import ProfileImageUpload from './ProfileImageUpload.tsx'

interface ProfileImageProps {
  croppingType: 'circle' | 'rectangle' | 'square'
  buttonLabel: string
  imageKey: string
  imageName: string
  info?: React.ReactNode
}

const ProfileImage: FC<ProfileImageProps> = ({
  croppingType,
  buttonLabel,
  imageKey,
  imageName,
  info,
}) => {
  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      borderRadius="24px"
      bgcolor="#FCFCFC"
      overflow="hidden"
      border="1px solid #EBEBEB"
      style={{ aspectRatio: '18.75 / 15' }}
    >
      <Box component="img" src="/icons/profile-image.svg" />
      <Box mt={1.5}>
        <Typography variant="xs_med">Upload image, (jpg or png)</Typography>
      </Box>
      <Box mt={2.5} fontSize={12} fontWeight={600} color="#232A31">
        <ProfileImageUpload
          croppingType={croppingType}
          buttonLabel={buttonLabel}
          imageKey={imageKey}
          imageName={imageName}
        />
      </Box>
      {info}
    </Box>
  )
}

export default ProfileImage
