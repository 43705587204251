interface Videos {
  people: string
  brand: string
}

export const videos: Videos = {
  people:
    'https://s3.amazonaws.com/videoshops-dev-static-videos/PersonalMRKT_Kendall_Medium.mp4',
  brand:
    'https://s3.amazonaws.com/videoshops-dev-static-videos/kat-radu-brand.mp4'
}

export const tabs = [
  {
    name: 'For Sellers',
    key: 'people'
  },
  {
    name: 'For Brands',
    key: 'brand'
  }
]
