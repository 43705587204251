import { FC } from 'react'
import { useParams } from 'react-router-dom'
import CreateAccount from './CreateAccount/CreateAccount.tsx'
import VerifyAccount from './VerifyAccount/VerifyAccount.tsx'
import VerifyCode from './VerifyCode/VerifyCode.tsx'
import MarketPlaceName from './MarketPlaceName/MarketPlaceName.tsx'
import MarketPlaceURL from './MarketPlaceURL/MarketPlaceURL.tsx'
import MarketPlaceDescription from './MarketPlaceDescription/MarketPlaceDescription.tsx'
import ProfileImage from './ProfileImage/ProfileImage.tsx'
import Categories from './Categories/Categories.tsx'
import ThemeColor from './ThemeColor/ThemeColor.tsx'
import Sellers from './Sellers/Sellers.tsx'
import Welcome from './Welcome/Welcome.tsx'

const SellerSteps: FC = () => {
  const { page } = useParams()

  switch (page) {
    case 'create-account':
      return <CreateAccount />
    case 'verify-account':
      return <VerifyAccount />
    case 'verify-code':
      return <VerifyCode />
    case 'name':
      return <MarketPlaceName />
    case 'link':
      return <MarketPlaceURL />
    case 'description':
      return <MarketPlaceDescription />
    case 'profile-image':
      return <ProfileImage />
    case 'categories':
      return <Categories />
    case 'theme':
      return <ThemeColor />
    case 'welcome':
      return <Welcome />
    default:
      return <Sellers />
  }
}

export default SellerSteps
