import { FC } from 'react'
import Box from '@mui/system/Box'
import ButtonBase from '@mui/material/ButtonBase'

interface CategoryProps {
  value: string
  label: string
  selected: boolean
  disabled: boolean
  onClick: (value: string) => void
}

const Category: FC<CategoryProps> = ({
  value,
  label,
  selected,
  disabled,
  onClick
}) => {
  return (
    <ButtonBase
      disableRipple
      disabled={disabled}
      sx={{
        m: 1,
        padding: '3px',
        borderRadius: '40px',
        alignItems: 'center',
        border: '1px solid',
        bgcolor: selected ? 'buttons.primary' : 'surfaces.frost',
        borderColor: selected ? 'buttons.primary' : 'borders.glacier',
        color: selected ? 'text.snow' : 'buttons.primary',
        opacity: disabled ? 0.3 : 1
      }}
      onClick={() => onClick(value)}
    >
      <Box
        width={44}
        height={44}
        borderRadius="50%"
        bgcolor="#f1f1f1"
        sx={{
          backgroundImage: `url("/homeappimages/affinities/${value}.png")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />
      <Box px={1} typography="xs_bold">
        {' '}
        {label}
      </Box>
    </ButtonBase>
  )
}

export default Category
