import { FC } from 'react'
import Section from '../../Section/Section'
import NewTitle from '../../Title/Title'
import styles from './styles.module.scss'
import SlideAlert from './SlideAlert/SlideAlert'
import { isIOS } from 'react-device-detect'
import { ReactComponent as WhiteShelf } from '@/assets/icons/white-shelve.svg'

import classnames from 'classnames'

const SlideInAlert: FC = () => {
  const isMobile = window.innerWidth < 768
  const title = (
    <div className="breakTitle">
      {!isMobile ? (
        <span data-line="Start Selling in Minutes!">
          Start Selling in Minutes!
        </span>
      ) : (
        <>
          <span data-line="Start Selling">Start Selling</span>
          <span data-line="in Minutes!">in Minutes!</span>
        </>
      )}
    </div>
  )

  const subtitleText = (
    <>
      <span className={styles.subText}>
        <p>Share your Shoppable</p>
        <WhiteShelf />
      </span>
      <p> With all your friends in one-click</p>
    </>
  )
  return (
    <Section
      className={classnames(
        styles.sectionSlideInAlertWrapper,
        isIOS && styles.isIOS
      )}
    >
      <div className={styles.slideInAlertBGV}></div>

      <div className={styles.slideInAlertContainer}>
        <span className={styles.titleWrapper}>
          <NewTitle
            children={title}
            titleColor="#fff"
            subText={subtitleText}
            secondaryTitle
          />
        </span>
        <div className={styles.alertDeviceWrapper}>
          <div className={styles.alertDevice}>
            <SlideAlert />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SlideInAlert
