import * as Yup from 'yup'
import { passwordValidation } from './PasswordValidation'

const vendorSetupFormSchema = Yup.object().shape({
  name: Yup.string().trim().required('Your Brand Name is required'),
  key: Yup.string().trim().required('Your Videoshops URL is required'),
  terms: Yup.boolean().oneOf([true], 'Please agree to the terms.'),
  description: Yup.string().nullable().trim(),
  profileImage: Yup.string().nullable().trim(),
  backgroundImage: Yup.string().nullable().trim(),
  affinities: Yup.array()
    .required(
      'Add at least one affinity to your marketplace to increase sales.'
    )
    .min(1, 'Add at least one affinity to your marketplace to increase sales.'),
  currency: Yup.string().trim(),
  commissionRate: Yup.number().required('Commission Rate is required'),
  email: Yup.string()
    .trim()
    .required('Email is required')
    .matches(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
      'Your Email does not seem to be a valid email.'
    ),
  password: passwordValidation,
  confirmPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  phone: Yup.string()
    .trim()
    .matches(/^-?(0|[1-9]\d*)$/, 'Invalid phone number format')
    .required('Phone number is required'),
  firstName: Yup.string().trim().required('First Name is required'),
  lastName: Yup.string().trim().required('Last Name is required'),
  brandName: Yup.string().trim().required('Brand Website is required'),
  brandWebsite: Yup.string().trim().required('Brand Website is required')
})

export default vendorSetupFormSchema
