import { FC, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import Box from '@mui/system/Box'
import Container from '@mui/system/Container'
import TopBar from '@/shared/components/TopBar/TopBar.tsx'
import BackButton from '@/shared/components/BackButton/BackButton.tsx'
import StepHeader from '@/components/OnboardingCompNew/StepHeader/StepHeader.tsx'
import formStyles from '@/components/Form/styles.module.scss'
import UploadImage from '../../CommonSteps/ProfileImage/UploadImage.tsx'
import styles from '../../styles.module.scss'
import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext.tsx'
import { OnBaordingProps } from '@/components/OnboardingComp/types.ts'
import { appConfig } from '@/app-config.ts'
import { putRequest } from '@/api/api.ts'
import { toast } from 'react-toastify'
import CustomToast from '@/components/Toaster/Toaster.tsx'
import WhatIsThis from '@/shared/components/InfoModal/WhatIsThis.tsx'
import FinalButton from '@/videoshops-shared/components/FinalButton/FinalButton.tsx'
import { OnboardingStep } from '@/constants/onboarding.constant.ts'
import useOnboarding from '../../useOnboarding.ts'

const imageKey = 'heroImageKey'
const imageName = 'heroImage'

const BrandHeroImage: FC = () => {
  const [loading, setLoading] = useState(false)
  const { saveOnboardingData } = useOnboarding()

  const navigate = useNavigate()
  const form = useForm({
    mode: 'onChange',
    defaultValues: { name: '' }
  })
  const { formData } = useContext(OnboardingContext) as OnBaordingProps

  const onSubmit = () => {
    const isUploaded = formData.marketplace.heroImageKey === localStorage.getItem('heroImageKey')
    if (!isUploaded && formData.marketplace.heroImageKey) {
      setLoading(true)
      const createUrl = `${appConfig.API_BASEL_URL}/vendor/hero-image`
      const body = {
        heroImageKey: formData.marketplace.heroImageKey,
        heroImage: formData.marketplace.heroImage
      }
      const headers = { Authorization: localStorage.getItem('TOKEN') }
      return putRequest(createUrl, body, { headers }).then(async (data) => {
        setLoading(false)
        if (data.data?.error) {
          toast(<CustomToast type="error" text={data.data?.error} />)
        }
        localStorage.setItem('heroImageKey', formData.marketplace.heroImageKey)
        const saved = await saveOnboardingData(
          OnboardingStep.BRAND_HERO_IMAGE,
          localStorage.getItem('TOKEN') || ''
        )
        if (saved) {
          navigate('/brand/setup/affinities')
        }
      })
    } else {
      navigate('/brand/setup/affinities')
    }
  }

  return (
    <div className={styles.stepPage}>
      <TopBar />
      <Box mt={{ xs: 1.5, md: 3.5 }}>
        <Container maxWidth="lg">
          <BackButton to="/brand/setup/brand-logo" />
        </Container>
        <Container maxWidth={false} sx={{ maxWidth: 411 }}>
          <Box mt={3}>
            <StepHeader title="Merchant Sign Up">Upload Your Brand Hero Image</StepHeader>
          </Box>
          <Box mt={1}>
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className={formStyles.formContainer}>
                <UploadImage
                  croppingType="square"
                  buttonLabel="Select hero image"
                  imageKey={imageKey}
                  imageName={imageName}
                  info={
                    <WhatIsThis
                      mt={2.5}
                      title="Your Brand Hero Image"
                      description="We will display your Brand Hero Image in brand tiles and on other pages throughout our platform."
                    />
                  }
                />
                <Box mt={4}>
                  <FinalButton
                    disabled={loading}
                    type="submit"
                    variant="primary"
                    loading={loading}
                    full
                  >
                    Next
                  </FinalButton>
                </Box>
              </form>
            </FormProvider>
          </Box>
        </Container>
      </Box>
    </div>
  )
}

export default BrandHeroImage
