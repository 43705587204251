import SvgIcon from '@mui/material/SvgIcon'
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon'

export default function Routing(props: SvgIconProps) {
  return (
    <SvgIcon fill="none" {...props}>
      <svg
        width="23"
        height="22"
        viewBox="0 0 23 22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 4H11.4344C14.4816 4 16.0053 4 16.5836 4.54729C17.0836 5.02037 17.3051 5.71728 17.1702 6.39221C17.014 7.17302 15.7701 8.05285 13.2823 9.81253L9.21772 12.6875C6.7299 14.4471 5.48599 15.327 5.32984 16.1078C5.19486 16.7827 5.41642 17.4796 5.91636 17.9527C6.49474 18.5 8.01836 18.5 11.0656 18.5H12M7.5 4C7.5 5.65685 6.15685 7 4.5 7C2.84315 7 1.5 5.65685 1.5 4C1.5 2.34315 2.84315 1 4.5 1C6.15685 1 7.5 2.34315 7.5 4ZM21.5 18C21.5 19.6569 20.1569 21 18.5 21C16.8431 21 15.5 19.6569 15.5 18C15.5 16.3431 16.8431 15 18.5 15C20.1569 15 21.5 16.3431 21.5 18Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}
