import Typography from '@mui/material/Typography'

interface StepCounterProps {
  step: number
  total: number
}

export default function StepCounter({ step, total }: StepCounterProps) {
  return (
    <Typography
      sx={{ typography: { xs: '2xs_bold', md: 'xs_bold' } }}
      color="ash"
    >
      Step {step} of {total}
    </Typography>
  )
}
