import { FC } from 'react'
import PreviewComponent from '@/components/PreviewContainer/PreviewContainer'
import TopBarOld from '@/components/TopBar/TopBarOld.tsx'
import creatorSetupFormSchema from '@/components/Form/Validations/CreatorSetupForm'
import styles from './styles.module.scss'
import FormComp from '@/components/Form/'
import { ToastContainer } from 'react-toastify'
import { LiveChatWidget } from '@livechat/widget-react'
import PasswordProtectHOC from '../HOC/PasswordProtectHOC'

const LIVE_CHAT_ID = import.meta.env.VITE_LIVE_CHAT_ID

const CreatorSetupComp: FC = () => {
  // console.log(
  //   'Creator Context',
  //   JSON.stringify(useOnboardingContext(), null, 2)
  // )
  return (
    <div className={styles.creatorSetupPageContainer}>
      <TopBarOld />
      <ToastContainer
        theme="light"
        autoClose={2000}
        newestOnTop={false}
        hideProgressBar={true}
        limit={2}
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
      />
      <div className={styles.PreviewColumn}>
        <PreviewComponent />
      </div>
      <div className="container-scroll">
        <div className={styles.formColumn}>
          <div className={styles.formInnerContent}>
            <FormComp
              apiEndpoint=""
              data={{}}
              formSlug={''}
              validationSchema={creatorSetupFormSchema}
            />
          </div>
        </div>
      </div>
      {/* {LIVE_CHAT_ID && <LiveChatWidget license={LIVE_CHAT_ID} group="0" />} */}
    </div>
  )
}

export default PasswordProtectHOC(CreatorSetupComp)
