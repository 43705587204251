import { FC } from 'react'
import styles from '../../styles.module.scss'
import SignUpForm from './SignUpForm/SignUpForm'

const VendorSignupForm: FC = () => {
  return (
    <div className={styles.stepContainer}>
      <div className={styles.vendorStepContent}>
        <h1>Connect to VideoShops</h1>
        <SignUpForm />
      </div>
    </div>
  )
}

export default VendorSignupForm
