import { FC } from 'react'
import Box from '@mui/system/Box'
import Alert from '@/components/OnboardingCompNew/SellerSteps/MarketPlaceURL/Alert.tsx'

interface URLSuggestionsProps {
  isValidURL: boolean
  values: string[]
  setUrl: (value: string) => void
}

const URLSuggestions: FC<URLSuggestionsProps> = ({
  isValidURL,
  values,
  setUrl
}) => {
  return values.length > 0 ? (
    <Box mt={0.5} borderRadius="6px" border="1px solid #ff3a44">
      <Box
        mt={2}
        textAlign="center"
        fontSize={14}
        fontWeight={700}
        color="#FF3A44"
      >
        <Alert variant="error" title="Link unavailable" />
      </Box>
      <Box
        mt={2}
        mb={2}
        textAlign="center"
        fontSize={13}
        fontWeight={500}
        color="#70777E"
      >
        Here are a few suggestions. <br />
        You can also choose one of your own
      </Box>
      {values.map((value) => (
        <Box
          px={1.5}
          py={2}
          fontSize={12}
          fontWeight={500}
          borderTop="1px solid #EBEBEB"
          color="#232A31"
          sx={{ cursor: 'pointer' }}
          onClick={() => setUrl(value)}
        >
          {value}
        </Box>
      ))}
    </Box>
  ) : !isValidURL ? (
    <Box
      mt={0.5}
      py={1.5}
      px={1}
      borderRadius="6px"
      border="1px solid #2F3B46"
      textAlign="center"
      fontSize={12}
      fontWeight={500}
      color="#FF3A44"
    >
      Make sure to remove any special characters (@#$%^&*') or spaces in your
      link if your name failed
    </Box>
  ) : null
}

export default URLSuggestions
