import { FC } from 'react'
import styles from '../../styles.module.scss'
import SelectComp from '@/components/Form/Fields/Select/Select'
import {
  commissionRateObject,
  currencyObject
} from '@/constants/commisionCurrency'

const CurrencyCommision: FC = () => {
  return (
    <div className={styles.stepContainer}>
      <h1>
        Select <span className="vs-primary-color">Seller Commission</span>
      </h1>
      {/* <p>Select Currency & Commission Split</p> */}
      <div>
        {/* <SelectComp
          colW={12}
          label="Enter Currency"
          name="currency"
          optionData={currencyObject}
          required
        /> */}
        <SelectComp
          colW={12}
          label="Select Seller Commission"
          name="commissionRate"
          optionData={commissionRateObject}
          required
        />
        <span className={styles.requiredWrapper}>
          <span className="required-asterix">*</span>Required
        </span>
        <span className={styles.commissionInfoWrapper}>
          <span className={styles.commissionInfoIconWrapper}>
            <span className="info-icon">i</span>
            <span>This rate can be adjusted every 30 days.</span>
          </span>
          <p>
            In addition, VideoShops deducts a 10% platform fee on the gross
            transaction amount.
          </p>
        </span>
      </div>
    </div>
  )
}

export default CurrencyCommision
