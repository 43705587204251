import { FC } from 'react'
import TopBarOld from '@/components/TopBar/TopBarOld.tsx'
import styles from './styles.module.scss'
import { useOnboardingContext } from '@/hooks/useOnboardingForm'
import { ToastContainer } from 'react-toastify'
import PreviewComponent from '../PreviewContainer/PreviewContainer'
import vendorSetupFormSchema from '../Form/Validations/VendorSetupForm'
import FormComp from '../Form'
import { LiveChatWidget } from '@livechat/widget-react'
const LIVE_CHAT_ID = import.meta.env.VITE_LIVE_CHAT_ID
const VendorSetupComp: FC = () => {
  const { currentStep } = useOnboardingContext()
  const isMarketingSteps = currentStep > 2
  const previewContainerStyles = isMarketingSteps
    ? { display: 'block' }
    : { display: 'none' }

  // console.log('Vendor Context', JSON.stringify(useOnboardingContext(), null, 2))

  return (
    <div className={styles.vendorSetupPageContainer}>
      <TopBarOld />
      <ToastContainer
        theme="light"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        limit={2}
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
      />
      <div className={styles.PreviewColumn} style={previewContainerStyles}>
        <PreviewComponent />
      </div>
      <div className="container-scroll">
        <div className={styles.formColumn}>
          <div className={styles.formInnerContent}>
            <FormComp
              apiEndpoint=""
              data={{}}
              formSlug={''}
              validationSchema={vendorSetupFormSchema}
            />
          </div>
        </div>
      </div>
      {/* {LIVE_CHAT_ID && <LiveChatWidget license={LIVE_CHAT_ID} group="0" />} */}
    </div>
  )
}

export default VendorSetupComp
