const googleAnalyticsEvents = {
  account: {
    create: { category: 'Account', action: 'Create Account' },
    signUp: { category: 'Account', action: 'Sign Up' },
  },
  shelf: {
    create: { category: 'Shelf', action: 'Create Shelf' },
    addProduct: { category: 'Shelf', action: 'Add Product to Shelf' },
    removeProduct: { category: 'Shelf', action: 'Remove Product from Shelf' },
    uploadVideo: { category: 'Shelf', action: 'Upload Video' },
  },
  cart: {
    addProduct: { category: 'Cart', action: 'Add Product to Cart' },
    removeProduct: { category: 'Cart', action: 'Remove Product from Cart' },
  },
  checkout: {
    goToCheckout: { category: 'Checkout', action: 'Go To Checkout' },
    setGuest: { category: 'Checkout', action: 'Add Guest Info' },
    setUser: { category: 'Checkout', action: 'Log In From Cart' },
    confirmDelivery: { category: 'Checkout', action: 'Confirm Delivery Info' },
    confirmShipping: { category: 'Checkout', action: 'Confirm Shipping Info' },
    confirmPayment: { category: 'Checkout', action: 'Confirm Payment Info' },
    placeOrder: { category: 'Checkout', action: 'Place Order' },
  },
}

export default googleAnalyticsEvents
