import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/system/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import TopBar from '@/shared/components/TopBar/TopBar.tsx'
import Container from '@mui/system/Container'
import BackButton from '@/shared/components/BackButton/BackButton.tsx'
import StepHeader from '../../StepHeader/StepHeader.tsx'
import styles from '../../styles.module.scss'
import { appConfig } from '@/app-config.ts'
import { putRequest } from '@/api/api.ts'
import useCategories from '@/hooks/useCategories.ts'
import StepCounter from '@/components/OnboardingCompNew/StepCounter.tsx'
import ProgressBar from '../../ProgressBar.tsx'
import Categories from '@/shared/components/Categories/Categories.tsx'
import FinalButton from '@/videoshops-shared/components/FinalButton/FinalButton.tsx'
import useOnboarding from '../../useOnboarding.ts'
import { OnboardingStep } from '@/constants/onboarding.constant.ts'

const CategoriesStep: FC = () => {
  const { categories, loading: categoriesLoading } = useCategories()
  const initCategories = JSON.parse(localStorage.getItem('categories') || '[]')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { saveOnboardingData } = useOnboarding()
  const [selected, setSelected] = useState<string[]>(initCategories)

  const onSubmit = async () => {
    const createUrl = `${appConfig.API_BASEL_URL}/user/recommendation-categories`
    const body = { recommendationCategories: selected }
    const headers = { Authorization: localStorage.getItem('TOKEN') }
    setLoading(true)
    const response: OnboardingResponseData = await putRequest(createUrl, body, {
      headers
    })
    setLoading(true)
    if (response.status === 200) {
      const saved = await saveOnboardingData(
        OnboardingStep.AFFINITIES,
        localStorage.getItem('TOKEN') || '',
        'creator'
      )
      if (saved) {
        navigate('/creator/setup/theme')
      }
    }
  }

  useEffect(() => {
    localStorage.setItem('categories', JSON.stringify(selected))
  }, [selected])

  return (
    <div className={styles.stepPage}>
      <TopBar />
      <ProgressBar value={(100 / 8) * 7} />
      <Box mt={{ xs: 1.5, md: 3.5 }}>
        <Container maxWidth="lg">
          <Box height={36} display="flex" alignItems="center" justifyContent="space-between">
            <BackButton to="/creator/setup/profile-image" />
            <StepCounter step={7} total={8} />
          </Box>
        </Container>
        <Container maxWidth={false} sx={{ maxWidth: 459 }}>
          <Box mt={2}>
            <StepHeader>What Do You Love?</StepHeader>
          </Box>
          <Box mt={2} textAlign="center">
            <Typography variant="md_med">Pick up to 5</Typography>
          </Box>
          <Box mt={3.5}>
            {categoriesLoading ? (
              <Box textAlign="center" m={4}>
                <CircularProgress />
              </Box>
            ) : (
              <Categories limit={5} list={categories} selected={selected} onChange={setSelected} />
            )}
            <Box mt={4}>
              <FinalButton
                disabled={loading || selected.length === 0}
                variant="primary"
                full
                onClick={onSubmit}
              >
                Next
              </FinalButton>
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  )
}

export default CategoriesStep
