import { FC } from 'react'
import { OnboardingProvider } from '@/context/OnboardingContext/OnboardingProvider'
import VendorSetupComp from '@/components/OnboardingCompNew/BrandSetupComp.tsx'

const BrandSetupPage: FC = () => {
  return (
    <OnboardingProvider siteType="vendor">
      <VendorSetupComp />
    </OnboardingProvider>
  )
}

export default BrandSetupPage
