import { FC, ReactElement, ReactNode, useState } from 'react'
import { InputStyled } from './input.styled'
import { UseFormRegister, DeepMap, FieldError } from 'react-hook-form'
import classNames from 'classnames'

export type InputType = 'text' | 'password'

export enum InputTextSize {
  SMALL,
  MEDIUM,
  LARGE
}

export enum InputTheme {
  DEFAULT,
  DARK
}

export interface InputProps {
  value?: string | number
  textValue?: string
  label?: string
  field: string
  type?: InputType
  register: UseFormRegister<any>
  trigger?: {
    triggerMethod: UseFormTrigger<any>
    fieldsToTriggerOnChange: FieldPath<any>[]
  }
  hintText?: string
  warningText?: string
  prefix?: string
  errors?: DeepMap<any, FieldError>
  required?: boolean
  centered?: boolean
  disabled?: boolean
  textSize?: InputTextSize
  maxLength?: number
  height?: string
  maxWidth?: string
  icon?: ReactElement
  button?: ReactNode
  className?: string
  autoComplete?: string
  checkForProfanity?: (input: string) => boolean
  forwardedRef?: (e: any) => void
  setError?: (name: string, error: ErrorType) => void
  onKeyDown?: (e: any) => void
  onKeyUp?: (e: any) => void
  onInputClicked?: (e: any) => void
  onInput?: (e: any) => void
  onPaste?: (e: any) => void
  onIconClick?: (e: any) => void
}

const Input: FC<InputProps> = ({
  value,
  textValue,
  label,
  field,
  type = 'text',
  register,
  trigger,
  hintText,
  warningText,
  prefix,
  errors,
  required = false,
  centered = false,
  disabled = false,
  textSize = InputTextSize.SMALL,
  maxLength,
  height,
  maxWidth,
  icon,
  button,
  onKeyDown,
  onKeyUp,
  setError,
  checkForProfanity,
  className,
  autoComplete = 'on',
  forwardedRef,
  onInputClicked,
  onInput,
  onPaste,
  onIconClick
}: InputProps) => {
  const { onChange, onBlur, name, ref } = register(field as any, { required })
  const [inputValue, setInputValue] = useState(textValue)

  const handleRef = (e: any) => {
    ref(e) // This applies the ref from react-hook-form
    if (forwardedRef) {
      forwardedRef(e) // This applies any additional ref handling
    }
  }

  const onInputChange = (e: any): void => {
    setInputValue(e.target.value)
    onChange(e).then(() => {
      if (checkForProfanity && setError) {
        if (checkForProfanity(e.target.value)) {
          setError(name, { type: 'manual', message: profanityErrorStr })
        }
      }

      if (trigger && trigger.triggerMethod && trigger.fieldsToTriggerOnChange) {
        trigger.triggerMethod(trigger.fieldsToTriggerOnChange).then()
      }
    })
  }

  return (
    <InputStyled maxWidth={maxWidth} height={height} onClick={onInputClicked}>
      <div className="input-styled">
        <div className={classNames('input-container', { disabled })}>
          <div
            className={classNames('input-wrapper', {
              'error-border': errors?.[field],
              'with-value': value,
              'input-disabled': disabled
            })}
          >
            {prefix && <span className="input-prefix">{prefix}</span>}
            <input
              className={classNames(className, {
                'input-text-small': textSize === InputTextSize.SMALL,
                'input-text-medium': textSize === InputTextSize.MEDIUM,
                'without-label': !label,
                'text-center': centered,
                'with-prefix': prefix
              })}
              type={type}
              onChange={onInputChange}
              onBlur={onBlur}
              name={name}
              maxLength={maxLength}
              disabled={disabled}
              ref={handleRef}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              autoComplete={autoComplete ?? 'on'}
              value={inputValue}
              onInput={onInput}
              onPaste={onPaste}
            />
            <label
              className={classNames({
                'has-input-value': textValue || value || prefix,
                required
              })}
              htmlFor={field}
            >
              {label}
            </label>
          </div>

          {button}
          {icon && (
            <div
              className={classNames(
                'icon',
                onIconClick && !disabled ? 'active' : ''
              )}
              onClick={onIconClick}
            >
              {icon}
            </div>
          )}
        </div>
        {hintText && <p className="hint-text">{hintText}</p>}
        {warningText && <p className="warning-text">{warningText}</p>}
        {errors && <p className="input-error">{errors?.[field]?.message}</p>}
      </div>
    </InputStyled>
  )
}

export default Input
