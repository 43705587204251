import axios from 'axios'

export const initAxiosConfiguration = (): void => {
  try {
    axios.defaults.headers.common['Content-Type'] = 'application/json'

    axios.interceptors.request.use(
      (config) => {
        if (config.url && config.url.includes('amazonaws.com')) return config

        const token = localStorage.getItem('accessToken') ?? ''
        const idtoken = localStorage.getItem('idToken') ?? ''

        config.headers['Authorization'] = token
        config.headers['idtoken'] = idtoken

        return config
      },
      (error) => Promise.reject(error)
    )
  } catch (error) {
    console.error(`Cannot initialize Axios ${error}`)
  }
}
