export const brandPhoneStack = [
  {
    name: 'ayla',
    videoTitle: 'My must-have Beaauty Products'
  },
  {
    name: 'olura',
    videoTitle: 'My must-have Beaauty Products'
  },
  {
    name: 'mini_bloom',
    videoTitle: 'My must-have Beaauty Products'
  },
  {
    name: 'pyt_beauty',
    videoTitle: 'My must-have Beaauty Products'
  },
  {
    name: 'darya_hope',
    videoTitle: 'My must-have Beaauty Products'
  },
  {
    name: 'six_golden',
    videoTitle: 'My must-have Beaauty Products'
  },
  {
    name: 'idew_care',
    videoTitle: 'My must-have Beaauty Products'
  }
]

export const personalPhoneStack = [
  {
    name: 'candice',
    videoTitle: 'My must-have beaauty products'
  },
  {
    name: 'amelia',
    videoTitle: 'My must-have Beaauty Products'
  },
  {
    name: 'tia',
    videoTitle: 'My favorite brow products'
  },
  {
    name: 'tyler',
    videoTitle: 'Quick makeup'
  }
  // {
  //   name: 'kendall',
  //   videoTitle: 'My daily routine'
  // },
  // {
  //   name: 'tia',
  //   videoTitle: 'My must-have Beaauty Products'
  // },
  // {
  //   name: 'joanna',
  //   videoTitle: 'My favorite brow products'
  // }
]
