import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useContext, useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import styles from './styles.module.scss'
import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext'
import { CreatorObject } from '@/components/OnboardingComp/OnboardingData/creator'
import Button from '../Button/Button'
import { getRequest, postRequest } from '@/api/api'
import { generateCreatorOnboardingSteps, generateVendorOnboardingSteps } from './usForm'
import { Link } from 'react-router-dom'
import CheckBox from './Fields/CheckBox/CheckBox'
import { VendorObject } from '../OnboardingComp/OnboardingData/vendor'
import { Id, toast } from 'react-toastify'
import { AxiosResponse } from 'axios'
import { appConfig } from '@/app-config'
import classNames from 'classnames'
import CustomToast from '../Toaster/Toaster'
import { APP_ROUTES } from '@/routes/routes'
import { BRAND_ONBOARDING_STEPS, SELLER_ONBOARDING_STEPS } from '@/constants/onboarding.constant'

const FormComp: React.FC<FormProps> = ({
  data,
  formSlug,
  validationSchema,
  apiEndpoint,
  showTerms,
}) => {
  const {
    currentStep,
    setCurrentStep,
    stepInputs,
    formData: formDataContext,
    siteType,
    setUserData,
    onboardActions,
    isLoading,
    setIsLoading,
  } = useContext(OnboardingContext) as OnBaordingState
  const [isStepRequired, setStepRequired] = useState(false)
  const [toggleNextButton, setToggleNextButton] = useState(false)
  const [toggleBackButton, setToggleBackButton] = useState(false)
  const formData = { ...formDataContext }
  const isCreator = siteType === 'creator'

  const isPreCreatorSignUpForm = isCreator && currentStep < SELLER_ONBOARDING_STEPS.SELLER_SIGN_UP
  const isPostVendorSignUp = !isCreator && currentStep > BRAND_ONBOARDING_STEPS.BRAND_SMS_VALIDATION
  const isVendorCompletd = currentStep === BRAND_ONBOARDING_STEPS.BRAND_STORE_URL
  const creatorDefaultObject = {
    ...CreatorObject.marketplace,
    ...CreatorObject.user,
  }
  const termsVissible =
    (currentStep === SELLER_ONBOARDING_STEPS.SELLER_SIGN_UP && isCreator) ||
    (siteType === 'vendor' && currentStep === BRAND_ONBOARDING_STEPS.BRAND_SIGN_UP)
  const agreementText = isCreator ? 'Sellers Agreement' : 'Brand Marketplace Agreement'
  const agreementURL = isCreator
    ? APP_ROUTES.SELLER_AGREEMENT
    : APP_ROUTES.BRAND_MARKETPLACE_AGREEMENT
  const vendorDefaultObject = {
    ...VendorObject.marketplace,
    ...VendorObject.user,
  }

  const form = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: isCreator ? creatorDefaultObject : vendorDefaultObject,
  })

  const { trigger, getValues, setValue } = form

  const formRef = useRef<HTMLFormElement | null>(null)
  let toastId: Id | undefined

  useEffect(() => {
    const requiredStepsForCreator = [1, 4, 5]
    const requiredStepsForVendor = [1, 2, 3, 7, 8, 9, 10]
    const nextButtonForVendor = [1, 3, 4, 5, 6, 7, 8, 9, 10]
    const localStorageFormData = localStorage.getItem('formData')
    if (localStorageFormData) {
      const formDataCopy = { ...JSON.parse(localStorageFormData) }
      if (formDataCopy.data.marketplace.name && !getValues('name')) {
        setValue('name', formDataCopy.data.marketplace.name)
      }
      if (formDataCopy.data.marketplace.key && !getValues('key')) {
        setValue('key', formDataCopy.data.marketplace.key)
      }
    }
    if (getValues('confirmPassword') === undefined) {
      setValue('confirmPassword', null)
    }
    setStepRequired(
      isCreator
        ? requiredStepsForCreator.includes(currentStep)
        : requiredStepsForVendor.includes(currentStep),
    )
    setToggleNextButton(
      isCreator ? isPreCreatorSignUpForm : nextButtonForVendor.includes(currentStep),
    )
    setToggleBackButton(
      isCreator
        ? isPreCreatorSignUpForm && currentStep > 1
        : isPostVendorSignUp && currentStep >= 4,
    )
  }, [currentStep, isCreator, setToggleNextButton, setToggleBackButton])

  const onSubmit = async (postURL: string, body?: any, authorizationHeader?: {}) => {
    try {
      toastId = toast(<CustomToast />)
      const headers = authorizationHeader ? { ...authorizationHeader } : undefined
      const onboardingFormResponse: AxiosResponse<OnboardingResponseData> = await postRequest(
        `${appConfig.API_BASEL_URL}${postURL}`,
        body,
        {
          headers,
        },
      )
      if (onboardingFormResponse) {
        const dataObject = onboardingFormResponse.data.data
        let successMessage = 'Request Was Succesful'
        if (postURL === '/vendor') {
          const authToken: string = dataObject.auth.authToken
          if (authToken) {
            localStorage.setItem('TOKEN', authToken)

            // TODO Calling the resend-code api to send the SMS
            // for phone verification during vendor sign up.
            // For creator sign up, this happens in client-app.
            // Ideally this can happen in the backend for both creator & vendor.
            const smsCallheaders = {
              headers: {
                Authorization: authToken,
              },
            }

            await getRequest(`${appConfig.API_BASEL_URL}/user/resend-code`, smsCallheaders)
          }
          const userObject = dataObject.user
          successMessage = `${userObject.firstName}, congrats! An account has been created.`

          setUserData((prevState) => ({
            ...prevState,
            isActive: userObject.active,
            hasMarketPlace: userObject.marketplaceId,
            phone: userObject.phone,
          }))
          setTimeout(() => {
            setIsLoading(false)
            onValidInput()
          }, 1000)
        }

        if (postURL === '/express/creator') {
          const dataObject = { ...onboardingFormResponse.data.data }

          successMessage = `${dataObject.user.firstName} Congrats! An account has been created. Redirecting to phone verification.`
          const authToken = dataObject.auth.authToken
          if (authToken) {
            setTimeout(() => {
              window.location.href = `${appConfig.CLIENT_APP_URL}?TOKEN=${authToken}`
            }, 1000)
            localStorage.removeItem('currentStep')
            localStorage.removeItem('formData')
            localStorage.removeItem('TOKEN')
            localStorage.removeItem('protectedPagePassword')
          }
        }

        if (postURL === '/marketplace/vendor') {
          successMessage = `Congrats! An account has been created. Redirecting to your account.`
          const authToken = localStorage.getItem('TOKEN')
          if (authToken) {
            setTimeout(() => {
              window.location.href = `${appConfig.CLIENT_APP_URL}?TOKEN=${authToken}`
            }, 1000)
          }
          localStorage.removeItem('currentStep')
          localStorage.removeItem('formData')
          localStorage.removeItem('TOKEN')
          localStorage.removeItem('protectedPagePassword')
        }
        toast.dismiss(toastId)
        toast(<CustomToast type="success" text={successMessage} />)
      }
    } catch (error: any) {
      if (error.response) {
        toast.dismiss(toastId)
        const errorMessage = error.response.data.error
          ? error.response.data.error
          : 'Error with Submission'
        toast(<CustomToast type="error" text={errorMessage} />)
      }
    }
  }

  const handleNext = async () => {
    setIsLoading(true)
    // setTimeout(() => {
    //   setIsLoading(false)
    // }, 1000)

    const values = getValues()
    const isFormValid = await trigger(stepInputs)
    // console.log('isFormValid', isFormValid, stepInputs, values)
    if (stepInputs && stepInputs.includes('key') && values?.key === undefined) {
      form.setError('key', {
        type: 'manual',
        message: 'Key is required',
      })
      setIsLoading(false)
      return
    }
    if (
      stepInputs &&
      stepInputs.includes('affinities') &&
      (getValues('affinities') === null || getValues('affinities')?.length === 0)
    ) {
      setIsLoading(false)
      return
    }

    if (stepInputs.includes('name') && (!values.name || !values.name.trim())) {
      form.setError('name', {
        type: 'manual',
        message: 'Name is required',
      })
      setIsLoading(false)
      return
    }

    if (
      stepInputs &&
      stepInputs.includes('commissionRate') &&
      values?.commissionRate === undefined
    ) {
      form.setError('commissionRate', {
        type: 'manual',
        message: 'Commission Rate is required',
      })
      setIsLoading(false)
      return
    }

    // if (
    //   stepInputs &&
    //   stepInputs.includes('description') &&
    //   !values?.description &&
    //   !getValues('description')
    // ) {
    //   form.setError('description', {
    //     type: 'manual',
    //     message: 'Description is required'
    //   })
    //   setIsLoading(false)
    //   return
    // }

    if (
      stepInputs &&
      stepInputs?.includes('terms') &&
      values?.term === undefined &&
      !getValues('terms')
    ) {
      form.setError('terms', {
        type: 'manual',
        message: 'You must agree to the terms',
      })
      setIsLoading(false)
      return
    }

    if (
      stepInputs &&
      !isFormValid &&
      (stepInputs.includes('commisionRate') || stepInputs.includes('currency'))
    ) {
      setIsLoading(false)
      return
    }

    if (siteType === 'vendor' && currentStep === BRAND_ONBOARDING_STEPS.BRAND_SIGN_UP) {
      if (getValues('password') && getValues('password').length > 0) {
        formData.user.password = getValues('password')
      }
      onSubmit('/vendor', formData.user)
      setIsLoading(false)
    } else if (siteType === 'vendor' && currentStep === BRAND_ONBOARDING_STEPS.BRAND_STORE_URL) {
      const parsedRate = parseInt(`${formData.marketplace.commissionRate}`, 10)
      if (!isNaN(parsedRate)) {
        const marketplaceData = {
          ...formData.marketplace,
          commissionRate: parsedRate,
        }

        const filteredMarketplace = Object.fromEntries(
          Object.entries(marketplaceData).filter(([_, value]) => value !== null),
        )
        const authorizationHeader = {
          Authorization: localStorage.getItem('TOKEN') || '',
        }

        onSubmit('/marketplace/vendor', filteredMarketplace, authorizationHeader)
        setIsLoading(false)
      }
    } else {
      onValidInput()
      setIsLoading(false)
    }
  }

  const handleCreatorSubmit = async () => {
    setIsLoading(true)
    const values = getValues()
    const isFormValid = await trigger(stepInputs)

    if (
      stepInputs &&
      stepInputs?.includes('terms') &&
      values?.term === undefined &&
      !getValues('terms')
    ) {
      form.setError('terms', {
        type: 'manual',
        message: 'You must agree to the terms',
      })
      setIsLoading(false)
      return
    }
    if (getValues('password') && getValues('password').length > 0) {
      formData.user.password = getValues('password')
    }
    if (getValues('password') !== getValues('confirmPassword')) {
      form.setError('confirmPassword', {
        type: 'manual',
        message: 'confirmPassword is required',
      })
      setIsLoading(false)
      return
    }

    const marketplace = {
      name: formData.marketplace.name,
      key: formData.marketplace.key,
      ...(formData.marketplace.profileImageKey
        ? { profileImageKey: formData.marketplace.profileImageKey }
        : {}),
      ...(formData.marketplace.backgroundImageKey
        ? { backgroundImageKey: formData.marketplace.backgroundImageKey }
        : {}),
      ...(formData.marketplace.description
        ? { description: formData.marketplace.description }
        : {}),
    }

    onSubmit('/express/creator', {
      marketplace,
      user: { ...formData.user },
    })
  }

  const onValidInput = () => {
    setCurrentStep((prev) => prev + 1)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key !== 'Enter' || e.target instanceof HTMLButtonElement) {
      return
    }
    e.preventDefault()
  }

  return (
    <FormProvider {...form}>
      <form ref={formRef} className={styles.formContainer} onKeyDown={handleKeyDown}>
        {siteType === 'creator'
          ? generateCreatorOnboardingSteps(currentStep)
          : generateVendorOnboardingSteps(currentStep)}
      </form>
      {termsVissible && (
        <div className={styles.termsWrapper}>
          <CheckBox name="terms">
            I agree to {''}
            <Link className="reg-link" to={agreementURL}>
              VideoShops {agreementText}
            </Link>
            ,{' '}
            {/* <Link className="reg-link" to={''}>
              Shipping Policy
            </Link> */}
            {/* ,{' '} */}
            <Link className="reg-link" to={APP_ROUTES.RETURNS}>
              Return Policy
            </Link>
            ,{' '}
            <Link className="reg-link" to={APP_ROUTES.TERMS_OF_USE}>
              Terms of Use
            </Link>
            ,{' '}
            <Link className="reg-link" to={APP_ROUTES.PRIVACY_POLICY}>
              Privacy Policy
            </Link>
            .
          </CheckBox>
        </div>
      )}
      <div
        className={classNames(styles.stepActionsContainer, {
          [styles.hide]: onboardActions,
        })}
      >
        {toggleBackButton && (
          <Button
            onClick={() => setCurrentStep((prevStep) => prevStep - 1)}
            children="Back"
            blackText
            greyOutline
            transparent
            medium
            fixed
          ></Button>
        )}
        {toggleNextButton && (
          <Button
            onClick={handleNext}
            children={isVendorCompletd ? 'Confirm' : 'Next'}
            disabled={isLoading}
            primary
            darkHover
            medium
            fixed
          ></Button>
        )}
        {currentStep === 6 && isCreator && (
          <div className={styles.launchVideoshopWrapper}>
            <Button onClick={handleCreatorSubmit} primary darkHover fullWidth>
              Launch Videoshops
            </Button>
          </div>
        )}
      </div>
      {!isStepRequired && (isPreCreatorSignUpForm || isPostVendorSignUp) && (
        <div
          className={classNames(styles.skipEvent, {
            [styles.hide]: onboardActions,
          })}
        >
          <span className="reg-link" onClick={handleNext}>
            Skip
          </span>
        </div>
      )}
    </FormProvider>
  )
}

export default FormComp
