import { Link as RouterLink } from 'react-router-dom'
import Box from '@mui/system/Box'
import Button from '@mui/material/Button'
// import { ReactComponent as CorneeUpLeftIcon } from '../../assets/icons/corner-up-left-icon.svg'
import { ReactComponent as CorneeUpLeftBackArrowIcon } from '../../assets/icons/back-arrow-icon.svg'

const styles = {
  button: {
    px: 2.5,
    height: 36,
    border: '1px solid #EBEBEB',
    textTransform: 'none',
    color: 'black',
    borderRadius: '48px',
    typography: 'xs_bold',
    bgcolor: '#FCFCFC'
  }
}

interface BackButtonProps {
  to: string
  callback?: () => void
}

export default function BackButton({ to, callback }: BackButtonProps) {
  return (
    <Button sx={styles.button} component={RouterLink} to={to} onClick={callback}>
      <Box mr={1.5} display="flex" alignItems="center">
        <CorneeUpLeftBackArrowIcon />
      </Box>
      Back
    </Button>
  )
}
