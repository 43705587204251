import { APP_ROUTES } from '@/routes/routes'

export const footerLinks = [
  {
    title: 'Company',
    links: [
      { name: 'About', url: APP_ROUTES.ABOUT },
      {
        name: 'Privacy Policy',
        url: APP_ROUTES.PRIVACY_POLICY
      },
      {
        name: 'Terms of Use',
        url: APP_ROUTES.TERMS_OF_USE
      }
      // {
      //   name: 'Affiliate Disclosure',
      //   url: APP_ROUTES.AFFILIATE_DISCLOSURE
      // }
    ]
  } /*,
  {
    title: 'Support',
    links: [
      // { name: 'Shipping', url: APP_ROUTES.SHIPPING },
      // { name: 'Returns', url: APP_ROUTES.RETURNS },
      { name: 'Contact Us', url: APP_ROUTES.CONTACT }
    ]
  }*/
]

export const twitterLink =
  'https://twitter.com/myvideoshops?s=21&t=9H-fV5BINUN0tJFKjRU26g'

export const instagramLink =
  'https://instagram.com/myvideoshops?igshid=YmMyMTA2M2Y='
