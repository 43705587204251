import { FC, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useOnboardingContext } from '@/hooks/useOnboardingForm'
import classNames from 'classnames'
import { ReactComponent as Mascot } from '@/assets/home/mascot.svg'
import { ReactComponent as CommisionBadge } from '@/assets/icons/commision-brand.svg'
import { ReactComponent as InNetorkIcon } from '@/assets/icons/in-network-badge.svg'
import { OnboardingDescription } from '@/constants/onboarting-copy'
import onboardingDefaultBG from '@/assets/images/onboarding-bg.png'

const PreviewComponent: FC = () => {
  const { formData, siteType } = useOnboardingContext()
  const { marketplace } = formData
  const [backgroundYPosition, setBackgroundYPosition] = useState('-10rem')
  const [userType, setUserType] = useState<string>('')

  const description =
    siteType === 'vendor'
      ? OnboardingDescription.BRAND
      : OnboardingDescription.SELLER
  const previewElipseName = <Mascot />
  const marketplaceName =
    marketplace && marketplace.name ? marketplace.name : ''
  const hasProfileImage = marketplace?.profileImage
  const previewImageStyle = hasProfileImage
    ? {
        backgroundImage: `url(${marketplace.profileImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }
    : {}
  const marketplaceDesc =
    marketplace && marketplace.description
      ? marketplace.description
      : `${description}`
  const hasBackgroundImage = marketplace?.backgroundImage
  const backgroundImage =
    marketplace && marketplace.backgroundImage
      ? `${marketplace.backgroundImage}`
      : onboardingDefaultBG
  // const backgroundImageStyle = hasBackgroundImage
  //   ? {
  //       backgroundImage: `url(${backgroundImage})`,
  //       '--dynamic-bg-position': backgroundYPosition,
  //       backgroundRepeat: 'no-repeat',
  //       backgroundSize: '100%'
  //     }
  //   : {
  //       backgroundImage: `url(${backgroundImage})`,
  //       backgroundSize: '100%',
  //       backgroundPosition: 'center'
  //     }
  const backgroundImageStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }

  useEffect(() => {
    if (window.location.pathname.includes('vendor')) {
      setUserType('vendor')
    } else if (window.location.pathname.includes('creator')) {
      setUserType('creator')
    }

    const handleResize = () => {
      const newY = window.innerHeight * 0.1 * -1
      setBackgroundYPosition(`${newY}px`)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [formData])

  return (
    <div
      className={classNames(styles.PreviewContainer)}
      style={backgroundImageStyle}
    >
      {hasBackgroundImage && <div className={styles.overlay}></div>}
      <div className={styles.PreviewInnerContainer}>
        {/* <div className={styles.PreviewTop}>
          <div className={styles.LeftIcon}>
            <img src="/icons/hamburger.svg" />
          </div>
          <div className={styles.RightIcons}>
            <img src="/icons/help-icon.svg" />
            <img src="/icons/heart-outline.svg" />
            <img src="/icons/cart-outline.svg" />
          </div>
        </div> */}
        <div className={styles.PreviewMiddle}>
          <div
            className={classNames(
              styles.userElipse,
              hasProfileImage && styles.removeBGColor
            )}
            style={previewImageStyle}
          >
            {!hasProfileImage && previewElipseName}
          </div>
          <div className={styles.marketplaceName}>
            {marketplaceName}
            {/* <div className={styles.marketplaceButtons}>
              <span>Follow</span>
              <span>
                <img src="/icons/qr-outline.svg" />
              </span>
              <span>
                <img src="/icons/share-outline.svg" />
              </span>
            </div> */}
          </div>
          {marketplace && marketplace.commissionRate && (
            <div className={styles.inNetworkIconWrapper}>
              <InNetorkIcon />
            </div>
          )}
        </div>
        <div className={styles.PreviewBottom}>{marketplaceDesc}</div>
        <div className={styles.bottomBarWrapper}>
          {marketplace && marketplace.commissionRate && (
            <div className={styles.badgeWrapper}>
              <CommisionBadge />
              {marketplace.commissionRate}%
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PreviewComponent
