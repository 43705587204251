import { FC, useContext } from 'react'
import capitalize from 'lodash/capitalize'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import Box from '@mui/system/Box'
import { toast } from 'react-toastify'
import Container from '@mui/system/Container'
import StepHeader from '../../StepHeader/StepHeader.tsx'
import { yupResolver } from '@hookform/resolvers/yup'
import { CreatorObject } from '@/components/OnboardingComp/OnboardingData/creator.ts'
import { UserInterface, userSchema } from '@/components/Form/Validations/CreatorSetupForm.ts'
import formStyles from '@/components/Form/styles.module.scss'
import BackButton from '@/shared/components/BackButton/BackButton.tsx'
import TopBar from '@/shared/components/TopBar/TopBar.tsx'
import { appConfig } from '@/app-config.ts'
import { postRequest } from '@/api/api.ts'
import SignUpForm from './SignUpForm.tsx'
import { sendCode } from '../../actions.ts'
import styles from '../../styles.module.scss'
import CustomToast from '@/components/Toaster/Toaster.tsx'
import StepCounter from '@/components/OnboardingCompNew/StepCounter.tsx'
import ProgressBar from '../../ProgressBar.tsx'
import { updateFormState } from '@/utils/formHelpers.ts'
import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext.tsx'
import { OnBaordingProps } from '@/components/OnboardingComp/types.ts'
import gaActions, { gaEvents } from '@/videoshops-shared/utils/ga.utils.ts'
import useOnboarding from '../../useOnboarding.ts'
import { OnboardingStep } from '@/constants/onboarding.constant.ts'

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const CreateAccount: FC = () => {
  const navigate = useNavigate()
  const { saveOnboardingData } = useOnboarding()

  const { setFormData } = useContext(OnboardingContext) as OnBaordingProps
  const form = useForm({
    resolver: yupResolver(userSchema),
    mode: 'onChange',
    defaultValues: CreatorObject.user
  })

  const notifyError = (response) => {
    const errorMessage = response?.data?.error ? response.data.error : 'Error with Submission'
    toast(<CustomToast type="error" text={errorMessage} />)
  }

  const onSubmit = async (data: UserInterface) => {
    const createUrl = `${appConfig.API_BASEL_URL}/creator`
    try {
      const response: OnboardingResponseData = await postRequest(createUrl, data)
      gaActions.event(gaEvents.account.signUp)
      localStorage.setItem('TOKEN', response.data.data.auth.authToken)
      sendCode({
        userId: response.data.data.user._id,
        countryCode: data.countryCode,
        phone: data.phone
      })
        .then(async () => {
          await saveOnboardingData(OnboardingStep.SIGNUP_FORM, response.data.data.auth.authToken)
          updateFormState(
            setFormData,
            'name',
            `${capitalize(data.firstName)} ${capitalize(data.lastName)}`
          )
          navigate('/creator/setup/verify-code?back=create-account')
        })
        .catch((data) => {
          toast(
            <CustomToast
              type="error"
              text={data?.response?.data?.error || 'Something went wrong'}
            />
          )
        })
    } catch (e) {
      notifyError(e.response)
    }
  }

  return (
    <div className={styles.stepPage}>
      <TopBar />
      <ProgressBar value={100 / 8} />
      <Box mt={{ xs: 1.5, md: 3.5 }}>
        <Container maxWidth="lg">
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <BackButton to="https://videoshops.com" />
            <StepCounter step={1} total={8} />
          </Box>
        </Container>
        <Container maxWidth={false} sx={{ maxWidth: 459 }}>
          <Box mt={2}>
            <StepHeader>Let’s Get Your Info</StepHeader>
          </Box>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className={formStyles.formContainer}>
              <SignUpForm />
            </form>
          </FormProvider>
        </Container>
      </Box>
    </div>
  )
}

export default CreateAccount
