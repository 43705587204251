import { useEffect, useState } from 'react'
import { getRequest } from '@/api/api.ts'
import { appConfig } from '@/app-config.ts'

type categoryType = {
  id: string
  displayValue: string
}

export default function useCategories() {
  const [categories, setCategories] = useState<categoryType[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const headers = { Authorization: localStorage.getItem('TOKEN') }
    getRequest(`${appConfig.API_BASEL_URL}/brand/manager/affinities`, {
      headers
    }).then(({ data }) => {
      setLoading(false)
      setCategories(data.data)
    })
  }, [])

  return { categories, loading }
}
