import styled from 'styled-components'

export const StyledOnboardingContent = styled.div`
  margin-top: 4rem;
`

export const StyledSignUpForm = styled.div`
  margin-top: 1.5rem;
`

export const StyledSignUpFormRow = styled.div`
  display: flex;
  gap: 0.5rem;
`
export const StyledSignUpActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  button:first-child {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
`

export const StyledFormRowWithStaticCopy = styled.div<{ height: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: ${({ height }) => height};
  .phone-static-copy {
    position: absolute;
    bottom: 0.2rem;
    left: 0;
  }
`
export const StyledSignUpSignUpActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  margin-top: 0.8rem;
  margin-bottom: 0.3rem;

  a {
    color: ${({ theme }) => theme.COLORS.black};
    text-decoration: none;
    font-weight: 600;
    font-size: ${({ theme }) => theme.FONT_SIZES.small.size};
    line-height: ${({ theme }) => theme.FONT_SIZES.small.lineHeight};
    &:hover {
      text-decoration: underline;
    }
  }
`

export const StyledRequiredWrapper = styled.div<{ marginTop?: string }>`
  display: flex;
  gap: 0.1rem;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : 0)};
  font-size: ${({ theme }) => theme.FONT_SIZES.small.size};
  font-weight: ${({ theme }) => theme.FONT_SIZES.small.weight};
  display: flex;
  line-height: ${({ theme }) => theme.FONT_SIZES.small.lineHeight};
  letter-spacing: 0em;
  color: ${({ theme }) => theme.COLORS.grey};
  span {
    margin-right: 0.1rem;
  }
  .required-asterix {
    margin-top: 0.3rem;
  }
`
export const StyledPasswordRulesWrapper = styled.div`
  padding: 0.1rem 0 1.3rem 0;
  ${({ theme }) => theme.breakpoints.down('tabletPortrait')} {
    ul {
      li {
        width: 99% !important;
      }
    }
  }
`

export const StyledSignUpFormStepWrapper = styled.div<{ marginTop?: string }>`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '2.1rem')};
`
