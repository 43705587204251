import styled from 'styled-components'

interface StyledInputrops {
  maxWidth?: string
  height?: string
}

export const InputStyled = styled.div<StyledInputrops>`
  width: 100%;

  .input-styled {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;

    .icon {
      width: 20px;
      height: 20px;
      color: var(--dolphin);
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        cursor: pointer;
      }
    }
  }

  .input-container {
    position: relative;
    display: flex;
  }

  .input-wrapper {
    border: 1px solid var(--pebble);
    border-radius: 6px;
    width: 100%;
    display: flex;
    overflow: hidden;

    .input-prefix {
      pointer-events: none;
      font-weight: 400;
      font-size: 14px;
      color: var(--battleship);
      margin: auto auto auto 0;
      padding: 24px 0 6px 12px;
      flex-shrink: 0;
    }

    input {
      display: block;
      width: 100%;
      color: var(--black);
      background-color: var(--white);
      outline: none;
      font-weight: 400;
      font-size: 14px;
      border: 0px solid var(--Greyscale-08-Dolphin, #6e7780);
      background: var(--All-Colors-Dust, #f1f2fc);
      padding: 21px 12px 6px 12px;
      height: ${({ height }: StyledInputrops) => height || 'auto'};
      max-width: ${({ maxWidth }: StyledInputrops) => maxWidth || '100%'};

      &.without-label {
        padding: 6px 12px 6px 12px;
      }

      &.with-prefix {
        padding-left: 2px;
      }
    }

    &.with-value {
      border: 1px solid var(--dolphin);
    }

    &.error-border {
      border: 1px solid var(--solo-cup);
    }
    &.input-disabled {
      border-color: var(--pebble);
    }

    input:disabled {
      color: var(--wolf-grey);
    }

    input:focus + label,
    .has-input-value {
      top: 20px;
      transform: translateY(-100%);
    }

    label {
      position: absolute;
      color: var(--wolf-grey);
      font-size: 10px;
      transition: all 100ms ease 0s;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 1.1px;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;

      &.required {
        &::after {
          content: ' *';
          font-size: inherit;
        }
      }
    }
  }

  .input-error {
    margin-top: 0.325rem;
    margin-bottom: 0.625rem;
    color: red !important;
    font-weight: 500;
    font-size: 11px;
    text-align: start;
  }
`
