import styles from './styles.module.scss'
import NewTitle from '@/components/Title/Title'
import { ReactComponent as MobileBack } from '@/assets/home/mobile-back.svg'
import { ReactComponent as BreadCrumbArrow } from '@/assets/home/breadcrumb-arrow.svg'
import Footer from '@/components/Footer/Footer'
import TopBar from '@/components/SubPagesComp/TopBar/TopBar'
import classNames from 'classnames'

function BrandAgreement() {
  const isMobile = window.innerWidth < 768
  const title = (
    <div className="breakTitle">
      <span data-line="VideoShops">
        <span className="vs-primary-color"> Brand Marketplace</span> Agreement
      </span>
    </div>
  )
  return (
    <>
      <TopBar />
      <div className={styles.subPageWrapper}>
        <div className={styles.aboutUsTopWrapper}>
          {isMobile ? (
            <a href="/">
              {' '}
              <MobileBack />
            </a>
          ) : (
            <div className={styles.breadcrumbWrapper}>
              <a href="/">Home</a> <BreadCrumbArrow /> Brand Marketplace
              Agreement
            </div>
          )}
        </div>
        <NewTitle children={title} titleColor="#000" />
        <div
          className={classNames(styles.subPageContent, styles.fullWidthContent)}
        >
          <h6>VIDEOSHOPS</h6>
          <h6>BRAND MARKETPLACE AGREEMENT</h6>
          <p>
            <strong>Last updated on: October 7, 2023 </strong>
          </p>
          <p>
            This &ldquo;Brand Marketplace Agreement&rdquo; (the &ldquo;
            <strong>
              <u>Agreement</u>
            </strong>
            &rdquo;) is between you (an individual or an entity, &ldquo;
            <strong>
              <u>You</u>
            </strong>
            &rdquo; or the &ldquo;
            <strong>
              <u>Brand</u>
            </strong>
            &rdquo;) and NOWwith Ventures, Inc. d/b/a &ldquo;VideoShops&rdquo;,
            167-177 Lafayette Street, 3rd Floor New York, NY 10012 (&ldquo;
            <strong>
              <u>VideoShops</u>
            </strong>
            &rdquo;) and is effective as of the date you click to accept this
            Agreement.&nbsp;
          </p>
          <p>
            This Agreement sets forth the terms and conditions of the
            Brand&rsquo;s participation to sell products through Personal
            Marketplace Sellers that register on (&ldquo;
            <strong>
              <u>VideoShops&rsquo; Platform</u>
            </strong>
            &rdquo;).&nbsp;
          </p>
          <p>
            <strong>
              <u>How Does VideoShops Platform Work?</u>
            </strong>
          </p>
          <p>
            VideoShops is a Decentralized eCommerce Platform that enables any
            individual and any brand to turn on social-selling on every social
            platform and messaging app.
          </p>
          <p>
            VideoShops&rsquo; Platform enables any individual to turn on a
            distributed end-to-end marketplace and get paid a commission on
            sales without needing prior brand approvals. For Brands, VideoShops
            supports every eCommerce platform (Shopify, Magento, Big Commerce,
            Woo, etc.) and enables you, the brand, to connect and integrate your
            full product catalog through VideoShops&rsquo; Enterprise API into
            the Brand Directory where Personal Marketplace Sellers; shop, share
            and sell products they discover and love. Your Brand&rsquo;s
            marketplace and products will then be featured and accessible in the
            Brand Directory on VideoShops Platform for all &ldquo;
            <strong>
              <u>Personal Marketplace Seller(s)</u>
            </strong>
            &rdquo; to shop and sell products and get paid a commission on
            sales.
          </p>
          <p>
            The content posted by Personal Marketplace Sellers (&ldquo;
            <strong>
              <u>Personal Marketplace Content</u>
            </strong>
            &rdquo;) is all shoppable by third party users (&ldquo;
            <strong>
              <u>Customers</u>
            </strong>
            &rdquo;) who may purchase your products (&ldquo;
            <strong>
              <u>Brand SKUs</u>
            </strong>
            &rdquo;) through Personal Marketplace Sellers. Brand content that is
            included in any Personal Marketplace can be posted through
            VideoShops&rsquo; distribution channels.
          </p>
          <div className={styles.sideContent}>
            <p>
              The Brand agrees not to use any materials contained in Personal
              Marketplace Content in any manner other than as provided in this
              Agreement.
            </p>
            <p>
              The Brand, VideoShops and the Personal Marketplace Seller each
              have the right to (i) promote any shared content featuring a Brand
              SKUs solely in accordance with this Agreement, and (ii) to remove
              any particular Personal Marketplace Content in accordance with
              this Agreement. The Brand agrees not to use any materials
              contained in Personal Marketplace Content in any manner other than
              as provided in this Agreement. The Brand acknowledges that the
              Brand must obtain any clearances or licenses from third party
              owners for the use of Personal Marketplace Content not expressly
              permitted in this Agreement and Program Policies
            </p>
            <p>
              The Brand acknowledges that the Brand must obtain any clearances
              or licenses from third party owners for the use of Personal
              Marketplace Content not expressly permitted in this Agreement and
              Program Policies.
            </p>
          </div>
          <p>
            <strong>
              <u>
                How Does a Brand Manage their Marketplace on VideoShops
                Platform?
              </u>
            </strong>
          </p>
          <p>
            Your &ldquo;Brand Marketplace&rdquo; is self-serve and easy to use
            (the &ldquo;
            <strong>
              <u>Brand</u>
            </strong>
            <u>
              <strong>Portal</strong>
            </u>
            &rdquo;); you manage, upload and edit in real time information about
            your Brand SKUs such as photos and videos of the Brand SKUs through
            the Brand Portal. The content that you upload including your
            trademarks, trade names and other intellectual property associated
            with the Brand and the Brand SKUs are collectively referred to as
            &ldquo;
            <strong>
              <u>Brand Content</u>
            </strong>
            &rdquo;.
          </p>
          <p>
            You can connect the Platform to your brand&rsquo;s social media
            networks that you use (e.g., Instagram, Facebook, Snapchat, YouTube,
            TikTok, Triller, Twitch, etc.) (collectively, &ldquo;
            <strong>
              <u>Social Networks</u>
            </strong>
            &rdquo;).
          </p>
          <p>
            In your Brand Portal, you can also manage interactions with Personal
            Marketplace Sellers and Customers. The Dashboard contains
            &ldquo;near-real-time&rdquo; data and insights with respect to the
            performance of Brand SKUs on the Platform (including insights
            related to views of Personal Marketplace Content containing;
            add-to-cart and purchase rates of Brand SKUs from such Personal
            Marketplace Seller&rsquo;s Content). The Dashboard will also include
            the Brand&rsquo;s specific sales reports and sufficient data to
            verify the Brand&rsquo;s sales and payments (subject to the
            &ldquo;Program Policies&rdquo; (as defined below).
          </p>
          <p>
            You can add other individuals who are authorized to manage your
            Brand Marketplace account (each a &ldquo;
            <strong>
              <u>Brand Authorized User</u>
            </strong>
            &rdquo;).&nbsp;
          </p>
          <div className={styles.sideContent}>
            <p>
              The Brand agree that any Brand Content furnished by or on behalf
              of the Brand for use under this Agreement, including to market and
              promote the Brand and Brand SKUs through and in connection with
              VideoShops&rsquo; Platform, shall at all times adhere to{' '}
              <a href="https://policies.nowwith.com/brand-program-policies">
                Program Policies
              </a>
              . The Brand shall cooperate and/or facilitate, as needed, in
              providing required product information and coordinating API or
              other automated data feed to ingest the Brand&rsquo;s Brand SKU
              catalog information in accordance with the Program Policies. The
              Brand and Brand SKUs will be featured and will need to be tagged
              by the Brand in all relevant search criteria associated with the
              provided Brand SKU product description upon ingestion and
              acceptance into the Platform.
            </p>
            <p>
              The Brand is solely responsible for managing its access
              credentials to its Brand Portal and is further solely responsible
              for any use of such credentials. The Brand is responsible for
              advising VideoShops immediately if any Brand Authorized User is no
              longer authorized by the Brand to access the Brand Portal and
              needs to be disabled.&nbsp;
            </p>
          </div>
          <p>
            <strong>
              <u>
                How is the transaction split between the Brand, VideoShops and
                the Personal Marketplace Seller?{' '}
              </u>
            </strong>
          </p>
          <p>
            In-network brands, like you, select the commission rate which can be
            set between 10% to 50% (in increments in 10% that will be paid to
            Personal Marketplace Seller on each sale. This commission gets paid
            to the Personal Marketplace Seller for selling any products that you
            make available in your VideoShops Brand Marketplace (the &ldquo;
            <strong>
              <u>Sales Commission</u>
            </strong>
            &rdquo;). You can make changes to the Personal Marketplace Seller
            commission rate every 30 days. So, for example, if you set the Sales
            Commission to 20%, that commission will appear on your product card
            for 30 days.
          </p>
          <p>
            VideoShops charges a 10% Platform fee off the top of every
            transaction (the &ldquo;
            <strong>
              <u>Platform Fee</u>
            </strong>
            &rdquo;).&nbsp;
          </p>
          <p>
            Therefore, for example, if you set a Personal Marketplace Seller
            commission for your brand at 20% and a product was sold for $100,
            the Personal Marketplace Seller will be paid $20 for a given sale of
            your Brand SKU that was marketed by that Personal Marketplace Seller
            to a Customer. And for that sale, VideoShops will retain $10 (i.e.,
            10% of $100).&nbsp;
          </p>
          <p>
            So, in this example, you would receive $67 after the following
            commission splits and fees.
          </p>
          <p>$100 sales price</p>
          <p>-$20 to the Seller</p>
          <p>-$3 to Stripe Connect</p>
          <p>
            <u>-$10 to VideoShops</u>
          </p>
          <p>
            $67 Net to you (the &ldquo;
            <strong>
              <u>Net Sales Amount</u>
            </strong>
            &rdquo;).&nbsp;
          </p>
          <div className={styles.sideContent}>
            <p>
              However, you agree that the sales prices of your Brand SKUs
              available to Customers on the Platform cannot be higher than the
              sale prices offered by other retailers.&nbsp;
            </p>
            <p>
              VideoShops does not guarantee that any Brand SKUs will be selected
              by Personal Marketplace Sellers for inclusion in Creator Content,
              nor is VideoShops required to make any particular Brand SKUs
              available for sale and may remove any Brand SKU from the Platform
              (in VideoShops&rsquo; sole discretion) in accordance with the{' '}
              <a href="https://policies.nowwith.com/brand-program-policies">
                Program Policies
              </a>
              .
            </p>
          </div>
          <p>
            <strong>
              <u>Who collects the money and how is the product shipped?</u>
            </strong>
          </p>
          <p>
            Customers place their orders through Personal Marketplace
            Seller&rsquo;s universal cart which utilizes Stripe Connect as a
            payment processor. VideoShops processes the payment and immediately
            credits your registered bank account less the commission to Seller
            and VideoShops&rsquo; Platform Fee (which are held by Stripe Connect
            during the 15 day returns window); it&rsquo;s that simple. So, the
            Customer sees your company name on their credit card and sales
            receipt.
          </p>
          <p>
            The Brand must ship Brand SKUs sold directly to the Customer in
            accordance with the terms of this Agreement. VideoShops does not
            maintain any product inventory to fulfill Customer orders.&nbsp;
          </p>
          <p>
            All necessary information related to each order placed on the
            Platform, including order number, product and variant details,
            pricing, necessary shipping information and any other Customer data
            necessary for the Brand to fulfill shipping the Brand SKU(s)
            purchased to a Customer shall be available in the Brand Portal.
          </p>
          <div className={styles.sideContent}>
            <p>
              The Brand must provide VideoShops with valid bank account
              information for a bank account acceptable by VideoShops. The Brand
              authorizes VideoShops to verify the Brand&rsquo;s information from
              time to time. The Brand shall provide VideoShops (or its
              authorized designees) with any other information that may be
              required to account to the Brand for such payments in compliance
              with applicable laws. In the event the Brand does not provide
              banking information as required hereunder, VideoShops shall accrue
              sums payable to the Brand until the Brand provides VideoShops with
              such required information, but in no event shall VideoShops be
              required to accrue any sums for the Brand&rsquo;s benefit for
              longer than 12 months (after which time VideoShops shall have no
              responsibility or liability for the same).
            </p>
            <p>
              Payments made to the Brand, as reduced by all deductions or
              withholdings described in the Agreement, will constitute full
              payment and settlement to the Brand of amounts payable under the
              Agreement.
            </p>
          </div>
          <p>
            <strong>
              <u>What is VideoShops Returns Policy?</u>
            </strong>
          </p>
          <p>VideoShops has a 15 day return policy.&nbsp;</p>
          <p>
            In the event that a customer initiates a return on an order during
            the 15 day period, VideoShops will return the Platform Fee (except
            as otherwise specifically provided for below) and the Sales
            Commission to you in accordance with Stripe Connects Policies.You
            are responsible to return the entire amount back to the customer
            once the product is returned.
          </p>
          <p>
            <strong>
              <u>Same-DayPay: an optional feature selected by enrollment</u>
            </strong>
          </p>
          <p>
            Same DayPay is an incredible feature for brands to incentivize those
            Personal Marketplace Sellers who don&rsquo;t want to wait to get
            paid and are willing to take a lower guaranteed commission on sales
            for same day payment. Same-DayPay enables enrolled Personal
            Marketplace Sellers to get paid a Sales Commission of 10% upon
            receipt of sale, whether or not the product is returned and in lieu
            of higher commission rates, if applicable. So, for example, if you,
            the Brand, sets the Sales Commission rate to 30%, you only pay only
            10% to the Personal Marketplace Seller on that sale, rather than
            30%. However, if the product is returned, the Personal Marketplace
            Seller and VideoShops each retain their respective sales commissions
            and fees.
          </p>
          <p>
            In the event that the Brand&rsquo;s actions or performance may
            result in returns, chargebacks, claims, disputes, or violations of
            VideoShops&rsquo;{' '}
            <a href="https://policies.nowwith.com/brand-program-policies">
              Program Policies
            </a>
            , then VideoShops may in VideoShops&rsquo; sole discretion withhold
            any payments to the Brand for as long as VideoShops determine any
            related risks to VideoShops or third parties persist. VideoShops may
            (i) offset any amounts that are payable by the Brand to VideoShops
            (in reimbursement or otherwise) against any payments VideoShops may
            make to the Brand or amounts VideoShops may owe the Brand;
            (ii)&nbsp;invoice the Brand for amounts due to VideoShops, in which
            case the Brand will pay the invoiced amounts upon receipt of such
            invoice; (iii) reverse any credits to Your Bank Account; or (iv)
            collect payment or reimbursement from the Brand by any other lawful
            means and deduct VideoShops&rsquo; reasonable, third party out of
            pocket costs, expenses and legal fees associated with such
            collection. If the Brand&rsquo;s account has been used to engage in
            deceptive, fraudulent, or illegal activity, or the Brand repeatedly
            violate VideoShops&rsquo; Program Policies (as determined by
            VideoShops), then VideoShops may in VideoShops&rsquo; sole
            discretion permanently withhold any payments to the Brand.
          </p>
          <div className={styles.sideContent}>
            <p>
              If any excess payment has been made to the Brand for any reason
              whatsoever, VideoShops reserve the right to adjust or offset the
              same against any subsequent Brand Revenue Share paid or payable
              under the Agreement.
            </p>
          </div>
          <p>
            <strong>
              <u>24/7 Customer Support</u>
            </strong>
          </p>
          <p>
            VideoShops provides automated 24/7 customer chat support with
            respect to orders generated from Personal Marketplace Sellers on the
            Platform. The Brand agrees to comply with VideoShops&rsquo; minimum
            customer support requirements to provide information about the
            Brand&rsquo;s SKUs and answers to Customers&rsquo; questions as may
            be required from time to time.
          </p>
          <p>
            <strong>
              <u>What Rights Do You Grant to VideoShops?</u>
            </strong>
          </p>
          <p>
            You grant to VideoShops, licensees, designees, successors and
            assigns (including Personal Marketplace Sellers, as applicable), the
            following rights throughout the world:
          </p>
          <p>
            (i) the right to share, sell, feature, promote your Brand and
            Marketplace in VideoShops public relations and promotional materials
            including all media now known or hereafter devised.
          </p>
          <p>
            (ii) the right to use the Brand SKUs and/or all or any portion of
            shoppable Brand Content in connection with the following:
          </p>
          <p>
            (iii) the dissemination of information about you and/or any Brand
            SKUs in connection with the marketing and/or sale of Brand SKUs to
            Customers.
          </p>
          <p>
            (iv) the creation and use of marketing and promotional materials, in
            any format or media now known or hereafter devised, that promote the
            Brand, the Brand Content and/or any Brand SKUs, the distribution of
            Personal Marketplace Content and VideoShops;
          </p>
          <p>
            (v) permission for VideoShops, and for any Personal Marketplace
            Seller, to (A) &ldquo;whitelist&rdquo;, &ldquo;boost&rdquo; and/or
            otherwise support via paid media any posts of Shoppable Brand
            Content (including Personal Marketplace Content), and (B) tag
            Brand&rsquo;s handles in social media posts that includes
            Brands&rsquo; shoppable products.
          </p>
          <p>
            (vi) Edit, adapt and otherwise modify Brand Content to ensure
            quality control, to make the content interactive and shoppable. The
            foregoing rights do not include any right to edit or modify any of
            the Brand&rsquo;s trademarks (other than for proportionately
            resizing). The Brand acknowledges that the Brand does not have any
            right to control or take down any Personal Marketplace Content or
            any Brand SKUs included in Personal Marketplace Content or otherwise
            on any of the Platform&rsquo;s editorial or video player pages,
            other than on the Brand Marketplace or as permitted in accordance
            with the{' '}
            <a href="https://policies.nowwith.com/brand-program-policies">
              Program Policies
            </a>
            .&nbsp;
          </p>
          <p>
            <strong>
              <u>Your responsibilities</u>
            </strong>
            <strong>:</strong>
          </p>
          <p>
            The Brand agrees to comply with, VideoShops&rsquo; Terms of Use,
            Privacy Policy and other policies made available on the Platform
            (collectively, the &ldquo;
            <a href="https://policies.nowwith.com/brand-program-policies">
              Program Policies
            </a>
          </p>
          <p>
            <strong>
              <u>Third Party Rights and Obligations</u>
            </strong>
          </p>
          <p>
            In the event the Brand includes name, license or other materials
            owned by third parties in Brand Content, the Brand shall be solely
            responsible for obtaining and/or paying for all licenses, waivers,
            clearances and other approvals or payment obligations required
            (&ldquo;
            <strong>
              <u>Third Party Obligations</u>
            </strong>
            &rdquo;) so that the Brand Content may be used in all manners
            contemplated in this Agreement without restriction in accordance
            with the Program Policies. In such connection, the Brand represents
            and warrants that the Brand has secured (and paid for, if
            applicable) all consents, permissions and releases required to allow
            the Brand Content featuring any third party and/or third party
            content (such as music) to be used by or on behalf of the Brand and
            VideoShops (and its permitted licensees and designees) shall have no
            obligations, financial or otherwise, in connection therewith.&nbsp;
          </p>
          <p>
            &ldquo;
            <strong>
              <u>VideoShops&rsquo; Property</u>
            </strong>
            &rdquo; shall mean VideoShops&rsquo; name as well as the brands{' '}
            <strong>
              <em>MYVIDEOSHOP(S), NOWWITH, NOW//WITH, SHOPPERTAINMENT</em>
            </strong>{' '}
            and all related names, trademarks, trade names, product names,
            service names, service marks, trade dress, designs and design marks,
            copyrights, slogans, logos or other intellectual property and/or
            other identifiers of VideoShops, including any discoveries,
            inventions, innovations, improvements, data, technology, know-how,
            trade secrets, copyrights and other intellectual property that is
            owned and/or controlled by VideoShops. During the Term of this
            Agreement, VideoShops grants the Brand the limited, non-exclusive,
            revocable, royalty-free right to use, display, publish and/or
            reference VideoShops&rsquo; company name, the word mark VIDEOSHOPS,
            MYVIDEOSHOPS, MY VIDEOSHOP, NOWWITH, NOW//WITH and VideoShops&rsquo;
            official logo marks for each (the &ldquo;
            <strong>
              <u>Licensed VideoShops&rsquo; Property</u>
            </strong>
            &rdquo;) solely as embodied and/or referenced in Brand Content
            produced and used by the Brand as permitted in accordance with the
            terms of this Agreement including the{' '}
            <a href="https://policies.nowwith.com/brand-program-policies">
              Program Policies
            </a>{' '}
            exclusively on the Platform, provided that the Brand shall not (i)
            use VideoShops&rsquo; Property other than the Licensed
            VideoShops&rsquo; Property as expressly permitted hereunder, (ii)
            use Licensed VideoShops&rsquo; Property in any manner to suggest any
            sponsorship, affiliation, endorsement or other connection between
            the Brand and VideoShops (or any third party) other than in
            connection with the Brand&rsquo;s participation in the Brand
            Platform (unless otherwise agreed by the Brand and VideoShops in
            writing, if applicable) and/or (iii) use and/or reference
            VideoShops&rsquo; Property in any manner, and/or otherwise take any
            action, that may harm or otherwise adversely affect the
            VideoShops&rsquo; Property and/or the goodwill associated with the
            VideoShops&rsquo; Property.&nbsp;
          </p>
          <p>
            The Brand acknowledges and agrees that VideoShops has exclusive
            ownership rights in and to the VideoShops&rsquo; Property. The Brand
            agrees not to authorize or assist any third party to, (x) take any
            action that might in any way impair the right, title and interest of
            VideoShops in and to the VideoShops&rsquo; Property and/or (y)
            challenge or otherwise contest the validity, ownership or
            enforceability of any VideoShops&rsquo; Property or bring any action
            for non-infringement. All uses of VideoShops&rsquo; Property shall
            inure to the benefit of VideoShops, and the Brand shall not acquire,
            directly or by implication, any rights or license in the
            VideoShops&rsquo; Property except as expressly provided in this
            Agreement. The Brand&rsquo;s right to use the Licensed
            VideoShops&rsquo; Property will terminate immediately if the Brand
            violates any of the terms of this Agreement and shall automatically
            cease upon the termination of this Agreement for any reason
            whatsoever. VideoShops shall not acquire any right, title or
            interest in and to the Brand&rsquo;s trademarks, trade names, trade
            dress or other intellectual property; any goodwill generated in the
            Brand&rsquo;s intellectual property will inure solely to the Brand.
          </p>
          <p>
            <strong>
              <u>Links to VideoShops Platform + Website</u>
            </strong>
          </p>
          <p>
            As a registered VideoShops Brand Marketplace user, the Brand may
            integrate a link on the Brand&rsquo;s official website (&ldquo;
            <strong>
              <u>Brand Website</u>
            </strong>
            &rdquo;) that immediately links to your VideoShops Brand Marketplace
            at no cost, provided that any shopping enabled or promoted in
            connection with the Brand Marketplace and/or Personal Marketplace
            Content shall solely be powered by and/or through VideoShops
            Platform. In linking the Brand Site to VideoShops Platform (through
            a &ldquo;
            <strong>
              <u>Unique </u>
            </strong>
            <strong>
              <u>Link</u>
            </strong>
            &rdquo;), the Brand agree to the following: the Brand shall not
            modify any graphic image or text contained on the VideoShops Site in
            any way, and Brand Site shall not contain any content of the
            VideoShops Site or any materials that are proprietary to VideoShops,
            without VideoShops&rsquo; prior written permission. The Brand will
            not frame any page on the VideoShops Site. VideoShops reserves all
            rights in all graphic images and text contained on VideoShops Site
            and all other VideoShops Property. VideoShops reserves the right to
            refuse to permit the Brand to link Brand Site to the VideoShops
            Site, or to revoke such permission, for any reason in its sole
            discretion.
          </p>
          <p>
            To protect the security of VideoShops&rsquo; platform, VideoShops
            may, but is not required to, impose transaction limits on some or
            all sales or other transactions through the Platform relating to the
            value of any transaction or disbursement, the cumulative value of
            all transactions or disbursements during a period of time, or the
            number of transactions per day or other period of time and
            VideoShops shall not be liable to the Brand in connection therewith.
          </p>
          <p>
            Payments to Personal Marketplace Sellers: Any payments (if
            applicable) to Personal Marketplace Sellers related to Brand SKUs or
            any other authorized sales commission payments via the Brand Portal
            shall be made directly by VideoShops to each such Personal
            Marketplace Seller. The Brand agrees that any payments or
            transactions related to the Brand and/or Brand SKUs within Personal
            Marketplace Content distributed via the Platform shall solely take
            place via tools provided by the VideoShops service and any
            violations of this shall be cause for immediate termination from the
            Platform (however, nothing herein shall prevent exclusive
            endorsement deals between Brands and Personal Marketplace Sellers
            made on a broader basis for content outside of the Platform).
            Termination shall be in addition to, and not in lieu of, other
            remedies available to VideoShops at law and/or in equity, including
            without limitation an action to recover the funds due to VideoShops
            for a violation of the foregoing.
          </p>
          <p>
            <strong>
              <u>Tax Matters</u>
            </strong>
          </p>
          <p>
            VideoShops is responsible for all Personal Marketplace
            Seller&rsquo;s tax reporting. The Brand will be solely responsible
            for the collection, reporting, and payment of any and all of the
            Brand&rsquo;s sales taxes, except and only to the extent that (i)
            VideoShops (directly or through a third party authorized payment
            processing vendor) automatically calculates, collects, or remits
            taxes on the Brand&rsquo;s behalf according to applicable law; or
            (ii) VideoShops expressly agrees to receive taxes or other
            transaction-based charges on the Brand&rsquo;s behalf in connection
            with tax calculation services made available by VideoShops (or its
            authorized vendor) and used by the Brand. All fees and payments
            payable by the Brand to VideoShops under this Agreement (including
            the{' '}
            <a href="https://policies.nowwith.com/brand-program-policies">
              Brand Program Policies
            </a>
            ) are exclusive of any applicable taxes, deductions or withholding
            (including but not limited to cross-border withholding taxes), and
            the Brand will be responsible for paying VideoShops any of the
            Brand&rsquo;s taxes imposed on such fees and any deduction or
            withholding required on any payment. It is expressly understood that
            VideoShops may deduct or withhold any taxes that VideoShops may be
            legally obligated to deduct or withhold from any amounts payable to
            the Brand under the Brand Platform. The Brand shall provide
            VideoShops with any tax information that VideoShops may request from
            the Brand from time to time to comply with VideoShops&rsquo;
            obligations. If the Brand does not provide the tax information
            requested by VideoShops, VideoShops reserves the right (in addition
            to other rights and remedies available to VideoShops) to withhold
            the Brand Revenue Share otherwise payable to the Brand until the
            Brand provide this information or the Brand provide information that
            satisfies VideoShops (in VideoShops&rsquo; discretion).
          </p>
          <p>
            It is expressly understood and agreed that any request by the Brand
            to make Third Party Payments hereunder, if accommodated by
            VideoShops in its sole discretion (with no obligation to do so),
            shall be subject to the terms and conditions of this Agreement
            including this Tax Matters section. Without limitation of the
            foregoing, in no event shall VideoShops make any Third Party
            Payments unless and until the applicable third party has delivered
            to VideoShops (or its authorized vendor) all necessary bank
            information (as described in the Payment to Brand section above) and
            other tax information that may be requested from time to time to
            comply with any tax reporting obligations in connection with such
            payments (and to keep such information updated as needed, including
            promptly advising VideoShops of any change to such third
            party&rsquo;s address and/or other information provided).
            VideoShops&rsquo; compliance with this authorization will constitute
            accommodation to the Brand alone, and nothing herein shall
            constitute the applicable third party to whom the Brand directs a
            Brand Third Party Payment a beneficiary of this Agreement.
            VideoShops shall have no responsibility or liability to the Brand or
            such third party for erroneous payments made as a result of
            information provided that is incorrect, incomplete or not properly
            updated as required hereunder, or failure to comply with the request
            to make Third Party Payments in its discretion. The Brand shall
            indemnify VideoShops for any claims asserted against VideoShops and
            any damages, losses or expenses incurred by VideoShops by reason of
            any such Third Party Payments or requests therefor.
          </p>
          <p>
            <strong>
              <u>Confidentiality and Personal Data</u>
            </strong>
          </p>
          <p>
            During the course of the Brand&rsquo;s use of VideoShops Platform,
            the Brand may receive Confidential Information. &ldquo;
            <strong>
              <u>Confidential Information</u>
            </strong>
            &rdquo; means information relating to VideoShops (and its officers,
            directors, stockholders, employees or other representatives), the
            Platform or VideoShops Customers and/or users that is not known to
            the general public including, but not limited to, any information
            identifying or unique to specific Customers; reports, insights and
            other information about the Platform and/or Customers; data derived
            from the Platform except for data (other than Customer personal
            data) arising from the sale of the Brand&rsquo;s products comprising
            of products sold, prices, sales, volumes and time of the
            transaction; and technical or operational specifications relating to
            the Platform. For the purposes of this Agreement, Customer personal
            data constitutes Confidential Information at all times which, as
            between the Brand and VideoShops, shall be considered
            VideoShops&rsquo; exclusive property and cannot be used or processed
            by the Brand in any way inconsistent with applicable law. The Brand
            shall only use Customer personal data for the purpose of fulfilling
            Customer orders under this Agreement unless otherwise expressly
            agreed by VideoShops in writing (in VideoShops&rsquo; sole
            discretion) or by the applicable Customer through the tools (if any)
            VideoShops provide to Customer on the Platform. Except to the extent
            required to fulfill Customer orders, the Brand shall not resell,
            transfer, export or otherwise use, exploit or process any such
            Customer personal data other than as required by law or as expressly
            agreed by VideoShops in advance in writing, and in all events the
            Brand will only use Customer Personal Data with, and in accordance
            with, a Customer&rsquo;s express, duly documented consent. The Brand
            agree that for the Term of the Agreement and 5 years after
            termination or such longer period herein prescribed: (a) all
            Confidential Information will remain VideoShops&rsquo; exclusive
            property (which ownership shall continue in perpetuity after the
            Term); (b) the Brand will use Confidential Information only as is
            reasonably necessary for the Brand&rsquo;s participation in the
            Platform and will not resell or repurpose such information in any
            manner without obtaining VideoShops&rsquo; prior written consent (as
            well as the consent of the applicable Customer); (c) the Brand will
            not otherwise disclose Confidential Information to any other person
            or entity except as required to comply with applicable law and/or to
            the extent reasonably necessary to perform under this Agreement, and
            the Brand shall ensure that all authorized persons or entities who
            have access to Confidential Information in connection with the
            Brand&rsquo;s account will be made aware of, and required to comply
            with, the confidentiality obligations herein (provided the Brand
            shall remain liable for any breach by such representatives of this
            paragraph); (d) the Brand will take all reasonable measures to
            protect the Confidential Information against any use or disclosure
            that is not expressly permitted in this Agreement; and (e) the Brand
            will retain Confidential Information only for so long as its use is
            necessary for participation on the Platform or to fulfill the
            Brand&rsquo;s statutory obligations (e.g. tax) and in all cases will
            delete such information upon termination or as soon as no longer
            required for the fulfillment of statutory obligations.
            Notwithstanding the foregoing, the Brand are not prohibited from
            sharing Confidential Information as may be required by applicable
            law to any governmental entity that has jurisdiction over the Brand,
            provided that the Brand limit the disclosure to the minimum
            necessary and explicitly indicate the confidential nature of the
            shared information to the governmental entity. The Brand may not
            issue any press release or make any public statement related to the
            Platform, or use VideoShops&rsquo; name, trademarks, or logo, in any
            way (including in promotional material) without VideoShops&rsquo;
            advance written permission or as otherwise expressly permitted in
            this Agreement or misrepresent or embellish the relationship between
            VideoShops in any way. The Brand will take no action that would
            cause VideoShops or the Platform to be in violation of applicable
            laws, including without limitation any privacy laws, data retention
            and/or destruction laws, data transfer laws, or otherwise, nor will
            use any Customer data in a manner contrary to VideoShops&rsquo;{' '}
            <a href="https://policies.nowwith.com/brand-program-policies">
              Program Policies
            </a>
            .
          </p>
          <p>
            <strong>
              <u>Required Disclosures / FTC Guidelines</u>
            </strong>
          </p>
          <p>
            The Brand agrees to comply with applicable laws, including FTC
            guidelines, with respect to the marketing and sale of Brand SKUs,
            including as set forth in the{' '}
            <a href="https://policies.nowwith.com/brand-program-policies">
              Program Policies
            </a>
            . The Brand understands it is a legal requirement that Customers and
            other end users clearly understand that Personal Marketplace Sellers
            may be entitled to compensation as a result of purchases of products
            through their Creator Content. The Brand agree that VideoShops may
            clearly disclose this financial relationship between the Brand and
            the Creator on the Brand Marketplace and any other location where
            VideoShops may authorize the Brand&rsquo;s display of Creator
            Content, such as: &ldquo;Personal Marketplace Sellers are paid from
            qualifying purchases of products through the VideoShops
            Marketplace&rdquo;. In addition, the Brand will also need to
            disclose any endorsement or similar relationship between the Brand
            and a Personal Marketplace Seller to the extent that a separate
            endorsement or similar relationship exists unrelated to VideoShops.
            Except as required by applicable law, the Brand will not make public
            statements about this Agreement or the details of the Brand&rsquo;s
            participation in the Brand Platform without VideoShops&rsquo;
            advance written permission. The Brand will not misrepresent the
            extent of VideoShops&rsquo; relationship with the Brand (for
            example, VideoShops does not specifically sponsor or endorse the
            Brand or the Brand&rsquo;s Brand Marketplace) or otherwise suggest
            there is any legal or other affiliation between the Brand and
            VideoShops or any other person or entity (including any Creator,
            except to the extent the Brand do have a separate endorsement,
            sponsorship or similar relationship that exists outside of the
            Brand&rsquo;s use of the VideoShops Site with a Creator and such
            statements are permitted by such Creator), except as expressly
            permitted in the Agreement.&nbsp;
          </p>
          <p>
            <strong>
              <u>Representations and Warranties </u>
            </strong>
          </p>
          <p>
            The Brand represents and warrants to VideoShops that (a) the Brand
            is duly organized, validly existing and in good standing under the
            laws of the applicable jurisdiction in which the Brand&rsquo;s
            business is registered, and the Brand has the right, power and
            authority to enter into this Agreement and grant all of the rights
            granted herein, (b) the Brand shall comply with the terms of this
            Agreement (including the{' '}
            <a href="https://policies.nowwith.com/brand-program-policies">
              Program Policies
            </a>
            ) and all applicable laws in the Brand&rsquo;s performance of this
            Agreement, in the creation, production, manufacture, labelling,
            packaging, distribution, advertising marketing and sale of the
            Brand&rsquo;s merchandise (including without limitation, labor
            standards, consumer product and safety laws, disclosure of
            information to consumers and truth in advertising laws and
            regulations, etc.), and in the processing of any Customer personal
            data, (c) the merchandise shipped under any purchase order meets its
            product specifications at the time of shipment and is fit and safe
            for the use for which it was manufactured, (d) such merchandise
            (including packaging, labeling and inserts) does not, and the resale
            thereof by VideoShops will not, violate any applicable law,
            regulation or ordinance or infringe on any intellectual property or
            other right of any third party and (e) the Brand and the
            Brand&rsquo;s affiliates are not subject to any sanctions or
            prohibitions by any governmental authority.
          </p>
          <p>
            <strong>
              <u>Indemnification</u>
            </strong>
          </p>
          <p>
            The Brand agrees to indemnify, defend, and hold harmless VideoShops
            and its subsidiary and affiliated companies and related entities,
            program partners and licensees, and their respective directors,
            officers, employees, contractors, stockholders, agents,
            representatives and designees (including Personal Marketplace
            Sellers), from and against any and all losses, damages, liabilities,
            penalties, credit monitoring service costs, data breach notification
            costs, costs of settlement and expenses (including, without
            limitation, reasonable attorneys&rsquo; fees and expenses) arising
            from any third party claims, suits, proceedings, demands or causes
            of action (including, without limitation, governmental actions)
            (collectively, &ldquo;
            <strong>
              <u>Losses</u>
            </strong>
            &rdquo;) arising out of, resulting from, or relating to (a) the
            design, creation, manufacture, distribution and/or use of the Brand
            SKUs, Brand Content and any information provided by or on behalf of
            the Brand in connection therewith, including any death, bodily
            injury or property damage occurring or alleged to have occurred as a
            result of products provided by the Brand, statements, instructions
            for use or warnings on the label(s), boxes, inserts or other
            packaging for the Brand&rsquo;s products or directions for use or
            application provided or approved by the Brand, (b) the negligence or
            willful misconduct of the Brand, or the Brand Authorized Users,
            employees, contractors, agents or representatives, in the furnishing
            of merchandise or in the performance of work under any purchase
            order, (c) any actual or alleged infringement by the Brand, products
            provided by the Brand, or the Brand&rsquo;s intellectual property or
            any third party intellectual property right, (d) any actual or
            alleged violation of or noncompliance by the Brand, the
            Brand&rsquo;s products and/or the Brand&rsquo;s use of the Platform
            with any applicable law, regulation or ordinance, (e) any allegation
            that any product provided by the Brand was defective or harmful or
            violated a warranty of the Brand in any way, (f) any actual or
            alleged breach by the Brand of this Agreement, (g) any unauthorized
            use of the Brand&rsquo;s access credentials for the Brand Portal,
            whether by the Brand, the Brand&rsquo;s Brand Authorized Users, or
            any third party, (h) any claims related to the Brand&rsquo;s
            processing (or failure to process) a purchase order as contemplated
            and required in this Agreement, or (i) the Brand&rsquo;s taxes and
            duties or the collection, payment or failure to collect or pay the
            Brand&rsquo;s taxes or duties, or the failure to meet tax
            registration obligations or duties. All indemnification obligations
            of the Brand under this Agreement will survive termination of this
            Agreement or any purchase order. Upon the making or filing of any
            such claim, action or demand, VideoShops shall be entitled to
            withhold from any amounts payable to the Brand under this Agreement
            such amounts as are reasonably related to the potential liability in
            issue; provided, however, VideoShops shall release any such amounts
            withheld if no litigation is commenced within twelve (12) months
            following the assertion of such claim, action or demand unless
            VideoShops believe, in VideoShops&rsquo; reasonable judgment, that
            such a proceeding may be instituted notwithstanding the passage of
            time. Notwithstanding the foregoing, VideoShops shall not withhold
            any monies pursuant to the immediately preceding sentence if the
            Brand make bonding arrangements satisfactory to VideoShops in
            VideoShops&rsquo; sole discretion, to assure VideoShops of
            reimbursement for all damages, liabilities, costs and expenses
            (including reasonable actual and documented legal expenses and
            reasonable outside counsel fees) which VideoShops may incur as a
            result of such claim. The Brand shall be notified of any such claim,
            action or demand and shall have the right, at the Brand&rsquo;s own
            expense, to participate in the defense thereof with counsel of the
            Brand&rsquo;s own choosing; provided, however, that
            VideoShops&rsquo; decision in connection with the defense of any
            such claim, action or demand shall be final.
          </p>
          <p>
            <u>Term; Termination</u>
          </p>
          <p>
            This Brand Marketplace Agreement will commence upon the
            Brand&rsquo;s registration to create a Brand Marketplace and shall
            terminate as provided in the next two sentences (the &ldquo;
            <strong>
              <u>Term</u>
            </strong>
            &rdquo;). Either party may terminate this Agreement (a) at any time
            upon 30 days&rsquo; written notice; or (b) upon the other party's
            material breach of this Agreement, provided that the non-breaching
            party provides 7 days&rsquo; written notice to the breaching party
            and the breach is not cured within such 7-day period. The Brand can
            provide termination notice by logging into the Brand&rsquo;s account
            on the Brand Marketplace and selecting the option to close the
            Brand&rsquo;s account in &ldquo;Account Settings&rdquo;. VideoShops
            may provide written notice to the Brand of termination through the
            Brand&rsquo;s account on the Brand Marketplace as well as by sending
            notice by email to the Brand&rsquo;s then-current email address
            noted in the Brand Marketplace account. Termination of this
            Agreement shall not relieve the parties of obligations accrued
            through the date of termination (including fulfillment of and
            customer service for any orders placed by Customers prior to the end
            of the Term and any subsequent shipping, handling, returns, etc.
            related to such sales that may occur after the end of the Term). All
            provisions in this Agreement that should by their nature survive
            termination shall so survive.
          </p>
          <p>
            Notwithstanding anything to the contrary contained in this Agreement
            and for the avoidance of doubt, VideoShops has the right to
            immediately suspend public access to the Brand Marketplace in its
            sole business discretion for any violations of this Agreement
            (including the{' '}
            <a href="https://policies.nowwith.com/brand-program-policies">
              Program Policies
            </a>
            ).
          </p>
          <p>
            Termination of this Agreement shall not affect the rights granted to
            VideoShops or its designees pursuant to this Agreement during the
            Term. Accordingly, any rights granted and exercised during the Term,
            including the distribution of any Brand Content, the creation of any
            post on Socials, the creation of any permitted promotional content
            or the like, shall survive the expiration or termination of this
            Agreement (e.g., it shall not be a breach of this Agreement if a
            post on a Social that was properly made prior to the end of the Term
            continues to be included in a feed after the Term).
          </p>
          <p>
            <u>Disclaimer of Liability</u>
          </p>
          <p>
            Neither VideoShops nor any parent or any of VideoShops&rsquo;
            respective affiliates, licensors, partners, officers, directors,
            stockholders, members, managers, employees or other representatives
            shall be liable (i) for any matter directly or indirectly relating
            to the creation, maintenance or operation of the Brand Marketplace
            or the Brand&rsquo;s violation of this Agreement and/or (ii) for
            indirect, incidental, special, punitive, consequential, exemplary
            damages, or any loss of revenue, profits, goodwill, loss of use or
            loss of data arising in connection with the Platform, even if
            VideoShops have been advised of the possibility of those damages.
            Further, VideoShops&rsquo; aggregate liability arising in connection
            with the Agreement will not exceed the total amounts paid to the
            Brand under this Agreement in the 12 months immediately preceding
            the date on which the event giving rise to the most recent claim of
            liability occurred. The Brand hereby waives any right or remedy in
            equity, including the right to seek specific performance, injunctive
            or other equitable relief, in connection with this Agreement. This
            paragraph shall be enforced to the maximum extent permitted by
            applicable law, and nothing in this paragraph will operate to limit
            liabilities that cannot be limited under applicable law.
          </p>
          <p>
            It is expressly agreed that the Platform, and all content and
            elements thereof and any information provided for the same, are
            provided &ldquo;as-is&rdquo;, and the Brand agrees that the Brand is
            using the Platform at the Brand&rsquo;s own risk. To the maximum
            extent permissible under applicable law and except as agreed in the
            &ldquo;Representations and Warranties&rdquo; section above,
            VideoShops expressly disclaim any and all representations or
            warranties related to the Platform, the activities contemplated by
            this Agreement, including any warranties of merchantability or use,
            fitness for a particular purpose, title, quiet enjoyment, and
            non-infringement, as well as any other implied warranties, such as
            warranties regarding data loss, availability, accuracy,
            functionality and lack of malware, or damages as the result of the
            illegal activities of third parties. VideoShops do not provide any
            guarantee or warranty that the Platform will meet any specific
            standard or be available for use at any time or time to time, that
            the Platform will be free of errors, interruptions, harmful
            components or failures or that transactions may not be impacted by
            any such failures.
          </p>
          <p>
            <strong>
              <u>Force Majeure</u>
            </strong>
          </p>
          <p>
            VideoShops will not be liable for any delay or failure to perform
            any of VideoShops&rsquo; obligations under this Agreement by
            reasons, events or other matters beyond VideoShops&rsquo; reasonable
            control, including without limitation telecommunication failures,
            utility failures, or equipment failures; labor disputes, riots, war,
            or terrorist attacks; nonperformance of VideoShops&rsquo; vendors or
            suppliers, fires or acts of God; or any other event over which
            VideoShops have no reasonable control. For the avoidance of doubt,
            nothing in this Agreement will excuse the Brand&rsquo;s payment
            obligations to VideoShops.&nbsp;
          </p>
          <p>
            <u>Successors; Assignment; Waiver </u>
          </p>
          <p>
            This Agreement shall be binding upon and inure to the benefit of the
            parties and their respective successors in interest and permitted
            assigns. The Brand may not assign this Agreement, in whole or in
            part, without VideoShops&rsquo; prior written consent. No failure by
            VideoShops to enforce any provision of this Agreement (including the{' '}
            <a href="https://policies.nowwith.com/brand-program-policies">
              Program Policies
            </a>
            ) shall constitute a waiver of VideoShops&rsquo; right to
            subsequently enforce such provision or any other provision of the
            Agreement.
          </p>
          <p>
            <u>Independent Contractors </u>
          </p>
          <p>
            The Brand and VideoShops are independent contractors, and nothing in
            this Agreement will create any partnership, joint venture, agency,
            franchise, sales representative, or employment relationship between
            the Brand and VideoShops or VideoShops&rsquo; respective affiliates.
            The Brand will have no authority to make or accept any offers or
            representations on behalf of VideoShops or its affiliates.
          </p>
          <p>
            <u>Governing Law and Jurisdiction; Severability</u>
          </p>
          <p>
            This Agreement and the rights and obligations of the parties shall
            be governed by and construed according to the laws of the state of
            New York, without regard to its choice of law provisions.
          </p>
          <p>
            To the fullest extent permitted by applicable law, neither the Brand
            nor VideoShops will seek to have a dispute heard as a class action
            or private attorney general action or in any other proceeding in
            which either party acts or proposes to act in a consolidated or
            representative capacity, regardless of the forum or venue. No
            arbitration or proceeding can be combined with another without the
            prior written consent of all parties to the arbitrations or
            proceedings.
          </p>
          <p>
            The Brand and VideoShops agree to arbitrate, as provided below, all
            disputes between the parties hereto, that are not resolved
            informally, except with respect to any claim for a preliminary
            injunction or similar equitable relief related to the ownership or
            enforcement of intellectual property rights and/or any misuse of
            such intellectual property rights (&ldquo;
            <strong>
              <u>Excluded Claims</u>
            </strong>
            &rdquo;). This obligation and agreement to arbitrate applies
            regardless of whether the underlying action arises in contract,
            warranty, tort, or otherwise, and relates to claim related to or
            arising under this Agreement, its enforceability, and any claim
            regarding the agreement to arbitrate and waive the right to
            participate in any class action type claims or otherwise.&nbsp;
          </p>
          <p>
            If the parties hereto cannot amicably resolve a dispute, the same
            will be resolved by binding arbitration before a neutral
            arbitrator(s). The arbitration will be conducted by the American
            Arbitration Association (AAA) under its commercial rules. The
            parties will cooperate with AAA and with one another in selecting a
            mutually approved arbitrator from the AAA panel of neutrals with at
            least 10 years of experience in the applicable area(s) of the
            disputes. If the parties cannot agree on one arbitrator within 15
            days of the commencement of the arbitration, the parties shall each
            select an arbitrator with the qualifications specified in this
            paragraph within 15 days, and those two arbitrators shall mutually
            select a third arbitrator, who shall serve as Chair of the
            arbitration tribunal, within 15 days of their appointment.
            Notwithstanding the foregoing, in the event that the claims involve
            over $1,000,000 in dispute, either party may require the appointment
            of three arbitrators in accordance with the procedure in the
            preceding sentence. The determination and award of the arbitrator(s)
            shall be based upon application of New York law. Payment of all
            filing, administration and arbitrator fees will be governed by the
            AAA&rsquo;s rules. The decision of such arbitrator(s) will be final
            except for a limited right of appeal under the U.S. Federal
            Arbitration Act. BOTH PARTIES HERETO ARE GIVING UP AND WAIVING THE
            RIGHT TO LITIGATE A DISPUTE BEFORE A JURY OR A JUDGE IN COURT OTHER
            THAN WITH RESPECT TO EXCLUDED CLAIMS. Arbitration will be conducted
            either electronically (by telephone or other means of video
            conference as permitted by the American Arbitration Association) or
            in the borough of Manhattan, New York, New York. The Brand and
            VideoShops hereby agree to submit to the exclusive jurisdiction of
            the federal courts for the Southern District of New York and the
            state courts city in New York, New York, where necessary (e.g.) to
            compel arbitration, to stay any filed proceedings pending
            arbitration, or to confirm, modify, vacate, or enter judgment on any
            award by the arbitrator.
          </p>
          <p>
            To the extent litigation or other proceeding is permitted in local
            or federal courts hereunder, each party irrevocably submits to the
            exclusive jurisdiction of the federal courts for the Southern
            District of New York and state court of the State of New York
            located in New York, NY, United States, and the Brand expressly
            waive any objection to the personal jurisdiction and venue of such
            courts, including, without limitation, on grounds of forum non
            conveniens. Notwithstanding anything to the contrary contained in
            this Agreement, VideoShops may seek injunctive or other relief in
            any state, federal or national court of competent jurisdiction for
            any infringement of VideoShops&rsquo; or any other person or
            entity&rsquo;s intellectual property or proprietary rights.
          </p>
          <p>
            For service of process and notices of claims, the then-current
            contact person/entity noted in the Brand&rsquo;s account shall be
            authorized to receive and accept service of process on the
            Brand&rsquo;s behalf.&nbsp;
          </p>
          <p>
            In the event any provision of this Agreement is determined to be
            invalid or unenforceable for any reason, such provision shall be
            amended solely to the extent necessary to make such provision
            enforceable to the maximum extent possible and such provision shall
            be so enforced to give effect to the intent of the same. Any such
            invalid or modified provision shall in no way affect the validity or
            enforceability of any other provisions of the Agreement.&nbsp;
          </p>
          <p>
            <u>Entire Agreement; Interpretation</u>
          </p>
          <p>
            This Agreement (including the{' '}
            <a href="https://policies.nowwith.com/brand-program-policies">
              Program Policies
            </a>
            ) constitutes the entire agreement between the Brand and VideoShops
            with respect to the subject matter thereof and supersede all prior
            agreements. Except as expressly specified herein, no amendment,
            waiver, or discharge of any provision of this Agreement will be
            effective unless made in writing signed by VideoShops and the Brand
            (except where changes to the Platform may be binding upon the Brand
            as provided herein). In the event of a conflict between this
            Agreement and any purchase order or other pre-printed terms on forms
            submitted by the Brand, this Agreement shall control, and no
            pre-printed terms on any documents the Brand submits to VideoShops
            will serve to supplement this Agreement and its terms. If an
            ambiguity or question of intent or interpretation arises, this
            Agreement will be construed as if drafted jointly by the parties to
            this Agreement, and no presumption or burden of proof will arise
            favoring or disfavoring any party to this Agreement by virtue of the
            authorship of any of the provisions of this Agreement.
          </p>
        </div>
        <Footer />
      </div>
    </>
  )
}
export default BrandAgreement
