import { FC, useState, useContext } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
import Box from '@mui/system/Box'
import Container from '@mui/system/Container'
import TopBar from '@/shared/components/TopBar/TopBar.tsx'
import StepHeader from '../../StepHeader/StepHeader.tsx'
import InputField from '@/components/Form/Fields/InputField/InputField'
import formStyles from '@/components/Form/styles.module.scss'
import SelectComp from '@/components/Form/Fields/Select/Select.tsx'
import { COUNTRY_PHONE_CODES } from '@/constants/countryCodes.ts'
import { postRequest } from '@/api/api.ts'
import { appConfig } from '@/app-config.ts'
import { formType } from './types.ts'
import styles from '../../styles.module.scss'
import { sendCode } from '../../actions.ts'
import CustomToast from '@/components/Toaster/Toaster.tsx'
import StepCounter from '@/components/OnboardingCompNew/StepCounter.tsx'
import ProgressBar from '../../ProgressBar.tsx'
import FinalButton from '@/videoshops-shared/components/FinalButton/FinalButton.tsx'
import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext.tsx'
import { OnBaordingProps } from '@/components/OnboardingComp/types.ts'
import { updateFormState } from '@/utils/formHelpers.ts'
import capitalize from 'lodash/capitalize'

const schema = Yup.object().shape({
  countryCode: Yup.string(),
  phone: Yup.string()
    .trim()
    .matches(/^-?(0|[1-9]\d*)$/, 'Invalid phone number format: use format 3108882343')
    .min(8, 'Invalid phone number format: use format 3108882343')
    .max(15, 'Invalid phone number format: use format 3108882343')
    .required('Phone number is required'),
})

const VerifyAccount: FC = () => {
  const navigate = useNavigate()
  const { setFormData } = useContext(OnboardingContext) as OnBaordingProps
  const [loading, setLoading] = useState(false)
  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { countryCode: '+1', phone: '' },
  })
  const parsedHash = new URLSearchParams(window.location.hash.substring(1))

  const onSubmit = async (data: formType) => {
    const createUrl = `${appConfig.API_BASEL_URL}/cognito/creator`
    const body = { countryCode: data.countryCode }
    const headers = { Authorization: parsedHash.get('access_token') }
    setLoading(true)
    const notifyError = (response) => {
      const errorMessage = response?.data?.error ? response.data.error : 'Error with Submission'
      toast(<CustomToast type="error" text={errorMessage} />)
    }

    try {
      const response: OnboardingResponseData = await postRequest(createUrl, body, { headers })
      if (response?.data?.data?.auth?.authToken) {
        localStorage.setItem('TOKEN', response.data.data.auth.authToken)
        const googleUser = response.data.data.user
        sendCode({
          userId: response.data.data.user._id,
          countryCode: data.countryCode,
          phone: data.phone,
        })
          .then(({ data }) => {
            if (data.error) {
              toast(<CustomToast type="error" text={data.error} />)
              setLoading(false)
            } else {
              updateFormState(
                setFormData,
                'name',
                `${capitalize(googleUser.firstName)} ${capitalize(googleUser.lastName)}`,
              )
              navigate('/creator/setup/verify-code?back=verify-account')
            }
          })
          .catch(() => {
            toast(<CustomToast type="error" text="User has already been confirmed." />)
            setLoading(false)
          })
      } else {
        notifyError(response)
      }
    } catch (e) {
      notifyError(e.response)
    }
  }

  return (
    <div className={styles.stepPage}>
      <TopBar />
      <ProgressBar value={(100 / 8) * 2} />
      <Box mt={{ xs: 1.5, md: 3.5 }}>
        <Container maxWidth="lg">
          <Box height={36} display="flex" alignItems="center" justifyContent="flex-end">
            <StepCounter step={2} total={8} />
          </Box>
        </Container>
        <Container maxWidth={false} sx={{ maxWidth: 459 }}>
          <Box mt={3}>
            <StepHeader>Before We Get Started</StepHeader>
          </Box>
          <Box mt="22px">
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className={formStyles.formContainer}>
                <Box display="flex">
                  <SelectComp name="countryCode" optionData={COUNTRY_PHONE_CODES} required />
                  <Box flexGrow={1} ml={1}>
                    <InputField label="Mobile Number" name="phone" type="text" required />
                  </Box>
                </Box>
                <Box mt={3}>
                  <FinalButton
                    variant="primary"
                    disabled={!form.formState.isValid || loading}
                    type="submit"
                    full
                  >
                    Next
                  </FinalButton>
                </Box>
              </form>
            </FormProvider>
          </Box>
        </Container>
      </Box>
    </div>
  )
}

export default VerifyAccount
