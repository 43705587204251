import { ChangeEvent, useEffect, useRef, useState } from 'react'
import {
  CropperRef,
  Cropper,
  Priority,
  ImageRestriction,
  CoreSettings,
  CircleStencil,
  RectangleStencil,
  AspectRatio
} from 'react-advanced-cropper'
import 'react-advanced-cropper/dist/style.css'
import classNames from 'classnames'
import Box from '@mui/system/Box'
import styles from './styles.module.css'
import {
  applyBrightnessToCanvas,
  resizeImage
} from '../../components/ImageEditor/imageEditorHelpers'
import Button from '../../components/Button/Button'

import { getZoomFactor } from 'advanced-cropper/extensions/absolute-zoom'
import '../../styles/globals.css'
import { ImageEditorProps, ResObj } from '../../components/ImageEditor/types'
import { ReactComponent as CropIcon } from '../../assets/icons/image-editor/crop-icon.svg'
import FinalButton from '../FinalButton/FinalButton'

export default function ImageEditor({
  image,
  setOpenModal,
  croppingType,
  submitCrop,
  hasImageAlready
}: ImageEditorProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const cropperRef = useRef<CropperRef>(null)
  const [src, setSrc] = useState<string>(
    `${image}?cacheBuster=${Date.now()}` || '/images/hero-mobile-placeholder.png'
  )
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [zoomValue, setZoomValue] = useState(1)
  const [changeText, setChangeText] = useState('Upload Image')
  const [brightness, setBrightness] = useState(0.3)
  const [editorState, setEditorState] = useState('crop')
  const imageType = 'image/jpeg'
  const state = cropperRef.current?.getState() || null
  const settings = cropperRef.current?.getSettings() as CoreSettings
  const croppingTypeStencil = croppingType === 'circle' ? CircleStencil : RectangleStencil
  const buttonCropVariant = editorState === 'crop' ? 'tab-filled' : 'tab'
  const buttonAdjustVariant = editorState === 'adjust' ? 'tab-filled' : 'tab'

  useEffect(() => {
    if (image !== src || hasImageAlready) {
      setChangeText('Change Image')
    } else {
      setChangeText('Upload Image')
    }
  }, [image, src, hasImageAlready])

  useEffect(() => {
    const cropperImage = document.querySelector(
      '.advanced-cropper__background-wrapper img'
    ) as HTMLImageElement
    if (cropperImage) {
      cropperImage.style.filter = `brightness(${1.3 - brightness})`
    }
  }, [brightness])

  useEffect(() => {
    return () => {
      if (image) {
        URL.revokeObjectURL(image)
      }
    }
  }, [image, cropperRef.current])

  const onUpload = (e: any) => {
    e.preventDefault()
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const handleEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const cropper = cropperRef.current
    if (cropper) {
      const canvas = cropper.getCanvas()
      if (canvas) {
        applyBrightnessToCanvas(canvas, brightness)
        canvas.toBlob((blob) => {
          if (!blob) {
            return
          }
          const respObj: ResObj = {
            image: canvas.toDataURL(),
            imageType: imageType,
            blob: blob
          }
          submitCrop(respObj)
        }, imageType)
        onReset()
      }
    }
  }

  const onLoadImage = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0]
    if (file) {
      setIsLoading(true)
      setError(false)
      try {
        resizeImage(file, 2000, (resizedUrl) => {
          setSrc(resizedUrl)
          setIsLoading(false)
        })
      } catch (error) {
        console.error('Error loading image:', error)
        setError(true)
        setIsLoading(false)
      } finally {
        setIsLoading(false)
        if (!src) {
          setError(true)
        }
      }
    }
    event.target.value = ''
  }

  const onReset = () => {
    setOpenModal(false)
    setSrc('')
    setZoomValue(1)
    setBrightness(0.3)
  }

  const onZoom = (value: number, transitions?: boolean) => {
    if (cropperRef && cropperRef.current) {
      cropperRef.current.zoomImage(getZoomFactor(state, settings, value), {
        transitions: !!transitions
      })
    }
  }

  const handleZoomChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newZoomValue = Number(e.target.value)
    setZoomValue(newZoomValue)
    onZoom(newZoomValue - 1)
  }

  const handleEditorActionsClick = (e: React.MouseEvent<HTMLButtonElement>, actionType: string) => {
    e.preventDefault()
    setEditorState(actionType)
  }

  const CropperClass = classNames({
    [styles.cropperWrapperCircle]: croppingType === 'circle',
    [styles.cropperWrapperRectangle]: croppingType === 'rectangle',
    [styles.cropperWrapperSquare]: croppingType === 'square'
  })

  const aspectRatio: AspectRatio = {
    minimum: croppingType === 'circle' ? 1 : 18.75 / 15,
    maximum: croppingType === 'circle' ? 1 : 18.75 / 15
  }

  return (
    <>
      <div className={CropperClass}>
        <Cropper
          aspectRatio={aspectRatio}
          ref={cropperRef}
          src={src}
          className={styles.cropper}
          imageRestriction={ImageRestriction.fillArea}
          stencilComponent={croppingTypeStencil}
          priority={Priority.visibleArea}
          crossOrigin="anonymous"
        />
      </div>
      {error && (
        <p className={styles.errorMessage}>
          There was an error loading your image, please try again or upload a new image
        </p>
      )}

      <div className={styles.editorOptions}>
        <div className={styles.editorActions}>
          <Button
            label="Crop"
            variant={buttonCropVariant}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              handleEditorActionsClick(e, 'crop')
            }
            IconComponent={<CropIcon />}
          />
          <Button
            label="Brightness"
            variant={buttonAdjustVariant}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              handleEditorActionsClick(e, 'adjust')
            }
            IconComponent={<CropIcon />}
          />
        </div>
        {editorState === 'crop' && (
          <div className={styles.zoomSlider}>
            <h3>Zoom</h3>
            <input
              type="range"
              min="1"
              max="1.5"
              step="0.1"
              value={zoomValue}
              onChange={handleZoomChange}
            />
          </div>
        )}
        {editorState === 'adjust' && (
          <div className={styles.brightnessSlider}>
            <div className={styles.brightLabelWrapper}>
              <h3>Lighter</h3>
              <h3>Darker</h3>
            </div>
            <input
              type="range"
              min="0.3"
              max="1"
              step="0.01"
              value={brightness}
              onChange={(e) => setBrightness(Number(e.target.value))}
            />
          </div>
        )}
      </div>
      <Box
        mt={editorState === 'crop' ? -2.5 : 0}
        px="20px"
        pb="20px"
        display="flex"
        justifyContent="flex-end"
        width="100%"
      >
        <Button
          className={styles.actionButton}
          label={changeText}
          variant="outline"
          onClick={onUpload}
        />
        <Box ml={1}>
          <FinalButton variant="primary" size="md" onClick={handleEdit} loading={isLoading}>
            Apply
          </FinalButton>
        </Box>
      </Box>
      <input
        className="file-upload"
        ref={inputRef}
        type="file"
        accept="image/*,.heic"
        onChange={onLoadImage}
      />
    </>
  )
}
