import styles from './styles.module.scss'
import NewTitle from '@/components/Title/Title'
import { ReactComponent as MobileBack } from '@/assets/home/mobile-back.svg'
import { ReactComponent as BreadCrumbArrow } from '@/assets/home/breadcrumb-arrow.svg'
import Footer from '@/components/Footer/Footer'
import TopBar from '@/components/SubPagesComp/TopBar/TopBar'
import classNames from 'classnames'

function CookiePolicyPage() {
  const isMobile = window.innerWidth < 768
  const title = (
    <div className="breakTitle">
      <span data-line="VideoShops">
        Cookie <span className="vs-primary-color">Policy</span>
      </span>
    </div>
  )
  return (
    <>
      <TopBar />
      <div className={styles.subPageWrapper}>
        <div className={styles.aboutUsTopWrapper}>
          {isMobile ? (
            <a href="/">
              {' '}
              <MobileBack />
            </a>
          ) : (
            <div className={styles.breadcrumbWrapper}>
              <a href="/">Home</a> <BreadCrumbArrow /> Cookie Policy
            </div>
          )}
        </div>

        <NewTitle children={title} titleColor="#000" />
        <div
          className={classNames(styles.subPageContent, styles.fullWidthContent)}
        >
          <h6>VideoShops Cookie Policy</h6>
          <h6>Effective date: April 15, 2024</h6>
      </div>
        <Footer />
      </div>
    </>
  )
}
export default CookiePolicyPage
