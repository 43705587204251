export const getBackgroundIcon = (color: string) => {
  if (color.startsWith('#')) {
    return {
      backgroundColor: color
    }
  }

  return {
    backgroundImage: `url("/homeappimages/seller-theme/pattern-${color}-swatch.png")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }
}

export const getBackgroundImage = (color: string) => {
  if (color.startsWith('#')) {
    return {
      backgroundColor: color
    }
  }

  return {
    backgroundImage: `url("/homeappimages/seller-theme/pattern-${color}.png")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }
}
