import { FC } from 'react'
import InputField from '@/components/Form/Fields/InputField/InputField'
import styles from '../../styles.module.scss'
import { useOnboardingContext } from '@/hooks/useOnboardingForm'

const MarketPlaceName: FC = () => {
  const { siteType } = useOnboardingContext()
  const inputTypeName = siteType === 'creator' ? 'VideoShop' : 'Brand'
  const title =
    siteType === 'creator' ? (
      <h1>
        {' '}
        Create Your{' '}
        <span className="vs-primary-color"> {inputTypeName} Name</span>
      </h1>
    ) : (
      <h1>
        Enter Your{' '}
        <span className="vs-primary-color"> {inputTypeName} Name</span>
      </h1>
    )
  return (
    <div className={styles.stepContainer}>
      {title}
      <InputField
        colW={12}
        label={`Your ${inputTypeName} name`}
        name="name"
        type="text"
        required
      />
    </div>
  )
}

export default MarketPlaceName
