import { putRequest } from '@/api/api'
import { responseType } from './SellerSteps/VerifyAccount/types'
import { appConfig } from '@/app-config'
import { OnboardingStep } from '@/constants/onboarding.constant'

const API_BASE_URL = appConfig.API_BASEL_URL
const AUTH_TOKEN = localStorage.getItem('TOKEN') || ''

const useOnboarding = () => {
  const saveOnboardingData = async (
    onboardingStep: string,
    authToken?: string,
    siteType?: string
  ) => {
    try {
      const headers = {
        Authorization: authToken || AUTH_TOKEN
      }
      const onboardingState = JSON.parse(localStorage.getItem('formData') || '{}')

      if (!onboardingState.data) {
        return false
      }

      if (onboardingState.expiresAt) {
        delete onboardingState.expiresAt
      }

      const body = {
        onboardingStep: onboardingStep,
        onboardingState: onboardingState.data || onboardingState
      }

      if (onboardingStep === OnboardingStep.AFFINITIES) {
        const categories = JSON.parse(localStorage.getItem('categories') || '[]')
        if (siteType === 'creator') {
          delete onboardingState.data.marketplace.affinities

          body.onboardingState.user['categories'] = categories
        }
      }

      console.log('body', body)
      const response: responseType = await putRequest(`${API_BASE_URL}/user/onboarding`, body, {
        headers
      })
      console.log(response)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const resetOnboardingFromServer = async (authToken: string) => {
    localStorage.removeItem('formData')
    localStorage.removeItem('lastKnownStep')
  }

  return {
    saveOnboardingData,
    resetOnboardingFromServer
  }
}

export default useOnboarding
