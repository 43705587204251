import styles from './styles.module.scss'
import NewTitle from '@/components/Title/Title'
import { ReactComponent as MobileBack } from '@/assets/home/mobile-back.svg'
import { ReactComponent as BreadCrumbArrow } from '@/assets/home/breadcrumb-arrow.svg'
import Footer from '@/components/Footer/Footer'
import TopBarOld from '@/components/TopBar/TopBarOld.tsx'
import { useState } from 'react'
import { StaticPageContextProvider } from '@/context/StaticPageContext'
import { OnboardingProvider } from '@/context/OnboardingContext/OnboardingProvider'

function AboutUsPage() {
  const [siteType, setSiteType] = useState<string>('people')

  const payload = {
    siteType: siteType,
    setSiteType: setSiteType
  }
  const isMobile = window.innerWidth < 768
  const title = (
    <div className="breakTitle">
      <span data-line="VideoShops">
        About <span className="vs-primary-color">Us</span>
      </span>
    </div>
  )

  return (
    <OnboardingProvider siteType="vendor">
      <TopBarOld />
      <div className={styles.subPageWrapper}>
        <div className={styles.aboutUsTopWrapper}>
          {isMobile ? (
            <a href="/">
              {' '}
              <MobileBack />
            </a>
          ) : (
            <div className={styles.breadcrumbWrapper}>
              <a href="/">Home</a> <BreadCrumbArrow /> About Us
            </div>
          )}
        </div>
        <NewTitle children={title} titleColor="#000" />
        <div className={styles.subPageContent}>
          <h6>
            VideoShops Powers Social Selling <br />
            For Everyone
          </h6>
          <p>
            The platform enables any registered user to turn on their own
            VideoShops marketplace and access millions of brands that do not
            require approvals, inventory, or fees to get paid premium
            commissions on sales.
          </p>
          <p>
            VideoShops is integrated and supports every major eCommerce
            platform. Any DTC brand operating an online store with Shopify,
            Magento, Big Commerce, Woo or Sales Force can quickly connect their
            full product catalog and storefront into VideoShops Brand Directory.
            Sellers quickly and seamlessly add unlimited products into
            personalized digital shelves within their VideoShop to share and
            sell with their audiences across all social networks and messaging
            apps.
          </p>
          <p>
            VideoShops puts people in full control of social commerce and
            enables all users to own their direct customer relationships to
            scale their business.
          </p>
        </div>
        <Footer />
      </div>
    </OnboardingProvider>
  )
}
export default AboutUsPage
