import React, { FC } from 'react'
import Box from '@mui/material/Box'

import Menu from './Menu'

type TopBarTypes = {
  actions?: React.ReactNode
}

const TopBar: FC<TopBarTypes> = ({ actions }) => {
  return (
    <Box
      height={48}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      bgcolor="#fff"
      borderBottom="1px solid #EBEBEB"
    >
      <Box width="25%" display="flex">
        {/* <Menu /> */}
      </Box>
      <Box width="50%" display="flex" justifyContent="center">
        <Box component="img" src="/icons/videoshops-black.svg" />
      </Box>
      <Box mr={1.5} width="25%" display="flex" justifyContent="flex-end">
        {actions}
      </Box>
    </Box>
  )
}

export default TopBar
