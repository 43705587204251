import { FC } from 'react'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import Box from '@mui/system/Box'
import Container from '@mui/system/Container'
import StepHeader from '@/components/OnboardingCompNew/StepHeader/StepHeader.tsx'
import { yupResolver } from '@hookform/resolvers/yup'
import { CreatorObject } from '@/components/OnboardingComp/OnboardingData/creator.ts'
import { UserInterface } from '@/components/Form/Validations/CreatorSetupForm.ts'
import formStyles from '@/components/Form/styles.module.scss'
import BackButton from '@/shared/components/BackButton/BackButton.tsx'
import TopBar from '@/shared/components/TopBar/TopBar.tsx'
import { appConfig } from '@/app-config.ts'
import { postRequest } from '@/api/api.ts'
import SignUpForm from './SignUpForm.tsx'
import { sendCode } from '../../actions.ts'
import styles from '../../styles.module.scss'
import { passwordValidation } from '@/components/Form/Validations/PasswordValidation.ts'
import CustomToast from '@/components/Toaster/Toaster.tsx'
import useOnboarding from '../../useOnboarding.ts'
import { OnboardingStep } from '@/constants/onboarding.constant.ts'

const vendorSetupFormSchema = Yup.object().shape({
  brandName: Yup.string().trim().required('Your Merchant Name is required'),
  brandWebsite: Yup.string()
    .trim()
    .matches(
      /^https:\/\/(?:www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
      'Please enter a valid URL starting with https.'
    )
    .required('Merchant Website is required'),
  privacyUrl: Yup.string()
    .trim()
    .matches(
      /^https:\/\/(?:www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
      'Please enter a valid URL starting with https.'
    )
    .required('Privacy Policy URL is required'),
  termsUrl: Yup.string()
    .trim()
    .matches(
      /^https:\/\/(?:www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/,
      'Please enter a valid URL starting with https.'
    )
    .required('Terms URL is required'),
  terms: Yup.boolean()
    .oneOf([true], 'Please agree to the terms.')
    .required('Please agree to the terms.'),
  email: Yup.string()
    .trim()
    .required('Email is required')
    .matches(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
      'Your Email does not seem to be a valid email.'
    ),
  password: passwordValidation,
  confirmPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  phone: Yup.string()
    .trim()
    .matches(/^-?(0|[1-9]\d*)$/, 'Invalid phone number format')
    .min(7, 'Invalid phone number')
    .max(15, 'Invalid phone number')
    .required('Phone number is required'),
  firstName: Yup.string().trim().required('First Name is required'),
  lastName: Yup.string().trim().required('Last Name is required')
})

const CreateAccount: FC = () => {
  const navigate = useNavigate()
  const { saveOnboardingData } = useOnboarding()
  const form = useForm({
    resolver: yupResolver(vendorSetupFormSchema),
    mode: 'onChange',
    defaultValues: CreatorObject.user
  })

  const notifyError = (response) => {
    let errorMessage = response?.data?.error ? response.data.error : 'Error with Submission'
    const emailRegex = /(email\s+already\s+exists)/gi
    const isEmailRegistered = emailRegex.test(errorMessage)

    errorMessage = isEmailRegistered ? 'That Email Already Registered' : errorMessage
    toast(<CustomToast type="error" text={errorMessage} />)
  }

  const onSubmit = async (data: UserInterface) => {
    const createUrl = `${appConfig.API_BASEL_URL}/vendor`
    const body = { ...data, jobTitle: 'Merchant' }
    try {
      const response: OnboardingResponseData = await postRequest(createUrl, body)
      if (response?.data?.data?.auth?.authToken) {
        localStorage.setItem('TOKEN', response.data.data.auth.authToken)
        localStorage.setItem('userId', response.data.data.user._id)
        await saveOnboardingData(OnboardingStep.SIGNUP_FORM, response.data.data.auth.authToken)
        navigate('/brand/setup/verify-code?back=create-account')
        return sendCode({
          userId: response.data.data.user._id,
          countryCode: data.countryCode,
          phone: data.phone
        })
          .then(() => {
            navigate('/brand/setup/verify-code?back=create-account')
          })
          .catch((data) => {
            toast(
              <CustomToast
                type="error"
                text={data?.response?.data?.error || 'Something went wrong'}
              />
            )
          })
      } else {
        notifyError(response)
      }
    } catch (e) {
      notifyError(e.response)
    }
  }

  return (
    <div className={styles.stepPage}>
      <TopBar />
      <Box mt={{ xs: 1.5, md: 3.5 }}>
        <Container maxWidth="lg">
          <BackButton to="https://videoshops.com" />
        </Container>
        <Container maxWidth={false} sx={{ maxWidth: 411 }}>
          <Box mt={3}>
            <StepHeader title="Merchant Sign Up">Create An Account</StepHeader>
          </Box>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className={formStyles.formContainer}>
              <SignUpForm />
            </form>
          </FormProvider>
        </Container>
      </Box>
    </div>
  )
}

export default CreateAccount
