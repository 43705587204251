import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext'
import { useContext } from 'react'

export function useOnboardingContext() {
  const context = useContext(OnboardingContext)
  if (!context) {
    throw new Error('useOnboardingContext must be used within an OnboardingProvider')
  }
  return context
}
