import styles from './styles.module.scss'
import { useStaticPageContext } from '@/context/StaticPageContext'
import { SiteWideTypes } from '@/types/SiteContext.interface'
import { numberToMoneyString } from '@/utils/helpers'
import classNames from 'classnames'
import { useState, useEffect } from 'react'
import { DashboardDeviceProps } from './types'
import { ReactComponent as StatSign } from '@/assets/home/statSign.svg'
import { ReactComponent as PieChart } from '@/assets/home/pie.svg'
const VITE_VIDEO_URL = import.meta.env.VITE_VIDEO_URL

const DashboardDevice: React.FC<DashboardDeviceProps> = ({ inFocus }) => {
  const { siteType } = useStaticPageContext() as SiteWideTypes
  const isPeople = siteType === 'people'
  const moneyAmmount = isPeople ? 'k' : 'M'
  const [currentRevenue, setCurrentRevenue] = useState(0)
  const [currentViews, setCurrentViews] = useState(0)
  const [currentFollowers, setCurrentFollowers] = useState(0)
  const [currentProducts, setCurrentProducts] = useState(0)
  const [currentProductFollowers, setCurrentProductFollowers] = useState(0)
  const [currentStatsCardTotalRevenue, setCurrentStatsCardTotalRevenue] =
    useState(0)
  const [
    currentStatsCardVendorTotalShoppers,
    setCurrentStatsCardVendorTotalShoppers
  ] = useState(0)
  const [
    currentStatsCardCreatorInNetwork,
    setCurrentStatsCardCreatorInNetwork
  ] = useState(0)
  const [
    currentStatsCreatorCardOutNetwork,
    setCurrentStatsCreatorCardOutNetwork
  ] = useState(0)

  useEffect(() => {
    let animationId: ReturnType<typeof setInterval>
    let timeoutId: ReturnType<typeof setTimeout>
    resetStats()
    if (siteType) {
      resetStats()
    }
    if (inFocus) {
      animationId = setInterval(() => {
        setCurrentRevenue((prev: number) => prev + 0.4)
        setCurrentViews((prev: number) => prev + 10)
        setCurrentFollowers((prev: number) => prev + 10)
        setCurrentProducts((prev: number) => prev + 10)
        setCurrentProductFollowers((prev: number) => prev + 10)
        setCurrentStatsCardTotalRevenue((prev: number) => prev + 0.1)
        setCurrentStatsCardVendorTotalShoppers((prev: number) => prev + 10)
        setCurrentStatsCardCreatorInNetwork((prev: number) => prev + 10)
        setCurrentStatsCreatorCardOutNetwork((prev: number) => prev + 10)
      }, 100)
    } else {
      resetStats()
    }
    timeoutId = setTimeout(() => {
      clearInterval(animationId)
    }, 60 * 1000)
    return () => {
      clearInterval(animationId)
      clearTimeout(timeoutId)
    }
  }, [inFocus, siteType])

  const resetStats = () => {
    const totalRevenue = isPeople ? 4078 : 1834.078
    const totalViews = isPeople ? 2364.723 : 2364.723
    const totalFollowers = isPeople ? 2.364 : 2.364
    const totalProducts = isPeople ? 46.723 : 46.723
    const totalProductFollowers = isPeople ? 1.34 : 1.34
    const statsCardTotalRevenue = isPeople ? 1.4 : 12
    const statsCardVendorTotalShoppers = 2100
    const statsCardCreatorInNetwork = 490
    const statsCreatorCardOutNetwork = 260
    setCurrentRevenue(totalRevenue)
    setCurrentViews(totalViews)
    setCurrentFollowers(totalFollowers)
    setCurrentProducts(totalProducts)
    setCurrentProductFollowers(totalProductFollowers)
    setCurrentStatsCardTotalRevenue(statsCardTotalRevenue)
    setCurrentStatsCardVendorTotalShoppers(statsCardVendorTotalShoppers)
    setCurrentStatsCardCreatorInNetwork(statsCardCreatorInNetwork)
    setCurrentStatsCreatorCardOutNetwork(statsCreatorCardOutNetwork)
  }

  return (
    <div className={styles.dashboardStatPhone}>
      <div className={styles.videoContainer}>
        <video autoPlay playsInline muted loop>
          <source src={`${VITE_VIDEO_URL}serious-money.mp4`} type="video/mp4" />
        </video>
      </div>
      <div className={styles.dashboardStatsCardWrapper}>
        <img
          src={`/images/pers-card-dashboard-stat${
            !isPeople ? '-vendor' : ''
          }.png`}
          alt="dashboard stats"
        />
        <div
          className={
            isPeople
              ? styles.statsCardTotalRevenueWrapper
              : styles.statsCardTotalRevenueWrapperVendor
          }
        >
          {currentStatsCardTotalRevenue.toFixed(0)}
          {moneyAmmount}
        </div>
        {!isPeople ? (
          <div className={styles.statsCardVendorTotalShoppersWrapper}>
            {numberToMoneyString(currentStatsCardVendorTotalShoppers)}
          </div>
        ) : (
          <div className={styles.statsCardCreatorInNetworkWrapper}>
            {numberToMoneyString(currentStatsCardCreatorInNetwork)}
          </div>
        )}
        {isPeople && (
          <div className={styles.statsCreatorCardOutNetworkWrapper}>
            {numberToMoneyString(currentStatsCreatorCardOutNetwork)}
          </div>
        )}
      </div>
    </div>
  )
}

export default DashboardDevice
