import styles from './styles.module.scss'
import NewTitle from '@/components/Title/Title'
import { ReactComponent as MobileBack } from '@/assets/home/mobile-back.svg'
import { ReactComponent as BreadCrumbArrow } from '@/assets/home/breadcrumb-arrow.svg'
import Footer from '@/components/Footer/Footer'
import TopBar from '@/components/SubPagesComp/TopBar/TopBar'
import classNames from 'classnames'

function PrivacyPolicyPage() {
  const isMobile = window.innerWidth < 768
  const title = (
    <div className="breakTitle">
      <span data-line="VideoShops">
        Privacy <span className="vs-primary-color">Policy</span>
      </span>
    </div>
  )
  return (
    <>
      <TopBar />
      <div className={styles.subPageWrapper}>
        <div className={styles.aboutUsTopWrapper}>
          {isMobile ? (
            <a href="/">
              {' '}
              <MobileBack />
            </a>
          ) : (
            <div className={styles.breadcrumbWrapper}>
              <a href="/">Home</a> <BreadCrumbArrow /> Privacy Policy
            </div>
          )}
        </div>
        <div>
            <p>
              To view our Cookie Policy, click{' '}
              <a href="http://videoshops.com/cookie-policy" target="_blank">
                here
              </a>
              .
            </p>
        </div>

        <NewTitle children={title} titleColor="#000" />
        <div
          className={classNames(styles.subPageContent, styles.fullWidthContent)}
        >
          <h6>VideoShops Privacy Policy</h6>
          <h6>Effective date: October 7, 2023</h6>
          <p>
            We at VideoShops know you care about how your personal information
            is used and shared, and we take your privacy seriously.
          </p>
          <p>Please read the following to learn about our Privacy Policy.</p>
          <p>
            By using or accessing our Platform in any manner, you acknowledge
            that you accept the policies provided in this Privacy Policy, and
            you consent to VideoShops&rsquo; collection, use, and sharing of
            your information as provided herein.&nbsp;
          </p>
          <p>
            All terms used in this Privacy Policy shall be accorded the same
            meaning as provided in the Terms of Use (unless otherwise provided
            herein) and shall be subject to the Terms of Use.&nbsp; If you post
            your products for sale on our Platform, you will also be bound by
            the Brand Directory Agreement and if you use the Platform to sell
            products available on the Platform, you will be bound by the
            Personal Marketplace Agreement.
          </p>
          <p>
            <strong>
              <u>What does this Privacy Policy cover?</u>
            </strong>
          </p>
          <p>
            This Privacy Policy covers how VideoShops handles personally
            identifiable information ("
            <strong>
              <u>Personal Information</u>
            </strong>
            ") that VideoShops gathers when you are accessin g or using our
            Platform, but not to the practices of companies that VideoShops does
            not own or control, or with respect to individuals that VideoShops
            do not manage.
          </p>
          <p>
            VideoShops gathers various types of Personal Information from its
            users, as explained in more detail below, and VideoShops uses this
            Personal Information internally in connection with our Platform,
            including to personalize, provide, and improve our Platform, to
            allow you to set up a user account and profile, to contact you and
            allow other users to contact you, to fulfill your requests for
            certain products and services, and to analyze how you use our
            Platform.
          </p>
          <p>
            In certain cases, we may also share some Personal Information with
            third parties, but only as described below. As noted in the{' '}
            <strong>
              <em>Terms of Use</em>
            </strong>{' '}
            [
            <strong>
              <u>terms of service link here</u>
            </strong>{' '}
            ], VideoShops does not knowingly collect or solicit personal
            information from anyone under the age of 13. If you are under 13,
            please do not attempt to register to use the Platform or send any
            personal information about yourself to us. If we learn that we have
            collected personal information from a child under age 13, we will
            delete that information as quickly as possible. If you believe that
            a child under 13 may have provided personal information to
            VideoShops, please contact us at hello@VideoShops.com.
          </p>
          <p>
            <strong>
              <u>Will VideoShops ever change this Privacy Policy?</u>
            </strong>
          </p>
          <p>
            We&rsquo;re constantly trying to improve our Platform, so we may
            need to change this Privacy Policy from time to time, but we will
            alert you to changes by placing a notice on the Platform or by
            sending you an email. If you use our Platform after any changes to
            the Privacy Policy have been posted or provided to you, you will be
            bound by those changes.
          </p>
          <p>What Information does VideoShops Collect?</p>
          <p>
            <strong>Information You Provide to Us:</strong>
          </p>
          <p>
            We receive and store any information you knowingly provide to us.
            For example, through the registration process and/or through your
            account settings, we may collect Personal Information such as your
            name, email address, phone number, and third-party account
            credentials (for example, your log-in credentials for Facebook or
            other websites). If you provide your third-party account credentials
            to us or otherwise sign in to the Platform through a third party
            site or service, you understand some content and/or information in
            those accounts (&ldquo;
            <strong>
              <u>Third Party Account Information</u>
            </strong>
            &rdquo;) may be transmitted into your account with us, and that
            Third Party Account Information transmitted to our Platform is
            covered by this Privacy Policy; for example, the basic profile
            information we receive from Facebook about you, including your name,
            friend count, and age range, is Third Party Account Information
            covered by this Privacy Policy. Certain information may be required
            to register with us or to take advantage of some of our features.
          </p>
          <p>
            <strong>
              <u>Information Collected Automatically</u>
            </strong>
          </p>
          <p>
            Whenever you interact with our Platform, we automatically receive
            and record information on our server logs from your browser or
            device, which may include your IP address, geolocation data, device
            identification, &ldquo;cookie&rdquo; information, the type of
            browser and/or device you&rsquo;re using to access our Platform, and
            the page or feature you requested. &ldquo;Cookies&rdquo; are
            identifiers we transfer to your browser or device that allow us to
            recognize your browser or device and tell us how and when pages and
            features in our Platform are visited and by how many people. You may
            be able to change the preferences on your browser or device to
            prevent or limit your device&rsquo;s acceptance of cookies, but this
            may prevent you from taking advantage of some of our features. To
            the extent that we provide advertising on our Platform, our
            advertising partners may also transmit cookies to your browser or
            device, when you click on ads that appear on our Platform. Also, if
            you click on a link to a third-party website or service, a third
            party may also transmit cookies to you. Again, this Privacy Policy
            does not cover the use of cookies by any third parties, and we
            aren&rsquo;t responsible for their privacy policies and practices.
            Please be aware that cookies placed by third parties may continue to
            track your activities online even after you have left our Platform,
            and those third parties may not honor &ldquo;Do Not Track&rdquo;
            requests you have set using your browser or device. We may use this
            data to customize content for you that we think you might like,
            based on your usage patterns. We may also use it to improve our
            Platform &ndash; for example, this data can tell us how often users
            use a particular feature of our Platform, and we can use that
            knowledge to make our Platform useful to as many users as possible.
          </p>
          <p>
            <strong>
              Will VideoShops Share Any of the Personal Information it Receives?
            </strong>
          </p>
          <p>
            We may share your Personal Information with third parties as
            described in this section:
          </p>
          <p>
            <strong>
              <em>
                <u>&ldquo;Identity Removed&rdquo; Information</u>
              </em>
            </strong>
            . We may &ldquo;de-identify&rdquo; your Personal Information so that
            you are not identified as an individual, and provide that
            information to our partners, including analytics providers. We may
            also provide aggregate usage information to our partners (or allow
            partners to collect that information from you), who may use such
            information to understand how often and in what ways people use our
            Platform, so that they, too, can provide you with an optimal online
            experience. However, we never disclose aggregate usage or
            &ldquo;de-identified&rdquo; information to a partner (or allow a
            partner to collect such information) in a manner that would identify
            you as an individual person.
          </p>
          <p>
            <strong>
              <em>
                <u>Partners/Advertisers</u>
              </em>
            </strong>
            . We allow merchant partners, and if applicable, advertisers
            (collectively, &ldquo;<strong>Partners</strong>&rdquo;) to choose
            the demographic information of users who will see their
            advertisements and/or promotional offers and you agree that we may
            provide any of the information we have collected from you in
            non-personally identifiable form to an Advertiser, in order for that
            Advertiser to select the appropriate audience for those
            advertisements and/or offers. For example, we might use the fact you
            are located in New York, New York to show you ads or offers for New
            York, New York businesses, but we will not tell such businesses who
            you are. Or, we might allow Partners to display their ads to users
            with similar usage patterns to yours, but we will not disclose usage
            information to Partners except in aggregate form, and not in a
            manner that would identify you personally. Note that if an
            advertiser asks us to show an ad to a certain audience or audience
            segment and you respond to that ad, the advertiser may conclude that
            you fit the description of the audience they were trying to reach.
          </p>
          <p>
            <strong>
              <em>
                <u>Affiliated Businesses</u>
              </em>
            </strong>
            . We may share Personal Information and aggregated information with
            third parties, and we may provide such information to third parties,
            by license or otherwise. For example, we may share your Personal
            Information, or any content that you upload to our Platform, with
            our brand partners and other affiliated businesses. In certain
            situations, businesses or third party websites and products
            we&rsquo;re affiliated with may sell or provide products, services
            or promotions to you through or in connection with our Platform
            (either alone or jointly with us). For example, we have affiliate
            relationships with retailers and may make their products available
            to you through our Platform, or connect you to such retailers after
            you leave our website. We may share your Personal Information with
            such retailers in connection with your activity on our website. Your
            information may also be used by us or shared with our sponsors,
            partners, or other third parties in connection with marketing,
            promotional, and other offers, as well as product information, and
            for such third party&rsquo;s advertising, analytics and market
            research or other purposes. We have no control over the policies and
            practices of third party websites or businesses as to privacy or
            anything else, so if you choose to take part in any transaction or
            service relating to an affiliated website or business, please review
            all such business&rsquo; or websites&rsquo; policies.
          </p>
          <p>
            <strong>
              <em>
                <u>Agents</u>
              </em>
            </strong>
            . We employ other companies and people to perform tasks on our
            behalf and need to share your information with them to provide
            products or services to you. Unless we tell you differently, our
            agents do not have any right to use the Personal Information we
            share with them beyond what is necessary to assist us. Note that an
            &ldquo;agent&rdquo; may also be considered a &ldquo;partner&rdquo;
            in certain circumstances, and would be subject to the terms of the
            &ldquo;Identity Removed&rdquo; Information &nbsp;section above.
          </p>
          <p>
            <strong>
              <em>
                <u>User Profiles and Submissions.</u>
              </em>
            </strong>{' '}
            Certain user profile information, including your name, location, and
            any video or image content that such user has uploaded to our
            Platform, may be displayed to other users to facilitate user
            interaction within the Platform or address your request for our
            services. Please remember that any content you upload to your public
            user profile, along with any Personal Information or content that
            you voluntarily disclose online in a manner other users can view (on
            discussion boards, in messages and chat areas, etc.) becomes
            publicly available, and can be collected and used by anyone. We may
            also share this information with our brand partners or affiliates.
            Your user name may also be displayed to other users if and when you
            send messages or comments or upload images or videos through the
            Platform and other users can contact you through messages and
            comments.
          </p>
          <p>
            <strong>
              <em>Business Transfers.</em>
            </strong>{' '}
            We may choose to buy or sell assets, and may share and/or transfer
            customer information in connection with the evaluation of and entry
            into such transactions.&nbsp; Also, if we (or our assets) are
            acquired or if we go through some other change of control, Personal
            Information could be one of the assets transferred to or acquired by
            a third party.
          </p>
          <p>
            <strong>
              <em>Protection of VideoShops and Others.</em>
            </strong>{' '}
            We reserve the right to access, read, preserve, and disclose any
            information that we believe is necessary to comply with law or court
            order; enforce or apply our Terms of Use [insert link] and other
            agreements; or protect the rights, property, or safety of
            VideoShops, our employees, our users, or others.
          </p>
          <p>
            <strong>Is Personal Information about me secure?</strong>
          </p>
          <p>
            Your account is protected by your phone number or social account
            credentials. You must prevent unauthorized access to your account
            and Personal Information by selecting and protecting your sign-on
            mechanism appropriately and limiting access to your computer or
            device.
          </p>
          <p>
            We endeavor to protect the privacy of your account and other
            Personal Information we hold in our records, but unfortunately, we
            cannot guarantee complete security. Unauthorized entry or use,
            hardware or software failure, and other factors, may compromise the
            security of user information at any time.
          </p>
          <p>
            <strong>What Personal Information can I access?</strong>
          </p>
          <p>
            Through your account settings, you may access, and, in some cases,
            edit or delete the following information you&rsquo;ve provided to
            us:
          </p>
          <p>
            <strong>
              <em>
                <u>User profile information</u>
              </em>
            </strong>
            , includes images and videos you have uploaded to the site.
          </p>
          <p>
            The information you can view, update, and delete may change as the
            Platform changes.
          </p>
          <p>
            If you have any questions about viewing or updating information we
            have on file about you, please contact us at hello@VideoShops.com.
          </p>
          <p>
            Under California Civil Code, California residents are entitled to
            contact us to prevent disclosure of Personal Information to third
            parties for such third parties&rsquo; direct marketing purposes; in
            order to submit such a request, please contact us at
            hello@VideoShops.com.
          </p>
          <p>
            <strong>What choices do I have?</strong>
          </p>
          <p>
            You can always opt not to disclose information to us, but keep in
            mind some information may be needed to register with us or to take
            advantage of some features of our Platform.
          </p>
          <p>
            You may be able to add, update, or delete information as provided
            above. When you update information, however, we may maintain a copy
            of the unrevised information in our records.
          </p>
          <p>
            <strong>
              <em>You may disable your account at any time</em>
            </strong>
            . Some information may remain in our records after your deletion of
            such information from your account. We may use any aggregated data
            derived from or incorporating your Personal Information after you
            update or delete it, but not in a manner that would identify you
            personally.
          </p>
          <p>
            <strong>What if I have questions about this policy?</strong>
          </p>
          <p>
            If you have any questions or concerns regarding this{' '}
            <strong>
              <em>Privacy Policy,</em>
            </strong>{' '}
            please send us a message to
          </p>
          <p>
            <a href="mailto:hello@VideoShops.com.">hello@VideoShops.com</a>
          </p>
        </div>
        <Footer />
      </div>
    </>
  )
}
export default PrivacyPolicyPage
