import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from '../../styles.module.scss'
import { useFormContext } from 'react-hook-form'
import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext'
import { OnBaordingProps } from '@/components/OnboardingComp/types'
import { getValueFromNestedObject, updateFormState } from '@/utils/formHelpers'
import classNames from 'classnames'

type OptionEntry = {
  value: string
  label: string
}

interface CurrencySelectProps {
  label?: string
  colW?: number | string
  name: string
  optionData: OptionEntry[]
  className?: string
  required?: boolean
  helpText?: string
}

const SelectComp: React.FC<CurrencySelectProps> = ({
  label,
  colW,
  name,
  optionData,
  className,
  required,
  helpText
}) => {
  const selectRef = useRef<HTMLSelectElement>(null)
  const { trigger, formState, setValue, getValues, register } = useFormContext()
  const { formData, setFormData } = useContext(
    OnboardingContext
  ) as OnBaordingProps

  const { errors } = formState
  const error = errors[name]
  const fieldError = error as FieldErrorProps
  const activeValue = getValues(name)
  const [focused, setFocused] = useState(true)
  useEffect(() => {
    const retrievedValue = getValueFromNestedObject(formData, name)
    if (retrievedValue !== null) {
      setValue(name, retrievedValue)
      setFocused(true)
    } else if (
      name === 'countryCode' ||
      name === 'currency' ||
      name === 'commissionRate'
    ) {
      const defaultValue = optionData[0].value
      setValue(name, defaultValue)
      updateFormState(setFormData, name, defaultValue)
      setFocused(true)
    } else {
      console.log('Something happened during component setup.')
    }
  }, [])

  const handleBlur = () => {
    if (selectRef.current?.value) {
      return
    }
    setFocused(false)
  }

  const onClick = () => {
    setFocused(true)
    selectRef.current?.focus()
  }

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const currentValue = e.currentTarget.value
    setValue(name, currentValue === '' ? null : currentValue)
    trigger(name)
    updateFormState(setFormData, name, currentValue)
  }

  return (
    <div
      className={classNames(
        `col-${colW}`,
        styles.formGroup,
        styles.selectWrapper,
        className
      )}
      onClick={onClick}
    >
      {label && (
        <label>
          {label}
          {required && <span className={styles.requiredAsterix}>*</span>}
        </label>
      )}
      <select
        {...register(name)}
        ref={selectRef}
        name={name}
        value={activeValue || ''}
        onChange={onChange}
        defaultValue={optionData[0].value}
        onFocus={() => setFocused(true)}
        onBlur={handleBlur}
      >
        {optionData.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className={styles.fieldErrors}>
        {helpText && <p className={styles.helpText}>{helpText}</p>}
        {fieldError?.message && <p>{fieldError.message}</p>}
      </div>
    </div>
  )
}

export default SelectComp
