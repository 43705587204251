import { FC, useEffect } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import Box from '@mui/system/Box'
import InputField from '@/components/Form/Fields/InputField/InputField'
import TopBar from '@/shared/components/TopBar/TopBar.tsx'
import Container from '@mui/system/Container'
import Typography from '@mui/material/Typography'
import BackButton from '@/shared/components/BackButton/BackButton.tsx'
import StepHeader from '../../StepHeader/StepHeader.tsx'
import formStyles from '@/components/Form/styles.module.scss'
import styles from '../../styles.module.scss'
import StepCounter from '@/components/OnboardingCompNew/StepCounter.tsx'
import ProgressBar from '../../ProgressBar.tsx'
import FinalButton from '@/videoshops-shared/components/FinalButton/FinalButton.tsx'
import useOnboarding from '../../useOnboarding.ts'
import { OnboardingStep } from '@/constants/onboarding.constant.ts'

const MAX_LENGTH = 30

const nameValidation = Yup.object().shape({
  name: Yup.string()
    .max(MAX_LENGTH, 'Please try again! Maximum length is 30 characters.')
    .required('Name is required')
    .matches(/^[a-zA-ZÀ-ÿ0-9_\s'’-]+$/, 'No special characters allowed')
})

const MarketPlaceName: FC = () => {
  const navigate = useNavigate()
  const { saveOnboardingData } = useOnboarding()

  const form = useForm({
    mode: 'onChange',
    defaultValues: { name: '' },
    resolver: yupResolver(nameValidation)
  })
  const name = form.watch('name') || ''

  const onSubmit = async () => {
    const saved = await saveOnboardingData(
      OnboardingStep.SHOP_NAME,
      localStorage.getItem('TOKEN') || ''
    )
    if (saved) {
      navigate('/creator/setup/link')
    }
  }

  useEffect(() => {
    form.trigger('name')
    if (name) {
      form.setValue('name', name)
    }
  }, [name])

  return (
    <div className={styles.stepPage}>
      <TopBar />
      <ProgressBar value={(100 / 8) * 3} />
      <Box mt={{ xs: 1.5, md: 3.5 }}>
        <Container maxWidth="lg">
          <Box height={36} display="flex" alignItems="center" justifyContent="space-between">
            <BackButton to="/creator/setup/verify-code" />
            <StepCounter step={3} total={8} />
          </Box>
        </Container>
        <Container maxWidth={false} sx={{ maxWidth: 459 }}>
          <Box mt={2}>
            <StepHeader>Confirm Your Shop Name</StepHeader>
            <Box mt="22px" textAlign="center">
              <Typography align="center" variant="xs_med" color="ash">
                Your shop name is permanent and cannot be changed.
              </Typography>
            </Box>
          </Box>
          <Box mt="22px">
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className={formStyles.formContainer}>
                <Box>
                  <InputField label="Shop Name" name="name" type="text" required />
                </Box>
                <Box mt={3}>
                  <FinalButton
                    disabled={name === '' || name.length > MAX_LENGTH}
                    type="submit"
                    variant="primary"
                    full
                  >
                    Next
                  </FinalButton>
                </Box>
              </form>
            </FormProvider>
          </Box>
        </Container>
      </Box>
    </div>
  )
}

export default MarketPlaceName
