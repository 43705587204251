import { FC, useRef } from 'react'
import styles from '../../styles.module.scss'
import { useOnboardingContext } from '@/hooks/useOnboardingForm'

import { toast } from 'react-toastify'
import CustomToast from '@/components/Toaster/Toaster'
import { postRequest } from '@/api/api'
import { UploadResponseData } from '@/components/Form/Fields/FileUpload/types'
import { appConfig } from '@/app-config'
import { useFormContext } from 'react-hook-form'
import { updateFormState } from '@/utils/formHelpers'
import heroDefaultBG from '@/assets/images/brand-card-thumbnail-placeholder.svg'

import useImageEditor from '../../../../videoshops-shared/hooks/ImageEditor/useImageEditorHook'
import ImageEditor from '../../../../videoshops-shared/components/ImageEditor/ImageEditor'
import Modal from '../../../../videoshops-shared/components/Utility/Modal/Modal'
import { ResObj } from '../../../../videoshops-shared/components/ImageEditor/types'

const BrandDirectoryThumbnail: FC = () => {
  const { formData, onboardActions, siteType, setFormData } =
    useOnboardingContext()
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { setValue } = useFormContext()
  const { setImage, image, setOpenModal, openModal } = useImageEditor()
  const { marketplace } = formData
  const hasThumbnailImage = marketplace?.thumbnailImage
  const bgimage =
    marketplace && hasThumbnailImage
      ? `${marketplace.thumbnailImage}`
      : heroDefaultBG
  const previewImageStyle = { backgroundImage: `url(${bgimage})` }
  const thumbnailImageKey = 'thumbnailImageKey'
  const thumbnailImage = 'thumbnailImage'

  const handleOnClick = () => {
    setOpenModal(true)
    setImage(bgimage)
  }
  const handleSubmitImageEdit = async (resObj: ResObj) => {
    if (!resObj) return
    const toastId = toast(<CustomToast />)
    timeoutRef.current = setTimeout(() => {
      toast.dismiss(toastId)
      toast(
        <CustomToast
          type="error"
          text="Please try again with a smaller file size image."
        />
      )
    }, 15000)
    try {
      const headers = {
        'Content-Type': resObj.imageType,
        ...(siteType === 'vendor'
          ? { Authorization: localStorage.getItem('TOKEN') || '' }
          : {})
      }

      const uploadFileRequest = await postRequest<UploadResponseData>(
        `${appConfig.UPLOAD_BASE_URL}/user/upload`,
        resObj.blob,
        { headers }
      )
      if (
        uploadFileRequest.status === 200 &&
        uploadFileRequest.data &&
        'data' in uploadFileRequest.data
      ) {
        const { key, path } = (uploadFileRequest.data as UploadResponseData)
          .data
        setValue(thumbnailImageKey, key)
        setValue(thumbnailImage, path)
        updateFormState(setFormData, thumbnailImage, path)
        updateFormState(setFormData, thumbnailImageKey, key)
        toast.dismiss(toastId)
        clearTimeout(timeoutRef.current!)
        toast(
          <CustomToast type="success" text="Image Uploaded Successfully!" />
        )
      } else {
        toast.dismiss(toastId)
        clearTimeout(timeoutRef.current!)
        toast(
          <CustomToast
            type="error"
            text="Image Upload Failed Please try again."
          />
        )
      }
    } catch (error) {
      toast.dismiss(toastId)
      clearTimeout(timeoutRef.current!)
      toast(
        <CustomToast
          type="error"
          text="Image Upload Failed Please try again."
        />
      )
    }
  }
  return (
    <div className={styles.stepContainer}>
      <h1>
        Choose <span className="vs-primary-color">Thumbnail Image</span>
      </h1>
      {!onboardActions && (
        <div
          className={styles.thumbnailImageWrapper}
          style={previewImageStyle}
          onClick={handleOnClick}
        >
          {hasThumbnailImage && (
            <div className={styles.userElipseIconWrapper}>
              <img src={`/icons/camera-blue.svg`} />
            </div>
          )}
        </div>
      )}
      {image && (
        <Modal
          onClose={() => console.log('dcfd')}
          open={openModal}
          heading="Thumbnail Image"
        >
          <ImageEditor
            image={image}
            setOpenModal={setOpenModal}
            croppingType="square"
            submitCrop={handleSubmitImageEdit}
            hasImageAlready={hasThumbnailImage}
          />
        </Modal>
      )}
    </div>
  )
}

export default BrandDirectoryThumbnail
