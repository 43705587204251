import { FC, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import styles from '@/components/OnboardingCompNew/styles.module.scss'
import TopBar from '@/shared/components/TopBar/TopBar.tsx'
import Box from '@mui/system/Box'
import Container from '@mui/system/Container'
import BackButton from '@/shared/components/BackButton/BackButton.tsx'
import StepHeader from '@/components/OnboardingCompNew/StepHeader/StepHeader.tsx'
import formStyles from '@/components/Form/styles.module.scss'
import TextArea from '@/components/Form/Fields/TextArea/TextArea.tsx'
import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext.tsx'
import { OnBaordingProps } from '@/components/OnboardingComp/types.ts'
import FinalButton from '@/videoshops-shared/components/FinalButton/FinalButton.tsx'
import { OnboardingStep } from '@/constants/onboarding.constant'
import useOnboarding from '../../useOnboarding'

const BrandDescription: FC = () => {
  const navigate = useNavigate()
  const { saveOnboardingData } = useOnboarding()

  const { formData } = useContext(OnboardingContext) as OnBaordingProps
  const form = useForm({
    mode: 'onChange',
    defaultValues: { description: '' }
  })
  const description =
    formData.marketplace.description || 'Tell sellers and shoppers about your brand.'

  const onSubmit = async () => {
    const saved = await saveOnboardingData(
      OnboardingStep.SHOP_DESCRIPTION,
      localStorage.getItem('TOKEN') || ''
    )
    if (saved) {
      navigate('/brand/setup/brand-logo')
    }
  }

  return (
    <div className={styles.stepPage}>
      <TopBar />
      <Box mt={{ xs: 1.5, md: 3.5 }}>
        <Container maxWidth="lg">
          <BackButton to="/brand/setup/name" />
        </Container>
        <Container maxWidth={false} sx={{ maxWidth: 411 }}>
          <Box mt={3}>
            <StepHeader title="Merchant Sign Up">Brand Description</StepHeader>
          </Box>
          <Box mt={2}>
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className={formStyles.formContainer}>
                <TextArea label="Description" name="description" placeholder={description} />
                <Box mt={4}>
                  <FinalButton type="submit" variant="primary" full>
                    Next
                  </FinalButton>
                </Box>
              </form>
            </FormProvider>
          </Box>
        </Container>
      </Box>
    </div>
  )
}

export default BrandDescription
