export const hasAnyNonNullValue = (obj: Record<string, any>): boolean => {
  return Object.values(obj).some((value) => value !== null)
}

export const hasNoNullValues = (obj: Record<string, any>): boolean => {
  for (const key in obj) {
    if (obj[key] === null) return false
    if (typeof obj[key] === 'object' && !hasNoNullValues(obj[key])) return false
  }
  return true
}

export const updateFormDataWithMapping = (
  formValues: any,
  activeObject: any,
  activeKeyMap: any
) => {
  const transformed = JSON.parse(JSON.stringify(activeObject))
  for (const key in formValues) {
    if (activeKeyMap.user.includes(key)) {
      transformed.user[key as keyof typeof activeObject.user] = formValues[key]
    } else if (activeKeyMap.marketplace.includes(key)) {
      transformed.marketplace[key as keyof typeof activeObject.marketplace] =
        formValues[key]
    } else {
      console.log(`Key not found in activeKeyMap: ${key}`)
    }
  }
  return transformed
}

export const updateFormState = (
  setFormData: React.Dispatch<React.SetStateAction<CreatorState | VendorState>>,
  changeKey: string,
  newValue?: string
) => {
  setFormData((prevData) => {
    const updatedData: CreatorState | VendorState = { ...prevData }
    if (Object.prototype.hasOwnProperty.call(updatedData, changeKey)) {
      updatedData[changeKey] = newValue
    } else {
      for (const key in updatedData) {
        if (
          typeof updatedData[key] === 'object' &&
          Object.prototype.hasOwnProperty.call(updatedData[key], changeKey)
        ) {
          updatedData[key][changeKey] = newValue
        }
      }
    }
    // console.log(changeKey, newValue, "FORMAPPING ", updatedData)
    return updatedData
  })
}
export const getValueFromNestedObject = (obj: any, key: string): any | null => {
  if (!obj) return null
  if (Object.prototype.hasOwnProperty.call(obj, key)) return obj[key]

  for (const prop in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, prop) &&
      typeof obj[prop] === 'object'
    ) {
      const foundValue = getValueFromNestedObject(obj[prop], key)
      if (foundValue !== null) return foundValue
    }
  }
  return null
}
