import { FC } from "react";
import Box from "@mui/material/Box";
import cn from "classnames";
import styles from "../../styles.module.scss";
import Button from "@/components/Button/Button.tsx";
import googleIcon from "@/assets/google-icon.svg";

const getGoogleSignInUrl = () => {
  const clientId = import.meta.env.VITE_COGNITO_CLIENT_ID;
  const redirectUri = `${window.location.origin}/creator/setup/verify-account`;
  const cognitoDomain = import.meta.env.VITE_COGNITO_DOMAIN;
  return `https://${cognitoDomain}/oauth2/authorize?identity_provider=Google&redirect_uri=${redirectUri}&response_type=TOKEN&client_id=${clientId}&scope=email+openid+profile+aws.cognito.signin.user.admin`;
};

const ContinueWithGoogle: FC = () => {
  return (
    <Button
      fullWidth
      className={cn(styles.button, styles.googleButton)}
      onClick={() => {
        window.location.href = getGoogleSignInUrl();
      }}
    >
      <Box component="img" src={googleIcon} alt="google icon" />
      Continue with Google
    </Button>
  );
};

export default ContinueWithGoogle;
