import { FC } from 'react'
import styles from '../../styles.module.scss'
import SignUpForm from './SignUpForm/SignUpForm'

const CreatorSignupForm: FC = () => {
  return (
    <div className={styles.stepContainer}>
      <h1>
        {' '}
        Express <span className="vs-primary-color">Sign Up</span>
      </h1>
      {/* <div className={styles.signupSubTextHeading}>
        <span>Creator Sign Up</span>
      </div> */}
      <div>
        <SignUpForm />
        {/* <Button className={styles.creatorFormSignuUpButton} onClick={() => setFormVisible(true)} primary={!formVisible} white={formVisible} fullWidth>
                    <span>
                        <span>
                            <img src="/icons/social-email.svg"></img>
                        </span>
                        <span>
                            Continue with Mobile
                        </span>
                    </span>
                </Button> */}
        {/* {formVisible && (      
                )} */}
      </div>
    </div>
  )
}

export default CreatorSignupForm
