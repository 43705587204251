import Box from '@mui/material/Box'

interface ProgressBarProps {
  value: number
}

export default function ProgressBar({ value }: ProgressBarProps) {
  return (
    <Box bgcolor="#F5F5F5" height="3px">
      <Box width={`${value}%`} bgcolor="#5231FA" height="3px" />
    </Box>
  )
}
