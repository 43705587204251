import styled from 'styled-components'

export const StyledFinalButton = styled.button`
  --button-bg-color: #000;
  --button-color: #fcfcfc;
  --_size: 3.125rem;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  background-color: var(--button-bg-color);
  color: var(--button-color);
  block-size: var(--_size);
  border-radius: 4rem;
  padding-inline: 2rem;
  font-size: 0.875rem;
  font-weight: 700;
  border: 1px solid var(--button-bg-color);
  position: relative;
  transition: 160ms;
  min-inline-size: var(--_size);
  font-family: Montserrat;

  &.full {
    inline-size: 100%;
  }

  &.loading {
    & > * {
      opacity: 0;
    }

    .loading-icon {
      opacity: 1;
      position: absolute;
      color: inherit;
      inset-inline-start: 50%;
      inset-block-start: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:disabled {
    cursor: default;
    pointer-events: none;

    &:not(.loading) {
      --button-color: #b0b9c1;
      --button-bg-color: #ebebeb;
      border-color: #ebebeb;
    }
  }

  &:hover,
  &:active {
    --button-bg-color: #141414;
  }

  &.primary {
    --button-bg-color: #5231fa;

    &:hover,
    &:active {
      --button-bg-color: #291591;
    }
  }

  &.secondary {
    --button-bg-color: #f3f7fd;
    --button-color: #5231fa;
    border-color: #d5e1f3;

    &:hover,
    &:active {
      border-color: #5231fa;
    }
  }

  &.tertiary {
    --button-bg-color: #fcfcfc;
    --button-color: #000;
    border-color: #ebebeb;

    &:hover,
    &:active {
      --button-bg-color: #f5f5f5;
    }

    &:disabled:not(.loading) {
      --button-color: #b0b9c1;
    }
  }

  &.xl {
    --_size: 4rem;
    font-size: 1rem;
  }

  &.lg {
    --_size: 3.125rem;
    font-size: 1rem;
    font-weight: 600;
  }

  &.md {
    --_size: 2.25rem;
    padding-inline: 1.25rem;
    font-weight: 600;
    font-size: 0.75rem;
  }

  &.sm {
    --_size: 1.75rem;
    padding-inline: 1rem;
    font-weight: 600;
    font-size: 0.75rem;

    svg {
      inline-size: 1rem;
      block-size: 1rem;
    }
  }

  &.xs {
    --_size: 1.625rem;
    padding-inline: 1rem;
    font-weight: 600;
    font-size: 0.625rem;

    svg {
      inline-size: 0.75rem;
      block-size: 0.75rem;
    }
  }

  &.icon-only {
    padding-inline: 0rem;
    aspect-ratio: 1 / 1;
  }
`
