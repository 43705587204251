import { FC } from "react";
import { StyledHeroDetails } from "./HeroDetails.styled";
import { ReactComponent as CartShopIcon } from "../../shared/assets/icons/cart-shop.svg";
import { ReactComponent as VS } from "../../shared/assets/icons/powered-by-videoshops-stacked.svg";

interface HeroDetailsProps {
  marketplace: Marketplace;
  small?: boolean;
}

const HeroDetails: FC<HeroDetailsProps> = ({ marketplace, small }) => {
  return (
    <StyledHeroDetails className={small ? "small" : ""}>
      <div className="hero-header-actions"></div>
      <div className="hero-details-content">
        <CartShopIcon className="shop-products" />
        <div className="storefront-name">{marketplace.name}</div>
        <p>{marketplace.description}</p>
      </div>
      <div className="hero-footer">
        <span></span>
        <span className="logo">
          <VS />
        </span>
      </div>
    </StyledHeroDetails>
  );
};

export default HeroDetails;
