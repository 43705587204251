import { createContext, ReactElement, useContext } from 'react'

type DefaultContext<DataItem = {}> = DataItem

const defaultContext: DefaultContext<any> = {}
const StaticPageContextRaw = createContext<DefaultContext<any>>(defaultContext)

const { Provider } = StaticPageContextRaw

type Props<DataItem> = {
  data: DataItem
  children: ReactElement
}

export const StaticPageContextProvider: React.FC<Props<any>> = ({
  data,
  children
}) => {
  return <Provider value={data}>{children}</Provider>
}

export function useStaticPageContext<DataItem>(): DefaultContext<DataItem> {
  return useContext(StaticPageContextRaw)
}

export { StaticPageContextRaw }
