import { FC } from 'react'
import { OnboardingProvider } from '@/context/OnboardingContext/OnboardingProvider'
import CreatorSetupComp from '@/components/OnboardingCompNew/CreatorSetupComp'

const VendorSetupPage: FC = () => {
  return (
    <OnboardingProvider siteType="creator">
      <CreatorSetupComp />
    </OnboardingProvider>
  )
}

export default VendorSetupPage
