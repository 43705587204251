import classNames from 'classnames'
import styles from './styles.module.css'
import { ButtonProps } from './types'

const Button = ({
  label,
  variant = 'default',
  onClick,
  IconComponent,
  className
}: ButtonProps): JSX.Element => {
  const buttonClass = classNames({
    [styles.default]: variant === 'default',
    [styles.outline]: variant === 'outline',
    [styles.filled]: variant === 'filled',
    [styles['tab-filled']]: variant === 'tab-filled',
    [styles['tab']]: variant === 'tab'
  })

  return (
    <button className={classNames(buttonClass, className)} onClick={onClick}>
      {IconComponent && <span className={styles.icon}>{IconComponent}</span>}
      {label}
    </button>
  )
}

export default Button
