// theme.ts
import { DefaultTheme } from 'styled-components'

export const BREAKPOINTS: { [key: string]: string } = {
  mobile: '576px',
  tabletPortrait: '768px',
  tabletPortraitLarge: '992px',
  tabletLandscape: '1200px',
  laptopLarge: '1440px'
}

export const FONT_SIZES: {
  [key: string]: { size: string; lineHeight: string; weight: number }
} = {
  xs: { size: '0.625rem', lineHeight: '1.25rem', weight: 400 },
  small: { size: '0.75rem', lineHeight: '1rem', weight: 400 },
  regular: { size: '1rem', lineHeight: '1.5rem', weight: 400 },
  medium: { size: '1.5rem', lineHeight: '2rem', weight: 600 }
}

export const COLORS: { [key: string]: string } = {
  primary: 'rgba(82, 49, 250, 1)',
  danger: '#eb0f29',
  black: '#000',
  grey: '#70777e'
}

export const isMobile = () => window.innerWidth < parseInt(BREAKPOINTS.mobile)

export const isIOSDevice = () =>
  /iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent || '')

export const isAndroidDevice = () => /Android/i.test(navigator.userAgent || '')

const onboardingTheme: DefaultTheme = {
  breakpoints: {
    up: (size: keyof typeof BREAKPOINTS) => `@media (min-width: ${BREAKPOINTS[size]})`,
    down: (size: keyof typeof BREAKPOINTS) => `@media (max-width: ${BREAKPOINTS[size]})`
  },
  FONT_SIZES,
  COLORS,
  isMobile,
  isIOSDevice,
  isAndroidDevice
}

export default onboardingTheme
