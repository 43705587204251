import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ReactComponent as AlertErrorIcon } from '@/shared/assets/icons/alert-error.svg'
import { ReactComponent as AlertPendingIcon } from '@/shared/assets/icons/alert-pending.svg'
import { ReactComponent as AlertSuccessIcon } from '@/shared/assets/icons/alert-success.svg'

interface AlertProps {
  variant: 'pending' | 'success' | 'error'
  title: string
}

const icons = {
  error: <AlertErrorIcon />,
  pending: <AlertPendingIcon />,
  success: <AlertSuccessIcon />
}

const colors = {
  error: '#FF3A44',
  pending: '#B0B9C1',
  success: '#32A071'
}

export default function Alert({ variant, title }: AlertProps) {
  return (
    <Box
      display="inline-flex"
      alignItems="center"
      height={36}
      color={colors[variant]}
      borderRadius="8px"
      overflow="hidden"
      border={`1px solid ${colors[variant]}`}
    >
      <Box
        bgcolor={colors[variant]}
        px="6px"
        height="100%"
        display="flex"
        alignItems="center"
      >
        {icons[variant]}
      </Box>
      <Typography px="10px" variant="2xs_bold" fontWeight={700}>
        {title}
      </Typography>
    </Box>
  )
}
