import { useEffect, MutableRefObject } from 'react'

const useOnClickOutside = (
  ref: MutableRefObject<any>,
  handler?: (event: MouseEvent | TouchEvent) => void,
): void => {
  useEffect(() => {
    if (!handler || !ref) {
      return
    }

    const listener = (event: MouseEvent | TouchEvent) => {
      const el = ref?.current

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return
      }

      handler && handler(event)
    }
    // TODO: This is working because of a bug. The event listeners for closing the modal should not be active while there is no modal active.
    // TODO: Using mousedown event is a happy bug as the event is triggered before a click so it would try to close the modal before opening it, unlike click event
    document.addEventListener(`mousedown`, listener)
    document.addEventListener(`touchstart`, listener)

    return () => {
      document.removeEventListener(`mousedown`, listener)
      document.removeEventListener(`touchstart`, listener)
    }

    // Reload only if ref or handler changes
  }, [ref, handler])
}

export default useOnClickOutside
