import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import classNames from 'classnames'
import styles from '../../styles.module.scss'
import { useFormContext } from 'react-hook-form'
import { getValueFromNestedObject, updateFormState } from '@/utils/formHelpers'
import { OnBaordingProps } from '@/components/OnboardingComp/types'
import { OnboardingContext } from '@/context/OnboardingContext/OnboardingContext'
import { getRequest } from '@/api/api'
import { toast } from 'react-toastify'
import debounce from 'lodash/debounce'
import { codifyStr, isMobile } from '@/utils/helpers'
import { appConfig } from '@/app-config'
import CustomToast from '@/components/Toaster/Toaster'
import { profanityErrorStr } from '@/constants/profantiy'
import { checkForProfanity } from '@/utils/moderation.utils'
import URLSuggestions from './URLSuggestions.tsx'

interface AxiosResponseData {
  data: any
}

const URLField: React.FC<FormElementProps> = ({
  label,
  colW,
  name,
  placeholder,
  type,
  className,
  required,
  helpText
}) => {
  const [focused, setFocused] = useState(true)
  const [recommendedKeys, setRecommendedKeys] = useState<string[]>([])
  const [marketPlaceURL, setMarketPlaceURL] = useState('')
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null
  )
  const inputRef = useRef<HTMLInputElement>(null)
  const { setFormData, formData, setIsLoading } = useContext(
    OnboardingContext
  ) as OnBaordingProps
  const {
    trigger,
    formState,
    setValue,
    register,
    getValues,
    setError,
    clearErrors
  } = useFormContext()
  const activeValue = getValues(name)
  const validURLRegex = /^[a-z0-9_-]+$/
  const [isValidURL, setValidURL] = useState(false)

  const validateURLDebounced = useCallback(
    debounce((url: string, isURLValid: boolean) => {
      const toastId = toast(<CustomToast text="Checking URL..." />)
      if (validURLRegex.test(url)) {
        validateURL(url)
      }
      setTimeout(() => {
        toast.dismiss(toastId)
      }, 2000)
    }, 2000),
    []
  )

  const validateURL = useCallback(async (url: string) => {
    if (!validURLRegex.test(url)) {
      return
    }
    try {
      setRecommendedKeys([])
      const response: AxiosResponseData = await getRequest(
        `${appConfig.API_BASEL_URL}/marketplace/available/${url}`
      )

      if (
        response &&
        response.data.data &&
        response.data.data.isMarketplaceAvailable
      ) {
        toast(
          <CustomToast type="success" text={`Congrats ${url} is available!`} />
        )

        setValidURL(true)
        setTimeout(() => {
          setIsLoading(false)
        }, 4000)
      } else {
        if (response?.data?.data?.recommendedKeys) {
          setRecommendedKeys(response.data.data.recommendedKeys)
        }

        setValidURL(false)
        setIsLoading(true)
        throw new Error('Sorry this name is taken. Try again.')
      }
    } catch (error: any) {
      setIsLoading(true)
      const errorMessage =
        error?.response?.data?.error ?? error.message ?? 'Error with Submission'
      toast(<CustomToast type="error" text={errorMessage} />)
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)
    const retrievedValue = getValueFromNestedObject(formData, name)
    const tempURL =
      retrievedValue !== null
        ? retrievedValue
        : formData.marketplace.name
        ? codifyStr(formData.marketplace.name)
        : ''

    setMarketPlaceURL(tempURL)

    if (validURLRegex.test(tempURL)) {
      setValidURL(true)
      setValue(name, tempURL)
      validateURLDebounced(tempURL, isValidURL)
      updateFormState(setFormData, name, tempURL)
    } else {
      setValue(name, tempURL)
      setValidURL(false)
      setIsLoading(true)
      toast(
        <CustomToast
          type="error"
          text={`${tempURL || marketPlaceURL} is not a valid URL`}
        />
      )
    }
  }, [])

  const { errors } = formState
  const error = errors[name]
  const fieldError = error as FieldErrorProps

  const onClick = () => {
    setFocused(true)
    inputRef.current?.focus()
  }

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (typingTimeout) clearTimeout(typingTimeout)
    setTypingTimeout(
      setTimeout(() => {
        if (!validURLRegex.test(marketPlaceURL)) {
          return
        } else if (isMobile()) {
          inputRef.current?.blur()
        }
      }, 1000)
    )
  }

  const handleChange = (currentValue: string) => {
    setValidURL(false)
    setIsLoading(true)
    if (validateURLDebounced) {
      validateURLDebounced.cancel()
    }
    if (checkForProfanity(currentValue)) {
      setError(name, { type: 'manual', message: profanityErrorStr })
      setValidURL(false)
      return
    }
    const isValid = validURLRegex.test(currentValue)
    setValue(name, currentValue)
    trigger(name)

    if (!isValid || !validURLRegex.test(currentValue)) {
      setError(name, {
        type: 'manual',
        message: 'Your Store Key should be all lowercase and contain no spaces'
      })
      setValidURL(false)
      setMarketPlaceURL('')
      updateFormState(setFormData, name, currentValue)
      return
    }

    setValidURL(true)
    clearErrors(name)

    setMarketPlaceURL(currentValue)
    const formValues = getValues()
    updateFormState(setFormData, name, currentValue, formValues)
    validateURLDebounced(currentValue, isValidURL)
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.currentTarget.value.trim()
    handleChange(currentValue)
  }

  const setSuggestedUrl = (value: string) => {
    if (inputRef.current) {
      inputRef.current.value = value
    }
    handleChange(value)
  }

  return (
    <>
      <div
        className={classNames(
          `col-${colW}`,
          styles.URLField,
          styles.formGroup,
          className
        )}
        onClick={onClick}
      >
        <label className={focused ? styles.labelFocused : ''}>
          {label}
          {required && <span className={styles.requiredAsterix}>*</span>}
        </label>
        <div
          className={classNames(
            styles.urlInputContainer,
            isValidURL ? '' : styles.urlInputError
          )}
        >
          <span className={styles.prefix}>www.videoshops.com/</span>
          <input
            {...register(name)}
            ref={inputRef}
            className={`form-control ${errors[name] && styles.invalid}`}
            name={name}
            defaultValue={activeValue}
            type={type}
            onKeyUp={onKeyUp}
            onChange={onChange}
          />
        </div>
        <URLSuggestions
          isValidURL={isValidURL}
          values={recommendedKeys}
          setUrl={setSuggestedUrl}
        />
      </div>
    </>
  )
}

export default URLField
