import classNames from 'classnames'
import { useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styles from '../styles.module.scss'

export default function ContactTextArea({
  label,
  colW,
  name,
  placeholder,
  className,
  required,
  helpText,
  maxCount
}: FormElementProps) {
  const [focused, setFocused] = useState(false)
  const [currentLength, setCurrentLength] = useState(0)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const { trigger, formState, setValue, register, getValues } = useFormContext()
  const { errors } = formState
  const error = errors[name]
  const fieldError = error as FieldErrorProps
  const activeValue = getValues(name)

  const handleBlur = () => {
    if (textAreaRef.current?.value) {
      return
    }
    setFocused(false)
  }

  const onClick = () => {
    setFocused(true)
    textAreaRef.current?.focus()
  }

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const currentValue = e.currentTarget.value.trim()
    setValue(name, currentValue === '' ? null : currentValue)
    trigger(name)
    setCurrentLength(currentValue.length)
  }

  return (
    <>
      <div
        className={classNames(
          `col-${colW}`,
          styles.formGroup,
          styles.textAreaWrapper,
          className,
          focused && styles.focusedWrapper
        )}
        onClick={onClick}
      >
        <label>
          {label}
          {required && <span className="required-asterix">*</span>}
        </label>
        <textarea
          {...register(name)}
          ref={textAreaRef}
          className={`form-control ${errors[name] && styles.invalid}`}
          name={name}
          maxLength={maxCount && maxCount}
          defaultValue={activeValue}
          onChange={onChange}
          onFocus={() => setFocused(true)}
          onBlur={handleBlur}
          placeholder={placeholder}
        />
        {maxCount && (
          <span className={styles.charCount}>
            <span className={styles.currentLength}>{currentLength}</span> /{' '}
            {maxCount}
          </span>
        )}

        <div className={styles.fieldErrors}>
          {helpText && <p>{helpText}</p>}
          {fieldError?.message && <p>{fieldError.message}</p>}
        </div>
      </div>
    </>
  )
}
