import { FC, useState } from 'react'
import styles from './styles.module.scss'
import Button from '../Button/Button'
import { useStaticPageContext } from '../../context/StaticPageContext'
import { SiteWideTypes } from '@/types/SiteContext.interface'
import classNames from 'classnames'
import { isIOS } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@/routes/routes'

const MarketPlaceInput: FC = () => {
  const { siteType } = useStaticPageContext() as SiteWideTypes
  const navigate = useNavigate()
  const placeHolderName =
    siteType === 'brand'
      ? 'Enter Your Brand Name'
      : 'Enter Your VideoShops Name'
  const [name, setName] = useState('')
  const [showPlaceholder, setShowPlaceholder] = useState(true)

  const handleSubmit = () => {
    localStorage.removeItem('formData')
    localStorage.removeItem('currentStep')
    localStorage.removeItem('TOKEN')
    const formData = {
      data: {
        marketplace: {
          name: name.trim()
        }
      }
    }
    localStorage.setItem('formData', JSON.stringify(formData))
    const siteTypeOnBoardingURL =
      siteType === 'people' ? APP_ROUTES.CREATOR_SETUP : APP_ROUTES.BRAND_SETUP
    navigate(siteTypeOnBoardingURL)
  }

  const styleClass = classNames({
    [styles.newMarketInputWrapper]: true,
    [styles.isIOS]: isIOS
  })

  const onFocus = () => {
    setShowPlaceholder(false)
  }
  const onBlur = () => {
    setShowPlaceholder(true)
  }

  return (
    <>
      <div className={styleClass}>
        <div className={styles.newMarketInput}>
          {/* <span className={styles.prefix}>videoshops.com/</span> */}
          <input
            type="text"
            placeholder={showPlaceholder ? placeHolderName : undefined}
            value={name}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <Button children="Let's Go" onClick={handleSubmit} primary />
      </div>
    </>
  )
}

export default MarketPlaceInput
