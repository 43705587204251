import styled from 'styled-components'

export const StyledHeroDetails = styled.div`
  display: grid;
  grid-template-rows: 1.75rem 1fr 1.75rem;
  gap: 0.5rem;
  align-items: center;
  flex: 1;

  .hero-header-actions {
    justify-self: flex-end;
  }

  .hero-details-content {
    max-inline-size: 22.3125rem;

    .shop-products {
      inline-size: min(18.5rem, 100%);
      block-size: auto;
      display: block;
      margin-inline-start: 1rem;
      margin-block-end: 1rem;
    }

    .storefront-name {
      font-size: 1.875rem;
      font-weight: 700;
      line-height: 1.625rem;
      letter-spacing: -0.05625rem;
      margin-inline-start: 1.375rem;
      margin-block-end: 1rem;
    }

    p {
      font-size: 0.625rem;
      font-style: normal;
      font-weight: 700;
      line-height: 0.875rem;
      text-transform: uppercase;
      margin-inline-start: 1.375rem;
      color: inherit;
      word-wrap: break-word;
    }
  }

  .hero-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    .logo svg {
      inline-size: 6rem;
      block-size: initial;
      display: block;
    }
  }

  &.small {
    padding: 1rem;
    position: relative;
    display: block;

    /* grid-template-rows: 0.75rem 1fr 0.75rem; */

    .hero-details-content {
      max-inline-size: initial;
      text-align: center;
      margin: 0;

      .shop-products {
        inline-size: min(11.62rem, 100%);
        margin-inline: auto;
        margin-block-end: 0.5rem;
      }

      .storefront-name {
        margin: 0 0 0.5rem 0;
        text-align: center;
        font-size: 1.375rem;
        font-weight: 700;
        letter-spacing: -0.04125rem;
      }

      p {
        font-size: 0.625rem;
        font-weight: 700;
        line-height: 0.875rem;
        text-transform: initial;
        margin: 0 auto;
        max-inline-size: 48ch;
        margin-block-end: 0.5rem;
      }
    }

    .hero-footer {
      justify-content: flex-end;
    }
  }

  @media (max-width: 768px) {
    padding: 1rem;
    position: relative;
    display: block;

    .hero-header-actions {
      position: absolute;
      top: 0;
      right: 1rem;
    }

    .hero-details-content {
      max-inline-size: initial;
      text-align: center;
      margin: 0;

      .shop-products {
        inline-size: min(11.62rem, 100%);
        margin-inline: auto;
        margin-block-end: 0.5rem;
      }

      .storefront-name {
        margin: 0 0 0.5rem 0;
        text-align: center;
        font-size: 1.375rem;
        font-weight: 700;
        letter-spacing: -0.04125rem;
      }

      p {
        font-size: 0.625rem;
        font-weight: 700;
        line-height: 0.875rem;
        text-transform: initial;
        margin: 0 auto;
        max-inline-size: 48ch;
        margin-block-end: 0.5rem;
      }
    }
  }
`
