import { FC } from 'react'
import styles from '../../styles.module.scss'
import URLField from '@/components/Form/Fields/URLField/URLField'
import { Link } from 'react-router-dom'
import CheckBox from '@/components/Form/Fields/CheckBox/CheckBox'
import { useOnboardingContext } from '@/hooks/useOnboardingForm'

const MarketPlaceURL: FC = () => {
  const { onBoardingTitleBranding } = useOnboardingContext()
  const titleBrand =
    onBoardingTitleBranding === 'Brand' ? 'Brand Directory' : 'Personal Seller'

  return (
    <div className={styles.stepContainer}>
      <h1>
        This is Your{' '}
        <span className="vs-primary-color">{''}VideoShops Link</span>
      </h1>
      <div>
        <URLField
          colW={12}
          label={`YOUR VideoShops link`}
          name="key"
          type="text"
          // helpText="Your VideoShop link is permanent and cannot be changed"
          required
        />
        {/* <CheckBox name="terms">
          I agree to Videoshops{' '}
          <Link className="reg-link" to={''}>
            Terms of Use
          </Link>
          ,{' '}
          <Link className="reg-link" to={''}>
            Privacy Policy
          </Link>
          , and{' '}
          <Link className="reg-link" to={''}>
            {titleBrand} Agreemant
          </Link>
          .
        </CheckBox> */}
      </div>
    </div>
  )
}

export default MarketPlaceURL
